<template>
  <form class="new-pay3__lesson-form" ref="lessonForm">
    <div class="new-pay3__form-content">
      <h2 class="new-pay3__title">19.9元抢</h2>
      <div class="new-pay3__form-basic-info">
        <div class="new-pay3__form-item new-pay3__grade-subject">
          <select
            class="new-pay3__form-item--content new-pay3__grade"
            v-model="weakGrade"
            ref="grade"
          >
            <option
              :value="value"
              v-for="{ label, value } in subjectGrade"
              :key="value"
            >
              {{ label }}
            </option>
          </select>
          <select
            class="new-pay3__form-item--content new-pay3__subject"
            v-model="weakSubject"
            ref="subject"
          >
            <option
              :value="value"
              v-for="{ label, value } in weakSubjectArr"
              :key="value"
            >
              {{ label }}
            </option>
          </select>
        </div>

        <div class="new-pay3__form-item">
          <input
            type="text"
            placeholder="请输入您孩子的姓名"
            class="new-pay3__form-item--content new-pay3__username"
            :class="{ alert: userNameAlert }"
            v-model="userName"
          />
        </div>
        <div class="new-pay3__form-item">
          <!-- <span class="area-code">+86</span> -->
          <input
            type="number"
            placeholder="请输入您的手机号码"
            :class="{ alert: mobileAlert }"
            class="new-pay3__tel new-pay3__form-item--content"
            v-model="mobile"
            maxlength="11"
          />
        </div>
        <div class="new-pay3__form-item">
          <input
            type="number"
            v-model="codeNumber"
            placeholder="请输入验证码"
            class="new-pay3__codeNumber new-pay3__form-item--content"
          />
          <div class="new-pay3__send-code" @click.stop="initGeetest">
            {{ codeCountDown }}
          </div>
        </div>
      </div>
    </div>

    <div class="new-pay3__form-protocol">
      <img
        :src="getCheckedIcon"
        @click="checkProtocol = !checkProtocol"
        class="new-pay3__checkoutImg"
        alt
      />
      <div>
        <span
          @click="checkProtocol = !checkProtocol"
          class="new-pay3__form-protocol--text"
          >同意我们的</span
        >
        <span
          @click.stop="showProtocol = true"
          class="new-pay3__form-protocol--high-light"
          >《用户隐私条款》</span
        >
      </div>
    </div>
    <div class="new-pay3__info">
      <img
        class="new-pay3__lock"
        src="https://web-data.zmlearn.com/image/vRuqLVCMvKdxPdhcoQBoCv/%E5%BD%A2%E7%8A%B6.png"
      />
      <span>为了您的权益，您的隐私将被严格保密</span>
    </div>

    <div class="new-pay3__zm-btn" @click="handleSubmitBtn()"></div>

    <div class="new-pay3__warn">
      <p>特价产品，一经购买不予退款</p>
      <p>购买成功后，会有课程顾问与您联系</p>
    </div>

    <div :class="{ 'mask-box': true }" v-if="showMask">
      <div class="verification-box">
        <img
          src="https://web-data.zmlearn.com/image/vRuqLVCMvKdxPdhcoQBoCv/%E5%BD%A2%E7%8A%B6.png"
          @click="hideMask()"
          class="closeIcon"
          alt
        />
        <div class="phone-text">
          验证码已发送至{{ mobile | filterPhoneNumber }}
        </div>
        <div class="code-box">
          <input type="number" v-model="codeNumber" class="codeNumber" />
          <div class="send-code" @click.stop="initGeetest">
            {{ codeCountDown }}
          </div>
        </div>
        <div class="verification-btn" @click="checkBtn()">
          最后一步，立即领取
        </div>
        <div class="bottom-title">领取后规划师会与您电话沟通课程详情</div>
      </div>
    </div>

    <protocol :showProtocol.sync="showProtocol"></protocol>
    <fail-dialog ref="failDialog"></fail-dialog>
  </form>
</template>

<script>
import api from "@/api.js";
import cookies from 'js-cookie';
import {
  request,
  queryObj,
  isEmptyObject,
} from "@/utils/util";
import urlConfig from "@/config";
import Protocol from "@/components/lesson-form/protocol";
import failDialog from "@/components/fail-dialog/fail-dialog";
import Toast from "@/base/toast/toast";
import Geetest from "@/utils/geetest/kidsApiGeetest"; // 极验代码
import {
  mapState
} from "vuex";


const FROM_DICT = {

  "3": {
    trigger_site: "弹窗立即购买",
    event_id: "click_kid_act_19.9_ljgm02",
    event_name: "点击|少儿|官网m端|弹窗立即购买",
    position: "弹窗立即购买",
  },
};

export default {
  name: "lesson-form",
  components: {
    failDialog,
    Protocol,
  },
  data () {
    return {
      getCode: "获取验证码",
      codeCountDownNum: 60,
      codeCountSubmitNum: 3,
      vertifyOnOff: true, // 获取验证码按钮是否可点击
      userName: "",
      weakGrade: "PRIMARY_SCHOOL_3",
      weakSubject: "CHINESE_LOGIC",
      mobile: "",
      userNameAlert: false,
      mobileAlert: false,
      checkProtocol: true,
      showProtocol: false,
      open_id: "",
      showMask: false,
      codeNumber: "",
      changMobile: false,
      subjectGrade: [
        { "label": "幼儿园中班", "value": "KINDERGARTEN_2" },
        { "label": "幼儿园大班", "value": "KINDERGARTEN_3" },
        { "label": "小一", "value": "PRIMARY_SCHOOL_1" },
        { "label": "小二", "value": "PRIMARY_SCHOOL_2" },
        { "label": "小三", "value": "PRIMARY_SCHOOL_3" }
      ],
      weakSubjectArr: [
        { "label": "语文思维训练", "value": "CHINESE_LOGIC" },
        { "label": "数理逻辑训练", "value": "MATH_LOGIC" }
      ],
      iconCheckState: {
        checked: 'https://web-data.zmlearn.com/image/ut7ZfqyGo5aSiWmLPWCdCr/%E7%BC%96%E7%BB%84%205.png',
        unchecked: 'https://web-data.zmlearn.com/image/7cCwbkGdtrLweuC3eEEHSw/%E6%A4%AD%E5%9C%86%E5%BD%A2.png'
      },
      isSubmitting: false,
      latitude: '',
      longitude: ''

    };
  },
  filters: {
    filterPhoneNumber (number) {
      if (number.length === 11) {
        let pat = /(\d{3})\d*(\d{4})/;
        return (number = number.replace(pat, "$1****$2"));
      }
    },
  },
  watch: {
    userName () {
      this.userNameAlert = false;
    },
    mobile () {
      this.mobileAlert = false;
      this.changMobile = true;
    },
  },
  created () {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((res) => {
        this.latitude = String(res.coords.latitude)
        this.longitude = String(res.coords.longitude)
      })
    }
  },

  mounted () {

    this.open_id = this.urlQueryObj.open_id;
    // this.isWX = navigator.userAgent.toLowerCase().match(/MicroMessenger/i) === "micromessenger";
  },
  computed: {
    ...mapState([
      "subjectToLabel",
      "urlQueryObj",
      "subjectData"
    ]),
    codeCountDown () {
      if (this.codeCountDownNum === 60 || this.codeCountDownNum <= 0) {
        return this.getCode;
      } else {
        return this.codeCountDownNum + "s";
      }
    },
    getCheckedIcon () {
      const { checked, unchecked } = this.iconCheckState;
      return this.checkProtocol && checked || unchecked;
    },
  },

  methods: {
    hideMask () {
      this.showMask = false;
    },
    // 验证码发送成功并开始倒计时
    codeSendSuccess () {
      Toast("验证码发送成功");
      const timer = setInterval(() => {
        this.codeCountDownNum--;
        if (this.codeCountDownNum <= 0 || this.changMobile) {
          clearInterval(timer);
          this.vertifyOnOff = true;
          this.codeCountDownNum = 60;
          this.codeNumber = "";
        }
      }, 1000);
    },
        // 极验
    async initGeetest () {
      if (!/^\d+$/.test(this.mobile)) {
        return Toast("请输入手机号");
      }
      if (!/^\d{11}$/.test(this.mobile)) {
        return Toast("请填写正确手机号");
      }
      const captchaObj = await Geetest.request({
        product: "bind",
        width: "320px",
      });
      this.captchaObj = captchaObj;
      captchaObj
        .onReady(() => {
          setTimeout(() => {
            this.captchaObj.verify();
          }, 100);
        })
        .onSuccess(async () => {
          try {
            const result = captchaObj.getValidate();
            if (!result) {
              return Toast("请完成验证");
            }
            const data = {
              areaCode: '86',
              mobile: this.mobile,
              geetestChallenge: result.geetest_challenge,
              geetestValidate: result.geetest_validate,
              geetestSeccode: result.geetest_seccode,
              codeType: 4,
            };
            await this.identifySubmitBtn(data);
          } catch (e) {
            Toast(e.message);
            this.captchaObj = captchaObj;
          } finally {
            captchaObj.destroy();
          }
        })
        .onError(() => {
          Toast({
            message: "您刷新太多次了, 故关闭验证弹框, 请从新验证",
            duration: 2000,
          });
          captchaObj.destroy();
          this.captchaObj = captchaObj;
        })
        .onClose(() => {
          captchaObj.destroy();
          this.captchaObj = captchaObj;
        });
    },
    // 发送验证码 验证码发送并倒计时
    async identifySubmitBtn (data) {
      if (this.vertifyOnOff) {
        this.vertifyOnOff = false;
        try {
          // const data = { mobile: this.mobile, codeType: 4, areaCode: '86' };
          const { data: { code, message } } = await api.getIdentityCode(data);
          if (code * 1 === 0) {
            this.changMobile = false;
            this.codeSendSuccess();
          } else {
            this.vertifyOnOff = true;
            Toast(message || "验证码发送失败，请稍后再试");
          }
        } catch (e) {
          this.vertifyOnOff = true;
          Toast(e);
        }
      }
    },

    // 验证表单
    validateForm () {
      return new Promise((resolve, reject) => {
        if (!/^([a-zA-Z]|[\u4e00-\u9fa5]){1,}$/.test(this.userName)) {
          this.userNameAlert = true;
          reject("请填写正确的孩子姓名");
        } else if (this.userName.length >= 7) {
          this.userNameAlert = true;
          reject("请填写正确的孩子姓名");
        } else if (this.mobile.length === 0) {
          this.mobileAlert = true;
          reject("请输入手机号");
        } else if (!/^\d{11}$/.test(this.mobile)) {
          this.mobileAlert = true;
          reject("请填写正确手机号");
        } else if (
          !/^\d+$/.test(this.codeNumber)
        ) {
          reject("请输入正确的验证码");
        } else if (!this.checkProtocol) {
          reject("请同意用户隐私协议");
        } else {
          resolve("success");
        }
      });
    },

    // 埋点
    doTrack (position, params) {
      if (ZM_JSSDK) {
        let eventData = {
          eventId: position.event_id,
          eventParam: {
            name: position.event_name,
            grade: this.weakGrade,
            subject: this.weakSubject,
            trigger_site: position.trigger_site,
            position: position.position,
            url: location.href,
            ...(params || {})
          },
        };
        ZM_JSSDK.sendEvent(eventData);
      }
    },

    // 验证表单并注册
    async handleSubmitBtn () {
      if (this.isSubmitting) { return; }
      this.isSubmitting = true;
      this.doTrack(FROM_DICT[3]);
      try {
        await this.validateForm();
        await this.doRegister();
      } catch (e) {
        Toast(e);
      } finally {

        this.isSubmitting = false;
      }
    },

    // 注册并跳转支付
    async doRegister () {
      try {
        const { channel_code = '', channel_keyword = '' } = this.$route.query;
        const data = {
          account: this.mobile,
          areaCode: "86",
          code: channel_code,
          credentials: this.codeNumber,
          key: channel_keyword,
          loginTime: 0,
          verifyType: 3
        };
        const res = await api.kidsLogin(data);
        const { data: { code, message } } = res || {};
        if (code * 1 === 0) {
          console.log('doRegister =====> success');
          const { data: { data: { accessToken } } } = res;
          cookies.set('accessToken', accessToken);
          if (accessToken) {
            api.recordData({
              code: channel_code,
              key: channel_keyword,
              latitude: this.latitude,
              longitude: this.longitude,
              source: /micromessenger/i.test(window.navigator.userAgent) ? 'wx' : 'h5'
            })
          }

          this.submitAdData(accessToken);
          console.log('doRegister =====> end');
        } else {
          Toast(message);
        }
        console.log('doRegister code =====> ', code, message);
      } catch (e) {
        Toast(e);
        console.log('doRegister error =====> ', JSON.stringify(e));
      }
    },

    // 跳转支付
    goToPay (token, code, key) {
      console.log('go to pay =====> redirect');
      const redirectUrl = encodeURIComponent(location.href + '&paid=1');
      // window.location.href = `${urlConfig.zminet}/learningCenter/wechatAuth?redirect_url=${redirectUrl}`
      window.location.href = `${urlConfig.marketingZmtest}/equity_sell?bu=2&saleId=${urlConfig.newPay3SaleId}&channel_code=${code || 1}&channel_keyword=${key || 1}&routeName=orderConfirm&token=${token}&redirect_url=${redirectUrl}`;
    },

    // QQ广点通
    // 微信朋友圈
    async submitAdData (accessToken) {
      let commonRes = {};
      try {
        const { gdt_vid = '', channel_code = '', qz_gdt = '' } = this.$route.query;
        const params = {
          actionType: "RESERVATION", // RESERVATION-注册 COMPLETE_ORDER-下单
          clickId: qz_gdt || gdt_vid,
          source: channel_code,
          url: location.href,
          value: 0
        };
        if (gdt_vid) {
          let weChatData = params;
          if (!channel_code) {
            weChatData = { ...params, source: '21035' }
          }
          try {
            const res = await api.submitDataForWeChatAdData(weChatData);
            commonRes = res;
            await this.doTrack(FROM_DICT[3], { position: 'weChat request ended' });
            console.log('ad wx ======> RESERVATION', JSON.stringify(res));
          } catch (e) {
            console.log('ad wx error======> RESERVATION', JSON.stringify(e));
          }

        } else {
          let qqData = params;
          if (!channel_code) {
            qqData = { ...params, source: '21020' };
          }
          try {
            const res = await api.submitDataForGDT(qqData);
            commonRes = res;
            await this.doTrack(FROM_DICT[3], { position: 'qq request ended' });
            console.log('ad qq ======> RESERVATION', JSON.stringify(res));
          } catch (e) {
            console.log('ad qq error======> RESERVATION', JSON.stringify(e));
          }

        }
      } catch (e) {
        console.log(JSON.stringify(e));
      } finally {
        console.log('================>');
        console.log(JSON.stringify(commonRes.data));
        console.log('<==================');
        console.log('go to pay =====> RESERVATION');
        const { channel_code = '', channel_keyword = '' } = this.$route.query;
        this.goToPay(accessToken, channel_code, channel_keyword);
      }
    },

  },
};
</script>

<style lang="scss" scoped>
$theme: #ff6e35;
$fz-protocol: 22px;
$bgColor: #fff;
$black0: #000;
$black3: #333;
$black6: #666;
$black9: #999;
$space: 14px;
@mixin utils-flex(
  $align-item: center,
  $justify-content: center,
  $direction: row,
  $wrap: nowrap
) {
  display: flex;
  align-items: $align-item;
  justify-content: $justify-content;
  flex-flow: $direction $wrap;
}
.new-pay3 {
  &__lesson-form {
    width: 660px;
    background: #fff;
    border-radius: 32px;
  }
  &__form-item {
    width: 100%;
    padding: 0 32px 16px;
    box-sizing: border-box;
    @include utils-flex;
    &--content {
      width: 100%;
      height: 86px;
      padding: 0 14px;
      border-radius: 40px;
      border: 1px solid $theme;
      box-sizing: border-box;
    }
  }
  &__grade-subject {
    justify-content: space-between;
  }
  &__grade,
  &__subject {
    position: relative;
    width: 48%;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url('https://web-data.zmlearn.com/image/8kzGTUynStSU5ZM1u8uSqm/arrow_03.png')
      no-repeat 90% center transparent;
    background-size: 26px 16px;
    background-color: #ffffff;
    padding-right: 14px;
  }
  &__title {
    font-size: 55px;
    padding: 40px;
    color: $theme;
  }
  &__send-code {
    position: absolute;
    right: 60px;
    font-size: 28px;
    color: $theme;
  }
  &__form-protocol {
    @include utils-flex;
    margin-top: 20px;
    &--text {
      font-size: $fz-protocol;
      color: #2b2b2b;
    }
    &--high-light {
      font-size: $fz-protocol;
    }
    &--high-light {
      color: $theme;
    }
  }
  &__checkoutImg {
    width: 26px;
    margin-right: $space;
  }
  &__lock {
    width: 18px;
    margin-right: $space;
  }
  &__zm-btn {
    width: 602px;
    height: 103px;
    margin: 32px auto;
    background: url('https://web-data.zmlearn.com/image/wNwqqyD2TZigU3X573wnha/btn_03.png')
      center center no-repeat;
    background-size: 100% 100%;
  }
  &__warn {
    padding-bottom: 30px;
  }
}
</style>
