<template>
  <transition name="fade">
    <div :class="position" class="toast" v-show="visible">
      <i>{{ message }}</i>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Toast',
  data () {
    return {
      visible: false,
      message: '',
      position: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.toast {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  word-wrap: break-word;
  padding: 12px;
  text-align: center;
  z-index: 99999;
  font-size: 24px;
  max-width: 80%;
  color: #fff;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  i {
    font-style: normal;
  }
  &.middle {
    top: 50%;
  }
  &.bottom {
    top: 90%;
  }
  &.top {
    top: 10%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: transform 0.4s;
}

.fade-enter,
.fade-leave-active {
  transform: translate(-50%, -50%) scale(0);
}
</style>
