<template>
  <div>
    <template v-if="type === 'math'">
      <header class="header">
        <img src="https://web-data.zmlearn.com/image/xzG5t922AvM7kJ2aXEVCxx/6.png" class="head-img track-module" v-if="!offline"/>
        <img src="https://web-data.zmlearn.com/image/vNGDxqBihUAa33oX3dp254/%E7%94%BB%E6%9D%BF%206.png" class="head-img track-module" v-else/>
        <lesson-form
          :type="type"
          :extraClass="extraClass"
          :point="point"
        ></lesson-form>
        <!-- <img
          class="receive"
          src="https://static-app-manager.zmlearn.com/WRE4Z84j7270.png"
          @click="showForm('1', 'click_kid_act13_ljlq')"
          alt=""
        /> -->
      </header>
      <img src="https://web-data.zmlearn.com/image/wnnxDFpBVJksMpiRnyWTxY/banner.png" alt="" style="background-size: contain;
    width: 94vw;
    margin-top: 2.4vw;
    box-sizing: border-box;" v-if="offline">
      <img
        class="main main-1 track-module"
        src="https://web-data.zmlearn.com/image/cyXjCpB6vY94XGHJc96Xs8/NWVtO3v-7375.png"
        alt=""
      />
      <img
        class="main track-module"
        src="https://static-app-manager.zmlearn.com/8uPjlohU1069.png"
        alt=""
      />
      <div class="system-wrap track-module">
        <div class="tab-wrap">
          <a
            href="javascript:;"
            @click="changeBanner(item - 1)"
            v-for="item in 5"
            :key="item"
          ></a>
        </div>
        <img
          class="sysyem-item"
          v-show="curBanner === index"
          v-for="(item, index) in bannerUrl"
          :key="item"
          :src="item"
          alt=""
        />
        <img
          class="main"
          src="https://web-data.zmlearn.com/image/ctWtwXQkWdw2zAfsuVDrWx/course-system.png"
          alt=""
        />
      </div>
      <img
        class="main track-module"
        src="https://web-data.zmlearn.com/image/waxoWznsLQSKwugsTrXBJW/pic.png"
        alt=""
      />
      <!-- <img
        class="main track-module"
        src="https://static-app-manager.zmlearn.com/rQ7LWGcG7891.png"
        alt=""
      /> -->
      <a
        class="footer"
        @click="showForm('2', 'click_kid_act13_ljlq')"
        href="javscript:;"
      >
        <img
          src="https://web-data.zmlearn.com/image/osv2D5SXBxBupXRNHZ8Buo/aWO2kR382554.png"
          alt=""
        />
      </a>
    </template>
    <template v-else>
      <header class="header">
        <img
          class="head-img track-module"
          src="https://web-data.zmlearn.com/image/9s1PEytdSvey9VsNBxetuA/chineselanding.png"
          alt=""
        />
        <lesson-form
          :type="type"
          :extraClass="extraClass"
          :point="point"
        ></lesson-form>
        <!-- <img
          class="receive"
          src="https://static-app-manager.zmlearn.com/E_AUqQZ31638.png"
          @click="showForm('1', 'click_kid_act14_ljlq')"
          alt=""
        /> -->
      </header>

      <img
        class="main main-1 track-module"
        src="https://static-app-manager.zmlearn.com/ccYRSikk9985.png"
        alt=""
      />
      <img
        class="main track-module"
        src="https://static-app-manager.zmlearn.com/vXMmeqSv6244.png"
        alt=""
      />
      <img
        class="main track-module"
        src="https://static-app-manager.zmlearn.com/dBSoD49B3396.png"
        alt=""
      />
      <img
        class="main track-module"
        src="https://static-app-manager.zmlearn.com/4b6oqmkc2803.png"
        alt=""
      />
      <img
        class="main track-module"
        src="https://static-app-manager.zmlearn.com/rQ7LWGcG7891.png"
        alt=""
      />
      <a
        class="footer"
        @click="showForm('2', 'click_kid_act14_ljlq')"
        href="javscript:;"
      >
        <img
          src="https://static-app-manager.zmlearn.com/bu5RcTNL6278.png"
          alt=""
        />
      </a>
    </template>

    <div class="dialog-form" v-show="showDialogForm">
      <div class="lesson-form-content">
        <div class="lesson-form-box">
          <lesson-form :type="type"></lesson-form>
          <img
            alt="close"
            class="dialog-form-close"
            src="https://web-data.zmlearn.com/image/pMeJFjz6QKFohpr9b4wPSH/icon_close.png"
            @click="showDialogForm = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lessonForm from "./lesson-form";
import api from '@/api.js'
export default {
  components: {
    lessonForm,
  },
  props: {
    type: String,
    default: 'math'
  },
  data () {
    return {
      curBanner: 0,
      showDialogForm: false,
      isFirst: true,
      bannerUrl: [
        "https://static-app-manager.zmlearn.com/z2k23vjU1732.png",
        "https://static-app-manager.zmlearn.com/uTlYylfJ5458.png",
        "https://static-app-manager.zmlearn.com/E5BvNjXS4955.png",
        "https://static-app-manager.zmlearn.com/tS7dOvhN4979.png",
        "https://static-app-manager.zmlearn.com/_zWqbfLj5796.png",
      ],
      extraClass: true,
      point: true,
      offline:false
    };
  },
  mounted () {
    api.getinfo({
        "actionName": "info",
        "activityId": 3703,
        "engineName": "common-driver"
    }).then(res => {
        if(res.data.data.params.startTimeStamp <= res.data.data.params.currentTimeStamp && res.data.data.params.currentTimeStamp <= res.data.data.params.endTimeStamp){
            this.offline =true
        }
    });
    const modules = [...document.querySelectorAll('.track-module')];
    const observe = new IntersectionObserver(item => {
      if (this.isFirst) {
        this.isFirst = false;
        ZM_JSSDK &&
          ZM_JSSDK.sendEvent({
            eventId: this.type === 'chinese' ? 'watch_kid_act14_piece' : 'watch_kid_act13_piece',
            eventParam: {
              num: String(1)
            }
          });
      } else {
        item.forEach(element => {
          if (element.isIntersecting) {
            ZM_JSSDK &&
              ZM_JSSDK.sendEvent({
                eventId: this.type === 'chinese' ? 'watch_kid_act14_piece' : 'watch_kid_act13_piece',
                eventParam: {
                  num: String(modules.indexOf(element.target) + 1)
                }
              });
          }
        })
      }
    }, {
      threshold: 0.5
    });

    modules.forEach(item => {
      observe.observe(item);
    });
  },
  methods: {
    changeBanner (index) {
      this.curBanner = index;
    },
    showForm (position, eventId) {
      this.showDialogForm = true;
      ZM_JSSDK &&
        ZM_JSSDK.sendEvent({
          eventId,
          eventParam: {
            position,
            code: this.$route.query.channel_code,
          }
        });
    }
  },
};
</script>
<style lang="scss" scoped>
.header {
  position: relative;
  display: flex;
  justify-content: center;
}

.head-img {
  width: 100%;
  height: 100%;
}

.receive {
  position: absolute;
  bottom: 213px;
  left: 50%;
  width: 634px;
  height: 138px;
  transform: translateX(-50%);
}

.system-wrap {
  position: relative;

  .sysyem-item {
    position: absolute;
    top: 240px;
    left: 50%;
    width: 671px;
    height: 621px;
    transform: translateX(-50%);
  }

  .tab-wrap {
    display: flex;
    position: absolute;
    top: 240px;
    left: 50%;
    z-index: 1;
    width: 671px;
    height: 75px;
    transform: translateX(-50%);
    font-size: 0;

    a {
      width: 120px;
      height: 100%;

      + a {
        margin-left: 20px;
      }
    }
  }
}

.main {
  margin-top: 60px;
  width: 100%;
}

.main-1 {
  margin-top: 23px;
}

.dialog-form {
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
.lesson-form-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .dialog-form-close {
    margin-top: 20px;
    width: 75px;
    height: 75px;
  }
}

.footer {
  position: fixed;
  bottom: -6px;
  left: 0;
  width: 100%;

  img {
    width: 100%;
  }
}
</style>