<template>
  <div id="year-2020-double-11" class="year-2020-double-11">
    <div ref="main" class="content">
      <div class="banner" ref="header">
        <img :src="pictures.banner" alt="掌门少儿" ref="banner" />
        <v-lazy-image
          :src="pictures.courseContent"
          alt="19.9元秒杀"
          class="buy-course"
        />
        <v-lazy-image :src="pictures.learningTools" alt="赠送教具" />
        <v-lazy-image :src="pictures.exerciseBooks" alt="赠送练习册" />
        <v-lazy-image
          :src="pictures.btnToBuy"
          alt="19.9元秒杀"
          class="to-buy"
        />
        <button @click="toggleDialogForm('双11落地页免费领取', '2')"></button>
      </div>
      <div class="children">
        <v-lazy-image :src="pictures.children" />
      </div>
      <div class="course-intro">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in introList"
            :key="index"
          >
            <img :src="item" />
          </div>
        </div>
      </div>
      <div class="course-advance">
        <v-lazy-image :src="pictures.course" />
      </div>
      <div class="teacher-intro">
        <v-lazy-image :src="pictures.teacher" />
      </div>

      <div class="dialog-form" v-if="showDialogForm">
        <div class="lesson-form-content" ref="lessonFormContent">
          <div class="lesson-form-box">
            <lesson-form></lesson-form>
            <img
              alt="close"
              class="dialog-form-close"
              src="https://web-data.zmlearn.com/image/pMeJFjz6QKFohpr9b4wPSH/icon_close.png"
              @click="toggleDialogForm('双11落地页立即领取', 0)"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      ref="fixedBottom"
      :class="['get-course', fixedBottom ? 'fixed-bottom' : '']"
      @click="toggleDialogForm('双11落地页立即领取', '3')"
    >
      <v-lazy-image :src="pictures.fixBottom" alt="点击领取双11专属礼包" />
    </div>
  </div>
</template>

<script>
import api from "@/api.js";
import lessonForm from "./lesson-form";
import VLazyImage from "v-lazy-image";
import { Swiper, Pagination, Autoplay } from 'swiper';
Swiper.use([Pagination, Autoplay])
import 'swiper/swiper-bundle.css';
const FROM_DICT = {
  "1": {
    trigger_site: "进入19.9秒杀页面",
    event_id: "enter_kid_act_19.9_ms",
    event_name: "进入|少儿|官网m端|19.9立即秒杀",
    position: "19.9元秒杀首屏",
  },
  "2": {
    trigger_site: "19.9秒杀",
    event_id: "click_kid_act_19.9_ms",
    event_name: "点击|少儿|官网m端|首屏立即秒杀",
    position: "落地页首屏中19.9元秒杀",
  },
  "3": {
    trigger_site: "底部立即购买",
    event_id: "click_kid_act_19.9_ljgm01",
    event_name: "点击|少儿|官网m端|底部立即购买",
    position: "底部立即购买",
  },
  "4": {
    trigger_site: "支付完成",
    event_id: "paid_kid_act_19.9_success",
    event_name: "进入|少儿|注册落地页|支付成功",
    position: "支付成功后跳转到落地页",
  },
};

export default {
  name: "MS19_9",
  components: {
    lessonForm,
    VLazyImage
  },
  data () {
    return {
      index_form_showmsg: "预约登记后会有课程顾问与您电话联系",
      showDialog: false,
      showDialogForm: false,
      showBottomBtn: false,
      formPopType: 1,
      homepageWeekSubject: '数理逻辑训练',
      introList: [
        'https://web-data.zmlearn.com/image/4Vbdiimhp2rwU1QAVoYDd4/003-1.png',
        'https://web-data.zmlearn.com/image/nyVALVnmxwdcwQed3Qipdt/003-2.png'
      ],
      pictures: {
        banner: 'https://web-data.zmlearn.com/image/9CKdt8SJszPLJHuHsRMLiE/banner.png',
        courseContent: 'https://web-data.zmlearn.com/image/3X881zWufw1pghawyUMB8H/001.png',
        learningTools: 'https://web-data.zmlearn.com/image/eTt6HgMoiG7w6hwwjSi9L8/002.png',
        exerciseBooks: 'https://web-data.zmlearn.com/image/jDPjLNEvBmpp3ct4K37zkx/P1_04.png',
        btnToBuy: 'https://web-data.zmlearn.com/image/5CfDtT15kZuxDx1qMToNz4/003.png',
        children: 'https://web-data.zmlearn.com/image/rRA5iQ1etSocW9vdPVoAZz/002.png',
        teacher: 'https://web-data.zmlearn.com/image/jjNdXdCRexmkcycSqtNFqm/005.png',
        course: 'https://web-data.zmlearn.com/image/o5yUfJkYtEmezNhYNBHSxo/004.png',
        fixBottom: 'https://web-data.zmlearn.com/image/imHjoU3pMW9qCYCTqUr96/bottom.png'
      },
      fixedBottom: true
    };
  },
  created () {
    const { paid } = this.$route.query;
    console.log('ad page in');
    if (paid * 1 === 1) {
      console.log('ad done page in');
      this.redirectSubmitAdData();
      this.doTrack(FROM_DICT['4'])
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.checkPosition, false);
      this.checkPosition();
      this.doTrack(FROM_DICT['1']);
    });
    var mySwiper = new Swiper('.course-intro', {
      loop: true,
      autoplay: true,
    });
  },
  destroyed () {
    window.removeEventListener("scroll", this.checkPosition);
  },
  methods: {
    toggleDialogForm (type, from) {
      console.log('ad user click ');
      this.btnType = type;
      this.showDialogForm = !this.showDialogForm;
      if (!from) { return; }
      this.doTrack(FROM_DICT[from])
    },
    checkPosition () {
      const bannerHeight = this.$refs.banner.clientHeight;
      const headerHeight = this.$refs.header.clientHeight;
      const screenHeight = window.screen.availHeight;
      const scrollY = window.scrollY;
      const footerBottomY = this.$refs.main.clientHeight - screenHeight;
      this.fixedBottom = scrollY < bannerHeight || (scrollY > headerHeight && scrollY < footerBottomY - 1);
    },
    // QQ广点通
    // 微信朋友圈
    // 支付后重定向到点前页面
    async redirectSubmitAdData () {
      let commonRes = {};
      try {
        const { gdt_vid = '', channel_code = '', qz_gdt = '' } = this.$route.query;
        const params = {
          actionType: "COMPLETE_ORDER", // RESERVATION-注册 COMPLETE_ORDER-下单
          clickId: qz_gdt || gdt_vid,
          source: channel_code,
          url: location.href,
          value: 1990 // 订单价格(19.9元 === 1990分)
        };
        if (gdt_vid) {
          console.log('ad is wx COMPLETE_ORDER======>');
          let weChatData = params;
          if (!channel_code) {
            weChatData = { ...params, source: '21035' }
          }
          const res = await api.submitDataForWeChatAdData(weChatData);
          commonRes = res;
          console.log('ad wx COMPLETE_ORDER======>', JSON.stringify(res));
        } else {
          console.log('ad is qq COMPLETE_ORDER======>');
          let qqData = params;
          if (!channel_code) {
            qqData = { ...params, source: '21020' };
          }
          const res = await api.submitDataForGDT(qqData);
          commonRes = res;
          console.log('ad qq COMPLETE_ORDER======>', JSON.stringify(res));
        }
      } catch (e) {
        console.log('ad error COMPLETE_ORDER======>', JSON.stringify(e));
      } finally {
        console.log('COMPLETE_ORDER================>');
        console.log(JSON.stringify(commonRes.data));
        console.log('<==================COMPLETE_ORDER');
        console.log('go to pay =====> COMPLETE_ORDER');
      }
    },

    // 埋点
    doTrack (position) {
      if (ZM_JSSDK) {
        let eventData = {
          eventId: position.event_id,
          eventParam: {
            name: position.event_name,
            trigger_site: position.trigger_site,
            position: position.position,
            url: location.href
          },
        };
        ZM_JSSDK.sendEvent(eventData);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.year-2020-double-11 {
  position: relative;
  background-color: #fff4d5;
  .content {
    width: 750px;

    .banner {
      position: relative;
      width: 100%;
      font-size: 0;
      img {
        width: 100%;
      }
      .buy-course {
        margin-top: -140px;
      }
      button,
      .to-buy {
        position: absolute;
        left: 50%;
        bottom: 56px;
        transform: translateX(-50%);
        width: 631px;
        height: 151px;
        background: transparent;
        border: none;
      }
    }

    .banner,
    .children,
    .course-advance,
    .teacher-intro {
      font-size: 0;
      img {
        width: 100%;
      }
    }
    .course-intro {
      width: 100%;
      height: 100%;
    }
    .swiper-slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
    }
    .to-buy {
      width: 631px !important;
    }

    .children {
      margin-top: 20px;
    }
  }
  .dialog-form {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .lesson-form-content {
    position: absolute;
    width: 660px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .dialog-form-close {
      position: absolute;
      display: block;
      width: 75px;
      height: 75px;
      left: 50%;
      transform: translateX(-50%);
      bottom: -120px;
    }
  }
  .lesson-form-box {
    height: 100%;
  }
  .get-course {
    font-size: 0;
    img {
      width: 100%;
    }
    &.fixed-bottom {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
  }
}
</style>
<style lang="scss">
/*.year-2020-double-11 {*/
/*    .mask-box {*/
/*        border-radius: 32px;*/
/*    }*/
/*    .form-protocol {*/
/*        .checkoutImg {*/
/*            width: 27px;*/
/*            height: 27px;*/
/*            margin-right: 20px;*/
/*        }*/

/*        .form-protocol-text {*/
/*            .high-light {*/
/*                color: #ff6e35;*/
/*            }*/
/*        }*/
/*    }*/
/*}*/

/*

     .form-protocol {
         margin-top: 44px;

         .checkoutImg {
             width: 28px;
             height: 28px;
             margin-right: 10px;
         }

         .form-protocol-text {
             font-size: 24px;

             .high-light {
                 color: #fb5058;
             }
         }
     } */
</style>