import api from '@/api.js'
const Geetest = {
  request: async (config) => {
    return api.kidsApiGeetest().then((res) => {
      return new Promise((resolve, reject) => {
        const {  gt, challenge, success  } = res.data;
        window.initGeetest({
          api_server: "apiv6.geetest.com",
          gt,
          challenge,
          offline: !success,
          new_captcha: true,
          ...config
        }, (captchaObj) => {
          resolve(captchaObj);
        });
      });
    })
  }
};
export default Geetest;
