import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import actions from './action'
import getters from './getters'

Vue.use(Vuex)

const state: any = () => ({
  isLoading: false,
  clickIds: { //点击 id 落地页URL中的click_id，对于广点通流量为URL中的qz_gdt，对于微信流量为URL中的gdt_vid
    qz_gdt: '',
    gdt_vid: ''
  },
  subjectData: [],
  subjectDataV1: [],
  kid_subjectData: [],
  subjectGrade: [],
  subjectGradeV1: [],
  kid_subjectGrade: [],
  subjectToLabel: {},
  // gradeToLabel: {}
  urlQueryObj: {},
  overseaGrade: [],
  overseaSubject: []
})


const store: any = () =>
  new Vuex.Store({
    state,
    getters,
    actions,
    mutations
  })

export default store
