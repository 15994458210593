export class Timers {
  public static step(cb: any) {
    return new Timers().step(cb);
  }
  private opt: any;
  private startTime: any;
  private timer: any;
  constructor(options?: any) {
    const defOpt = {
      timer: null,
      startTime: 60
    };
    this.opt = {
      ...defOpt,
      ...options
    };
    this.startTime = this.opt.startTime;
    this.timer = null;
  }
  public step(cb: any, once = true): any {
    once && cb && cb(this.startTime);
    return new Promise((r: any) => {
      this.timer = setInterval(() => {
        this.startTime--;
        // console.log(this.startTime, 'this.startTime');
        if ( this.startTime > 0) {
          cb && cb(this.startTime);
        } else {
          r();
          this.timer && clearInterval(this.timer);
        }
      }, 1000);
    });
  }
  public stop() {
    clearInterval(this.timer);
  }
}