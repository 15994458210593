





























































































import { Vue, Component } from 'vue-property-decorator';
import Geetest from '@/utils/geetest/index.js'; // 极验代码
import Toast from '@/base/toast/toast.js';
import api from '@/api';
import cookie from 'js-cookie';
import config from '@/config';
import wx from 'weixin-jsapi';
import { initWechat } from '@/utils/wechat/init-wechat';
import protocol from '@/components/protocol/index.vue';
import { dictionaryGradeNew, dictionaryBaseSubject } from '@/libs/dic';
@Component({
  components: { protocol },
})
export default class BuyClassForm extends Vue {
  private checkProtocol: any = false;
  private mobile: any = '';
  private credentials: any = '';
  private areaCode: any = '86';
  private vertifyOnOff: any = true;
  private captchaObj: any = {};
  private codeCountDownNum: any = 60;
  private cutDownTime: any = 15 * 60;
  private timeTips: any = '15:00';
  private timerId: any = null;
  private timer: any = null;
  private isPaid: any = this.$route.query.subOrderNo || '';
  private gradeCode: any = this.$route.query.gradeCode || 'PRIMARY_SCHOOL_3';
  private subjectCode: any = this.$route.query.subjectCode || 'MATH_LOGIC';
  private channel_code: any = this.$route.query.channel_code;
  private channel_keyword: any = this.$route.query.channel_keyword;
  private openId: any = this.$route.query.openId;
  private isWeChat: any = '';
  private gradeArr: any = [];
  private subjectArr: any = [];
  get codeCountDown() {
    if (this.codeCountDownNum == 60 || this.codeCountDownNum <= 0) {
      return '获取验证码';
    } else {
      return this.codeCountDownNum + 's';
    }
  }
  private created() {
    this.gradeArr = dictionaryGradeNew.map((item: any) => {
      item.itemActive = false;
      return item;
    });
    this.subjectArr = dictionaryBaseSubject.map((item: any) => {
      item.itemActive = false;
      return item;
    });
  }
  private mounted() {
    console.log(process.env, 'NODE_ENV');
    this.isWeChat = window.ZM_JSSDK.os.browserName === 'WeChat';
    this.countTime();
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    this.sel('grade', this.gradeCode);
    this.sel('subject', this.subjectCode);
    initWechat({}).then(() => {
      wx.ready(wx.hideOptionMenu);
    });
    switch (this.$route.query.type) {
      case '3742':
        this.$track.sendEvent({
          eventId: 'enter_kid_act61_paypage',
          eventParam: {
            code: this.channel_code,
          },
        });
        break;
      case '3811':
        this.$track.sendEvent({
          eventId: 'enter_kid_act62_paypage',
          eventParam: {
            code: this.channel_code,
          },
        });
        break;
      case '3835':
        this.$track.sendEvent({
          eventId: 'enter_kid_act63_paypage',
          eventParam: {
            code: this.channel_code,
          },
        });
        break;
      default:
        break;
    }
  }
  private changeProtocol(val: any) {
    this.checkProtocol = val;
  }
  private getPaidStatus() {
    if (this.$route.query.subOrderNo) {
      console.log('oooo', '支付完回来');
      // &subOrderNo=1373926153420720128
      // &subOrderNo=1373189041419372544
      api
        .getOrderStatus({ subOrderNo: this.$route.query.subOrderNo })
        .then((res: any) => {
          console.log(res.data, '支付状态查询');
          console.log(res.data.data, '支付状态查询');
          if (res.data.data === 'S') {
            this.tencetApi('PURCHASE',{})
            this.$router.push({
              name: 'h5Success',
              query: { type: this.$route.query.type },
            });
          } else {
            Toast(`支付失败：${res.data.data}`);
          }
        });
      this.isPaid = false;
    }
  }
  private sel(type: any, code: any) {
    console.log(type, code)
    let arr: any = [];
    if (type === 'grade') {
      this.gradeCode = code;
      arr = this.gradeArr;
    } else {
      arr = this.subjectArr;
      this.subjectCode = code;
    }
    let _index: any = '';
    arr = arr.map((item: any, index: any) => {
      item.itemActive = false;
      if (item.value === code) {
        _index = index;
      }
      return item;
    });
    arr[_index].itemActive = true;
  }
  // 倒计时
  private countTime() {
    try {
      if (this.cutDownTime <= 0) {
        this.timeTips = '00:00';
        clearTimeout(this.timerId);
        cookie.set('end', true);
        return;
      }
      this.cutDownTime--;
      // let h: any = Math.floor(this.cutDownTime / 3600);
      let m: any = Math.floor((this.cutDownTime % 3600) / 60);
      let s: any = Math.floor((this.cutDownTime % 3600) % 60);
      // h = h < 10 ? '0' + h : h;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      this.timeTips = `${m}:${s}`;
      this.timerId = setTimeout(this.countTime, 1000);
    } catch (e) {
      console.log(e);
    }
  }
  private validatePhoneNumber() {
    if (!/^\d+$/.test(this.mobile)) {
      Toast('请输入手机号');
      return false;
    }
    if (!/^\d{11}$/.test(this.mobile)) {
      Toast('请填写正确手机号');
      return false;
    }
    return true;
  }
  // 极验
  private async initGeetest() {
    if (!this.validatePhoneNumber()) {
      return;
    }
    const captchaObj = await Geetest.request({
      product: 'bind',
      width: '320px',
    });
    this.captchaObj = captchaObj;
    captchaObj
      .onReady(() => {
        setTimeout(() => {
          this.captchaObj.verify();
        }, 100);
      })
      .onSuccess(async () => {
        try {
          const result = captchaObj.getValidate();
          if (!result) {
            return Toast('请完成验证');
          }
          const data = {
            areaCode: this.areaCode,
            mobile: this.mobile,
            geetestChallenge: result.geetest_challenge,
            geetestValidate: result.geetest_validate,
            geetestSeccode: result.geetest_seccode,
          };
          await this.identifySubmitBtn(data);
        } catch (e) {
          Toast(e.message);
          this.captchaObj = captchaObj;
        } finally {
          captchaObj.destroy();
        }
      })
      .onError(() => {
        Toast({
          message: '您刷新太多次了, 故关闭验证弹框, 请从新验证',
          duration: 2000,
        });
        captchaObj.destroy();
        this.captchaObj = captchaObj;
      })
      .onClose(() => {
        captchaObj.destroy();
        this.captchaObj = captchaObj;
      });
  }
  // 发送验证码
  private async identifySubmitBtn(data: any) {
    if (this.vertifyOnOff) {
      try {
        const res = await api.sendSmsCode(data);
        console.log(res, 'ooooo');
        if (res.data.code * 1 === 0) {
          Toast('验证码发送成功');
          const timer = setInterval(() => {
            this.codeCountDownNum--;
            if (this.codeCountDownNum <= 0) {
              clearInterval(timer);
              this.vertifyOnOff = true;
              this.codeCountDownNum = 60;
              this.credentials = '';
            }
          }, 1000);
        } else {
          this.vertifyOnOff = true;
          Toast(res.data.message || '验证码发送失败，请稍后再试');
        }
      } catch (e) {
        Toast(e);
      }
      this.vertifyOnOff = false;
    }
  }
  private async tencetApi(actionType:String,params:any) {
    const {
      clickId = '',
      type
    } = this.$route.query;

    const source = type === '3742' ? '26842' : '26843'
    return await api.tencetApi({
      actionType,
      clickId,
      url: encodeURIComponent(window.location.href),
      // value:1,
      source,
      ...params
    })
  }
  /**
   * 预约报名
   * 1.校验表单
   * 2.发送请求
   */
  private appoint() {
    console.log(process.env.VUE_APP_SERVICE_M, 'process.env.VUE_APP_SERVICE_M');
    let appointParams = {
      activityCode: this.$route.query.type,
      areaCode: this.areaCode,
      mobile: this.mobile,
      stuGradeCode: this.gradeCode,
      weakSubjectCode: this.subjectCode,
      credentials: this.credentials,
      code: this.channel_code,
      key: this.channel_keyword,
      openId: this.openId,
      pageSource: this.isWeChat ? 'officialAccount' : 'webPc',
      appId: config.kidAppId,
      name: '掌小萌',
    };
    api
      .appoint2(appointParams)
      .then((res: any) => {
        console.log(res, '注册接口');
        if (res.code === '0') {
          this.tencetApi('REGISTER',{})
          console.log(res, res.data, 'res');
          if (res.data.status === 5) {
            Toast('您已购买课程，仅限购买一次');
            if (this.isWeChat) {
              this.$router.push({
                name: 'wxSuccess',
                query: { type: this.$route.query.type },
              });
            } else {
              this.$router.push({
                name: 'h5Success',
                query: { type: this.$route.query.type },
              });
            }
          }
          if (res.data.status === 4 || res.data.status === 1) {
            // 4-半年内有个预约 1-有1对1销售
            Toast({
              message: '仅限掌门少儿新用户购买',
              duration: 3000,
            });
          }
          if (res.data.status === 3) {
            // 绑定失败
            Toast({
              message: '请到掌门少儿学习中心公众号解绑手机号',
              duration: 3000,
            });
          }
          if (res.data.status === 2) {
            if (!res.data.returnData) {
              Toast({
                message: '获取支付信息失败，请重试',
              });
              return;
            }
            cookie.set('accessToken', res.data.accessToken);
            // res.data.returnData ="{"appId":"wx35abfc3702314419","mchId":"","mwebUrl":"","nonceStr":"6jAUMD9ncxl4doJG","packages":"prepay_id=wx1914262970676965efa8411a05721f0000","paySign":"847B3A0AF50E65CEA589F98CA6B4E52B","prepayId":"","signType":"MD5","timeStamp":"1616135189"}"
            const returnData = JSON.parse(res.data.returnData);
            const subOrderNo: any = res.data.subOrderNo;
            console.log(subOrderNo, 'subOrderNo');
            if (this.isWeChat) {
              wx.ready(() => {
                console.log(
                  returnData,
                  returnData.timeStamp,
                  '接口返回支付数据'
                );
                wx.chooseWXPay({
                  appId: returnData.appId, //公众号ID，由商户传入
                  timestamp: returnData.timeStamp, //时间戳，自1970年以来的秒数
                  nonceStr: returnData.nonceStr, //随机串
                  signType: returnData.signType, //微信签名方式
                  package: returnData.packages,
                  paySign: returnData.paySign,
                  success: (res: any) => {
                    console.log(res, 'success');
                    if (res.errMsg === 'chooseWXPay:ok') {
                      this.tencetApi('PURCHASE',{})
                      this.$router.push({
                        name: 'wxSuccess',
                        query: { type: this.$route.query.type },
                      });
                    }
                  },
                  complete: (res: any) => {
                    console.log(res, 'complete');
                  },
                  fail: (res: any) => {
                    console.log(res, 'fail');
                  },
                  cancel: (res: any) => {
                    console.log(res, 'cancel');
                  },
                });
                // 验签错误或者其他错误
                // wx.error(function (res: any) {
                //   Toast('支付错误！');
                // });
              });
            } else {
              let baseUrl: any = '';
              if (process.env.NODE_ENV === 'production') {
                baseUrl = 'https://m.zhangmenkid.com/';
              } else {
                baseUrl = 'http://m-test.zhangmenkid.com/';
              }
              window.location.href = `${
                returnData.mwebUrl
              }&redirect_url=${encodeURIComponent(
                `${baseUrl}m-page/buyClassForm?type=${this.$route.query.type}&subOrderNo=${subOrderNo}&channel_code=${this.channel_code}&channel_keyword=${this.channel_keyword}`
              )}`;
            }
          }
        } else {
          console.log(res.message);
          Toast(res.message);
        }
      })
      .catch((err: any) => {
        console.log(err, '接口请求失败');
        Toast(err);
      });
  }
  // 微信支付
  private payWx() {
    initWechat({}).then(() => {
      wx.ready(wx.hideOptionMenu);
    });
    if (!this.validatePhoneNumber()) {
      return;
    }
    if (!this.credentials) {
      Toast('验证码不能为空');
      return;
    }
    if (!this.checkProtocol) {
      Toast('请勾选用户协议和隐私政策');
      document.documentElement.scrollTop = 600;
      document.body.scrollTop = 600;
      return;
    }
    this.appoint();
    switch (this.$route.query.type) {
      case '3742':
        this.$track.sendEvent({
          eventId: 'click_kid_act61_ljzf',
          eventParam: {
            code: this.channel_code,
          },
        });
        break;
      case '3811':
        this.$track.sendEvent({
          eventId: 'click_kid_act62_ljzf',
          eventParam: {
            code: this.channel_code,
          },
        });
        break;
      case '3835':
        this.$track.sendEvent({
          eventId: 'click_kid_act63_ljzf',
          eventParam: {
            code: this.channel_code,
          },
        });
        break;
      default:
        break;
    }
  }
}
