import urlConfig from '@/config'

export const goToWeChatAuth = (redirectPath: string = location.href, authQuery: any = {}, path: string = 'base') => {
    const query = {needLogin: true, needToken: false, needEncrypt: false, ...authQuery};
    const  {needLogin, needToken, needEncrypt} = query;
    const encryptionType : string = needEncrypt && 'encryptionType=service' || '';
    window.location.href = `${process.env.VUE_APP_SERVICE_URL}kids/api/wxmp/mp/web/auth/learning/center/${path}?${encryptionType}needLogin=${needLogin}&needToken=${needToken}&redirect=${encodeURIComponent(redirectPath)}`;
};

// 跳转至购买权益的订单预览页面，然后支付，并重定向到制定页面
export const goToOrderAndPay = (saleId: string, channel_code: string, channel_keyword: string, token: string, redirect: string = location.href, openId: any = false) => {
    window.location.href = `${urlConfig.marketingZmtest}/equity_sell?bu=2&saleId=${saleId}&channel_code=${channel_code}&channel_keyword=${channel_keyword}&token=${token}&routeName=orderConfirm${openId ? `&openId=${openId}`: ''}&redirect_url=${redirect}`
};

export const isMobile = (): boolean => {
    return /Android|webOS|iPhone|iPad/i.test(navigator.userAgent);
};
// android
export const isAndroid = (): boolean => /android/g.test(navigator.userAgent.toLowerCase());
// ios
export function isIOS(): boolean {
    return /iPad|iPhone|iPod/.test(window.navigator.userAgent);
}
// wechat
export function isWeChat(): boolean {
    return /micromessenger/i.test(window.navigator.userAgent);
}
// native
export function isNative(): boolean {
    return /rebecca/.test(window.navigator.userAgent);
}
