

















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import './index.scss';
import { initWechat } from '@/utils/wechat/init-wechat';
@Component({
  components: {},
})
export default class BuyClass3742 extends Vue {
  private id: any = '';
  private cutDownTime: any = 15 * 60;
  // private timeTips: any = '15:00';
  private m: any = '';
  private s: any = '';
  private activeTab: any = true;
  private timerId: any = null;
  private timer: any = {};
  private toForm(position: any) {
    this.$track.sendEvent({
      eventId: 'click_kid_act61_ljqg',
      eventParam: {
        code: this.$route.query.channel_code,
        position,
      },
    });
    this.$router.push({
      name: 'buyClassForm',
      query: {
        openId: this.$route.query.openId,
        type: '3742',
        channel_code: this.$route.query.channel_code,
        gradeCode: this.$route.query.gradeCode,
        subjectCode: this.$route.query.subjectCode,
        channel_keyword: this.$route.query.channel_keyword,
        clickId: this.$route.query.gdt_vid,
      },
    });
  }
  private mounted() {
    this.countTime();
    let baseUrl = '';
    if (process.env.NODE_ENV === 'production') {
      baseUrl = 'https://m.zhangmenkid.com/';
    } else {
      baseUrl = 'http://m-test.zhangmenkid.com/';
    }
    const shareData = {
      title: '【0.01元】掌门少儿59节趣味思维课限时抢', // 分享大标题
      desc:
        '快！来！抢！我发现掌门少儿1门好课，学古诗，练逻辑，省心省钱又省力~', // 分享描述
      imgUrl:
        'https://web-data.zmlearn.com/image/ccjZQg7tYxT1KexWzgjPsi/%E5%9B%BE.jpg', // 分享icon
      link: `${baseUrl}m-page/buyClass3742?channel_code=${this.$route.query.channel_code}&channel_keyword=${this.$route.query.channel_keyword}`, // 分享链接
    };
    console.log(shareData, 'shareData');
    initWechat(shareData);
    this.$track.sendEvent({
      eventId: 'enter_kid_act61_homepage',
      eventParam: {
        code: this.$route.query.channel_code,
      },
    });
  }
  // 倒计时
  private countTime() {
    try {
      if (this.cutDownTime <= 0) {
        // this.timeTips = '00:00';
        this.m = '00';
        this.s = '00';
        clearTimeout(this.timerId);
        // return this.getActiveInfo();
        return;
      }
      this.cutDownTime--;
      // let h: any = Math.floor(this.cutDownTime / 3600);
      let m: any = Math.floor((this.cutDownTime % 3600) / 60);
      let s: any = Math.floor((this.cutDownTime % 3600) % 60);
      // h = h < 10 ? '0' + h : h;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      this.m = m;
      this.s = s;
      // this.timeTips = `${m}:${s}`;
      this.timerId = setTimeout(this.countTime, 1000);
    } catch (e) {
      console.log(e);
    }
  }
}
