<template>
  <div id="home-wrapper" class="home-wrapper">
    <div class="content">
      <div class="nav-bar">
        <div class="nav-bar-l">
          <a class="logo-icon" href="/">
            <img
              src="https://web-data.zmlearn.com/image/3H531zUB2JsFjVt6GpFiDw/logo.png"
              alt="Zhangmen.com"
            />
          </a>
          <a class="tel-icon" href="tel:021-10108868">
            <img
              src="https://web-data.zmlearn.com/image/6bnq1YNV54px6XCFuvC4z7/telephone.png"
              alt="Zhangmen.com"
            />
          </a>
        </div>
      </div>
      <div class="middle">
        <div class="first">
          <div class="img-box">
            <edit-banner @showBannerDialog="showBannerDialog"></edit-banner>
          </div>
        </div>
        <div class="first-form">
          <div class="lesson-form-box">
            <lesson-form
              :btnText="''"
              :hasBottom="false"
              TitleText
              btnType="首页少儿表单"
              comeFrom="kid-m"
              type="homePage"
              trigger_site="首页少儿表单"
              :index_form_showmsg="index_form_showmsg"
              @showSuccessDialog="showSuccessDialog"
            ></lesson-form>
          </div>
        </div>
        <div class="second">
          <img
            src="../../assets/image/newpayIndex/content1.png"
            class="pic2"
          />
          <div class="bottom-btn-box">
            <div
              @click="jumpZhichi('激发孩子学习兴趣', '第一个按钮')"
              class="btn"
            ></div>
            <div
              @click="toggleDialogForm('学习兴趣', '1')"
              class="price-btn"
            ></div>
          </div>
        </div>
        <div class="third third-chinese">
          <img
            src="../../assets/image/newpayIndex/content2.png"
            class="pic3"
          />
          <div class="bottom-btn-box">
            <div @click="jumpDetail(1)" class="btn"></div>
            <div
              @click="toggleDialogForm('语文思维课', '2')"
              class="price-btn"
            ></div>
          </div>
        </div>
        <div class="third third-math">
          <img
            src="https://web-data.zmlearn.com/image/sybfozKiijrTsLHubmWBUQ/index-banner1.png"
            class="pic3"
          />
          <div class="bottom-btn-box">
            <div @click="jumpDetail(2)" class="btn"></div>
            <div
              @click="toggleDialogForm('数理逻辑课', '3')"
              class="price-btn"
            ></div>
          </div>
        </div>
        <div class="fourth">
          <img
            src="https://web-data.zmlearn.com/image/rxqTvB64YfPXNxu5NqPPur/teacher.png"
            class="pic4"
          />
          <div class="bottom-btn-box">
            <div @click="jumpDetail(5)" class="btn"></div>
            <div
              @click="toggleDialogForm('预约好老师', '4')"
              class="price-btn"
            ></div>
          </div>
        </div>
        <div class="fiveth">
          <img
            src="https://web-data.zmlearn.com/image/pSa8A5X3hfB9v2anYas7A4/p5.png"
            class="pic5"
          />
          <div class="bottom-btn-box">
            <div
              @click="jumpZhichi('8步打造学习闭环', '第二个按钮')"
              class="btn"
            ></div>
            <div
              @click="toggleDialogForm('8步打造学习闭环', '5')"
              class="price-btn"
            ></div>
          </div>
        </div>
        <div class="sixth">
          <img
            src="../../assets/image/newpayIndex/content.png"
            class="pic6"
          />
          <div class="bottom-btn-box">
            <div @click="jumpZhichi('4大承诺', '第三个按钮')" class="btn"></div>
            <div
              @click="toggleDialogForm('4大承诺', '6')"
              class="price-btn"
            ></div>
          </div>
        </div>
        <div class="seventh" ref="seventh">
          <img
            src="https://web-data.zmlearn.com/image/xkWdWEXjiatAjgyWmiBDr2/p7.png"
            class="pic7"
          />
          <div class="seventh-btn">
            <div class="btn-box" @click="toggleDialogForm('少儿底部导航', '7')">
              <!-- <div @click="jumpZhichi('底部导航')" class="btn-left"></div>
              <div
                @click="toggleDialogForm('少儿底部导航', '7')"
                class="btn-right"
              ></div> -->
            </div>
          </div>
        </div>
        <div class="fixFotter" v-show="showBottomBtn">
          <div class="btn-box" @click="toggleDialogForm('少儿底部导航', '7')">
            <!-- <div @click="jumpZhichi('底部导航')" class="btn-left"></div>
            <div
              @click="toggleDialogForm('少儿底部导航', '7')"
              class="btn-right"
            ></div> -->
          </div>
        </div>
        <div ref="footerBottom" style="width: 100%">
          <zm-footer style="width: 100%; padding-top: 0"></zm-footer>
        </div>
        <div
          class="slide-btn"
          v-if="showDialog"
          @click="toggleDialogForm('少儿侧边栏', '10')"
        >
          <img
            src="../../assets/image/newpayIndex/fixed_btn.png"
          />
        </div>
      </div>
    </div>

    <div class="dialog-form" v-if="showDialogForm">
      <div
        class="lesson-form-content"
        :class="{ 'lesson-form-content2': formPopType == 2 }"
        ref="lessonFormContent"
      >
        <div class="lesson-form-box">
          <lesson-form
            :btnText="''"
            :hasBottom="false"
            TitleText
            :btnType="btnType"
            comeFrom="kid-m"
            type="homePage"
            :homepageWeekSubject="homepageWeekSubject"
            :trigger_site="trigger_site"
            @showSuccessDialog="showSuccessDialog"
          ></lesson-form>
          <img
            class="dialog-form-close"
            src="https://web-data.zmlearn.com/image/pMeJFjz6QKFohpr9b4wPSH/icon_close.png"
            @click="toggleDialogForm"
          />
        </div>
      </div>
    </div>
    <!-- 成功弹窗 -->
    <success-dialog ref="successDialog"></success-dialog>
    <kid-success-dialog ref="kidSuccessDialog"></kid-success-dialog>
  </div>
</template>

<script>
import lessonForm from "@/components/lesson-form/lesson-form";
import zmFooter from "@/components/zm-footer/zm-footer";
import editBanner from "@/components/banner/banner";
import urlConfig from "@/config";
import successDialog from "@/components/success-dialog/success-dialog.vue";
import kidSuccessDialog from "@/components/kid-success-dialog/kid-success-dialog";
import {
  shareMessage
} from "@/assets/js/shareMessage";
import {
  wxfuncUrlDel
} from "@/utils/util";

const FROM_DICT = [{
  "1": {
    trigger_site: "激发孩子学习兴趣-免费预约试听",
    event_id: "click_kid_m_yyst",
    event_name: "点击|少儿|官网m端|预约试听",
    position: "免费预约试听1",
  },
  "2": {
    trigger_site: "语文思维课-免费预约语文思维",
    event_id: "click_kid_m_yyst",
    event_name: "点击|少儿|官网m端|预约试听",
    position: "免费预约语文思维",
  },
  "3": {
    trigger_site: "数理逻辑课-免费预约数理逻辑课",
    event_id: "click_kid_m_yyst",
    event_name: "点击|少儿|官网m端|预约试听",
    position: "免费预约数理思维课",
  },
  "4": {
    trigger_site: "预约好老师-免费预约好老师",
    event_id: "click_kid_m_yyst",
    event_name: "点击|少儿|官网m端|预约试听",
    position: "免费预约老师",
  },
  "5": {
    trigger_site: "8步打造学习闭环-8步打造学习闭环",
    event_id: "click_kid_m_yyst",
    event_name: "点击|少儿|官网m端|预约试听",
    position: "免费预约试听2",
  },
  "6": {
    trigger_site: "4大承诺-4大承诺免费预约试听",
    event_id: "click_kid_m_yyst",
    event_name: "点击|少儿|官网m端|预约试听",
    position: "免费预约试听3",
  },
  "7": {
    trigger_site: "底部浮窗-少儿底部导航0元领课",
    event_id: "click_kid_m_0yuanlk",
    event_name: "点击|少儿|官网m端|0元领课",
    position: "底部浮窗",
  },
  "10": {
    trigger_site: "右侧浮窗-0元试听",
    event_id: "click_kid_m_0yuanlk",
    event_name: "点击|少儿|官网m端|0元领课",
    position: "侧边浮窗",
  },
  "12": {
    trigger_site: "首页少儿表单",
    event_id: "click_kid_m_yyst",
    event_name: "点击|少儿|官网m端|预约试听",
    position: "首页少儿表单",
  },
  "13": {
    trigger_site: "少儿官网手机端首页banner",
    event_id: "click_kid_m_banner",
    event_name: "点击|少儿|官网m端|banner",
    position: "首页banner",
  },
},];

export default {
  name: "home",
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      ZMSDK.onEvent("进入页面", "少儿m站", {
        bu: "2",
        channel_id: vm.$route.query.channel_code,
      });
    });
  },
  components: {
    lessonForm,
    zmFooter,
    editBanner,
    kidSuccessDialog,
    successDialog,
  },
  data () {
    return {
      index_form_showmsg: "预约登记后会有服务顾问与您电话联系",
      showDialog: false,
      showDialogForm: false,
      showBottomBtn: false,
      videoShow: false,
      btnType: "",
      formPopType: 1,
      videoSrc: "https://web-data.zmlearn.com/media/76f95c5a-07c7-4daf-99ec-0c88430e3fab.mp4",
      trigger_site: "",
      homepageWeekSubject: "",
    };
  },
  created () { },
  mounted () {
    wxfuncUrlDel(this);
    this.$nextTick(() => {
      window.addEventListener("scroll", this.checkPosition, false);
      this.checkPosition();
      shareMessage({
        title: "掌门少儿官网-专注3-12岁儿童在线思维能力提升！",
        desc: "现在送你价值99元思维提升课，点击免费领取！",
        imgUrl: "https://web-data.zmlearn.com/image/c931d661-3c4d-4b59-9dea-4267f0394e45.png",
      });
      ZM_JSSDK &&
        ZM_JSSDK.sendEvent({
          eventId: "enter_kid_m_homepage",
          eventParam: {
            name: "进入|少儿|官网m端|官网主页面",
          },
        });
    });
  },
  destroyed () {
    window.removeEventListener("scroll", this.checkPosition);
  },
  methods: {
    // banner 点击弹窗
    showBannerDialog () {
      this.toggleDialogForm("少儿官网手机端首页banner", "13");
    },
    jumpZhichi (clickFrom = "少儿底部导航", position = "第一个按钮") {
      ZM_JSSDK &&
        ZM_JSSDK.sendEvent({
          eventId: "click_kid_m_zxkf",
          eventParam: {
            name: "点击|少儿|官网m端|咨询客服",
            position,
          },
        });
      _hmt.push(["_trackEvent", "在线咨询", clickFrom, this.$route.name]);
      window.location.href =
        "https://www.sobot.com/chat/h5/index.html?sysNum=bfc2a73dae7342ada3d886355e2b10c3";
    },
    jumpDetail (activeTab) {
      const SUBJECT_DICT = [{
        "1": {
          subject: "语文思维",
          event_id: "click_kid_m_ckkcxq",
          event_name: "进入|少儿|官网m端|语文课程详情",
          position: "语文课程",
        },
        "2": {
          subject: "数理逻辑",
          event_id: "click_kid_m_ckkcxq",
          event_name: "进入|少儿|官网m端|数理课程详情",
          position: "数理课程",
        },
        "3": {
          subject: "学习力",
          event_id: "click_kid_m_ckkcxq",
          event_name: "学习力",
          position: "学习力",
        },
        "4": {
          subject: "语文思维",
          event_id: "click_kid_m_ckkcxq",
          event_name: "语文思维",
          position: "语文思维",
        },
        "5": {
          subject: "教师资格证",
          event_id: "click_kid_m_ckjszgz",
          event_name: "点击|少儿|官网m端|查看教师资格证",
          position: "教师资格证",
        },
      },];
      ZM_JSSDK &&
        ZM_JSSDK.sendEvent({
          eventId: SUBJECT_DICT[0][String(activeTab)].event_id,
          eventParam: {
            name: SUBJECT_DICT[0][String(activeTab)].event_name,
            subject: SUBJECT_DICT[0][String(activeTab)].subject,
            position: SUBJECT_DICT[0][String(activeTab)].position,
          },
        });
      let path = "";
      switch (activeTab) {
        case 1:
          _hmt.push([
            "_trackEvent",
            "查看课程详情",
            "语文思维",
            this.$route.name,
          ]);
          path = "/course-chinese";
          break;
        case 2:
          _hmt.push([
            "_trackEvent",
            "查看课程详情",
            "数理逻辑",
            this.$route.name,
          ]);
          path = "/course-math";
          break;
        case 3:
          _hmt.push([
            "_trackEvent",
            "查看课程详情",
            "学习力",
            this.$route.name,
          ]);
          path = "/course-study";
          break;
        case 4:
          _hmt.push([
            "_trackEvent",
            "查看课程详情",
            "语文思维",
            this.$route.name,
          ]);
          path = "/course-chinese";
          break;
        case 5:
          _hmt.push([
            "_trackEvent",
            "教师资格证",
            "教师资格证",
            this.$route.name,
          ]);
          path = "/teachers";
          window.location.href = "https://www.zhangmen.com/teachers";
          return;
          break;
      }
      const query = this.$route.query;
      delete query.ABTestUrl;
      const params = JSON.stringify(query)
        .replace(/[\"\{\}]/g, "")
        .replace(/\:/g, "=")
        .replace(/\,/g, "&");
      path = path + "?" + params;
      this.$router.push(path);
    },
    toggleDialogForm (type, from, popType) {
      this.btnType = type;
      if (popType == 2) {
        this.formPopType = 2;
      } else {
        this.formPopType = 1;
      }
      this.showDialogForm = !this.showDialogForm;
      if (type === "数理逻辑课") {
        this.homepageWeekSubject = "数理逻辑训练";
      } else {
        this.homepageWeekSubject = "语文思维训练";
      }
      if (from) {
        this.trigger_site = FROM_DICT[0][from].trigger_site;
        ZM_JSSDK &&
          ZM_JSSDK.sendEvent({
            eventId: FROM_DICT[0][from].event_id,
            eventParam: {
              name: FROM_DICT[0][from].event_name,
              position: FROM_DICT[0][from].position,
              order_site: this.trigger_site,
            },
          });
      }
      if (type) {
        switch (type) {
          case "语文思维课":
            _hmt.push([
              "_trackEvent",
              "免费预约语文思维",
              type,
              this.$route.name,
            ]);
            break;
          case "数理逻辑课":
            _hmt.push([
              "_trackEvent",
              "免费预约数理逻辑课",
              type,
              this.$route.name,
            ]);
            break;
          case "预约好老师":
            _hmt.push([
              "_trackEvent",
              "免费预约好老师",
              type,
              this.$route.name,
            ]);
            break;
          case "8步打造学习闭环":
            _hmt.push([
              "_trackEvent",
              "8步打造学习闭环免费预约试听",
              type,
              this.$route.name,
            ]);
            break;
          case "4大承诺":
            _hmt.push([
              "_trackEvent",
              "4大承诺免费预约试听",
              type,
              this.$route.name,
            ]);
            break;
          case "少儿底部导航":
            _hmt.push(["_trackEvent", "0元试听", type, this.$route.name]);
            break;
          case "少儿侧边栏":
            _hmt.push(["_trackEvent", "0元试听", type, this.$route.name]);
            break;
          case "少儿官网手机端首页banner":
            _hmt.push([
              "_trackEvent",
              "少儿banner免费试听",
              type,
              this.$route.name,
            ]);
            break;
          case "学习兴趣":
            _hmt.push([
              "_trackEvent",
              "学习兴趣免费预约试听",
              type,
              this.$route.name,
            ]);
            break;
        }
      }
    },
    checkPosition () {
      const footerBottomY =
        document.body.clientHeight -
        window.outerHeight -
        this.$refs.footerBottom.offsetHeight;
      this.showBottomBtn =
        window.scrollY > 600 && window.scrollY <= footerBottomY ? true : false;
      this.showDialog = window.scrollY > 600 ? true : false;
    },
    showSuccessDialog (result) {
      this.showDialogForm = false;
      switch (result.type) {
        case "kidSuccessDialog":
          this.$refs.kidSuccessDialog.postFormSuccess(result.mobile);
          break;
        case "successDialog":
          this.$refs.successDialog.postFormSuccess();
          break;
        default:
          console.log("异常~");
      }
    },
  },
};
</script>

<style lang="scss">
#home-wrapper {
  position: relative;
  a {
    text-decoration: none;
  }
  .content {
    width: 750px;

    .nav-bar {
      width: 750px;
      height: 98px;
      padding: 0 20px;
      box-sizing: border-box;
      z-index: 2;
      background: #ffffff;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .nav-bar-l {
        width: 100%;
        height: 100%;
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        align-items: center;

        a {
          height: 50px;
          display: flex;
          display: -webkit-flex;
          align-items: center;

          &.tel-icon {
            width: 178px;
            height: 26px;
          }

          &.logo-icon {
            width: 136px;
          }

          img {
            width: 100%;
          }
        }
      }
    }

    .middle {
      max-width: 750px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      .first {
        background: #fff8ef;

        .img-box {
          width: 750px;
          height: 360px;
          overflow: hidden;
          z-index: 1;
        }
      }

      .first-form {
        .lesson-form-box {
          width: 664px;
          margin: 40px auto 0;

          .lesson-form {
            box-shadow: 0.05333rem 0.05333rem 0.29333rem 0 rgba(223, 0, 0, 0.11);
            padding-top: 60px;
            box-sizing: border-box;
            height: 100%;
            border-radius: 32px;

            .form-content {
              text-align: center;

              h2 {
                display: inline-block;
                width: 540px;
                height: 70px;
                background: url('../../assets/image/newpayIndex/title.png')
                  no-repeat center;
                background-size: contain;
                padding: 0px;
              }

              .form-item {
                box-sizing: border-box;
                margin: 24px auto 24px;
                width: 592px;
                height: 80px;
                position: relative;
              }

              .flex-item {
                display: flex;
                display: -webkit-flex;
                margin-bottom: 0;
                flex-flow: row nowrap;
                justify-content: space-between;

                .arrow {
                  display: none;
                }
              }

              .grade,
              .subject,
              input {
                height: 80px;
                background: #f2f2f2;
                border: 1px solid #e0e0e0;
                border-radius: 10px;
                text-indent: 20px;
                color: #222222;
                font-size: 28px;
                line-height: 80px;

                &::-webkit-input-placeholder {
                  color: #a4a4a4;
                  font-size: 28px;
                }

                &::-moz-placeholder {
                  color: #a4a4a4;
                  font-size: 28px;
                }
              }

              .area-code {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 96px;
                height: 80px;
                border-radius: 10px;
                background: transparent;
                text-align: center;
                line-height: 80px;
                display: inline-block;
                color: #222222;
                font-size: 28px;
                border: 1px solid transparent;
              }

              input.tel {
                text-indent: 94px;
              }

              .grade,
              .subject {
                text-indent: 0 !important;
                padding-left: 20px;
                background-image: url('https://web-data.zmlearn.com/image/76uyXsFVHhgdV3TzNdhhTo/%E4%B8%8B%E4%B8%80%E6%AD%A5.png');
                background-size: 21px 12px;
                background-position: 95% center;
                background-repeat: no-repeat;
              }
            }

            .form-protocol {
              margin-top: 44px;

              .checkoutImg {
                width: 28px;
                height: 28px;
                margin-right: 10px;
              }

              .form-protocol-text {
                font-size: 24px;

                .high-light {
                  color: #fb5058;
                }
              }
            }

            .zm-btn {
              width: 428px;
              height: 90px;
              background: url('https://web-data.zmlearn.com/image/3GY7xbcAmUS9mGt7NbuaFg/Group.png')
                no-repeat center;
              background-size: contain;
              margin-bottom: 24px;
            }

            .info {
              margin: 0px;
              width: 100%;
              min-height: 32px;
              line-height: 32px;
              padding-bottom: 50px;
              color: #000;

              span {
                color: #000 !important;
                font-size: 24px;
              }

              p {
                color: #aaa;
                margin: 10px auto;
              }
            }
          }
        }
      }

      .second {
        width: 100%;
        height: 896px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 56px;

        img {
          width: 690px;
          height: 778px;
          display: block;
        }

        .bottom-btn-box {
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding: 0 4.26667vw;
            width: 92.26667vw;
            box-sizing: border-box;

          .btn {
            width: 280px;
            height: 85px;
            background: url('https://web-data.zmlearn.com/image/k6nKQXqDmxF55NLFnE6tUC/%E5%92%A8%E8%AF%A2.png')
              no-repeat center;
            background-size: contain;
          }

          .price-btn {
            width: 286px;
            height: 94px;
            background: url('../../assets/image/newpayIndex/_btn.png')
              no-repeat center 5px;
            background-size: contain;
            margin-bottom: -1px;
          }
        }
      }

      .third {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 900px;
        background: url('https://web-data.zmlearn.com/image/9BRa268BB42B1KssznHNdu/section_bg.png')
          no-repeat center;
        background-size: cover;
        padding-top: 86px;
        margin-top: 76px;
        box-sizing: border-box;
        align-items: flex-end;


        img {
          width: 692px;
        }

        .bottom-btn-box {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          padding: 0 32px;
          width: 692px;
          box-sizing: border-box;
          margin-top: 20px;

          .btn {
            width: 280px;
            height: 96px;
            background: url('../../assets/image/newpayIndex/_btn1.png')
              no-repeat center;
            background-size: contain;
            margin-bottom: -8px;
          }

          .price-btn {
            width: 326px;
            height: 96px;
            background: url('https://web-data.zmlearn.com/image/8pkg9zm6x3HCtsWqCKaeDp/%E8%AF%AD%E6%96%87.png')
              no-repeat center -7px;
            background-size: contain;
          }
        }

        &.third-math {
          background: none;
          height: 629px;
          padding-top: 0;

          img {
            width: 700px;
          }

          .bottom-btn-box {
            .price-btn {
              background: url('../../assets/image/newpayIndex/_btn2.png')
                no-repeat center 3px;
              background-size: contain;
            }
          }
        }
      }

      .fourth {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 1004px;
        background: url('https://web-data.zmlearn.com/image/9BRa268BB42B1KssznHNdu/section_bg.png')
          no-repeat center;
        background-size: cover;
        padding-top: 86px;
        margin-top: 75px;
        box-sizing: border-box;

        .pic4 {
          width: 712px;
          height: 704px;
          display: block;
        }

        .bottom-btn-box {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          padding: 0 32px;
          width: 692px;
          box-sizing: border-box;
          margin-top: 20px;

          .btn {
            width: 280px;
            height: 96px;
            display: block;
            background: url('https://web-data.zmlearn.com/image/gswQB3HYCYuJJ7evecx6gu/%E5%92%A8%E8%AF%A21-1.png')
              no-repeat center;
            background-size: contain;
            margin-bottom: -8px;
          }

          .price-btn {
            width: 286px;
            height: 96px;
            background: url('https://web-data.zmlearn.com/image/qvBXEDZmsKddoFu5rfNz1Y/%E8%AF%95%E5%90%AC1-1.png')
              no-repeat center -7px;
            background-size: contain;
          }
        }
      }

      .fiveth {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 776px;
        position: relative;
        margin-top: 75px;
        box-sizing: border-box;

        .pic5 {
          width: 582px;
          height: 646px;
          display: block;
        }

        .bottom-btn-box {
          display: flex;
          justify-content: space-between;
          padding: 0 40px;
          margin-top: 32px;
          width: 692px;
          box-sizing: border-box;
          align-items: flex-end;

          .btn {
            width: 280px;
            height: 98px;
            display: block;
            background: url('https://web-data.zmlearn.com/image/k6nKQXqDmxF55NLFnE6tUC/%E5%92%A8%E8%AF%A2.png')
              no-repeat center;
            background-size: contain;
            margin-bottom: -4px;
          }

          .price-btn {
            width: 288px;
            height: 98px;
            background: url('https://web-data.zmlearn.com/image/b8S72ooSHhrjXo3naLEcMC/%E8%AF%95%E5%90%AC.png')
              no-repeat center -3px;
            background-size: contain;
          }
        }
      }

      .sixth {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 1004px;
        background: url('https://web-data.zmlearn.com/image/9BRa268BB42B1KssznHNdu/section_bg.png')
          no-repeat center;
        background-size: cover;
        padding-top: 84px;
        margin-top: 75px;
        box-sizing: border-box;

        .pic6 {
          width: 600px;
          height: 704px;
          display: block;
        }

        .bottom-btn-box {
          display: flex;
          justify-content: space-between;
          padding: 0 40px;
          margin-top: 24px;
          width: 692px;
          box-sizing: border-box;
          align-items: flex-end;

          .btn {
            width: 280px;
            height: 98px;
            display: block;
            background: url('https://web-data.zmlearn.com/image/k6nKQXqDmxF55NLFnE6tUC/%E5%92%A8%E8%AF%A2.png')
              no-repeat center;
            background-size: contain;
            margin-bottom: -4px;
          }

          .price-btn {
            width: 288px;
            height: 98px;
            background: url('https://web-data.zmlearn.com/image/b8S72ooSHhrjXo3naLEcMC/%E8%AF%95%E5%90%AC.png')
              no-repeat center -3px;
            background-size: contain;
          }
        }
      }

      .seventh {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        text-align: center;

        .pic7 {
          width: 696px;
          height: 576px;
          display: block;
          margin: 74px 0 100px;
        }

        .seventh-btn {
          width: 750px;
          height: 135px;
          padding: 0;
          bottom: 0;
          left: 0;
          background: transparent
            url('../../assets/image/newpayIndex/bottom_btn.png')
            no-repeat center;
          background-size: 750px 135px;

          .btn-box {
            width: 100%;
            height: 100%;

            .btn-left {
              display: inline-block;
              width: 345px;
              height: 90px;
            }

            .btn-right {
              display: inline-block;
              width: 345px;
              height: 90px;
            }
          }
        }
      }

      .fixFotter {
        position: fixed;
        width: 750px;
        height: 135px;
        padding: 0;
        bottom: 0;
        left: 0;
        background: transparent
          url('../../assets/image/newpayIndex/bottom_btn.png')
          no-repeat center;
        background-size: 750px 135px;

        .btn-box {
          width: 100%;
          height: 100%;

          .btn-left {
            display: inline-block;
            width: 345px;
            height: 90px;
          }

          .btn-right {
            display: inline-block;
            width: 345px;
            height: 90px;
          }
        }
      }

      .slide-btn {
        position: fixed;
        z-index: 1;
        top: 30%;
        margin-top: 2rem;
        right: 0;
        transition: all 0.5s ease;
        display: block;
        transform: translateZ(0);

        img {
          display: block;
          width: 182px;
          height: 163px;
        }
      }
    }
  }

  .dialog-form {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);

    .lesson-form-content {
      position: absolute;
      width: 660px;
      height: 760px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .dialog-form-close {
        position: absolute;
        display: block;
        width: 75px;
        height: 75px;
        left: 50%;
        transform: translateX(-50%);
        bottom: -100px;
      }
    }

    .lesson-form-box {
      .lesson-form {
        box-shadow: 0.05333rem 0.05333rem 0.29333rem 0 rgba(4, 4, 4, 0.1);
        padding-top: 60px;
        box-sizing: border-box;
        height: 100%;
        border-radius: 32px;

        .form-content {
          text-align: center;

          h2 {
            display: inline-block;
            width: 540px;
            height: 70px;
            background: url('../../assets/image/newpayIndex/title.png')
              no-repeat center;
            background-size: contain;
            padding: 0px;
          }

          .form-item {
            box-sizing: border-box;
            margin: 24px auto 24px;
            width: 592px;
            height: 80px;
            position: relative;
          }

          .flex-item {
            display: flex;
            display: -webkit-flex;
            margin-bottom: 0;
            flex-flow: row nowrap;
            justify-content: space-between;

            .arrow {
              display: none;
            }
          }

          .grade,
          .subject,
          input {
            height: 80px;
            background: #f2f2f2;
            border: 1px solid #e0e0e0;
            border-radius: 10px;
            text-indent: 20px;
            color: #222222;
            font-size: 28px;
            line-height: 80px;

            &::-webkit-input-placeholder {
              color: #a4a4a4;
              font-size: 28px;
            }

            &::-moz-placeholder {
              color: #a4a4a4;
              font-size: 28px;
            }
          }

          .area-code {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 96px;
            height: 80px;
            border-radius: 10px;
            background: transparent;
            text-align: center;
            line-height: 80px;
            display: inline-block;
            color: #222222;
            font-size: 28px;
            border: 1px solid transparent;
          }

          input.tel {
            text-indent: 94px;
          }

          .grade,
          .subject {
            text-indent: 0 !important;
            padding-left: 20px;
            background-image: url('https://web-data.zmlearn.com/image/76uyXsFVHhgdV3TzNdhhTo/%E4%B8%8B%E4%B8%80%E6%AD%A5.png');
            background-size: 21px 12px;
            background-position: 95% center;
            background-repeat: no-repeat;
          }
        }

        .form-protocol {
          margin-top: 44px;

          .checkoutImg {
            width: 28px;
            height: 28px;
            margin-right: 10px;
          }

          .form-protocol-text {
            font-size: 24px;

            .high-light {
              color: #fb5058;
            }
          }
        }

        .zm-btn {
          width: 428px;
          height: 90px;
          background: url('https://web-data.zmlearn.com/image/3GY7xbcAmUS9mGt7NbuaFg/Group.png')
            no-repeat center;
          background-size: contain;
          margin-bottom: 24px;
        }

        .info {
          margin: 0px;
          width: 100%;
          height: 32px;
          line-height: 32px;
          padding-bottom: 50px;
          color: #000;

          span {
            color: #000 !important;
            font-size: 24px;
          }
        }
      }
    }

    .lesson-form-content2 {
      .lesson-form {
        .form-content {
          h2 {
            display: inline-block;
            width: 467px !important;
            height: 78px !important;
            background: url('https://web-data.zmlearn.com/image/9nSTRBoTJtwU4817TriFgW/formPopTitle2.png') no-repeat
              center !important;
            background-size: contain !important;
            z-index: 1;
          }
        }
      }

      .zm-btn {
        width: 450px !important;
        height: 106px !important;
        background: url('https://web-data.zmlearn.com/image/khJpSGe3S7QTSz9iyXNnas/formPopBtn2.png') no-repeat
          center !important;
        background-size: contain !important;
      }
    }
  }
}
</style>
