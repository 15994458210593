const oversea = () => import('@/views/oversea/index.vue');
import chinese from '@/v1Module/oversea/course/chinese.vue'
import math from '@/v1Module/oversea/course/math.vue'
import newMathFan from '@/v1Module/oversea/course/newMath.vue'
const oversea2 = () => import('@/views/oversea/oversea2.vue');



export default [
  {
    path: '/oversea5',
    name: 'oversea5',
    component: oversea,
    description: 'oversea5页面'
  },
  {
    path: '/oversea',
    name: 'oversea',
    component: oversea
  },
  {
    path: '/oversea5-01',
    name: 'oversea5-01',
    component: oversea
  },
  {
    path: '/oversea-chinese',
    name: 'oversea-chinese',
    component: chinese,
    description: '海外语文落地页'
  }, {
    path: '/oversea-chinese-01',
    name: 'oversea-chinese-01',
    component: chinese,
    description: '海外语文落地页，默认勾选'
  },
  {
    path: '/oversea-math',
    name: 'oversea-math',
    component: math,
    description: '海外数学落地页'
  }, {
    path: '/oversea-math-01',
    name: 'oversea-math-01',
    component: math,
    description: '海外数学落地页-默认勾选'
  },
  {
    path: '/oversea-math-fanti',
    name: 'traditionalMathTi',
    component: newMathFan,
    description: '海外数理繁体落地页'
  },
  {
    path: '/oversea2',
    name: 'oversea2',
    component: oversea2
  },
  {
    path: '/oversea3',
    name: 'oversea3',
    component: oversea
  },
  {
    path: '/oversea4',
    name: 'oversea4',
    component: oversea
  },
];
