<template>
  <div id="newPay" v-show="userStateGot">
    <div class="content">
      <div class="middle">
        <div class="first">
          <div class="img-box">
            <img :src="topbanPic" class="topban" v-if="!offline" />
            <img :src="topbanPic2" class="topban" v-else />
            <div class="bottom-image"></div>
          </div>
          <div class="price">
            <img
              src="https://web-data.zmlearn.com/image/5ASdemgDwbmaWdBk1JDywo/0.png"
              alt=""
            />
          </div>
          <div class="ask-everyone">
            <div class="asks">
              问大家 <span @click="lookAll">查看全部> </span>
            </div>
            <div class="qusetion">
              有买过的家长来说下吗，课程值得购买吗？<span>3个回答</span>
            </div>
            <div class="qusetion">
              课程质量怎么样，老师讲的好吗？<span>2个回答</span>
            </div>
            <div class="titles">这些人正在拼单，可直接参与</div>
            <div class="share-bill swiper-no-swiping">
              <div class="swiper-wrapper share-warpper">
                <div
                  class="swiper-slide"
                  v-for="list in slideList"
                  :key="list.times"
                >
                  <div class="slide-list">
                    <div class="left">
                      <div class="avatar">
                        <img :src="list.avatar" alt="" />
                      </div>
                      <div class="nickname" v-text="list.nickName"></div>
                    </div>
                    <div class="middles">
                      <span
                        >只差<em>{{ list.residue }}人</em>成团</span
                      >
                      <span class="mid-bot"
                        ><span>剩余{{ list.countDownTime.h }}</span
                        >:<span>{{ list.countDownTime.m }}</span
                        >:<span>{{ list.countDownTime.s }}</span></span
                      >
                    </div>
                    <div class="offered" @click="toggleDialogForm('3')">
                      0元参团
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="https://web-data.zmlearn.com/image/wnnxDFpBVJksMpiRnyWTxY/banner.png"
          alt=""
          style="
            background-size: contain;
            width: 94vw;
            margin-top: 2.4vw;
            box-sizing: border-box;
          "
          v-if="offline"
        />
        <div class="third"></div>
        <div class="fourth" :class="{ 'math-fourth': !tabContent }">
          <div class="tab-toggle">
            <span @click="tabs(1)"></span>
            <span @click="tabs(2)"></span>
          </div>
          <div class="tab-content">
            <div class="chinese" v-if="tabContent"></div>
            <div class="math" v-else></div>
          </div>
        </div>
        <div class="fifth-pic"></div>
        <div class="sixth-pic"></div>
        <div class="seventh-pic"></div>
        <div class="ninth-pic"></div>
        <div class="tenth-pic"></div>
        <div class="fifth"></div>
        <div class="seventh">
          <!-- <div class="left-buy" @click="toggleDialogForm('4')">
            <span><b>￥</b>399</span><em>单独购买</em>
          </div> -->
          <div class="right-buy" @click="toggleDialogForm('5')">
            <span><b>￥</b>0</span><em>参团</em>
          </div>
        </div>
        <div class="seventh seventhFix" v-show="showBottomBtnFixed">
          <!-- <div class="left-buy" @click="toggleDialogForm('4')">
            <span><b>￥</b>399</span><em>单独购买</em>
          </div> -->
          <div class="right-buy" @click="toggleDialogForm('5')">
            <span><b>￥</b>0</span><em>参团</em>
          </div>
        </div>
      </div>
    </div>
    <!--首次出弹层引导-->
    <div class="dialog-form" v-if="guideShow">
      <div class="guidance-imgs" @click="toggleDialogForm('2')">
        <img
          src="https://web-data.zmlearn.com/image/spzVoHEGxsticpaqxtmhkZ/popup0.png"
          alt=""
        />
      </div>
      <div class="guidance-close" @click="closeGuidance">
        <img
          src="https://web-data.zmlearn.com/image/p5nCNAXZrqswVNpeZKvfF/%E5%85%B3%20%20%E9%97%AD%402x.png"
          alt=""
        />
      </div>
    </div>
    <div class="dialog-form" v-if="showDialogForm">
      <div class="lesson-form-content" ref="lessonFormContent">
        <div class="lesson-form-box">
          <lesson-form
            type="newChannel"
            :btnText="btnText"
            :TitleText="TitleText"
            :needCodeStatus="true"
            :noRefund="true"
            :appId="appId"
            :openId="openId"
            @closeDialog="toggleDialogForm"
            @track="track"
          ></lesson-form>
        </div>
      </div>
    </div>
    <!--问答弹层-->
    <div
      class="question-wrap"
      :class="{ active: state === 1, passive: state === 0 }"
    >
      <div class="question-container">
        <div class="question-title">
          问大家<span @click="closed"
            ><img
              src="https://web-data.zmlearn.com/image/mDUFyg25iakUzWEREAjjoS/close.png"
              alt=""
          /></span>
        </div>
        <div class="question-contains">
          <div class="question-content">
            <div class="question">
              <p>
                <span class="ques"></span
                ><em>课程值得报名吗？有上过课的家长来说下。</em>
              </p>
            </div>
            <div class="answer">
              <p>
                <span class="ans"></span
                ><em
                  >笑**妈：我孩子挺喜欢，最好是家长陪着一起学，孩子也能坚持</em
                >
              </p>
              <p>
                <span class="ans"></span
                ><em
                  >小**岁：是真人老师上课，很值了，还是动画课件，孩子学的进去</em
                >
              </p>
              <p>
                <span class="ans"></span
                ><em>琴**熙：课程内容很吸引，孩子学习收益颇丰</em>
              </p>
            </div>
          </div>
          <div class="question-content">
            <div class="question">
              <p>
                <span class="ques"></span
                ><em>课程有效果吗？老师教的怎么样？</em>
              </p>
            </div>
            <div class="answer">
              <p>
                <span class="ans"></span
                ><em
                  >飞**翔：我家6岁学了，对比了几家，还是觉得掌门的老师有耐心，深入浅出，孩子可以接受</em
                >
              </p>
              <p>
                <span class="ans"></span
                ><em
                  >2**x：老师很nice，对孩子不会的地方也耐心引导，给孩子很多鼓励</em
                >
              </p>
            </div>
          </div>
          <div class="question-content">
            <div class="question">
              <p><span class="ques"></span><em>孩子5岁，大班适合学吗？</em></p>
            </div>
            <div class="answer">
              <p>
                <span class="ans"></span
                ><em>小**爸：应该可以，看基础，有适合低年龄孩子学习的课程</em>
              </p>
              <p>
                <span class="ans"></span
                ><em>x**节：适合的，我孩子4岁都在学，最好入学前评测一下</em>
              </p>
            </div>
          </div>
          <div class="question-content">
            <div class="question">
              <p>
                <span class="ques"></span><em>孩子看网课会不会对眼睛不好？</em>
              </p>
            </div>
            <div class="answer">
              <p>
                <span class="ans"></span
                ><em
                  >Y**h：一节课大概30分钟，应该不影响，让孩子一直不接触电子产品不太可能，与其看电视还不如上网课</em
                >
              </p>
            </div>
          </div>
          <div class="question-content">
            <div class="question">
              <p>
                <span class="ques"></span
                ><em>我孩子比较好动，怕坐不住，能报名吗？</em>
              </p>
            </div>
            <div class="answer">
              <p>
                <span class="ans"></span
                ><em
                  >2**秋：直播课是有差不多大的小朋友一起上的，老师的互动也多，动画的课件比较吸引，我们孩子上完挺轻松的</em
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lessonForm from './lesson-form'
import urlConfig from '@/config'
// import { KidsWeChatShare } from '@/assets/js/shareMessage'
// import { wxfuncUrlDel } from '@/utils/util'
import { Timers } from '@/utils/timer'
import api from '@/api.js'
import Toast from "@/base/toast/toast";
import { Swiper, Pagination, Autoplay } from 'swiper';
Swiper.use([Pagination, Autoplay])
// import question from './question';
import 'swiper/swiper-bundle.css';
export default {
  components: {
    lessonForm
  },
  data () {
    return {
      showDialog: false,
      showDialogForm: false,
      showBottomBtnFixed: false,
      activeTab: 1,
      TitleText: '',
      btnText: '',
      appId: '',
      topbanPic: 'https://web-data.zmlearn.com/image/kBonMd7dPC9fWujAtJVAoJ/banner%402x.png',
      topbanPic2: 'https://web-data.zmlearn.com/image/v9KQx1HFNzLFtdMzxQHRD7/1.png',
      offline: false,
      openId: '',
      LOGINFO: {},
      userStateGot: true,
      introList: [1, 2],
      tab: 1,
      tabContent: true,
      guideShow: true, // 引导弹层
      state: 0, // 问答状态
      timer: null,
      slideList: [
        {
          avatar: 'https://web-data.zmlearn.com/image/rzdJbGy7rNAdY9Yga4QSBc/one.jpg',
          price: 0,
          nickName: '草莓果酱',
          residue: 1,
          times: Date.parse(new Date()) + 3000,
          countDownTime: 0
        },
        {
          avatar: 'https://web-data.zmlearn.com/image/7BhKGMK5iagtySNBN24JxU/two.jpg',
          price: 0,
          nickName: '日暮归途',
          residue: 1,
          countDownTime: 0,
          times: Date.parse(new Date()) + 2000
        },
        {
          avatar: 'https://web-data.zmlearn.com/image/u9deSX83eZWA6ntrfDh6az/three.jpg',
          price: 0,
          nickName: '烟寒若雨',
          countDownTime: 0,
          residue: 1,
          times: Date.parse(new Date()) + 12000
        },
        {
          avatar: 'https://web-data.zmlearn.com/image/jfHzYJSXcjtSXtFUnZAy8J/four.jpg',
          price: 0,
          nickName: '特立独行的猪',
          residue: 1,
          countDownTime: 0,
          times: Date.parse(new Date()) + 5000
        },
        {
          avatar: 'https://web-data.zmlearn.com/image/uWibthbfUgM1d5JUt7XTpN/five.jpg',
          price: 0,
          nickName: '季末落花祭',
          residue: 1,
          countDownTime: 0,
          times: Date.parse(new Date()) + 4300
        },
        {
          avatar: 'https://web-data.zmlearn.com/image/ohS1wXW9rtNavWirn7eyaY/six.jpg',
          price: 0,
          nickName: '陌上花开',
          residue: 1,
          countDownTime: 0,
          times: Date.parse(new Date()) + 2100
        }
      ]
    }
  },
  created () {
    api.getinfo({
      "actionName": "info",
      "activityId": 3703,
      "engineName": "common-driver"
    }).then(res => {
      if (res.data.data.params.startTimeStamp <= res.data.data.params.currentTimeStamp && res.data.data.params.currentTimeStamp <= res.data.data.params.endTimeStamp) {
        this.offline = true
      }
    });
    const { openId = undefined } = this.$route.query;
    if (openId) {
      this.openId = openId;
    }
    this.initLogInfo();
    this.track('1');
    if (Number(this.$route.query.isOversea)) {
      this.guideShow = false
    }
  },
  mounted () {
    if (this.guideShow) {
      document.body.style.height = '100vh'
      document.body.style['overflow-y'] = 'hidden'
    }
    this.$nextTick(() => {
      this.slideList.map(list => {
        this.timer = new Timers({
          startTime: list.times - Date.parse(new Date())
        }).step(time => {
          list.countDownTime = this.limitFormatTime(time)
        })
      })
      setTimeout(() => {
        var mySwipers = new Swiper('.share-bill', {
          loop: true,
          autoplay: true,
          direction: 'vertical',
          slidesPerView: 2,
          slidesPerGroup: 2,
          initialSlide: 2
        });
      }, 1000)
    })
    // wxfuncUrlDel(this);
    this.$nextTick(async () => {
      window.addEventListener('scroll', this.checkPosition, false);
      this.checkPosition();
      // const { channel_code, channel_keyword } = this.$route.query;
      // this.appId = await KidsWeChatShare({
      //   title: '掌门少儿',
      //   desc: '免费领取价值398元试听课',
      //   imgUrl: 'https://activity-static.zmlearn.com/m/zmsr.png',
      //   link: `${location.origin}/new-pay?channel_code=${channel_code}&channel_keyword=${channel_keyword}`
      // }, ['menuItem:copyUrl']);
      console.log(this.appId, 'this.appIdthis.appId')
    });
    var mySwiper = new Swiper('.swiper-box', {
      loop: true,
      autoplay: true,
      direction: 'vertical'
    });
  },
  destroyed () {
    window.removeEventListener('scroll', this.checkPosition)
    this.timer && clearInterval(this.timer);
  },
  methods: {
    lookAll () {
      this.state = 1;
      document.body.style.height = '100vh'
      document.body.style['overflow-y'] = 'hidden'
    },
    limitFormatTime (date) {
      const d = Math.floor(date / 3600 / 24);
      let h = Math.floor((date / 3600) % 24);
      let m = Math.floor((date % 3600) / 60);
      let s = Math.floor((date % 3600) % 60);
      h = h < 10 ? '0' + h : h;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      return {
        d, // 天
        h, // 时
        m, // 分
        s // 秒
      };
    },
    closed () {
      this.state = 0;
      document.body.style.height = 'unset'
      document.body.style['overflow-y'] = 'scroll'
    },
    closeGuidance () {
      this.guideShow = false;
      document.body.style.height = 'unset'
      document.body.style['overflow-y'] = 'scroll'
    },
    initLogInfo () {
      const { channel_code: code = '' } = this.$route.query;
      const eventParam = {
        code,
      };
      this.LOGINFO = {
        '1': {
          eventId: 'enter_kid_act47_homepage',
          eventParam
        },
        '2': {
          eventId: 'click_kid_act47_0ylq',
          eventParam
        },
        '3': {
          eventId: 'click_kid_act47_0yct',
          eventParam
        },
        '4': {
          eventId: 'click_kid_act47_399ygm',
          eventParam
        },
        '5': {
          eventId: 'click_kid_act47_0yyjct',
          eventParam
        },
        '6': {
          eventId: 'click_kid_act47_ljlq',
          eventParam
        },
        '7': {
          eventId: 'enter_kid_act47_success',
          eventParam
        },
        '8': {
          eventId: 'enter_kid_act47_successpage',
          eventParam
        }
      }
    },
    track (type) {
      const info = this.LOGINFO[type];
      info && this.$track.sendEvent(info);
    },
    toggleDialogForm (type) {
      type && this.track(type);
      this.showDialogForm = !this.showDialogForm;
      if (!this.showDialogForm) {
        document.body.style.height = 'unset'
        document.body.style['overflow-y'] = 'scroll'
      }
    },
    checkPosition () {
      if (window.scrollY < 350) {
        this.showDialog = false;
        this.showBottomBtnFixed = false
      } else if (window.scrollY > 350 && window.scrollY < 3560) {
        this.showDialog = true;
        this.showBottomBtnFixed = true
      } else if (window.scrollY > 3560) {
        this.showDialog = false;
        this.showBottomBtnFixed = false
      }
    },
    tabs (tab) {
      this.tabContent = tab === 1;
    }
  }
}
</script>
<style lang="scss">
#newPay {
  .four {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url('https://web-data.zmlearn.com/image/ecwaWaeqWyfcwY86qW7Kyf/newSection3Title.png')
      no-repeat top center;
    background-size: contain;
    width: 687px;
    padding-top: 380px;
    margin-bottom: 30px;
    .intro2 {
      margin: 0 auto;
      height: 306px;
    }
    .swiper-box {
      height: 460px;
      width: 750px;
      z-index: 0;
      .swiper-pagination {
        bottom: 50px;
        .swiper-pagination-bullet {
          background: #ffcf97;
          transition: all 0.25s ease;
        }
        .swiper-pagination-bullet-active {
          width: 50px;
          height: 16px;
          border-radius: 30px;
          background: #ffad10;
          transition: all 0.25s ease;
        }
      }
    }
  }
  .first {
    .lesson-form-box {
      width: 732px !important;
      height: 1003px !important;
      /*background: url("https://web-data.zmlearn.com/image/81UyDAEH9Bz4y3ADuGyWyw/form-fake.png") no-repeat center;*/
      background: url('https://web-data.zmlearn.com/image/513p9hCmNUveEDjSHAAknP/%EF%BF%A59.9m.png')
        no-repeat center;
      background-size: cover;
      .btn-fake {
        position: absolute;
        bottom: 150px;
        left: 70px;
        width: 600px;
        height: 130px;
      }
    }
  }
  .bottom-area {
    padding: 0;
  }
  .dialog-form {
    .guidance-imgs {
      width: 750px;
      height: 1208px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .guidance-close {
      display: block;
      width: 58px;
      height: 58px;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.question-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);

  &.passive {
    visibility: hidden;
  }

  &.active {
    .question-container {
      transform: translateX(-50%);
    }
  }

  .question-container {
    position: fixed;
    bottom: 0px;
    left: 50%;
    width: 750px;
    height: 978px;
    -webkit-transform: translate(-50%, 100%);
    -ms-transform: translate(-50%, 100%);
    transform: translate(-50%, 100%);
    border-radius: 20px 20px 0px 0px;
    transition: all 0.3s ease-out;
    padding: 0 0 80px 0 !important;
    background: #f5f5f5;

    .question-title {
      height: 121px;
      text-align: center;
      color: #000;
      font-size: 40px;
      line-height: 121px;
      font-family: PingFang-SC-Medium;
      border-bottom: 3px solid #eee;

      span {
        display: inline-block;
        width: 33px;
        position: absolute;
        right: 33px;
        height: 33px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .question-contains {
      overflow: scroll;
      height: 100%;
      position: relative;
      z-index: 11;

      .question-content {
        background: #fff;
        width: 724px;
        box-sizing: border-box;
        margin: 26px auto 20px;
        border-radius: 25px;
        text-align: left;
        padding: 0 20px 20px 18px;

        .question {
          margin-bottom: 30px;
          padding-top: 39px;
          p {
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #1e1e1e;
            margin-right: 10px;
            line-height: 47px;
            display: inline-flex;

            .ques {
              display: inline-block;
              background: url('https://web-data.zmlearn.com/image/rNTGh1K7NCsCjWDWMqZk7u/question.png')
                no-repeat center/cover;
              width: 47px;
              height: 47px;
              vertical-align: middle;
              margin-right: 10px;
              flex: none;
            }
          }
        }
        .answer {
          p {
            margin-bottom: 20px;
            display: inline-flex;

            .ans {
              display: inline-block;
              margin-right: 10px;
              background: url('https://web-data.zmlearn.com/image/kFCz6ktBDCwfAvjtzi6xyy/answer.png')
                no-repeat center/cover;
              width: 47px;
              height: 47px;
              vertical-align: middle;
              flex: none;
            }
          }
        }
      }
    }
    // &::-webkit-scrollbar{
    //   display: none;
    // }
  }
}
#newPay {
  position: relative;
  .content {
    width: 750px;
    background-color: #f5f5f5;
    .nav-bar {
      width: 750px;
      height: 62px;
      z-index: 2;
      background: #ffffff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .nav-bar-l {
        margin: 0 auto;
        img {
          width: 142px;
        }
      }
      .nav-bar-r {
        display: flex;
        justify-content: space-between;
        width: 225px;
        margin-right: 10px;
        &:hover,
        &:active {
          text-decoration: none;
        }
        img {
          width: 30px;
          height: 30px;
        }
        h4 {
          display: inline-block;
          font-size: 28px;
          color: #f54f50;
          letter-spacing: 0;
          line-height: 28px;
        }
      }
    }
    .middle {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      max-width: 750px;
      margin: 0 auto;
      .first {
        .img-box {
          width: 750px;
          height: 725px;
          overflow: hidden;
          z-index: 1;
          position: relative;
          .topban {
            width: 750px;
            height: 725px;
            object-fit: cover;
          }
          .bottom-image {
            width: 750px;
            height: 46px;
            background: url('https://web-data.zmlearn.com/image/jmovVfsP7SfcrKx9BWLUjn/banner_bottom.png')
              no-repeat center;
            background-size: cover;
            position: absolute;
            bottom: -1px;
            z-index: -100;
          }
        }
        .price {
          width: 750px;
          height: 161.5px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .ask-everyone {
          background: #fff;
          height: 497px;
          text-align: left;
          padding: 30px 42px 0 32px;

          .asks {
            font-size: 36px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 26px;
            color: #373a4d;
            margin-bottom: 27px;

            span {
              font-size: 26px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #ff5241;
              line-height: 36px;
              float: right;
            }
          }

          .qusetion {
            font-size: 26px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #373a4d;
            line-height: 36px;
            margin-bottom: 20px;

            span {
              font-size: 26px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #373a4d;
              line-height: 36px;
              opacity: 0.5;
              float: right;
            }
          }

          .titles {
            font-size: 26px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #373a4d;
            line-height: 73px;
            height: 73px;
            border-top: 1px solid #d5d5d5;
            margin-top: 33px;
            position: relative;

            &::after {
              position: absolute;
              left: 0;
              bottom: -7px;
              content: '';
              width: 679px;
              height: 9px;
              background-size: 100% 100%;
              background: url('https://web-data.zmlearn.com/image/e5CXS7ft4xrknSZbtwTu6z/%E5%BD%A2%E7%8A%B6%20649%20%E6%8B%B7%E8%B4%9D%402x.png')
                no-repeat left center/cover;
            }
          }

          .share-bill {
            padding: 26px 0 0 18px;
            height: 220px;
            position: relative;
            overflow: hidden;
            .share-warpper {
              display: block;
              position: relative;
              top: 220px;

              .swiper-slide {
                height: 80px;
                overflow: hidden;
                .slide-list {
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  margin-bottom: 42px;

                  .left {
                    width: 300px;

                    .avatar {
                      display: inline-block;
                      width: 64px;
                      height: 64px;
                      border-radius: 50%;
                      overflow: hidden;
                      img {
                        width: 100%;
                        height: 100%;
                      }
                    }
                    .nickname {
                      display: inline-block;
                      font-size: 30px;
                      font-family: PingFang SC;
                      font-weight: 500;
                      color: #373a4d;
                      line-height: 36px;
                      vertical-align: top;
                      margin-top: 10px;
                      margin-left: 11px;
                    }
                  }

                  .middles {
                    font-size: 26px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #a4a2a5;
                    line-height: 32px;
                    width: 180px;

                    span {
                      display: block;

                      em {
                        color: #ff5241;
                      }
                    }

                    .mid-bot {
                      span {
                        display: inline;
                      }
                    }
                  }

                  .offered {
                    width: 167px;
                    height: 54px;
                    line-height: 54px;
                    color: #fff;
                    text-align: center;
                    font-size: 28px;
                    border-radius: 27px;
                    background: linear-gradient(0deg, #ff4a00 0%, #fe7d0b 100%);
                    margin-left: 12px;
                  }
                }
              }
            }
          }
        }
        .lesson-form-box {
          position: relative;
          margin-top: -1.1rem;
          z-index: 1;
        }
      }
      .second {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 70px;
        .title {
          background: url('https://web-data.zmlearn.com/image/qNvvErnfMtz7cHW7yc4fHA/newSection1Title.png')
            no-repeat center;
          background-size: contain;
          width: 669px;
          height: 38px;
          margin-bottom: 20px;
        }
        .sectionTab {
          position: relative;
          top: 0;
          left: 0;
          margin-bottom: 20px;
          .tabChange {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            width: 690px;
            height: 68px;
            div {
              flex: 1;
              height: 68px;
            }
          }
          .tab1 {
            width: 690px;
            height: 554px;
            background: url('https://web-data.zmlearn.com/image/9YtwTFyMdeZWGTA3b5zQD6/newSection1tab1.png')
              no-repeat center;
            background-size: contain;
          }
          .tab2 {
            width: 690px;
            height: 554px;
            background: url('https://web-data.zmlearn.com/image/7Qw94f8KepXrrF1jxfzeXN/newSection1tab2.png')
              no-repeat center;
            background-size: contain;
          }
          .tab3 {
            width: 690px;
            height: 554px;
            background: url('https://web-data.zmlearn.com/image/92bDaDXnohJfE2tukt7ksR/newSection1tab3.png')
              no-repeat center;
            background-size: contain;
          }
        }
      }
      .third {
        background: url('https://web-data.zmlearn.com/image/eDDeUWLsqeeQKDWTAXXmsm/P4%402x.png')
          no-repeat top center;
        background-size: contain;
        width: 750px;
        height: 771px;
        margin-top: 18px;
        box-sizing: border-box;
      }
      .fourth {
        margin-top: 18px;
        position: relative;
        background: url('https://web-data.zmlearn.com/image/r79T1qGXWfHsrgoDKZza1d/P2%402x.png')
          no-repeat center/cover;
        width: 750px;
        padding-top: 225px;
        box-sizing: border-box;
        background-size: 100% 100%;

        &.math-fourth {
          background: url('https://web-data.zmlearn.com/image/n1zMDxZiNiK4KvzUCgxTW5/P2%402x.png')
            no-repeat center/cover;
          background-size: 100% 100%;
        }

        .tab-toggle {
          height: 194px;
          display: inline-flex;

          span {
            width: 314px;
            height: 194px;

            &:first-child {
              background: url('https://web-data.zmlearn.com/image/rqF5Zyx4SgTajCM6gS12QM/chineseTab.png')
                no-repeat center/cover;
              background-size: 100% 100%;
            }
            &:last-child {
              background: url('https://web-data.zmlearn.com/image/efNLkKwmuoqKHDCqQi2eJy/mathTab.png')
                no-repeat center/cover;
              background-size: 100% 100%;
            }
          }
        }

        .tab-content {
          position: relative;
          top: -120px;
          left: -5px;
          .chinese {
            width: 637px;
            height: 826px;
            background: url('https://web-data.zmlearn.com/image/hS5vDU2qg1fsai3hyk58YR/chinesemat%402x.png')
              no-repeat center/cover;
            margin: auto;
          }

          .math {
            width: 637px;
            height: 1039px;
            background: url('https://web-data.zmlearn.com/image/6wTaYp7MtNnsxti93q8TTo/mathmat%402x.png')
              no-repeat center/cover;
            margin: auto;
          }
        }
      }
      .fifth-pic {
        background: url('https://web-data.zmlearn.com/image/9xhAecRduDRJiWXDV5NUwa/P3%402x.png')
          no-repeat top center;
        background-size: contain;
        width: 750px;
        height: 950px;
        margin-top: 18px;
        box-sizing: border-box;
      }
      .sixth-pic {
        background: url('https://web-data.zmlearn.com/image/jNutBQu7L7ffr6SAcLAggz/5%402x.png')
          no-repeat top center;
        background-size: contain;
        width: 750px;
        height: 871px;
        margin-top: 18px;
        box-sizing: border-box;
      }
      .seventh-pic {
        background: url('https://web-data.zmlearn.com/image/ak6YNGMNLn3PYXBFBNvRxd/P6%402x.png')
          no-repeat top center;
        background-size: contain;
        width: 750px;
        height: 477px;
        margin-top: 18px;
        box-sizing: border-box;
      }
      .ninth-pic {
        background: url('https://web-data.zmlearn.com/image/bKWHrjhtar8ABGqVCUTeVB/P7%402x.png')
          no-repeat top center;
        background-size: contain;
        width: 750px;
        height: 462px;
        margin-top: 18px;
        box-sizing: border-box;
      }
      .tenth-pic {
        background: url('https://web-data.zmlearn.com/image/pnehGKsPXUiTFLu5a5JKTX/bg.png')
          no-repeat top center;
        background-size: cover;
        width: 750px;
        height: 2114px;
        margin-top: 18px;
        box-sizing: border-box;
      }
      .btn-box {
        width: 100%;
        display: flex;
        justify-content: space-around;
        .listenBtn,
        .priceBtn {
          width: 325px;
          height: 90px;
        }
        .listenBtn {
          margin-right: 15px;
        }
      }
      .slide-btn {
        position: fixed;
        z-index: 99;
        top: 50%;
        margin-top: 2rem;
        right: 0;
        transition: all 0.5s ease;
        display: block;
        transform: translateZ(0);
        img {
          display: block;
          width: 172px;
          height: 125px;
        }
      }
      .seventh {
        padding-top: 35px;
        box-sizing: border-box;
        overflow: hidden;
        height: 184px;
        border-radius: 32px 32px 0px 0px;
        background: #fff;
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        padding-bottom: 26px;

        .left-buy {
          width: 277px;
          height: 123px;
          border: 3px solid #ff5144;
          background: #fff;
          border-radius: 62px;
          margin-right: 17px;

          span {
            display: block;
            font-size: 60px;
            font-family: Yuanti SC;
            font-weight: bold;
            margin-bottom: -4px;
            color: #ff5144;

            b {
              font-size: 39px;
              font-family: Avenir;
            }
          }

          em {
            font-size: 32px;
            color: #ff5144;
            line-height: 22px;
          }
        }

        .right-buy {
          width: 100%;
          height: 123px;
          line-height: 123px;
          background: linear-gradient(180deg, #ff7044 0%, #ff5144 100%);
          border-radius: 62px;

          span {
            display: inline-block;
            font-size: 68px;
            font-family: Yuanti SC;
            font-weight: bold;
            color: #fff;
            margin-bottom: -4px;

            b {
              font-family: Avenir;
              font-size: 54px;
              margin-left: 16px;
              position: relative;
              top: -4px;
            }
          }

          em {
            font-size: 54px;
            color: #fff;
            line-height: 22px;
            margin-left: 16px;
            position: relative;
            top: -4px;
          }
        }
        .seventh-btn {
          width: 750px;
          height: 152px;
          bottom: 0;
          left: 0;
          background: url('https://web-data.zmlearn.com/image/erCoNzPJJZhKu8eHjaNDyR/new-pay1-footer.png')
            no-repeat center;
          background-size: cover;
          .btn-box {
            .btn-left {
              display: inline-block;
              width: 345px;
              height: 90px;
            }
            .btn-right {
              display: inline-block;
              width: 345px;
              height: 90px;
            }
          }
        }
        &.seventhFix {
          position: fixed;
          overflow: hidden;
          padding-left: 45px;
          padding-right: 45px;
          width: 100%;
          bottom: 0;
          z-index: 9;
          .seventh-btn {
            width: 750px;
            height: 148px;
            bottom: 0;
            left: 0;
            background: url('https://web-data.zmlearn.com/image/erCoNzPJJZhKu8eHjaNDyR/new-pay1-footer.png')
              no-repeat center;
            background-size: cover;
            .btn-box {
              .btn-left {
                display: inline-block;
                width: 345px;
                height: 90px;
              }
              .btn-right {
                display: inline-block;
                width: 345px;
                height: 90px;
              }
            }
          }
        }
      }
    }
  }
  .dialog-form {
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    .lesson-form-content {
      position: fixed;
      width: 664px;
      height: 776px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .lesson-form-box {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
      }
    }
  }
  .lesson-form-box {
    width: 664px;
    margin: 0 auto;
  }
}
</style>
