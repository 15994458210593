const hualala = () => import('@/v1Module/oversea/hualala.vue');
const palfishread = () => import('@/v1Module/oversea/palfishread/index.vue');
const hexiaoxiang = () => import('@/v1Module/oversea/hexiaoxiang/math.vue');
const communityOperation = () => import('@/views/community-operation-register/index.vue');
export default [
  {
    path: '/hualala',
    name: 'oversea',
    component: hualala,
    description: '画啦啦落地页',
  },
  {
    path: '/palfishread',
    name: 'oversea',
    component: palfishread,
    description: '伴鱼落地页',
  },
  {
    path: '/hexiaoxiang',
    name: 'hexiaoxiang',
    component: hexiaoxiang,
    description: '河小象落地页 20200510',
  },
  {
    path: '/communityOperation',
    name: 'communityOperation',
    component: communityOperation,
    description: '社群运营注册页 20210513',
  },
];
