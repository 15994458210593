const sem = () => import('@/views/lp/sem.vue');
const G12 = () => import('@/views/lp/g12.vue');


export default [
  {
    path: '/lp/sem',
    name: 'sem',
    component: sem
  },
  {
    path: '/lp/g1',
    name: 'g1subject',
    component: G12,
    meta: {
      formType: 'common',
      subject: 'chinese'
    }
  },
];
