exports.kid_gradeData = [
    ['幼儿园中班', '幼儿园中班', "KINDERGARTEN_2"],
    ['幼儿园大班', '幼儿园大班', "KINDERGARTEN_3"],
    ['幼儿园小班', '幼儿园小班', "KINDERGARTEN_1"],
    ['小一', '小一', "PRIMARY_SCHOOL_1"],
    ['小二', '小二', "PRIMARY_SCHOOL_2"],
    ['小三', '小三', "PRIMARY_SCHOOL_3"],
    ['小四', '小四', "PRIMARY_SCHOOL_4"],
    ['小五', '小五', "PRIMARY_SCHOOL_5"],
    ['小六', '小六', "PRIMARY_SCHOOL_6"],
    ['初一', '初一', "JUNIOR_MIDDLE_SCHOOL_1"],
    ['初二', '初二', "JUNIOR_MIDDLE_SCHOOL_2"],
    ['初三', '初三', "JUNIOR_MIDDLE_SCHOOL_3"],
    ['初四', '初四', "JUNIOR_MIDDLE_SCHOOL_4"],
    ['高一', '高一', "SENIOR_MIDDLE_SCHOOL_1"],
    ['高二', '高二', "SENIOR_MIDDLE_SCHOOL_2"],
    ['高三', '高三', "SENIOR_MIDDLE_SCHOOL_3"]
]

exports.kid_subjectData = [
    [
        ['奥数', '数理逻辑训练', "MATH_LOGIC"],
        ['学习力训练', '学习力训练', 'LEARNING_ABILITY_TRAINING'],
        ['钢琴陪练', '钢琴陪练', 'PIANO_PARTNER']
    ],
    [
        ['奥数', '数理逻辑训练', 'MATH_LOGIC'],
        ['学习力训练', '学习力训练', 'LEARNING_ABILITY_TRAINING'],
        ['钢琴陪练', '钢琴陪练', 'PIANO_PARTNER']
    ],
    [
        ['奥数', '数理逻辑训练', 'MATH_LOGIC'],
        ['学习力训练', '学习力训练', 'LEARNING_ABILITY_TRAINING'],
        ['钢琴陪练', '钢琴陪练', "PIANO_PARTNER"]
    ],
    [
        ['语文', '语文逻辑训练', "CHINESE_LOGIC"],
        ['奥数', '数理逻辑训练', "MATH_LOGIC"],
        ['英语', '新概念英语', "ENGLISH_CONCEPT"],
        ['学习力训练', '学习力训练', "LEARNING_ABILITY_TRAINING"],
        ['钢琴陪练', '钢琴陪练', "PIANO_PARTNER"]
    ],
    [
        ['语文', '语文逻辑训练', "CHINESE_LOGIC"],
        ['奥数', '数理逻辑训练', "MATH_LOGIC"],
        ['英语', '新概念英语', "ENGLISH_CONCEPT"],
        ['学习力训练', '学习力训练', "LEARNING_ABILITY_TRAINING"],
        ['钢琴陪练', '钢琴陪练', "PIANO_PARTNER"]
    ],
    [
        ['语文', '语文逻辑训练', "CHINESE_LOGIC"],
        ['奥数', '数理逻辑训练', "MATH_LOGIC"],
        ['英语', '新概念英语', "ENGLISH_CONCEPT"],
        ['学习力训练', '学习力训练', "LEARNING_ABILITY_TRAINING"],
        ['钢琴陪练', '钢琴陪练', "PIANO_PARTNER"]
    ],
    [
        ['数学', '数学', "MATH"],
        ['语文', '语文', "CHINESE"],
        ['英语', '英语', "ENGLISH"],
        ['奥数', '奥数', "MATH_LOGIC"],
        ['钢琴陪练', '钢琴陪练', "PIANO_PARTNER"]
    ],
    [
        ['数学', '数学', "MATH"],
        ['语文', '语文', "CHINESE"],
        ['英语', '英语', "ENGLISH"],
        ['奥数', '奥数', "MATH_LOGIC"],
        ['钢琴陪练', '钢琴陪练', "PIANO_PARTNER"]
    ],
    [
        ['数学', '数学', "MATH"],
        ['语文', '语文', "CHINESE"],
        ['英语', '英语', "ENGLISH"],
        ['奥数', '奥数', "MATH_LOGIC"],
        ['钢琴陪练', '钢琴陪练', "PIANO_PARTNER"]
    ],
    [
        ['数学', '数学', "MATH"],
        ['语文', '语文', "CHINESE"],
        ['英语', '英语', "ENGLISH"],
        ['政治', '政治', "POLITICS"],
        ['历史', '历史', "HISTORY"],
        ['地理', '地理', "GEOGRAPHY"],
        ['化学', '化学', "CHEMISTRY"],
        ['生物', '生物', "BIOLOGY"],
        ['科学', '科学', "SCIENCE"],
        ['物理', '物理', "PHYSICAL"]
    ],
    [
        ['数学', '数学', "MATH"],
        ['语文', '语文', "CHINESE"],
        ['英语', '英语', "ENGLISH"],
        ['政治', '政治', "POLITICS"],
        ['历史', '历史', "HISTORY"],
        ['地理', '地理', "GEOGRAPHY"],
        ['化学', '化学', "CHEMISTRY"],
        ['生物', '生物', "BIOLOGY"],
        ['科学', '科学', "SCIENCE"],
        ['物理', '物理', "PHYSICAL"]
    ],
    [
        ['数学', '数学', "MATH"],
        ['语文', '语文', "CHINESE"],
        ['英语', '英语', "ENGLISH"],
        ['政治', '政治', "POLITICS"],
        ['历史', '历史', "HISTORY"],
        ['地理', '地理', "GEOGRAPHY"],
        ['化学', '化学', "CHEMISTRY"],
        ['生物', '生物', "BIOLOGY"],
        ['科学', '科学', "SCIENCE"],
        ['物理', '物理', "PHYSICAL"]
    ],
    [
        ['数学', '数学', "MATH"],
        ['语文', '语文', "CHINESE"],
        ['英语', '英语', "ENGLISH"],
        ['政治', '政治', "POLITICS"],
        ['历史', '历史', "HISTORY"],
        ['地理', '地理', "GEOGRAPHY"],
        ['化学', '化学', "CHEMISTRY"],
        ['生物', '生物', "BIOLOGY"],
        ['科学', '科学', "SCIENCE"],
        ['物理', '物理', "PHYSICAL"]
    ],
    [
        ['数学', '数学', "MATH"],
        ['语文', '语文', "CHINESE"],
        ['英语', '英语', "ENGLISH"],
        ['政治', '政治', "POLITICS"],
        ['历史', '历史', "HISTORY"],
        ['地理', '地理', "GEOGRAPHY"],
        ['化学', '化学', "CHEMISTRY"],
        ['生物', '生物', "BIOLOGY"],
        ['物理', '物理', "PHYSICAL"]
    ],
    [
        ['数学', '数学', "MATH"],
        ['语文', '语文', "CHINESE"],
        ['英语', '英语', "ENGLISH"],
        ['政治', '政治', "POLITICS"],
        ['历史', '历史', "HISTORY"],
        ['地理', '地理', "GEOGRAPHY"],
        ['化学', '化学', "CHEMISTRY"],
        ['生物', '生物', "BIOLOGY"],
        ['文数', '文数', "MATH_ARTS"],
        ['理数', '理数', "MATH_SCIENCE"],
        ['物理', '物理', "PHYSICAL"]
    ],
    [
        ['数学', '数学', "MATH"],
        ['语文', '语文', "CHINESE"],
        ['英语', '英语', "ENGLISH"],
        ['政治', '政治', "POLITICS"],
        ['历史', '历史', "HISTORY"],
        ['地理', '地理', "GEOGRAPHY"],
        ['化学', '化学', "CHEMISTRY"],
        ['生物', '生物', "BIOLOGY"],
        ['文数', '文数', "MATH_ARTS"],
        ['理数', '理数', "MATH_SCIENCE"],
        ['物理', '物理', "PHYSICAL"]
    ]
]

exports.subjectToLabelData = {
    数理逻辑训练: '奥数',
    语文逻辑训练: '语文',
    新概念英语: '英语'
}

// exports.gradeToLabel = {}
