<template>
  <div id="newPay">
    <div class="content">
      <div class="nav-bar">
        <div class="nav-bar-l">
          <img
            src="https://web-data.zmlearn.com/image/9Y2UtXePWVb3ociaEsByLV/pic_logo.png"
          />
        </div>
      </div>
      <div class="middle">
        <div class="first">
          <div class="img-box">
            <img :src="topbanPic" class="topban" />
            <div class="bottom-image"></div>
          </div>
          <div class="lesson-form-box">
            <div
              class="btn-fake"
              @click="toggleDialogForm(1, '50元领课')"
            ></div>
          </div>
        </div>
        <div class="third"></div>
        <div class="four">
          <div class="swiper-box">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img
                  class="intro2"
                  src="https://web-data.zmlearn.com/image/to7WcqoUzK6U2BA8f4BvD2/newSectionBan1.png"
                  alt=""
                />
              </div>
              <div class="swiper-slide">
                <img
                  class="intro2"
                  src="https://web-data.zmlearn.com/image/bPofCK5jNv8Yn2kfDCzri1/newSectionBan2.png"
                  alt=""
                />
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
        <div class="seventh">
          <div
            class="seventh-btn"
            @click="toggleDialogForm(1, '50元领课')"
          ></div>
        </div>
        <zm-footer style="width: 100%"></zm-footer>
        <div class="seventh seventhFix" v-show="showBottomBtnFixed">
          <div
            class="seventh-btn"
            @click="toggleDialogForm(1, '50元领课')"
          ></div>
        </div>
      </div>
    </div>

    <div class="dialog-form" v-if="showDialogForm">
      <div class="lesson-form-content" ref="lessonFormContent">
        <div class="lesson-form-box">
          <lesson-form
            type="newChannel"
            :btnText="btnText"
            :TitleText="TitleText"
            :needCodeStatus="true"
            :btnType="btnType"
            :comeFrom="comeFrom"
            :noRefund="true"
          ></lesson-form>
        </div>
        <img
          class="dialog-form-close"
          src="https://web-data.zmlearn.com/image/pMeJFjz6QKFohpr9b4wPSH/icon_close.png"
          @click="toggleDialogForm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import lessonForm from './lesson-form'
import zmFooter from '@/components/zm-footer/zm-footer'
import urlConfig from '@/config'
import { shareMessage } from '@/assets/js/shareMessage'
import { wxfuncUrlDel } from '@/utils/util'
import { Swiper, Pagination, Autoplay } from 'swiper';
Swiper.use([Pagination, Autoplay])
import 'swiper/swiper-bundle.css';
export default {
  components: {
    lessonForm,
    zmFooter
  },
  data () {
    return {
      showDialog: false,
      showDialogForm: false,
      showBottomBtnFixed: false,
      activeTab: 1,
      TitleText: '',
      btnText: '',
      btnType: '',
      comeFrom: '',
      topbanPic: 'https://web-data.zmlearn.com/image/sbRmW9kvq8muQSqKgsjP6a/huanglei.png'
    }
  },
  created () {
    this.comeFrom = this.$route.name
  },
  mounted () {
    wxfuncUrlDel(this); // 删除url中的isFollow参数(不知道为什么)
    this.$nextTick(() => {
      window.addEventListener('scroll', this.checkPosition, false)
      this.checkPosition()
      shareMessage({
        title: '掌门少儿',
        desc: '免费领取价值398元试听课',
        imgUrl: 'https://activity-static.zmlearn.com/m/zmsr.png'
      })
    })
    var mySwiper = new Swiper('.swiper-box', {
      loop: true,
      autoplay: true,
      pagination: {
        el: '.swiper-pagination',
      },
    });

  },
  destroyed () {
    window.removeEventListener('scroll', this.checkPosition)
  },
  methods: {
    toggleDialogForm (type, btn) {
      this.showDialogForm = !this.showDialogForm
      if (btn) {
        this.btnType = btn
      }
      if (type == 1) {
        this.btnText = '9.9元立即购买'
        this.TitleText = '<div style="color:#333;font-size:0.53rem;font-weight:normal;font-family:"PingFangSC-Medium">限时<span style="color: #ff6e35; padding: 0 0.1rem;">9.9元</span>领取</div>'
      }
    },
    checkPosition () {
      if (window.scrollY < 350) {
        this.showDialog = false
        this.showBottomBtnFixed = false
      } else if (window.scrollY > 350 && window.scrollY < 1240) {
        this.showDialog = true
        this.showBottomBtnFixed = true
      } else if (window.scrollY > 1240) {
        this.showDialog = false
        this.showBottomBtnFixed = false
      }
    },
  }
}
</script>
<style lang="scss">
#newPay {
  .four {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url('https://web-data.zmlearn.com/image/ecwaWaeqWyfcwY86qW7Kyf/newSection3Title.png')
      no-repeat top center;
    background-size: contain;
    width: 687px;
    padding-top: 380px;
    margin-bottom: 30px;
    .intro2 {
      margin: 0 auto;
      height: 306px;
    }
    .swiper-box {
      height: 460px;
      width: 750px;
      z-index: 0;
      .swiper-pagination {
        bottom: 50px;
        .swiper-pagination-bullet {
          background: #ffcf97;
          transition: all 0.25s ease;
        }
        .swiper-pagination-bullet-active {
          width: 50px;
          height: 16px;
          border-radius: 30px;
          background: #ffad10;
          transition: all 0.25s ease;
        }
      }
    }
  }
  .first {
    .swiper-slide {
      img {
        height: 394px;
        width: 100%;
        vertical-align: bottom;
      }
    }
    .lesson-form-box {
      width: 732px !important;
      height: 1003px !important;
      /*background: url("https://web-data.zmlearn.com/image/81UyDAEH9Bz4y3ADuGyWyw/form-fake.png") no-repeat center;*/
      background: url('https://web-data.zmlearn.com/image/513p9hCmNUveEDjSHAAknP/%EF%BF%A59.9m.png')
        no-repeat center;
      background-size: cover;
      .btn-fake {
        position: absolute;
        bottom: 150px;
        left: 70px;
        width: 600px;
        height: 130px;
      }
    }
  }
  .bottom-area {
    padding: 0;
  }
  .dialog-form {
    .lesson-form {
      .form-content {
        .flex-item {
          position: relative;
          .arrow {
            position: absolute;
            display: inline-block;
            width: 25px;
            height: 15px;
            background: url('https://web-data.zmlearn.com/image/gp1iZc6yFVryQA5hMSRVSs/newPopFormBtnBg.png')
              no-repeat center !important;
            background-size: contain !important;
            right: 50px;
            top: 50px;
          }
          .grade-arrow {
            top: 40px;
            right: 3.9rem;
          }
          .subject-arrow {
            top: 40px;
            right: 20px;
          }
        }
        .grade,
        .subject,
        input {
          font-size: 95%;
          background: #eee;
          border: none;
          border-radius: 10px;
          text-indent: 30px;
          color: #202020;
          &::-webkit-input-placeholder {
            color: #999;
          }
          &::-moz-placeholder {
            color: #999;
          }
        }
        .grade {
          width: 55% !important;
          position: relative;
          color: #202020;
        }
        .subject {
          width: 44% !important;
          position: relative;
          color: #202020;
        }
      }
      .form-protocol {
        .checkoutImg {
          width: 27px;
          height: 27px;
          margin-right: 20px;
        }
        .form-protocol-text {
          .high-light {
            color: #ff6e35;
          }
        }
      }
      .zm-btn {
        position: relative;
        width: 450px;
        height: 106px;
        background: url('https://web-data.zmlearn.com/image/gp1iZc6yFVryQA5hMSRVSs/newPopFormBtnBg.png')
          no-repeat center;
        background-size: cover;
        margin: 20px auto;
        span {
          position: absolute;
          transform: translate(-50%, -50%);
          left: 50%;
          top: 50%;
          height: 106px;
          line-height: 90px;
          font-size: 35px;
          text-align: center;
        }
      }
      .get-identify-code {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 30px;
        border-radius: 50px;
        background-color: rgb(255, 103, 82);
        width: 165px;
        height: 50px;
        font-size: 24px;
        line-height: 50px;
        text-align: center;
      }
    }
  }
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#newPay {
  position: relative;
  .content {
    width: 750px;
    background-color: #fff4d5;
    .nav-bar {
      width: 750px;
      height: 62px;
      z-index: 2;
      background: #ffffff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .nav-bar-l {
        margin: 0 auto;
        img {
          width: 142px;
        }
      }
      .nav-bar-r {
        display: flex;
        justify-content: space-between;
        width: 225px;
        margin-right: 10px;
        &:hover,
        &:active {
          text-decoration: none;
        }
        img {
          width: 30px;
          height: 30px;
        }
        h4 {
          display: inline-block;
          font-size: 28px;
          color: #f54f50;
          letter-spacing: 0;
          line-height: 28px;
        }
      }
    }
    .middle {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      max-width: 750px;
      margin: 0 auto;
      .first {
        margin-bottom: 70px;
        .img-box {
          width: 750px;
          height: 530px;
          overflow: hidden;
          z-index: 1;
          position: relative;
          .topban {
            width: 750px;
            height: 555px;
            object-fit: cover;
          }
          .bottom-image {
            width: 750px;
            height: 46px;
            background: url('https://web-data.zmlearn.com/image/jmovVfsP7SfcrKx9BWLUjn/banner_bottom.png')
              no-repeat center;
            background-size: cover;
            position: absolute;
            bottom: -1px;
            z-index: -100;
          }
        }
        .lesson-form-box {
          position: relative;
          margin-top: -1.1rem;
          z-index: 1;
        }
      }
      .second {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 70px;
        .title {
          background: url('https://web-data.zmlearn.com/image/qNvvErnfMtz7cHW7yc4fHA/newSection1Title.png')
            no-repeat center;
          background-size: contain;
          width: 669px;
          height: 38px;
          margin-bottom: 20px;
        }
        .sectionTab {
          position: relative;
          top: 0;
          left: 0;
          margin-bottom: 20px;
          .tabChange {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            width: 690px;
            height: 68px;
            div {
              flex: 1;
              height: 68px;
            }
          }
          .tab1 {
            width: 690px;
            height: 554px;
            background: url('https://web-data.zmlearn.com/image/9YtwTFyMdeZWGTA3b5zQD6/newSection1tab1.png')
              no-repeat center;
            background-size: contain;
          }
          .tab2 {
            width: 690px;
            height: 554px;
            background: url('https://web-data.zmlearn.com/image/7Qw94f8KepXrrF1jxfzeXN/newSection1tab2.png')
              no-repeat center;
            background-size: contain;
          }
          .tab3 {
            width: 690px;
            height: 554px;
            background: url('https://web-data.zmlearn.com/image/92bDaDXnohJfE2tukt7ksR/newSection1tab3.png')
              no-repeat center;
            background-size: contain;
          }
        }
      }
      .third {
        background: url('https://web-data.zmlearn.com/image/pYmE8QWXgjz4Lmhu8iKvm2/newSection2.png')
          no-repeat top center;
        background-size: contain;
        width: 687px;
        height: 900px;
        padding-top: 820px;
        box-sizing: border-box;
      }
      .btn-box {
        width: 100%;
        display: flex;
        justify-content: space-around;
        .listenBtn,
        .priceBtn {
          width: 325px;
          height: 90px;
        }
        .listenBtn {
          margin-right: 15px;
        }
      }
      .slide-btn {
        position: fixed;
        z-index: 99;
        top: 50%;
        margin-top: 2rem;
        right: 0;
        transition: all 0.5s ease;
        display: block;
        transform: translateZ(0);
        img {
          display: block;
          width: 172px;
          height: 125px;
        }
      }
      .seventh {
        overflow: hidden;
        height: 152px;
        .seventh-btn {
          width: 750px;
          height: 152px;
          bottom: 0;
          left: 0;
          background: url('https://web-data.zmlearn.com/image/erCoNzPJJZhKu8eHjaNDyR/new-pay1-footer.png')
            no-repeat center;
          background-size: cover;
          .btn-box {
            .btn-left {
              display: inline-block;
              width: 345px;
              height: 90px;
            }
            .btn-right {
              display: inline-block;
              width: 345px;
              height: 90px;
            }
          }
        }
        &.seventhFix {
          position: fixed;
          overflow: hidden;
          height: 148px;
          bottom: 0;
          .seventh-btn {
            width: 750px;
            height: 148px;
            bottom: 0;
            left: 0;
            background: url('https://web-data.zmlearn.com/image/erCoNzPJJZhKu8eHjaNDyR/new-pay1-footer.png')
              no-repeat center;
            background-size: cover;
            .btn-box {
              .btn-left {
                display: inline-block;
                width: 345px;
                height: 90px;
              }
              .btn-right {
                display: inline-block;
                width: 345px;
                height: 90px;
              }
            }
          }
        }
      }
    }
  }
  .dialog-form {
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    .lesson-form-content {
      position: fixed;
      width: 664px;
      height: 776px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .lesson-form-box {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
      }
      .dialog-form-close {
        position: absolute;
        bottom: -150px;
        display: block;
        width: 75px;
        height: 75px;
        margin-left: 295px;
        margin-top: 75px;
      }
    }
  }
  .lesson-form-box {
    width: 664px;
    margin: 0 auto;
  }
}
</style>