<template>
  <form
    class="new-pay3__lesson-form"
    ref="lessonForm"
    :class="{ 'new-pay3__extra-class': extraClass }"
  >
    <div class="new-pay3__form-content">
      <div class="new-pay3__form-basic-info">
        <div class="new-pay3__form-item new-pay3__grade-subject">
          <select
            class="new-pay3__form-item--content new-pay3__grade"
            v-model="weakGrade"
            ref="grade"
            @change="getCurrentValue"
          >
            <option
              :value="value"
              v-for="{ label, value } in subjectGrade"
              :key="value"
            >
              {{ label }}
            </option>
          </select>
          <select
            class="new-pay3__form-item--content new-pay3__subject"
            v-model="weakSubject"
            ref="subject"
          >
            <option
              :value="value"
              v-for="{ label, value } in weakSubjectArr"
              :key="value"
            >
              {{ label | global_filter_subject(newWeakGrade) }}
            </option>
          </select>
        </div>

        <div class="new-pay3__form-item">
          <input
            type="text"
            placeholder="请输入孩子的姓名"
            class="new-pay3__form-item--content new-pay3__username"
            :class="{ alert: userNameAlert }"
            v-model="userName"
          />
        </div>
        <div class="new-pay3__form-item">
          <span class="area-code" @click="showCountry = true"
            >+{{ areaCode }}</span
          >
          <input
            type="number"
            placeholder="请输入上课联系手机号"
            :class="{ alert: mobileAlert }"
            class="new-pay3__tel new-pay3__form-item--content"
            v-model="mobile"
            maxlength="11"
          />
        </div>
        <div class="new-pay3__form-item">
          <input
            type="number"
            v-model="codeNumber"
            placeholder="请输入验证码"
            class="new-pay3__codeNumber new-pay3__form-item--content"
          />
          <div class="new-pay3__send-code" @click.stop="identifySubmitBtn()">
            {{ codeCountDown }}
          </div>
        </div>
      </div>
    </div>

    <img
      class="new-pay3__zm-btn"
      href="javascript:;"
      src="https://static-app-manager.zmlearn.com/WRE4Z84j7270.png"
      @click="handleSubmitBtn()"
    />
    <protocol :defaultValue="checkProtocol" @changeProtocol="changeProtocol" />
    <div class="new-pay3__info">
      <img
        class="new-pay3__lock"
        src="https://web-data.zmlearn.com/image/vRuqLVCMvKdxPdhcoQBoCv/%E5%BD%A2%E7%8A%B6.png"
      />
      <span>为了您的权益，您的隐私将被严格保密</span>
    </div>

    <div :class="{ 'mask-box': true }" v-if="showMask">
      <div class="verification-box">
        <img
          src="https://static-app-manager.zmlearn.com/iCAIDA-d4998.png"
          @click="hideMask()"
          class="closeIcon"
          alt
        />
        <div class="phone-text">
          验证码已发送至{{ mobile | filterPhoneNumber }}
        </div>
        <div class="code-box">
          <input type="number" v-model="codeNumber" class="codeNumber" />
          <div class="send-code" @click.stop="identifySubmitBtn()">
            {{ codeCountDown }}
          </div>
        </div>
        <div class="verification-btn" @click="checkBtn()">
          最后一步，立即领取
        </div>
        <div class="bottom-title">领取后规划师会与您电话沟通课程详情</div>
      </div>
    </div>
    <fail-dialog ref="failDialog"></fail-dialog>
    <country v-model="showCountry" @select="areaCode = $event"></country>
  </form>
</template>

<script>
import api from '@/api.js'
import { GeetestCode } from '@/components/Geetest/index'
import cookies from 'js-cookie';
import {
  request,
  queryObj,
  isEmptyObject,
} from "@/utils/util";
import urlConfig from "@/config";
import protocol from '@/components/protocol/index'
import failDialog from "@/components/fail-dialog/fail-dialog";
import Toast from "@/base/toast/toast";
import country from './country/index.vue';
import { dictionaryNewGradeOversea, dictionaryBaseSubject } from '@/libs/dic';


import {
  mapState
} from "vuex";


const FROM_DICT = {

  "3": {
    trigger_site: "弹窗立即购买",
    event_id: "click_kid_act_19.9_ljgm02",
    event_name: "点击|少儿|官网m端|弹窗立即购买",
    position: "弹窗立即购买",
  },
};

export default {
  name: "lesson-form",
  components: {
    failDialog,
    protocol,
    country,
  },
  props: {
    'type': {
      type: String
    },
    'extraClass': {
      default: false,
      type: Boolean
    },
    'point': {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      getCode: "获取验证码",
      codeCountDownNum: 60,
      codeCountSubmitNum: 3,
      areaCode: 86,
      showCountry: false,
      vertifyOnOff: true, // 获取验证码按钮是否可点击
      userName: "",
      weakGrade: "PRIMARY_SCHOOL_3",
      newWeakGrade: 'PRIMARY_SCHOOL_3',
      newWeakGradeLabel: '小三',
      weakSubject: "MATH_LOGIC",
      mobile: "",
      userNameAlert: false,
      mobileAlert: false,
      checkProtocol: (this.$route.name === 'oversea-math-01' || this.$route.name === 'oversea-chinese-01') ? true : false,
      showProtocol: false,
      open_id: "",
      showMask: false,
      codeNumber: "",
      changMobile: false,
      subjectGrade: [],
      weakSubjectArr: [],
      iconCheckState: {
        checked: 'https://web-data.zmlearn.com/image/ut7ZfqyGo5aSiWmLPWCdCr/%E7%BC%96%E7%BB%84%205.png',
        unchecked: 'https://web-data.zmlearn.com/image/7cCwbkGdtrLweuC3eEEHSw/%E6%A4%AD%E5%9C%86%E5%BD%A2.png'
      },
      isSubmitting: false,
      hasRealGrade: false,
    };
  },
  filters: {
    filterPhoneNumber (number) {
      if (number.length === 11) {
        let pat = /(\d{3})\d*(\d{4})/;
        return (number = number.replace(pat, "$1****$2"));
      }
    },
  },
  watch: {
    userName () {
      this.userNameAlert = false;
    },
    mobile () {
      this.mobileAlert = false;
      this.changMobile = true;
    },
    overseaGrade (newGrade) {
      this.subjectGrade = newGrade
    },
    overseaSubject (newSubject) {
      this.weakSubjectArr = newSubject[this.$refs.grade.selectedIndex];
    }
  },
  created () {
    // this.subjectGrade = dictionaryNewGradeOversea
    // this.weakSubjectArr = dictionaryBaseSubject
    if (process.browser) {
      this.$store.dispatch('getSubjectData')
    }
    if (this.type === 'chinese') {
      this.weakSubject = '语文思维训练'
    }
  },
  mounted () {
    this.open_id = this.urlQueryObj.open_id;
    // this.subjectGrade = this.overseaGrade;
    // this.weakSubjectArr = this.overseaSubject
    // this.isWX = navigator.userAgent.toLowerCase().match(/MicroMessenger/i) === "micromessenger";
  },
  computed: {
    ...mapState([
      "subjectToLabel",
      "urlQueryObj",
      "subjectData",
      'overseaGrade',
      'overseaSubject'
    ]),
    codeCountDown () {
      if (this.codeCountDownNum === 60 || this.codeCountDownNum <= 0) {
        return this.getCode;
      } else {
        return this.codeCountDownNum + "s";
      }
    },
    getCheckedIcon () {
      const { checked, unchecked } = this.iconCheckState;
      return this.checkProtocol && checked || unchecked;
    },
  },

  methods: {
    getCurrentValue (val) {
      this.newWeakGrade = val.target.value;
      this.weakSubjectArr = this.overseaSubject[this.$refs.grade.selectedIndex];
      if (val.target.value === 'PRIMARY_SCHOOL_4' || val.target.value === 'PRIMARY_SCHOOL_5' || val.target.value === 'PRIMARY_SCHOOL_6') {
        this.newWeakGrade = 'PRIMARY_SCHOOL_3';
        this.hasRealGrade = true
      } else {
        this.hasRealGrade = false
      }
      this.weakSubject = this.weakSubjectArr[0].value;
      this.newWeakGradeLabel = this.getGradeLabel(val.target.value);
    },
    changeProtocol (val) {
      this.checkProtocol = val;
    },
    hideMask () {
      this.showMask = false;
    },
    /**
     * return label 进行匹配
     */
    getGradeLabel (val) {
      let label = ''
      this.subjectGrade.map((item) => {
        if (item.value === this.weakGrade) {
          label = item.label
          return item.label;
        }
      })
      return label;
    },
    // 发送验证码 验证码发送并倒计时
    identifySubmitBtn () {
      GeetestCode(this.areaCode, this.mobile, this);
    },

    // 验证表单
    validateForm () {
      return new Promise((resolve, reject) => {
        if (!/^([a-zA-Z]|[\u4e00-\u9fa5]){1,}$/.test(this.userName)) {
          this.userNameAlert = true;
          reject("请填写正确的孩子姓名");
        } else if (this.userName.length >= 7) {
          this.userNameAlert = true;
          reject("请填写正确的孩子姓名");
        } else if (this.mobile.length === 0) {
          this.mobileAlert = true;
          reject("请输入手机号");
        } else if (
          !/^\d+$/.test(this.codeNumber)
        ) {
          reject("请输入正确的验证码");
        } else if (!this.checkProtocol) {
          reject("请勾选用户协议和隐私政策");
        } else {
          resolve("success");
        }
      });
    },

    // 埋点
    doTrack (position) {
      if (ZM_JSSDK) {
        let eventData = {
          eventId: position.event_id,
          eventParam: {
            name: position.event_name,
            grade: this.weakGrade,
            subject: this.weakSubject,
            trigger_site: position.trigger_site,
            position: position.position
          },
        };
        ZM_JSSDK.sendEvent(eventData);
      }
    },

    // 验证表单并注册
    async handleSubmitBtn () {
      if (this.point) {
        ZM_JSSDK &&
          ZM_JSSDK.sendEvent({
            eventId: this.type === 'math' ? 'click_kid_act13_ljlq' : 'click_kid_act14_ljlq',
            eventParam: {
              position: this.type === 'math' ? '1' : '2',
              code: this.$route.query.channel_code,
            }
          });
      } else {
        ZM_JSSDK &&
          ZM_JSSDK.sendEvent({
            eventId: this.type === 'math' ? 'click_kid_act13_ljlq02' : 'click_kid_act14_ljlq02',
            eventParam: {
              code: this.$route.query.channel_code,
            }
          });
      }
      if (this.isSubmitting) { return; }
      this.isSubmitting = true;
      try {
        await this.validateForm();
        await this.doRegister();
      } catch (e) {
        Toast(e);
      } finally {
        this.isSubmitting = false;
      }
    },

    // 注册
    async doRegister () {
      try {
        const data = {
          areaCode: this.areaCode,
          mobile: this.mobile,
          name: this.userName,
          stuGradeCode: this.newWeakGrade,
          weakSubjectCode: this.weakSubject,
          credentials: this.codeNumber,
          code: this.$route.query.channel_code || "",
          key: this.$route.query.channel_keyword || "",
          checkCodeFlag: true,
          appointmentValue: 9,
        }
        if (this.hasRealGrade) {
          data.jsonInfo = JSON.stringify({ 'commentTitle': '海外注册用户', 'commentBody': `海外注册用户，实际年级为${this.newWeakGradeLabel}年级` })
        }
        const res = await api.KidAppointmentWithCode(data)
        if (res.data.code * 1 === 0) {
          ZM_JSSDK &&
            ZM_JSSDK.sendEvent({
              eventId: this.type === 'math' ? 'enter_kid_act13_success' : 'enter_kid_act14_success',
              eventParam: {
                phone_num: `${this.areaCode}-${this.mobile}`,
                code: this.$route.query.channel_code,
              }
            });
          window.fbq && window.fbq('track', 'Lead');
          window.gtag && window.gtag('event', 'conversion', { 'send_to': 'AW-600839676/AtU-CPruwNwBEPyrwJ4C' });
          window.gtag && window.gtag('event', 'conversion', { 'send_to': 'AW-465936768/bOk6CM32m-wBEIDDlt4B' });
          this.$router.push({
            name: this.areaCode === 86 ? 'appointment-success-kid' : 'appointment-success-kid-not86',
            params: {
              mobile: this.areaCode === 86 ? this.mobile : `${this.areaCode}-${this.mobile}`,
              flatfrom: this.$route.name || '',
              grade: this.weakGrade,
              channel_code: this.$route.query.channel_code,
              channel_keyword: this.$route.query.channel_code,
              showPeilian: false,
              from: window.location.pathname
            },
          });
        } else {
          Toast(res.data.message);
        }
      } catch (e) {
        Toast(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$theme: #ff6e35;
$fz-protocol: 22px;
$bgColor: #fff;
$black0: #000;
$black3: #333;
$black6: #666;
$black9: #999;
$space: 14px;
.area-code {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-right: 7px;
  border-radius: 20px;
  padding-left: 19px;
  width: 126px;
  height: 86px;
  background: #eeeeee;
  font-size: 28px;
  color: #999999;
  box-sizing: border-box;
}
@mixin utils-flex(
  $align-item: center,
  $justify-content: center,
  $direction: row,
  $wrap: nowrap
) {
  display: flex;
  align-items: $align-item;
  justify-content: $justify-content;
  flex-flow: $direction $wrap;
}
.new-pay3 {
  &__lesson-form {
    overflow: hidden;
    width: 688px;
    height: 886px;
    background: url('https://static-app-manager.zmlearn.com/tKEKA2hi2687.png')
      no-repeat -12px 0 / cover;
  }
  &__extra-class {
    position: absolute;
    bottom: -20px;
    background: none;
    .new-pay3__form-content {
      margin-top: 120px;
    }
    .new-pay3__zm-btn {
      // visibility: hidden ;
    }
    .new-pay3__send-code {
      right: 50px;
    }
  }
  &__form-content {
    margin-top: 178px;
  }
  &__form-item {
    width: 100%;
    padding: 0 32px 16px;
    box-sizing: border-box;
    @include utils-flex;
    &--content {
      width: 100%;
      height: 86px;
      padding: 0 20px;
      border-radius: 20px;
      border: none;
      box-sizing: border-box;
      color: #202020;
      background: #eee;
      font-size: 28px;

      &::placeholder {
        color: #999;
      }
    }
  }
  &__grade-subject {
    justify-content: space-between;
  }
  &__grade,
  &__subject {
    position: relative;
    width: 48%;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url('https://web-data.zmlearn.com/image/8kzGTUynStSU5ZM1u8uSqm/arrow_03.png')
      no-repeat 90% center transparent;
    background-size: 26px 16px;
    background-color: #eee;
    padding: 0 30px 0 20px;
    font-size: 28px;
  }
  &__title {
    font-size: 55px;
    padding: 40px;
    color: $theme;
  }
  &__send-code {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 80px;
    border-radius: 10px;
    width: 181px;
    height: 66px;
    background: #ff7533;
    font-size: 28px;
    color: #fff;
  }
  &__form-protocol {
    @include utils-flex;
    &--text {
      font-size: $fz-protocol;
      color: #2b2b2b;
    }
    &--high-light {
      font-size: $fz-protocol;
    }
    &--high-light {
      color: $theme;
    }
  }
  &__checkoutImg {
    width: 26px;
    margin-right: $space;
  }
  &__lock {
    width: 18px;
    margin-right: $space;
  }
  &__info {
    margin-top: 20px;
    color: #575757;
  }
  &__zm-btn {
    width: 634px;
    height: 138px;
  }
}
</style>
