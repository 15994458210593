export default {
  SET_LOADING_STATE: (state: any, status: any) => {
    state.isLoading = status
  },

  SET_CLICK_IDS(state: any, payload: any) {
    let { key, value } = payload
    if (key && value) {
      state.clickIds[key] = value
    }
  },

  // 获取年级科目
  GET_SUBJECT_DATA(state: any, res: any) {
    let grade: any = []
    let gradeV1: any = []
    let kid_grade: any = [] // for lesson_form_kid
    let subject: any = []
    let subjectV1: any = []
    let kid_subject: any = [] // for lesson_form_kid
    let subjectToLabel: any = {}
    // let gradeToLabel = {}
    // 海外年级科目
    let overseaGrade: any = []
    let overseaSubject: any = []

    try {
      const initGrade = res.data

      if (!initGrade.length) {
        throw new Error('no data')
      }

      initGrade.forEach((i: any, index: any) => {
        // 初四对应初三会导致v-model只能渲染第一个label为初三的项目，临时修改，后面待完善
        if (i.extendLabel == '初四' && i.label == '初三') {
          i.label = '初四'
        }
        if ((i.value || i.extendValue).indexOf('KINDERGARTEN') > -1) {
          i.children.forEach((item: any, indexC: number) => {
            if (item.value !== 'MATH_LOGIC' && item.value !== 'CHINESE_LOGIC') {
              delete i.children[indexC];
            }
          });
        }
        grade.push([i.value, i.extendLabel])
        gradeV1.push([i.label, i.extendLabel, i.value])
        overseaGrade.push({
          'label': i.extendLabel,
          'value': i.value
        })

        kid_grade.push([i.label, i.extendLabel, i.value]) // for lesson_form_kid
        let childsubject: any = []
        let childsubjectV1: any = []
        let kid_childsubject: any = []
        let overseaChildsubject: any = []
        subject.push(childsubject)
        subjectV1.push(childsubjectV1)
        kid_subject.push(kid_childsubject) // for lesson_form_kid
        overseaSubject.push(overseaChildsubject)

        res.data[index].children && res.data[index].children.forEach((item: any) => {
          if ((item.extendLabel || item.label) != '学习力训练') {
            childsubject.push([item.label, item.value])
            childsubjectV1.push([item.extendLabel, item.label, item.value])
            kid_childsubject.push([item.extendLabel, item.label, item.value]) // for lesson_form_kid
            if ((item.extendLabel || item.label) != '未入园') {
              overseaChildsubject.push({
                'label': item.label,
                'value': item.value
              })
            }
          }
          if (item.label !== item.extendLabel && !subjectToLabel[item.label]) {
            // subjectToLabel[item.label] = item.extendLabel
            subjectToLabel[item.extendLabel] = item.label
          }
        })
      })

      state.subjectGrade = grade
      state.subjectGradeV1 = gradeV1
      state.kid_subjectGrade = kid_grade // for lesson_form_kid
      state.subjectData = subject
      state.subjectDataV1 = subjectV1
      state.kid_subjectData = kid_subject // for lesson_form_kid
      state.subjectToLabel = subjectToLabel
      state.overseaGrade = overseaGrade.slice(0, 8)
      state.overseaSubject = overseaSubject.slice(0, 8)
      console.log('state.overseaSubject', state.overseaSubject)
      // state.gradeToLabel = gradeToLabel
    } catch (e) {
      const {
        gradeData,
        subjectData,
        subjectToLabelData
        // gradeToLabel
      } = require('@/assets/js/prepareData')

      const {
        kid_gradeData,
        kid_subjectData
      } = require('@/assets/js/kid_prepareData')

      state.subjectGrade = gradeData
      state.subjectGradeV1 = gradeData
      state.kid_subjectGrade = kid_gradeData // for lesson_form_kid
      state.subjectData = subjectData
      state.subjectDataV1 = subjectData
      state.kid_subjectData = kid_subjectData // for lesson_form_kid
      state.subjectToLabel = subjectToLabelData
      // state.gradeToLabel = gradeToLabel
    }
  }
}
