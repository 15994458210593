import buyClass493501 from '@/views/buyClassAI/buyClass493501/index.vue'
import buyClass493502 from '@/views/buyClassAI/buyClass493502/index.vue'
import wxSuccessAI from '@/views/buyClassAI/basePage/wxSuccess.vue'
import h5SuccessAI from '@/views/buyClassAI/basePage/h5Success.vue'
import buyClassFormAI from '@/views/buyClassAI/basePage/form.vue'
export default [
  {
    path: '/buyClass4935-01',
    name: 'buyClass4935-01',
    component: buyClass493501,
    description: '0.01AI落地页-3811',
  },
  {
    path: '/buyClass4935-02',
    name: 'buyClass4935-02',
    component: buyClass493502,
    description: '0.01AI落地页-3742',
  },
  {
    path: '/buyClassFormAI',
    name: 'buyClassFormAI',
    component: buyClassFormAI,
    description: '表单填写',
    beforeEnter: (to: any, from: any, next: any) => {
      console.log(to, 'op')
      if (window.ZM_JSSDK.os.browserName === 'WeChat' && !to.query.openId) {
        let baseUrl: any = ''
        let mUrl: any = ''
        if (
          process.env.NODE_ENV === 'production'
        ) {
          baseUrl = 'https://kids-active.zhangmenkid.com/'
          mUrl = 'https://m.zhangmenkid.com/';
        } else {
          baseUrl = 'https://kids-active.zmaxis.com/'
          mUrl = 'http://m-test.zhangmenkid.com/'
          // mUrl = 'http://172.25.108.47:8088/'
        }
        const rediUrl = `${mUrl}m-page/buyClassFormAI?type=${to.query.type}&channel_code=${to.query.channel_code}&channel_keyword=${to.query.channel_keyword}&subjectCode=${to.query.subjectCode}&gradeCode=${to.query.gradeCode}&clickId=${to.query.clickId}`;
        const url = `${baseUrl}kids/api/wxmp/mp/web/auth/learning/center/base?needToken=false&needLogin=false&redirect=${encodeURIComponent(`${rediUrl}`)}`;
        window.location.replace(url);
        // next();
      } else {
        next();
      }
    }

  },
  {
    path: '/wxSuccessAI',
    name: 'wxSuccessAI',
    component: wxSuccessAI,
    description: '微信支付成功页'
  },
  {
    path: '/h5SuccessAI',
    name: 'h5SuccessAI',
    component: h5SuccessAI,
    description: 'h5支付成功页'
  }
]