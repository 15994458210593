export const dictionaryGradeAndSubject = [
  {
    label: '未入园',
    value: 'PREPARATORY_KINDERGAREN', // 接口返回的就是如此，拼写错误
    children: [{ label: '语文素养', value: 'ACCOMPLISHMENT' }, { label: '英语启蒙', value: 'ENLIGHTENMENT' }]
  },
  {
    label: '幼儿园小班',
    value: 'KINDERGARTEN_1',
    children: [{ label: '语文素养', value: 'ACCOMPLISHMENT' }, { label: '北美外教', value: 'NORTH_AMERICAN_TEACHERS' }]
  },
  {
    label: '幼儿园中班',
    value: 'KINDERGARTEN_2',
    children: [{ label: '语文思维训练', value: 'CHINESE_LOGIC' }, { label: '数理逻辑训练', value: 'MATH_LOGIC' }]
  },
  {
    label: '幼儿园大班',
    value: 'KINDERGARTEN_3',
    children: [{ label: '语文思维训练', value: 'CHINESE_LOGIC' }, { label: '数理逻辑训练', value: 'MATH_LOGIC' }]
  },
  {
    label: '小一',
    value: 'PRIMARY_SCHOOL_1',
    children: [{ label: '语文思维训练', value: 'CHINESE_LOGIC' }, { label: '数理逻辑训练', value: 'MATH_LOGIC' }]
  },
  {
    label: '小二',
    value: 'PRIMARY_SCHOOL_2',
    children: [{ label: '语文思维训练', value: 'CHINESE_LOGIC' }, { label: '数理逻辑训练', value: 'MATH_LOGIC' }]
  },
  {
    label: '小三',
    value: 'PRIMARY_SCHOOL_3',
    children: [{ label: '语文思维训练', value: 'CHINESE_LOGIC' }, { label: '数理逻辑训练', value: 'MATH_LOGIC' }]
  },
  {
    label: '小四',
    value: 'PRIMARY_SCHOOL_4',
    children: [{ label: '数学', value: 'MATH' }, { label: '语文', value: 'CHINESE' }, { label: '英语', value: 'ENGLISH' }, { label: '数理思维', value: 'MATH_OLYMPIAD' }, { label: '钢琴陪练', value: 'PIANO_PARTNER' }, { label: '小提琴陪练', value: 'VIOLIN' }]
  },
  {
    label: '小五',
    value: 'PRIMARY_SCHOOL_5',
    children: [{ label: '数学', value: 'MATH' }, { label: '语文', value: 'CHINESE' }, { label: '英语', value: 'ENGLISH' }, { label: '数理思维', value: 'MATH_OLYMPIAD' }, { label: '钢琴陪练', value: 'PIANO_PARTNER' }, { label: '小提琴陪练', value: 'VIOLIN' }]
  },
  {
    label: '小六',
    value: 'PRIMARY_SCHOOL_6',
    children: [{ label: '数学', value: 'MATH' }, { label: '语文', value: 'CHINESE' }, { label: '英语', value: 'ENGLISH' }, { label: '数理思维', value: 'MATH_OLYMPIAD' }, { label: '钢琴陪练', value: 'PIANO_PARTNER' }, { label: '小提琴陪练', value: 'VIOLIN' }]
  },
  {
    label: '初一',
    value: 'JUNIOR_MIDDLE_SCHOOL_1',
    children: [{ label: '数学', value: 'MATH' }, { label: '语文', value: 'CHINESE' }, { label: '英语', value: 'ENGLISH' }, { label: '政治', value: 'POLITICS' }, { label: '历史', value: 'HISTORY' }, { label: '地理', value: 'GEOGRAPHY' }, { label: '生物', value: 'BIOLOGY' }, { label: '科学', value: 'SCIENCE' }, { label: '钢琴陪练', value: 'PIANO_PARTNER' }, { label: '小提琴陪练', value: 'VIOLIN' }]
  },
  {
    label: '初二',
    value: 'JUNIOR_MIDDLE_SCHOOL_2',
    children: [{ label: '数学', value: 'MATH' }, { label: '语文', value: 'CHINESE' }, { label: '英语', value: 'ENGLISH' }, { label: '政治', value: 'POLITICS' }, { label: '历史', value: 'HISTORY' }, { label: '地理', value: 'GEOGRAPHY' }, { label: '生物', value: 'BIOLOGY' }, { label: '科学', value: 'SCIENCE' }, { label: '钢琴陪练', value: 'PIANO_PARTNER' }, { label: '物理', value: 'PHYSICAL' }, { label: '小提琴陪练', value: 'VIOLIN' }]
  },
  {
    label: '初三',
    value: 'JUNIOR_MIDDLE_SCHOOL_3',
    children: [{ label: '数学', value: 'MATH' }, { label: '语文', value: 'CHINESE' }, { label: '英语', value: 'ENGLISH' }, { label: '政治', value: 'POLITICS' }, { label: '历史', value: 'HISTORY' }, { label: '地理', value: 'GEOGRAPHY' }, { label: '化学', value: 'CHEMISTRY' }, { label: '生物', value: 'BIOLOGY' }, { label: '科学', value: 'SCIENCE' }, { label: '钢琴陪练', value: 'PIANO_PARTNER' }, { label: '物理', value: 'PHYSICAL' }, { label: '小提琴陪练', value: 'VIOLIN' }]
  },
  {
    label: '初四',
    value: 'JUNIOR_MIDDLE_SCHOOL_4',
    children: [{ label: '数学', value: 'MATH' }, { label: '语文', value: 'CHINESE' }, { label: '英语', value: 'ENGLISH' }, { label: '政治', value: 'POLITICS' }, { label: '历史', value: 'HISTORY' }, { label: '地理', value: 'GEOGRAPHY' }, { label: '化学', value: 'CHEMISTRY' }, { label: '生物', value: 'BIOLOGY' }, { label: '科学', value: 'SCIENCE' }, { label: '钢琴陪练', value: 'PIANO_PARTNER' }, { label: '物理', value: 'PHYSICAL' }, { label: '小提琴陪练', value: 'VIOLIN' }]
  },
  {
    label: '高一',
    value: 'SENIOR_MIDDLE_SCHOOL_1',
    children: [{ label: '数学', value: 'MATH' }, { label: '语文', value: 'CHINESE' }, { label: '英语', value: 'ENGLISH' }, { label: '政治', value: 'POLITICS' }, { label: '历史', value: 'HISTORY' }, { label: '地理', value: 'GEOGRAPHY' }, { label: '化学', value: 'CHEMISTRY' }, { label: '生物', value: 'BIOLOGY' }, { label: '钢琴陪练', value: 'PIANO_PARTNER' }, { label: '物理', value: 'PHYSICAL' }, { label: '小提琴陪练', value: 'VIOLIN' }]
  },
  {
    label: '高二',
    value: 'SENIOR_MIDDLE_SCHOOL_2',
    children: [{ label: '数学', value: 'MATH' }, { label: '语文', value: 'CHINESE' }, { label: '英语', value: 'ENGLISH' }, { label: '政治', value: 'POLITICS' }, { label: '历史', value: 'HISTORY' }, { label: '地理', value: 'GEOGRAPHY' }, { label: '化学', value: 'CHEMISTRY' }, { label: '生物', value: 'BIOLOGY' }, { label: '钢琴陪练', value: 'PIANO_PARTNER' }, { label: '物理', value: 'PHYSICAL' }, { label: '小提琴陪练', value: 'VIOLIN' }]
  },
  {
    label: '高三',
    value: 'SENIOR_MIDDLE_SCHOOL_3',
    children: [{ label: '数学', value: 'MATH' }, { label: '语文', value: 'CHINESE' }, { label: '英语', value: 'ENGLISH' }, { label: '政治', value: 'POLITICS' }, { label: '历史', value: 'HISTORY' }, { label: '地理', value: 'GEOGRAPHY' }, { label: '化学', value: 'CHEMISTRY' }, { label: '生物', value: 'BIOLOGY' }, { label: '钢琴陪练', value: 'PIANO_PARTNER' }, { label: '物理', value: 'PHYSICAL' }, { label: '小提琴陪练', value: 'VIOLIN' }]
  }
];

export const dictionaryGrade = [
  { label: '未入园', value: 'PREPARATORY_KINDERGAREN' },
  { label: '幼儿园小班', value: 'KINDERGARTEN_1' },
  { label: '幼儿园中班', value: 'KINDERGARTEN_2' },
  { label: '幼儿园大班', value: 'KINDERGARTEN_3' },
  { label: '小一', value: 'PRIMARY_SCHOOL_1' },
  { label: '小二', value: 'PRIMARY_SCHOOL_2' },
  { label: '小三', value: 'PRIMARY_SCHOOL_3' },
  { label: '小四', value: 'PRIMARY_SCHOOL_4' },
  { label: '小五', value: 'PRIMARY_SCHOOL_5' },
  { label: '小六', value: 'PRIMARY_SCHOOL_6' },
  { label: '初一', value: 'JUNIOR_MIDDLE_SCHOOL_1' },
  { label: '初二', value: 'JUNIOR_MIDDLE_SCHOOL_2' },
  { label: '初三', value: 'JUNIOR_MIDDLE_SCHOOL_3' },
  { label: '初四', value: 'JUNIOR_MIDDLE_SCHOOL_4' },
  { label: '高一', value: 'SENIOR_MIDDLE_SCHOOL_1' },
  { label: '高二', value: 'SENIOR_MIDDLE_SCHOOL_2' },
  { label: '高三', value: 'SENIOR_MIDDLE_SCHOOL_3' }
];

export const dictionaryMiddleGrade = [
  { label: '幼儿园中班', value: 'KINDERGARTEN_2' },
  { label: '幼儿园大班', value: 'KINDERGARTEN_3' },
  { label: '小一', value: 'PRIMARY_SCHOOL_1' },
  { label: '小二', value: 'PRIMARY_SCHOOL_2' },
  { label: '小三', value: 'PRIMARY_SCHOOL_3' },
  { label: '小四', value: 'PRIMARY_SCHOOL_4' },
  { label: '小五', value: 'PRIMARY_SCHOOL_5' },
  { label: '小六', value: 'PRIMARY_SCHOOL_6' },
  { label: '初一', value: 'JUNIOR_MIDDLE_SCHOOL_1' },
  { label: '初二', value: 'JUNIOR_MIDDLE_SCHOOL_2' },
  { label: '初三', value: 'JUNIOR_MIDDLE_SCHOOL_3' },
  { label: '初四', value: 'JUNIOR_MIDDLE_SCHOOL_4' },
  { label: '高一', value: 'SENIOR_MIDDLE_SCHOOL_1' },
  { label: '高二', value: 'SENIOR_MIDDLE_SCHOOL_2' },
  { label: '高三', value: 'SENIOR_MIDDLE_SCHOOL_3' }
];

export const dictionaryBaseGrade = [
  { label: '幼儿园中班', value: 'KINDERGARTEN_2' },
  { label: '幼儿园大班', value: 'KINDERGARTEN_3' },
  { label: '小一', value: 'PRIMARY_SCHOOL_1' },
  { label: '小二', value: 'PRIMARY_SCHOOL_2' },
  { label: '小三', value: 'PRIMARY_SCHOOL_3' },
];

export const dictionaryBaseSubject = [
  { label: '语文思维训练', value: 'CHINESE_LOGIC' },
  { label: '数理逻辑训练', value: 'MATH_LOGIC' },
];

export const dictionaryNewGrade = [
  { label: '幼儿园中班', value: 'KINDERGARTEN_2' },
  { label: '幼儿园大班', value: 'KINDERGARTEN_3' },
  { label: '小一', value: 'PRIMARY_SCHOOL_1' },
  { label: '小二', value: 'PRIMARY_SCHOOL_2' },
  { label: '小三', value: 'PRIMARY_SCHOOL_3' },
];
export const dictionaryNewGradeOversea = [
  { label: '幼儿园小班', value: 'KINDERGARTEN_1' },
  { label: '幼儿园中班', value: 'KINDERGARTEN_2' },
  { label: '幼儿园大班', value: 'KINDERGARTEN_3' },
  { label: '小一', value: 'PRIMARY_SCHOOL_1' },
  { label: '小二', value: 'PRIMARY_SCHOOL_2' },
  { label: '小三', value: 'PRIMARY_SCHOOL_3' },
  { label: '小四', value: 'PRIMARY_SCHOOL_4' },
  { label: '小五', value: 'PRIMARY_SCHOOL_5' },
  { label: '小六', value: 'PRIMARY_SCHOOL_6' },
];
export const dictionaryMiddleGradeNew = [
  { label: '幼儿园小班', value: 'KINDERGARTEN_1' },
  { label: '幼儿园中班', value: 'KINDERGARTEN_2' },
  { label: '幼儿园大班', value: 'KINDERGARTEN_3' },
  { label: '小一', value: 'PRIMARY_SCHOOL_1' },
  { label: '小二', value: 'PRIMARY_SCHOOL_2' },
  { label: '小三', value: 'PRIMARY_SCHOOL_3' },
  { label: '小四', value: 'PRIMARY_SCHOOL_4' },
  { label: '小五', value: 'PRIMARY_SCHOOL_5' },
  { label: '小六', value: 'PRIMARY_SCHOOL_6' },
  { label: '初一', value: 'JUNIOR_MIDDLE_SCHOOL_1' },
  { label: '初二', value: 'JUNIOR_MIDDLE_SCHOOL_2' },
  { label: '初三', value: 'JUNIOR_MIDDLE_SCHOOL_3' },
  { label: '初四', value: 'JUNIOR_MIDDLE_SCHOOL_4' },
  { label: '高一', value: 'SENIOR_MIDDLE_SCHOOL_1' },
  { label: '高二', value: 'SENIOR_MIDDLE_SCHOOL_2' },
  { label: '高三', value: 'SENIOR_MIDDLE_SCHOOL_3' }
];
export const dictionaryGradeAndSubjectNew = [
  {
    label: '未入园',
    value: 'PREPARATORY_KINDERGAREN', // 接口返回的就是如此，拼写错误
    children: [{ label: '语文素养', value: 'ACCOMPLISHMENT' }, { label: '英语启蒙', value: 'ENLIGHTENMENT' }]
  },
  { // 幼儿园小班 数理逻辑训练 & 语文思维训练
    label: '幼儿园小班',
    value: 'KINDERGARTEN_1',
    children: [{ label: '语文思维训练', value: 'CHINESE_LOGIC' }, { label: '数理逻辑训练', value: 'MATH_LOGIC' }]
  },
  {
    label: '幼儿园中班',
    value: 'KINDERGARTEN_2',
    children: [{ label: '语文思维训练', value: 'CHINESE_LOGIC' }, { label: '数理逻辑训练', value: 'MATH_LOGIC' }]
  },
  {
    label: '幼儿园大班',
    value: 'KINDERGARTEN_3',
    children: [{ label: '语文思维训练', value: 'CHINESE_LOGIC' }, { label: '数理逻辑训练', value: 'MATH_LOGIC' }]
  },
  {
    label: '小一',
    value: 'PRIMARY_SCHOOL_1',
    children: [{ label: '语文思维训练', value: 'CHINESE_LOGIC' }, { label: '数理逻辑训练', value: 'MATH_LOGIC' }]
  },
  {
    label: '小二',
    value: 'PRIMARY_SCHOOL_2',
    children: [{ label: '语文思维训练', value: 'CHINESE_LOGIC' }, { label: '数理逻辑训练', value: 'MATH_LOGIC' }]
  },
  {
    label: '小三',
    value: 'PRIMARY_SCHOOL_3',
    children: [{ label: '语文思维训练', value: 'CHINESE_LOGIC' }, { label: '数理逻辑训练', value: 'MATH_LOGIC' }]
  },
  {
    label: '小四',
    value: 'PRIMARY_SCHOOL_4',
    children: [{ label: '数学', value: 'MATH' }, { label: '语文', value: 'CHINESE' }, { label: '英语', value: 'ENGLISH' }, { label: '数理思维', value: 'MATH_OLYMPIAD' }, { label: '钢琴陪练', value: 'PIANO_PARTNER' }, { label: '小提琴陪练', value: 'VIOLIN' }]
  },
  {
    label: '小五',
    value: 'PRIMARY_SCHOOL_5',
    children: [{ label: '数学', value: 'MATH' }, { label: '语文', value: 'CHINESE' }, { label: '英语', value: 'ENGLISH' }, { label: '数理思维', value: 'MATH_OLYMPIAD' }, { label: '钢琴陪练', value: 'PIANO_PARTNER' }, { label: '小提琴陪练', value: 'VIOLIN' }]
  },
  {
    label: '小六',
    value: 'PRIMARY_SCHOOL_6',
    children: [{ label: '数学', value: 'MATH' }, { label: '语文', value: 'CHINESE' }, { label: '英语', value: 'ENGLISH' }, { label: '数理思维', value: 'MATH_OLYMPIAD' }, { label: '钢琴陪练', value: 'PIANO_PARTNER' }, { label: '小提琴陪练', value: 'VIOLIN' }]
  },
  {
    label: '初一',
    value: 'JUNIOR_MIDDLE_SCHOOL_1',
    children: [{ label: '数学', value: 'MATH' }, { label: '语文', value: 'CHINESE' }, { label: '英语', value: 'ENGLISH' }, { label: '政治', value: 'POLITICS' }, { label: '历史', value: 'HISTORY' }, { label: '地理', value: 'GEOGRAPHY' }, { label: '生物', value: 'BIOLOGY' }, { label: '科学', value: 'SCIENCE' }, { label: '钢琴陪练', value: 'PIANO_PARTNER' }, { label: '小提琴陪练', value: 'VIOLIN' }]
  },
  {
    label: '初二',
    value: 'JUNIOR_MIDDLE_SCHOOL_2',
    children: [{ label: '数学', value: 'MATH' }, { label: '语文', value: 'CHINESE' }, { label: '英语', value: 'ENGLISH' }, { label: '政治', value: 'POLITICS' }, { label: '历史', value: 'HISTORY' }, { label: '地理', value: 'GEOGRAPHY' }, { label: '生物', value: 'BIOLOGY' }, { label: '科学', value: 'SCIENCE' }, { label: '钢琴陪练', value: 'PIANO_PARTNER' }, { label: '物理', value: 'PHYSICAL' }, { label: '小提琴陪练', value: 'VIOLIN' }]
  },
  {
    label: '初三',
    value: 'JUNIOR_MIDDLE_SCHOOL_3',
    children: [{ label: '数学', value: 'MATH' }, { label: '语文', value: 'CHINESE' }, { label: '英语', value: 'ENGLISH' }, { label: '政治', value: 'POLITICS' }, { label: '历史', value: 'HISTORY' }, { label: '地理', value: 'GEOGRAPHY' }, { label: '化学', value: 'CHEMISTRY' }, { label: '生物', value: 'BIOLOGY' }, { label: '科学', value: 'SCIENCE' }, { label: '钢琴陪练', value: 'PIANO_PARTNER' }, { label: '物理', value: 'PHYSICAL' }, { label: '小提琴陪练', value: 'VIOLIN' }]
  },
  {
    label: '初四',
    value: 'JUNIOR_MIDDLE_SCHOOL_4',
    children: [{ label: '数学', value: 'MATH' }, { label: '语文', value: 'CHINESE' }, { label: '英语', value: 'ENGLISH' }, { label: '政治', value: 'POLITICS' }, { label: '历史', value: 'HISTORY' }, { label: '地理', value: 'GEOGRAPHY' }, { label: '化学', value: 'CHEMISTRY' }, { label: '生物', value: 'BIOLOGY' }, { label: '科学', value: 'SCIENCE' }, { label: '钢琴陪练', value: 'PIANO_PARTNER' }, { label: '物理', value: 'PHYSICAL' }, { label: '小提琴陪练', value: 'VIOLIN' }]
  },
  {
    label: '高一',
    value: 'SENIOR_MIDDLE_SCHOOL_1',
    children: [{ label: '数学', value: 'MATH' }, { label: '语文', value: 'CHINESE' }, { label: '英语', value: 'ENGLISH' }, { label: '政治', value: 'POLITICS' }, { label: '历史', value: 'HISTORY' }, { label: '地理', value: 'GEOGRAPHY' }, { label: '化学', value: 'CHEMISTRY' }, { label: '生物', value: 'BIOLOGY' }, { label: '钢琴陪练', value: 'PIANO_PARTNER' }, { label: '物理', value: 'PHYSICAL' }, { label: '小提琴陪练', value: 'VIOLIN' }]
  },
  {
    label: '高二',
    value: 'SENIOR_MIDDLE_SCHOOL_2',
    children: [{ label: '数学', value: 'MATH' }, { label: '语文', value: 'CHINESE' }, { label: '英语', value: 'ENGLISH' }, { label: '政治', value: 'POLITICS' }, { label: '历史', value: 'HISTORY' }, { label: '地理', value: 'GEOGRAPHY' }, { label: '化学', value: 'CHEMISTRY' }, { label: '生物', value: 'BIOLOGY' }, { label: '钢琴陪练', value: 'PIANO_PARTNER' }, { label: '物理', value: 'PHYSICAL' }, { label: '小提琴陪练', value: 'VIOLIN' }]
  },
  {
    label: '高三',
    value: 'SENIOR_MIDDLE_SCHOOL_3',
    children: [{ label: '数学', value: 'MATH' }, { label: '语文', value: 'CHINESE' }, { label: '英语', value: 'ENGLISH' }, { label: '政治', value: 'POLITICS' }, { label: '历史', value: 'HISTORY' }, { label: '地理', value: 'GEOGRAPHY' }, { label: '化学', value: 'CHEMISTRY' }, { label: '生物', value: 'BIOLOGY' }, { label: '钢琴陪练', value: 'PIANO_PARTNER' }, { label: '物理', value: 'PHYSICAL' }, { label: '小提琴陪练', value: 'VIOLIN' }]
  }
];
export const dictionaryGradeNew = [
  // { label: '幼儿园小班', value: 'KINDERGARTEN_1' },
  { label: '幼儿园中班', value: 'KINDERGARTEN_2' },
  { label: '幼儿园大班', value: 'KINDERGARTEN_3' },
  { label: '一年级', value: 'PRIMARY_SCHOOL_1' },
  { label: '二年级', value: 'PRIMARY_SCHOOL_2' },
  { label: '三年级', value: 'PRIMARY_SCHOOL_3' },
];
export const dictionaryMdashboard = [
  {
    'childNodeCode': 'RESERVATIONS_COURSE',
    'extendLabel': '幼儿园小班',
    'children': [
      {
        'childNodeCode': null,
        'extendLabel': '语文',
        'children': null,
        'childNodeName': null,
        'label': '语文思维训练',
        'extendValue': 'CHINESE',
        'value': 'CHINESE_LOGIC'
      },
      {
        'childNodeCode': null,
        'extendLabel': '奥数',
        'children': null,
        'childNodeName': null,
        'label': '数理逻辑训练',
        'extendValue': 'MATH_OLYMPIAD',
        'value': 'MATH_LOGIC'
      }
    ],
    'childNodeName': '预约科目',
    'label': '幼儿园小班',
    'extendValue': null,
    'value': 'KINDERGARTEN_1'
  },
  {
    'childNodeCode': 'RESERVATIONS_COURSE',
    'extendLabel': '幼儿园中班',
    'children': [
      {
        'childNodeCode': null,
        'extendLabel': '语文',
        'children': null,
        'childNodeName': null,
        'label': '语文思维训练',
        'extendValue': 'CHINESE',
        'value': 'CHINESE_LOGIC'
      },
      {
        'childNodeCode': null,
        'extendLabel': '奥数',
        'children': null,
        'childNodeName': null,
        'label': '数理逻辑训练',
        'extendValue': 'MATH_OLYMPIAD',
        'value': 'MATH_LOGIC'
      }
    ],
    'childNodeName': '预约科目',
    'label': '幼儿园中班',
    'extendValue': null,
    'value': 'KINDERGARTEN_2'
  },
  {
    'childNodeCode': 'RESERVATIONS_COURSE',
    'extendLabel': '幼儿园大班',
    'children': [
      {
        'childNodeCode': null,
        'extendLabel': '语文',
        'children': null,
        'childNodeName': null,
        'label': '语文思维训练',
        'extendValue': 'CHINESE',
        'value': 'CHINESE_LOGIC'
      },
      {
        'childNodeCode': null,
        'extendLabel': '奥数',
        'children': null,
        'childNodeName': null,
        'label': '数理逻辑训练',
        'extendValue': 'MATH_OLYMPIAD',
        'value': 'MATH_LOGIC'
      }
    ],
    'childNodeName': '预约科目',
    'label': '幼儿园大班',
    'extendValue': null,
    'value': 'KINDERGARTEN_3'
  },
  {
    'childNodeCode': 'RESERVATIONS_COURSE',
    'extendLabel': '一年级',
    'children': [
      {
        'childNodeCode': null,
        'extendLabel': '语文',
        'children': null,
        'childNodeName': null,
        'label': '语文思维训练',
        'extendValue': 'CHINESE',
        'value': 'CHINESE_LOGIC'
      },
      {
        'childNodeCode': null,
        'extendLabel': '奥数',
        'children': null,
        'childNodeName': null,
        'label': '数理逻辑训练',
        'extendValue': 'MATH_OLYMPIAD',
        'value': 'MATH_LOGIC'
      },
      {
        'childNodeCode': null,
        'extendLabel': '学习力训练',
        'children': null,
        'childNodeName': null,
        'label': '学习力训练',
        'extendValue': 'LEARNING_ABILITY_TRAINING',
        'value': 'LEARNING_ABILITY_TRAINING'
      },
      {
        'childNodeCode': null,
        'extendLabel': '钢琴陪练',
        'children': null,
        'childNodeName': null,
        'label': '钢琴陪练',
        'extendValue': 'PIANO',
        'value': 'PIANO_PARTNER'
      },
      {
        'childNodeCode': null,
        'extendLabel': '小提琴陪练',
        'children': null,
        'childNodeName': null,
        'label': '小提琴陪练',
        'extendValue': 'VIOLIN',
        'value': 'VIOLIN'
      }
    ],
    'childNodeName': '预约科目',
    'label': '小一',
    'extendValue': null,
    'value': 'PRIMARY_SCHOOL_1'
  },
  {
    'childNodeCode': 'RESERVATIONS_COURSE',
    'extendLabel': '二年级',
    'children': [
      {
        'childNodeCode': null,
        'extendLabel': '语文',
        'children': null,
        'childNodeName': null,
        'label': '语文思维训练',
        'extendValue': 'CHINESE',
        'value': 'CHINESE_LOGIC'
      },
      {
        'childNodeCode': null,
        'extendLabel': '奥数',
        'children': null,
        'childNodeName': null,
        'label': '数理逻辑训练',
        'extendValue': 'MATH_OLYMPIAD',
        'value': 'MATH_LOGIC'
      },
      {
        'childNodeCode': null,
        'extendLabel': '学习力训练',
        'children': null,
        'childNodeName': null,
        'label': '学习力训练',
        'extendValue': 'LEARNING_ABILITY_TRAINING',
        'value': 'LEARNING_ABILITY_TRAINING'
      },
      {
        'childNodeCode': null,
        'extendLabel': '钢琴陪练',
        'children': null,
        'childNodeName': null,
        'label': '钢琴陪练',
        'extendValue': 'PIANO',
        'value': 'PIANO_PARTNER'
      },
      {
        'childNodeCode': null,
        'extendLabel': '小提琴陪练',
        'children': null,
        'childNodeName': null,
        'label': '小提琴陪练',
        'extendValue': 'VIOLIN',
        'value': 'VIOLIN'
      }
    ],
    'childNodeName': '预约科目',
    'label': '小二',
    'extendValue': null,
    'value': 'PRIMARY_SCHOOL_2'
  },
  {
    'childNodeCode': 'RESERVATIONS_COURSE',
    'extendLabel': '三年级',
    'children': [{
      'childNodeCode': null,
      'extendLabel': '语文',
      'children': null,
      'childNodeName': null,
      'label': '语文思维训练',
      'extendValue': 'CHINESE',
      'value': 'CHINESE_LOGIC'
    },
    {
      'childNodeCode': null,
      'extendLabel': '奥数',
      'children': null,
      'childNodeName': null,
      'label': '数理逻辑训练',
      'extendValue': 'MATH_OLYMPIAD',
      'value': 'MATH_LOGIC'
    },
    {
      'childNodeCode': null,
      'extendLabel': '学习力训练',
      'children': null,
      'childNodeName': null,
      'label': '学习力训练',
      'extendValue': 'LEARNING_ABILITY_TRAINING',
      'value': 'LEARNING_ABILITY_TRAINING'
    },
    {
      'childNodeCode': null,
      'extendLabel': '钢琴陪练',
      'children': null,
      'childNodeName': null,
      'label': '钢琴陪练',
      'extendValue': 'PIANO',
      'value': 'PIANO_PARTNER'
    },
    {
      'childNodeCode': null,
      'extendLabel': '小提琴陪练',
      'children': null,
      'childNodeName': null,
      'label': '小提琴陪练',
      'extendValue': 'VIOLIN',
      'value': 'VIOLIN'
    }
    ],
    'childNodeName': '预约科目',
    'label': '小三',
    'extendValue': null,
    'value': 'PRIMARY_SCHOOL_3'
  },
  {
    'childNodeCode': 'RESERVATIONS_COURSE',
    'extendLabel': '四年级',
    'children': [{
      'childNodeCode': null,
      'extendLabel': '数学',
      'children': null,
      'childNodeName': null,
      'label': '数学',
      'extendValue': 'MATH',
      'value': 'MATH'
    },
    {
      'childNodeCode': null,
      'extendLabel': '语文',
      'children': null,
      'childNodeName': null,
      'label': '语文',
      'extendValue': 'CHINESE',
      'value': 'CHINESE'
    },
    {
      'childNodeCode': null,
      'extendLabel': '英语',
      'children': null,
      'childNodeName': null,
      'label': '英语',
      'extendValue': 'ENGLISH',
      'value': 'ENGLISH'
    },
    {
      'childNodeCode': null,
      'extendLabel': '奥数',
      'children': null,
      'childNodeName': null,
      'label': '数理思维',
      'extendValue': 'MATH_OLYMPIAD',
      'value': 'MATH_OLYMPIAD'
    },
    {
      'childNodeCode': null,
      'extendLabel': '钢琴陪练',
      'children': null,
      'childNodeName': null,
      'label': '钢琴陪练',
      'extendValue': 'PIANO',
      'value': 'PIANO_PARTNER'
    },
    {
      'childNodeCode': null,
      'extendLabel': '小提琴陪练',
      'children': null,
      'childNodeName': null,
      'label': '小提琴陪练',
      'extendValue': 'VIOLIN',
      'value': 'VIOLIN'
    },
    {
      'childNodeCode': null,
      'extendLabel': '日语',
      'children': null,
      'childNodeName': null,
      'label': '日语',
      'extendValue': 'JAPANESE',
      'value': 'JAPANESE'
    }
    ],
    'childNodeName': '预约科目',
    'label': '小四',
    'extendValue': null,
    'value': 'PRIMARY_SCHOOL_4'
  },
  {
    'childNodeCode': 'RESERVATIONS_COURSE',
    'extendLabel': '五年级',
    'children': [{
      'childNodeCode': null,
      'extendLabel': '数学',
      'children': null,
      'childNodeName': null,
      'label': '数学',
      'extendValue': 'MATH',
      'value': 'MATH'
    },
    {
      'childNodeCode': null,
      'extendLabel': '语文',
      'children': null,
      'childNodeName': null,
      'label': '语文',
      'extendValue': 'CHINESE',
      'value': 'CHINESE'
    },
    {
      'childNodeCode': null,
      'extendLabel': '英语',
      'children': null,
      'childNodeName': null,
      'label': '英语',
      'extendValue': 'ENGLISH',
      'value': 'ENGLISH'
    },
    {
      'childNodeCode': null,
      'extendLabel': '奥数',
      'children': null,
      'childNodeName': null,
      'label': '数理思维',
      'extendValue': 'MATH_OLYMPIAD',
      'value': 'MATH_OLYMPIAD'
    },
    {
      'childNodeCode': null,
      'extendLabel': '钢琴陪练',
      'children': null,
      'childNodeName': null,
      'label': '钢琴陪练',
      'extendValue': 'PIANO',
      'value': 'PIANO_PARTNER'
    },
    {
      'childNodeCode': null,
      'extendLabel': '小提琴陪练',
      'children': null,
      'childNodeName': null,
      'label': '小提琴陪练',
      'extendValue': 'VIOLIN',
      'value': 'VIOLIN'
    },
    {
      'childNodeCode': null,
      'extendLabel': '日语',
      'children': null,
      'childNodeName': null,
      'label': '日语',
      'extendValue': 'JAPANESE',
      'value': 'JAPANESE'
    }
    ],
    'childNodeName': '预约科目',
    'label': '小五',
    'extendValue': null,
    'value': 'PRIMARY_SCHOOL_5'
  },
  {
    'childNodeCode': 'RESERVATIONS_COURSE',
    'extendLabel': '六年级/预初',
    'children': [{
      'childNodeCode': null,
      'extendLabel': '数学',
      'children': null,
      'childNodeName': null,
      'label': '数学',
      'extendValue': 'MATH',
      'value': 'MATH'
    },
    {
      'childNodeCode': null,
      'extendLabel': '语文',
      'children': null,
      'childNodeName': null,
      'label': '语文',
      'extendValue': 'CHINESE',
      'value': 'CHINESE'
    },
    {
      'childNodeCode': null,
      'extendLabel': '英语',
      'children': null,
      'childNodeName': null,
      'label': '英语',
      'extendValue': 'ENGLISH',
      'value': 'ENGLISH'
    },
    {
      'childNodeCode': null,
      'extendLabel': '奥数',
      'children': null,
      'childNodeName': null,
      'label': '数理思维',
      'extendValue': 'MATH_OLYMPIAD',
      'value': 'MATH_OLYMPIAD'
    },
    {
      'childNodeCode': null,
      'extendLabel': '钢琴陪练',
      'children': null,
      'childNodeName': null,
      'label': '钢琴陪练',
      'extendValue': 'PIANO',
      'value': 'PIANO_PARTNER'
    },
    {
      'childNodeCode': null,
      'extendLabel': '小提琴陪练',
      'children': null,
      'childNodeName': null,
      'label': '小提琴陪练',
      'extendValue': 'VIOLIN',
      'value': 'VIOLIN'
    },
    {
      'childNodeCode': null,
      'extendLabel': '日语',
      'children': null,
      'childNodeName': null,
      'label': '日语',
      'extendValue': 'JAPANESE',
      'value': 'JAPANESE'
    }
    ],
    'childNodeName': '预约科目',
    'label': '小六',
    'extendValue': null,
    'value': 'PRIMARY_SCHOOL_6'
  },
  {
    'childNodeCode': 'RESERVATIONS_COURSE',
    'extendLabel': '初一',
    'children': [{
      'childNodeCode': null,
      'extendLabel': '数学',
      'children': null,
      'childNodeName': null,
      'label': '数学',
      'extendValue': 'MATH',
      'value': 'MATH'
    },
    {
      'childNodeCode': null,
      'extendLabel': '语文',
      'children': null,
      'childNodeName': null,
      'label': '语文',
      'extendValue': 'CHINESE',
      'value': 'CHINESE'
    },
    {
      'childNodeCode': null,
      'extendLabel': '英语',
      'children': null,
      'childNodeName': null,
      'label': '英语',
      'extendValue': 'ENGLISH',
      'value': 'ENGLISH'
    },
    {
      'childNodeCode': null,
      'extendLabel': '政治',
      'children': null,
      'childNodeName': null,
      'label': '政治',
      'extendValue': 'POLITICS',
      'value': 'POLITICS'
    },
    {
      'childNodeCode': null,
      'extendLabel': '历史',
      'children': null,
      'childNodeName': null,
      'label': '历史',
      'extendValue': 'HISTORY',
      'value': 'HISTORY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '地理',
      'children': null,
      'childNodeName': null,
      'label': '地理',
      'extendValue': 'GEOGRAPHY',
      'value': 'GEOGRAPHY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '生物',
      'children': null,
      'childNodeName': null,
      'label': '生物',
      'extendValue': 'BIOLOGICAL',
      'value': 'BIOLOGY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '科学',
      'children': null,
      'childNodeName': null,
      'label': '科学',
      'extendValue': 'SCIENCE',
      'value': 'SCIENCE'
    },
    {
      'childNodeCode': null,
      'extendLabel': '钢琴陪练',
      'children': null,
      'childNodeName': null,
      'label': '钢琴陪练',
      'extendValue': 'PIANO',
      'value': 'PIANO_PARTNER'
    },
    {
      'childNodeCode': null,
      'extendLabel': '小提琴陪练',
      'children': null,
      'childNodeName': null,
      'label': '小提琴陪练',
      'extendValue': 'VIOLIN',
      'value': 'VIOLIN'
    },
    {
      'childNodeCode': null,
      'extendLabel': '日语',
      'children': null,
      'childNodeName': null,
      'label': '日语',
      'extendValue': 'JAPANESE',
      'value': 'JAPANESE'
    }
    ],
    'childNodeName': '预约科目',
    'label': '初一',
    'extendValue': null,
    'value': 'JUNIOR_MIDDLE_SCHOOL_1'
  },
  {
    'childNodeCode': 'RESERVATIONS_COURSE',
    'extendLabel': '初二',
    'children': [{
      'childNodeCode': null,
      'extendLabel': '数学',
      'children': null,
      'childNodeName': null,
      'label': '数学',
      'extendValue': 'MATH',
      'value': 'MATH'
    },
    {
      'childNodeCode': null,
      'extendLabel': '语文',
      'children': null,
      'childNodeName': null,
      'label': '语文',
      'extendValue': 'CHINESE',
      'value': 'CHINESE'
    },
    {
      'childNodeCode': null,
      'extendLabel': '英语',
      'children': null,
      'childNodeName': null,
      'label': '英语',
      'extendValue': 'ENGLISH',
      'value': 'ENGLISH'
    },
    {
      'childNodeCode': null,
      'extendLabel': '政治',
      'children': null,
      'childNodeName': null,
      'label': '政治',
      'extendValue': 'POLITICS',
      'value': 'POLITICS'
    },
    {
      'childNodeCode': null,
      'extendLabel': '历史',
      'children': null,
      'childNodeName': null,
      'label': '历史',
      'extendValue': 'HISTORY',
      'value': 'HISTORY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '地理',
      'children': null,
      'childNodeName': null,
      'label': '地理',
      'extendValue': 'GEOGRAPHY',
      'value': 'GEOGRAPHY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '生物',
      'children': null,
      'childNodeName': null,
      'label': '生物',
      'extendValue': 'BIOLOGICAL',
      'value': 'BIOLOGY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '科学',
      'children': null,
      'childNodeName': null,
      'label': '科学',
      'extendValue': 'SCIENCE',
      'value': 'SCIENCE'
    },
    {
      'childNodeCode': null,
      'extendLabel': '钢琴陪练',
      'children': null,
      'childNodeName': null,
      'label': '钢琴陪练',
      'extendValue': 'PIANO',
      'value': 'PIANO_PARTNER'
    },
    {
      'childNodeCode': null,
      'extendLabel': '物理',
      'children': null,
      'childNodeName': null,
      'label': '物理',
      'extendValue': 'PHYSICAL',
      'value': 'PHYSICAL'
    },
    {
      'childNodeCode': null,
      'extendLabel': '小提琴陪练',
      'children': null,
      'childNodeName': null,
      'label': '小提琴陪练',
      'extendValue': 'VIOLIN',
      'value': 'VIOLIN'
    },
    {
      'childNodeCode': null,
      'extendLabel': '日语',
      'children': null,
      'childNodeName': null,
      'label': '日语',
      'extendValue': 'JAPANESE',
      'value': 'JAPANESE'
    }
    ],
    'childNodeName': '预约科目',
    'label': '初二',
    'extendValue': null,
    'value': 'JUNIOR_MIDDLE_SCHOOL_2'
  },
  {
    'childNodeCode': 'RESERVATIONS_COURSE',
    'extendLabel': '初三',
    'children': [{
      'childNodeCode': null,
      'extendLabel': '数学',
      'children': null,
      'childNodeName': null,
      'label': '数学',
      'extendValue': 'MATH',
      'value': 'MATH'
    },
    {
      'childNodeCode': null,
      'extendLabel': '语文',
      'children': null,
      'childNodeName': null,
      'label': '语文',
      'extendValue': 'CHINESE',
      'value': 'CHINESE'
    },
    {
      'childNodeCode': null,
      'extendLabel': '英语',
      'children': null,
      'childNodeName': null,
      'label': '英语',
      'extendValue': 'ENGLISH',
      'value': 'ENGLISH'
    },
    {
      'childNodeCode': null,
      'extendLabel': '政治',
      'children': null,
      'childNodeName': null,
      'label': '政治',
      'extendValue': 'POLITICS',
      'value': 'POLITICS'
    },
    {
      'childNodeCode': null,
      'extendLabel': '历史',
      'children': null,
      'childNodeName': null,
      'label': '历史',
      'extendValue': 'HISTORY',
      'value': 'HISTORY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '地理',
      'children': null,
      'childNodeName': null,
      'label': '地理',
      'extendValue': 'GEOGRAPHY',
      'value': 'GEOGRAPHY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '化学',
      'children': null,
      'childNodeName': null,
      'label': '化学',
      'extendValue': 'CHEMISTRY',
      'value': 'CHEMISTRY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '生物',
      'children': null,
      'childNodeName': null,
      'label': '生物',
      'extendValue': 'BIOLOGICAL',
      'value': 'BIOLOGY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '科学',
      'children': null,
      'childNodeName': null,
      'label': '科学',
      'extendValue': 'SCIENCE',
      'value': 'SCIENCE'
    },
    {
      'childNodeCode': null,
      'extendLabel': '钢琴陪练',
      'children': null,
      'childNodeName': null,
      'label': '钢琴陪练',
      'extendValue': 'PIANO',
      'value': 'PIANO_PARTNER'
    },
    {
      'childNodeCode': null,
      'extendLabel': '物理',
      'children': null,
      'childNodeName': null,
      'label': '物理',
      'extendValue': 'PHYSICAL',
      'value': 'PHYSICAL'
    },
    {
      'childNodeCode': null,
      'extendLabel': '小提琴陪练',
      'children': null,
      'childNodeName': null,
      'label': '小提琴陪练',
      'extendValue': 'VIOLIN',
      'value': 'VIOLIN'
    },
    {
      'childNodeCode': null,
      'extendLabel': '日语',
      'children': null,
      'childNodeName': null,
      'label': '日语',
      'extendValue': 'JAPANESE',
      'value': 'JAPANESE'
    }
    ],
    'childNodeName': '预约科目',
    'label': '初三',
    'extendValue': null,
    'value': 'JUNIOR_MIDDLE_SCHOOL_3'
  },
  {
    'childNodeCode': 'RESERVATIONS_COURSE',
    'extendLabel': '初四',
    'children': [{
      'childNodeCode': null,
      'extendLabel': '数学',
      'children': null,
      'childNodeName': null,
      'label': '数学',
      'extendValue': 'MATH',
      'value': 'MATH'
    },
    {
      'childNodeCode': null,
      'extendLabel': '语文',
      'children': null,
      'childNodeName': null,
      'label': '语文',
      'extendValue': 'CHINESE',
      'value': 'CHINESE'
    },
    {
      'childNodeCode': null,
      'extendLabel': '英语',
      'children': null,
      'childNodeName': null,
      'label': '英语',
      'extendValue': 'ENGLISH',
      'value': 'ENGLISH'
    },
    {
      'childNodeCode': null,
      'extendLabel': '政治',
      'children': null,
      'childNodeName': null,
      'label': '政治',
      'extendValue': 'POLITICS',
      'value': 'POLITICS'
    },
    {
      'childNodeCode': null,
      'extendLabel': '历史',
      'children': null,
      'childNodeName': null,
      'label': '历史',
      'extendValue': 'HISTORY',
      'value': 'HISTORY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '地理',
      'children': null,
      'childNodeName': null,
      'label': '地理',
      'extendValue': 'GEOGRAPHY',
      'value': 'GEOGRAPHY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '化学',
      'children': null,
      'childNodeName': null,
      'label': '化学',
      'extendValue': 'CHEMISTRY',
      'value': 'CHEMISTRY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '生物',
      'children': null,
      'childNodeName': null,
      'label': '生物',
      'extendValue': 'BIOLOGICAL',
      'value': 'BIOLOGY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '科学',
      'children': null,
      'childNodeName': null,
      'label': '科学',
      'extendValue': 'SCIENCE',
      'value': 'SCIENCE'
    },
    {
      'childNodeCode': null,
      'extendLabel': '钢琴陪练',
      'children': null,
      'childNodeName': null,
      'label': '钢琴陪练',
      'extendValue': 'PIANO',
      'value': 'PIANO_PARTNER'
    },
    {
      'childNodeCode': null,
      'extendLabel': '物理',
      'children': null,
      'childNodeName': null,
      'label': '物理',
      'extendValue': 'PHYSICAL',
      'value': 'PHYSICAL'
    },
    {
      'childNodeCode': null,
      'extendLabel': '小提琴陪练',
      'children': null,
      'childNodeName': null,
      'label': '小提琴陪练',
      'extendValue': 'VIOLIN',
      'value': 'VIOLIN'
    }
    ],
    'childNodeName': '预约科目',
    'label': '初四',
    'extendValue': null,
    'value': 'JUNIOR_MIDDLE_SCHOOL_4'
  },
  {
    'childNodeCode': 'RESERVATIONS_COURSE',
    'extendLabel': '高一',
    'children': [{
      'childNodeCode': null,
      'extendLabel': '数学',
      'children': null,
      'childNodeName': null,
      'label': '数学',
      'extendValue': 'MATH',
      'value': 'MATH'
    },
    {
      'childNodeCode': null,
      'extendLabel': '语文',
      'children': null,
      'childNodeName': null,
      'label': '语文',
      'extendValue': 'CHINESE',
      'value': 'CHINESE'
    },
    {
      'childNodeCode': null,
      'extendLabel': '英语',
      'children': null,
      'childNodeName': null,
      'label': '英语',
      'extendValue': 'ENGLISH',
      'value': 'ENGLISH'
    },
    {
      'childNodeCode': null,
      'extendLabel': '政治',
      'children': null,
      'childNodeName': null,
      'label': '政治',
      'extendValue': 'POLITICS',
      'value': 'POLITICS'
    },
    {
      'childNodeCode': null,
      'extendLabel': '历史',
      'children': null,
      'childNodeName': null,
      'label': '历史',
      'extendValue': 'HISTORY',
      'value': 'HISTORY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '地理',
      'children': null,
      'childNodeName': null,
      'label': '地理',
      'extendValue': 'GEOGRAPHY',
      'value': 'GEOGRAPHY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '化学',
      'children': null,
      'childNodeName': null,
      'label': '化学',
      'extendValue': 'CHEMISTRY',
      'value': 'CHEMISTRY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '生物',
      'children': null,
      'childNodeName': null,
      'label': '生物',
      'extendValue': 'BIOLOGICAL',
      'value': 'BIOLOGY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '钢琴陪练',
      'children': null,
      'childNodeName': null,
      'label': '钢琴陪练',
      'extendValue': 'PIANO',
      'value': 'PIANO_PARTNER'
    },
    {
      'childNodeCode': null,
      'extendLabel': '物理',
      'children': null,
      'childNodeName': null,
      'label': '物理',
      'extendValue': 'PHYSICAL',
      'value': 'PHYSICAL'
    },
    {
      'childNodeCode': null,
      'extendLabel': '小提琴陪练',
      'children': null,
      'childNodeName': null,
      'label': '小提琴陪练',
      'extendValue': 'VIOLIN',
      'value': 'VIOLIN'
    },
    {
      'childNodeCode': null,
      'extendLabel': '日语',
      'children': null,
      'childNodeName': null,
      'label': '日语',
      'extendValue': 'JAPANESE',
      'value': 'JAPANESE'
    }
    ],
    'childNodeName': '预约科目',
    'label': '高一',
    'extendValue': null,
    'value': 'SENIOR_MIDDLE_SCHOOL_1'
  },
  {
    'childNodeCode': 'RESERVATIONS_COURSE',
    'extendLabel': '高二',
    'children': [{
      'childNodeCode': null,
      'extendLabel': '数学',
      'children': null,
      'childNodeName': null,
      'label': '数学',
      'extendValue': 'MATH',
      'value': 'MATH'
    },
    {
      'childNodeCode': null,
      'extendLabel': '语文',
      'children': null,
      'childNodeName': null,
      'label': '语文',
      'extendValue': 'CHINESE',
      'value': 'CHINESE'
    },
    {
      'childNodeCode': null,
      'extendLabel': '英语',
      'children': null,
      'childNodeName': null,
      'label': '英语',
      'extendValue': 'ENGLISH',
      'value': 'ENGLISH'
    },
    {
      'childNodeCode': null,
      'extendLabel': '政治',
      'children': null,
      'childNodeName': null,
      'label': '政治',
      'extendValue': 'POLITICS',
      'value': 'POLITICS'
    },
    {
      'childNodeCode': null,
      'extendLabel': '历史',
      'children': null,
      'childNodeName': null,
      'label': '历史',
      'extendValue': 'HISTORY',
      'value': 'HISTORY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '地理',
      'children': null,
      'childNodeName': null,
      'label': '地理',
      'extendValue': 'GEOGRAPHY',
      'value': 'GEOGRAPHY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '化学',
      'children': null,
      'childNodeName': null,
      'label': '化学',
      'extendValue': 'CHEMISTRY',
      'value': 'CHEMISTRY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '生物',
      'children': null,
      'childNodeName': null,
      'label': '生物',
      'extendValue': 'BIOLOGICAL',
      'value': 'BIOLOGY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '钢琴陪练',
      'children': null,
      'childNodeName': null,
      'label': '钢琴陪练',
      'extendValue': 'PIANO',
      'value': 'PIANO_PARTNER'
    },
    {
      'childNodeCode': null,
      'extendLabel': '物理',
      'children': null,
      'childNodeName': null,
      'label': '物理',
      'extendValue': 'PHYSICAL',
      'value': 'PHYSICAL'
    },
    {
      'childNodeCode': null,
      'extendLabel': '小提琴陪练',
      'children': null,
      'childNodeName': null,
      'label': '小提琴陪练',
      'extendValue': 'VIOLIN',
      'value': 'VIOLIN'
    },
    {
      'childNodeCode': null,
      'extendLabel': '日语',
      'children': null,
      'childNodeName': null,
      'label': '日语',
      'extendValue': 'JAPANESE',
      'value': 'JAPANESE'
    }
    ],
    'childNodeName': '预约科目',
    'label': '高二',
    'extendValue': null,
    'value': 'SENIOR_MIDDLE_SCHOOL_2'
  },
  {
    'childNodeCode': 'RESERVATIONS_COURSE',
    'extendLabel': '未入园',
    'children': [
      {
        'childNodeCode': null,
        'extendLabel': '语文素养',
        'children': null,
        'childNodeName': null,
        'label': '语文素养',
        'extendValue': 'ACCOMPLISHMENT',
        'value': 'ACCOMPLISHMENT'
      },
      {
        'childNodeCode': null,
        'extendLabel': '英语启蒙',
        'children': null,
        'childNodeName': null,
        'label': '英语启蒙',
        'extendValue': 'ENLIGHTENMENT',
        'value': 'ENLIGHTENMENT'
      }
    ],
    'childNodeName': '预约科目',
    'label': '未入园',
    'extendValue': null,
    'value': 'PREPARATORY_KINDERGAREN'
  },
  {
    'childNodeCode': 'RESERVATIONS_COURSE',
    'extendLabel': '高三',
    'children': [{
      'childNodeCode': null,
      'extendLabel': '数学',
      'children': null,
      'childNodeName': null,
      'label': '数学',
      'extendValue': 'MATH',
      'value': 'MATH'
    },
    {
      'childNodeCode': null,
      'extendLabel': '语文',
      'children': null,
      'childNodeName': null,
      'label': '语文',
      'extendValue': 'CHINESE',
      'value': 'CHINESE'
    },
    {
      'childNodeCode': null,
      'extendLabel': '英语',
      'children': null,
      'childNodeName': null,
      'label': '英语',
      'extendValue': 'ENGLISH',
      'value': 'ENGLISH'
    },
    {
      'childNodeCode': null,
      'extendLabel': '政治',
      'children': null,
      'childNodeName': null,
      'label': '政治',
      'extendValue': 'POLITICS',
      'value': 'POLITICS'
    },
    {
      'childNodeCode': null,
      'extendLabel': '历史',
      'children': null,
      'childNodeName': null,
      'label': '历史',
      'extendValue': 'HISTORY',
      'value': 'HISTORY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '地理',
      'children': null,
      'childNodeName': null,
      'label': '地理',
      'extendValue': 'GEOGRAPHY',
      'value': 'GEOGRAPHY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '化学',
      'children': null,
      'childNodeName': null,
      'label': '化学',
      'extendValue': 'CHEMISTRY',
      'value': 'CHEMISTRY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '生物',
      'children': null,
      'childNodeName': null,
      'label': '生物',
      'extendValue': 'BIOLOGICAL',
      'value': 'BIOLOGY'
    },
    {
      'childNodeCode': null,
      'extendLabel': '钢琴陪练',
      'children': null,
      'childNodeName': null,
      'label': '钢琴陪练',
      'extendValue': 'PIANO',
      'value': 'PIANO_PARTNER'
    },
    {
      'childNodeCode': null,
      'extendLabel': '物理',
      'children': null,
      'childNodeName': null,
      'label': '物理',
      'extendValue': 'PHYSICAL',
      'value': 'PHYSICAL'
    },
    {
      'childNodeCode': null,
      'extendLabel': '小提琴陪练',
      'children': null,
      'childNodeName': null,
      'label': '小提琴陪练',
      'extendValue': 'VIOLIN',
      'value': 'VIOLIN'
    },
    {
      'childNodeCode': null,
      'extendLabel': '日语',
      'children': null,
      'childNodeName': null,
      'label': '日语',
      'extendValue': 'JAPANESE',
      'value': 'JAPANESE'
    }
    ],
    'childNodeName': '预约科目',
    'label': '高三',
    'extendValue': null,
    'value': 'SENIOR_MIDDLE_SCHOOL_3'
  }]
