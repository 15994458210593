<template>
  <div class="new-pay-share">
    {{ readyToCreatePoster }}
    <div class="new-pay-share__banner">
      <img :src="sources.banner" alt="掌门少儿" />
    </div>
    <div class="new-pay-share__process">
      <img :src="sources.process" alt="返现流程" />
      <button
        class="new-pay-share__process--invitation"
        @click="actionToCreatePoster"
      ></button>
    </div>
    <div class="new-pay-share__progress">
      <img
        :src="sources.progress"
        alt="邀请进度"
        class="new-pay-share__progress--icon"
      />
      <div class="new-pay-share__progress--base" v-if="totalFriends">
        <div class="new-pay-share__progress--total">
          <div class="new-pay-share__progress--type">
            <div class="new-pay-share__progress--type-title">邀请好友</div>
            <div class="new-pay-share__progress--type-title">
              <span class="new-pay-share__progress--title">累计返现金额</span>
              <span class="new-pay-share__progress--sub">(每月最高800元)</span>
            </div>
          </div>
          <div class="new-pay-share__progress--number">
            <div class="new-pay-share__progress--detail">
              <span class="new-pay-share__progress--detail-main">{{
                totalFriends
              }}</span>
              <span class="new-pay-share__progress--detail-unit">人</span>
            </div>
            <div class="new-pay-share__progress--detail">
              <span class="new-pay-share__progress--detail-main">{{
                totalMoney
              }}</span>
              <span class="new-pay-share__progress--detail-unit">元</span>
            </div>
          </div>
          <p class="new-pay-share__progress--warn">
            *返现金额将以每笔9.9元，分多次到账至微信账户，可前往微信钱包确认到账金额
          </p>
        </div>
        <div class="new-pay-share__progress--friends-list">
          <div class="new-pay-share__progress--friends-title">
            <span>好友头像</span><span>好友昵称</span><span>状态</span>
          </div>
          <ul>
            <li
              v-for="(item, index) in referUserList"
              :key="'friend' + index"
              class="new-pay-share__progress--friends-item"
            >
              <div class="new-pay-share__progress--friends-avatar">
                <img
                  :src="item.headImg || sources.avatar"
                  :alt="item.nickName"
                />
              </div>
              <span class="new-pay-share__progress--friends-nick">{{
                item.nickName
              }}</span>
              <span class="new-pay-share__progress--friends-state">已购买</span>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="new-pay-share__progress--empty">
        <img
          :src="sources.noOne"
          alt="暂无"
          class="new-pay-share__progress--empty-icon"
        />
      </div>
    </div>
    <div class="new-pay-share__poster" id="poster">
      <img
        :src="sources.poster"
        alt=""
        class="new-pay-share__poster--main"
        @load="posterLoaded"
      />
      <img
        :src="qrCodeUrl"
        alt=""
        class="new-pay-share__poster--qrcode"
        @load="qrCodeLoaded"
      />
    </div>
    <div class="new-pay-share__poster--mask"></div>
    <div
      class="new-pay-share__poster--created"
      v-show="showPoster"
      @click="hidePoster"
    >
      <ul class="new-pay-share__poster--content">
        <li class="new-pay-share__poster--img"></li>
        <li class="new-pay-share__poster--intro">
          长按保存专属海报，分享好友领取返现
        </li>
        <li class="new-pay-share__poster--close">X</li>
      </ul>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';
import QRCode from 'qrcode';
import api from '@/api.js'
import Toast from "@/base/toast/toast";
import CONFIG from '@/config'
import { KidsWeChatShare } from '@/assets/js/shareMessage'
export default {
  name: "NewPayShare",
  data () {
    return {
      singlePrice: 9.9,
      referUserList: [],
      sources: {
        banner: 'https://web-data.zmlearn.com/image/9tEtTR1haUPdnnrToavVwd/banner.png',
        process: 'https://web-data.zmlearn.com/image/vPw3wmTmD4k7t9ayL12fej/p1%402x.png',
        avatar: 'https://web-data.zmlearn.com/image/3TVggak5GnVAvodf8mDMbZ/%E5%A4%B4%E5%83%8F%402x.png',
        noOne: 'https://web-data.zmlearn.com/image/q7TeLZSryb9MiuWpxdWSa8/empty.png',
        progress: 'https://web-data.zmlearn.com/image/h5BvHD856wfvYrfVU7JDG3/progress.png',
        poster: 'https://web-data.zmlearn.com/image/3iFcoSzLavBufZKCij9aJH/poster.png'
      },
      showPoster: false,
      posterLoad: false,
      qrCodeLoad: false,
      actionState: false, // 用户是否点击过邀请好友
      qrCodeUrl: '',
      totalFriends: 0,
      totalMoney: 0,
      shareUrl: '',
      LOGINFO: {}
    }
  },
  beforeRouteEnter (to, from, next) {
    if (!to.query.accessToken && !to.query.userId) {
      location.href = `${CONFIG.kidAppGateway}/kids/api/wxmp/mp/web/auth/learning/center/userinfo?redirect=${encodeURIComponent(location.href)}&needLogin=false&needToken=true`;
    }
    next();
  },
  created () {
    this.getUserPaidInfo();
    this.initLogInfo(this.$route.query.openId);
    this.sendEvent(this.LOGINFO['1']);
  },
  async mounted () {
    await this.createQRCode();
    KidsWeChatShare({
      title: '掌门少儿',
      desc: '免费领取价值398元试听课',
      imgUrl: 'https://activity-static.zmlearn.com/m/zmsr.png',
      link: this.shareUrl
    }, ['menuItem:copyUrl'])
  },
  methods: {
    sendEvent (data) {
      ZM_JSSDK && ZM_JSSDK.sendEvent(data);
    },
    initLogInfo (openId) {
      this.LOGINFO = {
        '1': {
          eventId: 'enter_kid_act29_share',
          eventParam: {
            name: '进入|少儿|活动|邀请好友页面',
            desc: '进入9.9地推返现邀请好友页面触发',
            openId,
          }
        },
        '2': {
          eventId: 'click_kid_act29_yqhy',
          eventParam: {
            name: '点击|少儿|活动|邀请好友',
            desc: '点击邀请好友按钮触发',
            openId,
          }
        }
      }
    },
    posterLoaded () {
      this.posterLoad = true;
    },
    actionToCreatePoster () {
      const state = this.qrCodeLoad && this.posterLoad && this.actionState;
      if (state) {
        this.createPoster();
      } else {
        this.actionState = true;
      }

    },
    createPoster () {
      const poster = document.querySelector('.new-pay-share__poster--created-content');
      console.log('poster==============>');
      console.log(poster, 'poster');
      if (poster) {
        this.showPoster = true;
        return;
      }
      this.sendEvent(this.LOGINFO['2']);
      console.log('begin');
      html2canvas(
        document.querySelector('#poster'),
        { allowTaint: true, useCORS: true, backgroundColor: 'transparent', dpi: 300, }
      ).then((canvas) => {
        console.log('create');
        var img = new Image();
        img.src = canvas.toDataURL();
        img.className = 'new-pay-share__poster--created-content';
        document.querySelector('.new-pay-share__poster--img').appendChild(img);
        this.showPoster = true;
        // canvas.className = 'new-pay-share__poster--created-content';
        // document.querySelector('.new-pay-share__poster--created').appendChild(canvas);
      });
    },
    async getEncryptUserId (accessToken) {
      try {
        if (!accessToken) { return; }
        const { data: { data, code, message } } = await api.KidGetUserId(accessToken);
        code * 1 === 0 && (this.userId = data) || Toast(message);
      } catch (e) {
        Toast(e.message || '出错了，请重试~');
      }
    },
    async createQRCode () {
      try {
        const { channel_code, channel_keyword } = CONFIG.codeKey9Point9;
        let { userId = '', accessToken = '', } = this.$route.query;
        if (!userId) {
          await this.getEncryptUserId(accessToken);
        }
        this.shareUrl = `${CONFIG.shareUrl || location.origin}/m-page/new-pay?sUId=${userId || this.userId}&channel_code=${channel_code}&channel_keyword=${channel_keyword}`;
        this.qrCodeUrl = await QRCode.toDataURL(this.shareUrl);
      } catch (err) {
        console.error(err)
      }
    },
    qrCodeLoaded () {
      this.qrCodeLoad = true;
    },
    hidePoster () {
      this.showPoster = false;
      this.actionState = false;
    },
    // 获取用户支付信息（是否购买过，邀请人数，返现金额, 返现被邀请用户列表）
    async getUserPaidInfo () {
      const { accessToken, paid = 0 } = this.$route.query;
      console.log(accessToken, 'accessTokenaccessToken')
      if (paid === 1) { return; }
      try {
        const res = await api.KidUserHasPaid(accessToken);
        const { data: { data: { buy, count = 0, money = 0, referUserList = [] }, code, message }, } = res;
        if (code * 1 === 0) {
          if (buy) {
            this.referUserList = [...referUserList];
            this.totalMoney = money;
            this.totalFriends = count;
          }
        } else {
          Toast(message);
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  computed: {
    readyToCreatePoster () {
      const state = this.qrCodeLoad && this.posterLoad && this.actionState;
      if (state) {
        window.scrollTo(0, 0);
        this.createPoster();
      }
      return '';
    }
  }
}
</script>

<style scoped lang="scss">
@mixin disableUserSelect() {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.new-pay-share {
  width: 750px;
  background-color: #ffb444;
  padding-bottom: 32px;
  &__banner {
    width: 100%;
    padding-bottom: 10px;
    img {
      width: 100%;
    }
  }
  &__process {
    width: 100%;
    position: relative;
    &--invitation {
      position: absolute;
      bottom: 97px;
      left: 50%;
      transform: translateX(-50%);
      width: 550px;
      height: 110px;
      background: transparent;
      border: none;
    }
    img {
      width: 100%;
    }
  }
  &__progress {
    position: relative;
    margin: 0 auto;
    width: 690px;
    padding: 89px 27px 46px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0 6px 24px 0 #ffb444;
    border-radius: 30px;
    &--icon {
      width: 348px;
      height: 78px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) translateY(-19px);
    }
    &--empty {
      text-align: center;
      padding: 105px 0;
      img {
        width: 195px;
        height: 237px;
      }
    }
    &--total {
      width: 100%;
      background: #fff8e1;
      border-radius: 30px;
      overflow: hidden;
    }
    &--type {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 95px;
      background: #fff1c7;
      &-title {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 36px;
        font-family: Yuanti SC;
        font-weight: 400;
        color: #7f7f7f;
      }
    }
    &--sub {
      font-size: 22px;
      font-weight: 400;
      color: #7f7f7f;
    }
    &--number {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &--detail {
      width: 50%;
      padding: 4px 0 0;
      &-main,
      &-unit {
        font-family: Yuanti SC;
        font-size: 60px;
        font-weight: bold;
        color: #ff4544;
        line-height: 42px;
      }
      &-unit {
        font-size: 32px;
      }
    }
    &--warn {
      padding: 32px 56px;
      font-size: 28px;
      font-family: Yuanti SC;
      font-weight: 400;
      color: #ff4544;
      line-height: 40px;
    }
    &--friends {
      &-list {
        width: 100%;
        margin-top: 32px;
        background: #fff8e1;
        border-radius: 30px;
        overflow: hidden;
        padding-bottom: 32px;
      }
      &-title {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 95px;
        background: #fff1c7;
        span {
          width: 33.3%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 36px;
          font-family: Yuanti SC;
          font-weight: 400;
          color: #7f7f7f;
        }
      }
      &-item {
        padding: 22px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        * {
          width: 33.3% !important;
        }
      }
      &-avatar {
        img {
          width: 68px;
          height: 68px;
          border-radius: 50%;
        }
      }
      &-nick,
      &-state {
        font-size: 32px;
        font-family: Yuanti SC;
        font-weight: 400;
        color: #ff4544;
      }
    }
  }
  &__poster {
    position: fixed;
    top: 300px;
    left: 0;
    z-index: -2;
    &--main {
      width: 442px;
      height: 786px;
    }
    &--qrcode {
      position: fixed;
      width: 100px;
      z-index: 3;
      top: 955px;
      left: 305px;
    }
    &--created {
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      top: 0;
      left: 0;
      z-index: 2;
      @include disableUserSelect;
    }
    &--content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
    &--intro {
      padding: 35px 0 16px;
      font-size: 26px;
      font-family: Yuanti SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 35px;
    }
    &--img {
    }
    &--close {
      width: 52px;
      height: 52px;
      border: 2px solid #ffffff;
      border-radius: 50%;
      line-height: 50px;
      box-sizing: border-box;
      font-size: 32px;
      color: #fff;
    }
    &--mask {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 750px;
      height: 100%;
      background-color: #c0c0c0;
      z-index: -1;
    }
  }
}
</style>
<style lang="scss">
.new-pay-share {
  &__poster {
    &--created {
      &-content {
        width: 442px;
      }
    }
  }
}
</style>
