import API from '@/api'

export default {
  getSubjectData(context: { commit: any, state: any }) {
    API.getKidsOwnSubjectData()
      .then((gradeSubject: any) => {
        console.log(gradeSubject,'gradeSubjectgradeSubjectgradeSubject')
        context.commit('GET_SUBJECT_DATA', gradeSubject.data)
      })
      .catch(function (error: any) {
        context.commit('GET_SUBJECT_DATA', null)
        console.log(error)
      })
  },
  getMkidSubjectData(context: { commit: any, state: any }) {
    API.getMkidSubjectData()
      .then((gradeSubject: any) => {
        console.log(gradeSubject);
        context.commit('GET_SUBJECT_DATA', gradeSubject)
      })
      .catch(function (error: any) {
        context.commit('GET_SUBJECT_DATA', null)
        console.log(error)
      })
  },
  saveUrlQuery(context: { commit: any, state: any }, payload: any) {
    const urlQueryObj = Object.assign({}, payload);
    context.state.urlQueryObj = urlQueryObj;
  },
  bindSuccess(context: { commit: any, state: any }, payload: any) {
    context.state.urlQueryObj.isBind = "1";
  }
}
