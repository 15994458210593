<template>
  <div v-if="visible">
      <div class='bind-form-modal' ref="bindForm" v-if="visible" @touchmove.prevent :style="{height:height+'px'}">
          <div class="bind-form">
            <div class="close" @click="hidden">
                <img src="./img/chacha.png" class="close-img"/>
            </div>
            <div class="bind-form-title">
                验证成功后送育儿礼包！
            </div>
            <div class="vertify-code">
                <input type="phone" class="identifyingcode" @blur="onBlurChange" v-model="code" maxlength="6" placeholder="短信验证码"/>
                <div class="line"></div>
                <div class="get-code-btn" :class="sendBtnDisabled ?'send-btn-disabled' :''" @click="sendSmsCode">
                    {{sendBtnText}}
                </div>
            </div>
            <!-- <div class="tips">
                验证后可在公众号内咨询孩子排课进度
            </div> -->
            <div class="button" @click="bindAccount">
                立即验证
            </div>
        </div>
      </div>
  </div>
</template>

<script>
import api from "../../api";
import { request, queryObj, isEmptyObject } from "@/utils/util";
import Toast from "@/base/toast/toast";

import { mapState } from "vuex";

const seconds = 60;
export default {
  name: "bind-form",
  props:{
      mobile:String,
      open_id:String,
      closeDialog: Function,
      height:Number
  },
  data(){
      return {
          sendBtnText:"发送验证码",
          leftSeconds:seconds,
          sendBtnDisabled:false,
          visible:false,
          code:"",
          isSendCodeBtnDisabled:false
      }
  },
  methods:{
      async sendSmsCode(){
          if(!this.isSendCodeBtnDisabled){
              this.isSendCodeBtnDisabled = true;
const result = await api.sendKidWxSmsCode(this.mobile);
          console.log(result);
          if(result && result.code == '0'){
              this.comedown();
          }else{
              this.isSendCodeBtnDisabled = false;
              if(result && result.msg){
                  Toast({message:result.msg})
              }else{
                   Toast({message:'发送失败'})
              }
          }
          }
          
      },
      comedown(){
          this.leftSeconds--;
          this.sendBtnDisabled = true;
          this.sendBtnText = `重新获取(${this.leftSeconds})`;
          this.timer = setInterval(()=>{
              this.leftSeconds--;
              if(this.leftSeconds<=0){
                this.sendBtnText = "重新获取";
                this.leftSeconds = seconds;
                this.sendBtnDisabled = false;
                clearInterval(this.timer);
                this.isSendCodeBtnDisabled = false;
              }else{
                this.sendBtnText = `重新获取(${this.leftSeconds})`;
              }
          },1000)
      },
      show(){
          console.log(this.height);
          document.activeElement.scrollIntoViewIfNeeded(true);
          this.visible = true;
          this.sendSmsCode();
      },
      onBlurChange(){
          document.activeElement.scrollIntoViewIfNeeded(false);
      },
      hidden(){
        this.visible = false;
        //   this.$emit("closeDialog",false)
        if(this.timer) clearInterval(this.timer);
      },
      async bindAccount(){
          document.activeElement.scrollIntoViewIfNeeded(false);
          if(!this.code){
              Toast("验证码不能为空");
              return;
          }
          const data = {
              mobile:this.mobile,
              open_id:this.open_id,
              validateCode:this.code
          };
          // 绑定接口
          let result = await api.registerPeiyou(data);
          result = result.data;
          console.log(result);
          if(result && result.code == '0'){
            //   this.$emit("closeDialog",true);
            this.hidden();
            this.closeDialog(true)
          }else{
              Toast(result.message);
            //   this.$emit("closeDialog",false);
          }
      }
  }
};
</script>

<style lang="scss" scoped>
// @import './../../styles/mixin.scss';

.bind-form-modal {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    background: rgba(0,0,0,0.5);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 66666;
}
.bind-form-title {
    color: rgb(237,51,72);
    font-size: 34px;
    text-align: center;
    margin: 60px 0 0 0 ;
    font-weight: bold;
}
.bind-form{
    position: relative;
    margin: 0 100px;
    width: 550px;
    border-radius: 6px;
    background: #ffffff;
    margin-top: 460px;
    .close {
        position: absolute;
        right: 5px;
        top:5px;
        padding: 15px;
        .close-img {
            width: 24px;
            height: 24px;
        }
    }
    .vertify-code {
        height: 90px;
        margin: 50px 46px 0 46px;
        border-top: 1px solid #eeeeee;
        border-bottom: 1PX solid #eeeeee;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        .identifyingcode {
            width: 250px;
            text-indent: 20px;
            border:0px;
            font-size: 28px;
        }
        input::-webkit-input-placeholder {
            color: #999999;
        }
        input::-moz-placeholder {
            color: #999999;
        }
        .line {
            width: 1px;
            height: 60px;
            background: #EEEEEE;
        }
        .get-code-btn {
            width: 230px;
            height: 90px;
            line-height: 90px;
            font-family: PingFangSC-Regular;
            font-size: 28px;
            color: #333333;
            text-align: right;
            margin-right: 20px;
        }
        .send-btn-disabled {
            color: #DBDBDB;
        }
    }
    .tips{
        margin: 30px 0 0 0;
        font-family: PingFangSC-Regular;
        font-size: 26px;
        color: #999999;
        text-align: center;
    }
    .button {
        margin: 60px 46px 40px 46px;
        background: #FF6767;
        box-shadow: 0 4px 10px 0 rgba(255,103,103,0.50);
        border-radius: 40px;
        height: 80px;
        line-height: 80px;
        text-align: center;
        font-size: 34px;
        color: #FFFFFF;
    }
}

</style>
