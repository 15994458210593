// const wx = require('weixin-js-sdk');
import wx from 'weixin-jsapi'
import API from '@/api.js'
const api: any = API;
export const initWechat = async (config: any, flag = true) => {
  try {
    const params = {
      url: location.href.split('#')[0]
    };
    console.log(params.url, '获取签名的Url');
    const _wxInfo: any = await api.kidGetSignature(params.url);
    const wxInfo = _wxInfo.data.data;
    console.log('wxInfo==================>', wxInfo);
    if (wxInfo) {
      wx.config({
        appId: wxInfo.appId, // 必填，公众号的唯一标识
        timestamp: wxInfo.timestamp, // 必填，生成签名的时间戳
        nonceStr: wxInfo.nonceStr, // 必填，生成签名的随机串
        signature: wxInfo.signature, // 必填，签名，见附录1
        jsApiList: [
          'onMenuShareQQ',
          'onMenuShareQZone',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'hideMenuItems',
          'updateTimelineShareData',
          'updateAppMessageShareData',
          'hideOptionMenu',
          'showOptionMenu',
          'chooseWXPay'
        ]
      });
      wx.ready(() => {
        wx.hideMenuItems({
          menuList: [
            'menuItem:originPage',
            'menuItem:openWithQQBrowser',
            'menuItem:openWithSafari',
            'menuItem:copyUrl'
          ]
        });
        // 朋友圈
        wx.onMenuShareTimeline(config);
        // 个人
        wx.onMenuShareAppMessage(config);
        // 二次分享(如果想使用分享成功后调用success回调的话flag传false)
        if (flag) {
          wx.updateTimelineShareData(config);
          wx.updateAppMessageShareData(config);
        }
      });
    } else {
      wx.config({
        url: location.href.split('#')[0],
        jsApiList: [
          'onMenuShareQQ',
          'onMenuShareQZone',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'hideMenuItems',
          'updateTimelineShareData',
          'updateAppMessageShareData',
          'hideOptionMenu',
          'chooseWXPay'
        ]
      });
    }
  } catch (e) {
    console.log(e);
  }
};
// 检查单个wx sdk
export const initPay = async (config: any) => {
  try {
    const params = {
      url: location.href.split('#')[0]
    };
    const _wxInfo: any = await api.kidGetSignature(params.url);
    const wxInfo = _wxInfo.data.data;
    console.log(wxInfo, '初始签名');
    if (wxInfo) {
      wx.config({
        debug: false,
        appId: wxInfo.appId, // 必填，公众号的唯一标识
        timestamp: wxInfo.timestamp, // 必填，生成签名的时间戳
        nonceStr: wxInfo.nonceStr, // 必填，生成签名的随机串
        signature: wxInfo.signature, // 必填，签名，见附录1
        jsApiList: ["checkJsApi", "chooseWXPay", "updateAppMessageShareData", "updateTimelineShareData"]
      });
    }
    wx.ready((res: any) => {
      wx.checkJsApi({
        jsApiList: ['chooseWXPay'],
        success: (res: any) => {
          console.log('checked api:', res)
        },
        fail: (err: any) => {
          console.log('check api fail:', err)
        }
      })
    })
  } catch (e) {
    console.log(e);
  }
};
