// import api from "@/api"

export function request (name, link) {
  if (process.browser) {
    let url = decodeURI(link || location.href)
    let paraObj = {}
    let paraObjList = url.split('?').slice(1)
    let paraString = []
    let returnValue = ''

    let requestHandler = function (paraList) {
      let result = []
      for (let index = 0; index < paraList.length; index++) {
        paraList[index] = paraList[index].split('#')[0]
        result.push(paraList[index])
      }
      return result.join('&').split('&')
    }
    paraString = requestHandler(paraObjList)

    // 将所有请求参数放入paraObj
    let temp = ''
    for (let i = 0, length = paraString.length; i < length; i++) {
      temp = paraString[i]
      paraObj[
        temp.substring(0, temp.indexOf('=')).toLowerCase()
      ] = temp.substring(temp.indexOf('=') + 1, temp.length)
    }

    returnValue = paraObj[name.toLowerCase()] || ''

    return returnValue
  }
}

export function queryObj () {
  if (process.browser) {
    const str = location.search
    if (str) {
      let url = str.substr(1)
      let arr = url.split('&')
      let len = arr.length
      let i = 0
      let request = {}
      for (; i < len; i++) {
        request[arr[i].split('=')[0]] = arr[i].split('=')[1]
      }
      return request
    } else {
      return {}
    }
  }
}

export function isEmptyObject (obj) {
  if (process.browser) {
    for (let key in obj) {
      return false
    }
    return true
  }
}

export function wxfuncUrlDel (that) {
  if (that.$store.state.urlQueryObj.isFollow == null) {
    const query = that.$route.query;
    that.$store.dispatch("saveUrlQuery", query);
    try {
      history.replaceState({}, document.title, funcUrlDel("isFollow"))
    } catch (error) {

    }
  }
}

// 根据指定的参数名删除url中的参数
export function funcUrlDel (name) {
  var loca = window.location
  var baseUrl = loca.origin + loca.pathname + '?'
  var query = loca.search.substr(1)
  if (query.indexOf(name) > -1) {
    var obj = {}
    var arr = query.split('&')
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].split('=')
      obj[arr[i][0]] = arr[i][1]
    }
    delete obj[name]
    var url = baseUrl + JSON.stringify(obj).replace(/[\"\{\}]/g, "").replace(/\:/g, "=").replace(/\,/g, "&");
    return url
  }
}

export function setParams (obj, url) {
  let result = url || window.location.href
  if (Object.prototype.toString.call(obj) !== '[object Object]') return false
  for (let name in obj) {
    result = setParam(name, obj[name], result)
  }
  return result
}

export function addJs (src, cb) {
  if (typeof document === 'undefined') return
  const script = document.createElement('script')
  script.src = src
  script.onload = cb
  document.body.appendChild(script)
}

export function weiboJsUp (weibo_path) {
  //   let mark_id = markId
  //   let timestamp = new Date().getTime()
  //   let weibo_path = weiboPath

  //   api.weiboAdvertCallback({
  //     time: timestamp,
  //     mark_id: mark_id,
  //     behavior: 1001
  //   })
  console.log("weibopath", weibo_path)
  switch (weibo_path) {
    case '/lp/a1/wb_ld01':
      try {//微博埋点上报
        window.wbadmt.send({
          'eventid': '303959',
          'eventtype': 'form',
          'eventvalue': ''
        })
        window.wbadmt.send({
          'eventid': '400479',
          'eventtype': 'form',
          'eventvalue': ''
        })
        window.wbadmt.send({
          'eventid': '400668',
          'eventtype': 'form',
          'eventvalue': ''
        })
      } catch (error) {
      }
      break;
    case '/lp/b1/wb_ld01':
      try {//微博埋点上报
        window.wbadmt.send({
          'eventid': '309342',
          'eventtype': 'form',
          'eventvalue': ''
        })
        window.wbadmt.send({
          'eventid': '400556',
          'eventtype': 'form',
          'eventvalue': ''
        })
        window.wbadmt.send({
          'eventid': '400669',
          'eventtype': 'form',
          'eventvalue': ''
        })
      } catch (error) {
      }
      break;
    case '/lp/a2/wb_ld01':
      try {//微博埋点上报
        window.wbadmt.send({
          'eventid': '309343',
          'eventtype': 'form',
          'eventvalue': ''
        })
        window.wbadmt.send({
          'eventid': '400614',
          'eventtype': 'form',
          'eventvalue': ''
        })
        window.wbadmt.send({
          'eventid': '400673',
          'eventtype': 'form',
          'eventvalue': ''
        })
      } catch (error) {
      }
      break;
    case '/lp/b2/wb_ld01':
      try {//微博埋点上报
        window.wbadmt.send({
          'eventid': '309344',
          'eventtype': 'form',
          'eventvalue': ''
        })
        window.wbadmt.send({
          'eventid': '400616',
          'eventtype': 'form',
          'eventvalue': ''
        })
        window.wbadmt.send({
          'eventid': '400674',
          'eventtype': 'form',
          'eventvalue': ''
        })
      } catch (error) {
      }
      break;
    case '/lp/c0/wb_ld01':
      try {//微博埋点上报
        window.wbadmt.send({
          'eventid': '309345',
          'eventtype': 'form',
          'eventvalue': ''
        })
        window.wbadmt.send({
          'eventid': '400618',
          'eventtype': 'form',
          'eventvalue': ''
        })
        window.wbadmt.send({
          'eventid': '400675',
          'eventtype': 'form',
          'eventvalue': ''
        })
      } catch (error) {
      }
      break;
    case '/lp/d0/wb_ld01':
      try {//微博埋点上报
        window.wbadmt.send({
          'eventid': '309346',
          'eventtype': 'form',
          'eventvalue': ''
        })
        window.wbadmt.send({
          'eventid': '400625',
          'eventtype': 'form',
          'eventvalue': ''
        })
        window.wbadmt.send({
          'eventid': '400676',
          'eventtype': 'form',
          'eventvalue': ''
        })
      } catch (error) {
      }
      break;
  }
}
export function throttle (func, wait, options) {
  let timeout, context, args
  let previous = 0
  if (!options) options = {}

  let later = function () {
    previous = options.leading === false ? 0 : new Date().getTime()
    timeout = null
    func.apply(context, args)
    if (!timeout) context = args = null
  }

  let throttled = function () {
    let now = new Date().getTime()
    if (!previous && options.leading === false) previous = now
    let remaining = wait - (now - previous)
    context = this
    args = arguments
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      previous = now
      func.apply(context, args)
      if (!timeout) context = args = null
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining)
    }
  }
  throttled.cancel = function () {
    clearTimeout(timeout)
    previous = 0
    timeout = null
  }
  return throttled
}

export function generateEntype (data) {
  let publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC2Fp+ZaD/LVASrnQ5eXYyH1peUG3zgCYxer/Gu3qrvpJXhklB31lU2h6u06H1UsNQZ259zAFkac2JJdgi1nqL6FU0l1L8UVyStXhN2coOPBZpRGU4fmA6VMehuWc6bCRkZq4+15388Zz8hO+iWwYj3Q6IaYXwKXpRgCqV7dR6QrQIDAQAB";
  let encrypt = new JSEncrypt()
  encrypt.setPublicKey(publicKey)
  return encrypt.encrypt(data)
}

export function handleLocalStorage (method, key, value) {
  if (process.browser) {
    switch (method) {
      case 'get': {
        let temp = window.localStorage.getItem(key);
        if (temp) {
          return temp
        } else {
          return false
        }
      }
      case 'set': {
        window.localStorage.setItem(key, value);
        break
      }
      case 'remove': {
        window.localStorage.removeItem(key);
        break
      }
      default: {
        return false
      }
    }
  }
}

export function getAppId () {
  return process.env.NODE_ENV === 'production'
    ? 'wxf7af8822c99ce85f'
    : 'wxc2f4219954d545d8'
}

export function openWeixinClient () {
  const { opplatform } = queryObj();
  if (opplatform && opplatform == 'xs1v1app') {
    const { zm1v1jsBridge } = window;
    if (zm1v1jsBridge) {
      zm1v1jsBridge.callHandler('jsToNativeAction', JSON.stringify({ type: 'wakeUpOnlyWeiXin' }))
      return;
    }
  }
  if (navigator.userAgent.includes('app/braintrain')) {
    return;
  }
  location.href = "weixin://";
}
