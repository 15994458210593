







































import { Vue, Component } from 'vue-property-decorator';
import api from '@/api';
import Qrious from 'qrious';
import { initWechat } from '@/utils/wechat/init-wechat';
import wx from 'weixin-jsapi';
@Component({
  components: {},
})
export default class BuyClassForm extends Vue {
  private qrImg: any = '';
  private line1: any = '还差一步即可领取【掌门独家资料】哦！';
  private line2: any = '扫描下方二维码关注“掌门少儿学习中心”即可~';
  private created() {
    this.getImgUrl();
    if (
      this.$route.query.type === '3742' ||
      this.$route.query.type === '3835'
    ) {
      this.line1 = '还差一步即可领取【掌门59节趣味思维课】,';
      this.line2 = '扫描下方二维码添加课程老师；';
    }
    if (this.$route.query.type === '3811') {
      this.line1 = '还差一步即可领取【掌门9节超凡观察力课】，';
      this.line2 = '扫描下方二维码添加课程老师；';
    }
  }
  private mounted() {
    initWechat({}).then(() => {
      wx.ready(wx.hideOptionMenu);
    });
    switch (this.$route.query.type) {
      case '3742':
        this.$track.sendEvent({
          eventId: 'enter_kid_act61_success01',
          eventParam: {
            code: this.$route.query.channel_code,
          },
        });
        break;
      case '3811':
        this.$track.sendEvent({
          eventId: 'enter_kid_act62_success01',
          eventParam: {
            code: this.$route.query.channel_code,
          },
        });
        break;
      case '3835':
        this.$track.sendEvent({
          eventId: 'enter_kid_act63_success01',
          eventParam: {
            code: this.$route.query.channel_code,
          },
        });
        break;
      default:
        break;
    }
  }
  private async getImgUrl() {
    const res = await api.getWxImgUrl({ type: this.$route.query.type || 3742 });
    console.log(res.data.data.url, 'rere');
    this.qrImg = res.data.data.url;
    // const qr = new Qrious({
    //   element: document.getElementById('qr'),
    //   value: res.data.data.url,
    // });
  }
}
