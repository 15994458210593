import axios from 'axios'
import urlConfig from './config'
import qs from 'qs'
import cookies from 'js-cookie'

const wwwService = axios.create({
  timeout: 8000,
  transformRequest: [
    function (data) {
      data = qs.stringify(data)
      return data
    }
  ],
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  }
})

wwwService.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

const marketService = axios.create({
  baseURL: urlConfig.marketURL,
  timeout: 8000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    // 'Content-Type':'application/x-www-form-urlencoded',
    'X-Requested-With': 'XMLHttpRequest'
  }
})

const httpClient = axios.create({
  timeout: 8000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  }
})

export default {

  postLessonForm (apiUrl = `${urlConfig.appGateway}/api/market-web-server/v1/registration/appointment`, params) {
    // 为了兼容老数据这里做统一处理
    if (params) {

      const map = {
        "grade": "stuGrade",
        "channel_code": "code",
        "channel_keyword": "key"
      }

      for (let key in params) {
        if (key in map) {
          params[map[key]] = params[key]
          delete params[key]
        }
      }
    }
    return wwwService.post(apiUrl, params)
  },
  /**
   *
   * @param {*} apiUrl
   * @param {*} params
   * 其他接口不要走这里，这个是报名专用的
   */
  postLessonFormNew (params) {
    // 为了兼容老数据这里做统一处理
    if (params) {

      const map = {
        "grade": "stuGrade",
        "channel_code": "code",
        "channel_keyword": "key"
      }

      for (let key in params) {
        if (key in map) {
          params[map[key]] = params[key]
          delete params[key]
        }
      }
    }
    return httpClient.post(`${urlConfig.appGateway}/api/market-web-server/v1/registration/appointment`, params)
  },
  /**
   * 海外用户注册
   * @param {*} data
   */
  appoint2 (data) {
    return axios({
      // 新接口 /kids/api/wechat/oversea/register
      // 旧接口 /api/market-web-server/v2/registration/appointment
      // 旧接口url: `${urlConfig.kidAppGateway}/kids/api/wechat/oversea/register`,
      url: `${urlConfig.kidAppGateway}/kids/api/wechat/landing/page/nine/appointment/with/code/oversea`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data
    }).then(res => res.data)
  },
  /**
   * 海外用户注册 加极验
   * @param {*} data
   */
  appoint2Geetest (data) {
    return axios({
      url: `${urlConfig.kidAppGateway}/kids/api/wechat/landing/page/nine/appointment/with/code`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data
    }).then(res => res.data)
  },
  /**
   * 海外用户注册 无验证码
   * @param {*} data
  */
  appointnoSms (data) {
    return axios({
      url: `/kids/api/wechat/user/appointment/simple`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data
    }).then(res => res.data)
  },
  /**
   * 获取手机号码区号列表
   */
  getAreaCodeList () {
    return axios({
      url: `${urlConfig.appGateway}/api/market-web-server/v1/dict/getCountryCode`,
      method: 'GET'
    }).then(res => res.data)
  },
  encryptionMobile (data) {
    return axios({
      method: 'post',
      url: '/encryptBatch/api/safetycover-service/id/encrypt/batch',
      data: data,
      headers: {
        'appId': process.env.NODE_ENV == 'production' ? 'JfIJZrJKSuaYo7EQMkf9yg' : 'c61iamqhaT1m0gKBueCaavQ',
        'Content-Type': 'application/json;charset=utf-8'
      }
    })
  },
  getPvNum (params) {
    return wwwService.post('/api/user/get-statistic-pv', params)
  },
  // 预约排练
  postLessonFormPeilian (params) {
    return wwwService.post('/api/student/register-peilian', params)
  },
  // 是否预约过陪练
  postLessonHasPeilian (params) {
    return wwwService({
      method: 'get',
      url: '/api/student/has-register-peilian',
      params: params
    })
  },

  // 转介绍-少儿
  appointmentForMpReferralKid (data) {
    return axios({
      method: 'POST',
      url: '/appointmentForMpReferral/api/h5share/appointmentForMpReferral',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      data: data
    })
  },

  // 转介绍
  appointmentForMpReferral (params) {
    return marketService.post('/api/student/appointment-referral', params)
  },
  getPlatformOnOffStatus (params) {
    console.log(params);
    return wwwService({
      method: 'get',
      url: '/api/user/sem-channel-switch',
      params
    })
    // return axios({
    //   method: 'POST',
    //   url: '/api/user/sem-channel-switch',
    //   headers: {
    //     'Content-Type': 'application/json;charset=utf-8'
    //   },
    //   data: params
    // })
  },
  sendVerifier (params) {
    return wwwService.post('/api/student/send-sms-verifier', params)
  },
  sendVerifierNew (data) {
    return axios({
      url: `${urlConfig.appGateway}/api/market-web-server/v1/registration/sendSms`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data
    })
  },
  validateNew (params) {
    return httpClient.post(
      `${urlConfig.appGateway}/api/market-web-server/v1/registration/checkCode`,
      params
    )
  },
  reg (data) {
    const map = {
      "grade": "stuGrade",
      "channel_code": "code",
      "channel_keyword": "key"
    }

    for (let key in data) {
      if (key in map) {
        data[map[key]] = data[key]
        delete data[key]
      }
    }

    return axios({
      method: 'POST',
      url: `${urlConfig.appGateway}/api/market-web-server/v1/registration/reg`,
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      data: data
    })
  },
  regUpdate (data) {
    const map = {
      "grade": "stuGrade",
      "channel_code": "code",
      "channel_keyword": "key"
    }

    for (let key in data) {
      if (key in map) {
        data[map[key]] = data[key]
        delete data[key]
      }
    }

    return axios({
      method: 'POST',
      url: `${urlConfig.appGateway}/api/market-web-server/v1/registration/update/student`,
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      data: data
    })
  },
  hasTag (params) {
    return wwwService({
      method: 'get',
      url: `${urlConfig.mKids}/api/student/has-tag`,
      params: params
    })
  },
  getSubjectData () {
    return axios({
      method: 'post',
      url: `${urlConfig.appGateway}/api/operation-web-server/v1/ows/getDictGrade`,
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      data: {}
    })
  },
  getMkidSubjectData () {
    return axios({
      method: 'post',
      url: `${urlConfig.appGateway}/kids/user/wechat/dict/gradeSubject`,
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      data: {}
    })
  },
  getCourseList () {
    return axios({
      method: 'POST',
      url: `/node-host/api/biService/subjectWithOnlineNum`,
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    })
  },
  // 微信分享获取签名接口   目前m.zhangmenkid.com域名 挂在 掌门少儿订阅号下
  getSignature (signUrl) {
    return axios({
      method: 'post',
      url: '/wechat/zmsr/get-signature',
      data: {
        signUrl
      }
    })
  },

  // 微信分享获取签名接口   目前m.zhangmenkid.com域名 挂在 掌门1对1杭州
  getSignature1 (signUrl, appId) {
    return axios({
      method: 'post',
      // url: '/wechat/java/services/wxApiJsService/get_js_signature',
      url: '/kids/api/wechat/posterCenter/getJsApiSignature', // 新接口
      data: {
        wxUrl: signUrl,
        appId: appId
      }
    })
  },
  sendKidWxSmsCode (mobile) {
    return axios({
      method: 'get',
      url: '/kid/api/peiyou/send-code',
      params: {
        mobile
      }
    }).then(res => {
      return res.data;
    }).catch(error => {
      console.log(error)
      return null
    })
  },
  registerPeiyou (data) {
    return axios({
      method: 'post',
      url: '/kid/api/peiyou/register-to-bind',
      data: data
    }).then(res => {
      return res.data;
    }).catch(error => {
      console.log(error)
      return null
    })
  },

  // 根据手机号查询用户信息
  getUserInfo (data) {
    return axios({
      method: 'post',
      url: '/user/api/user/doubleWriteUser/queryESUsers',
      data: data
    }).then(res => res && res.data)
      .catch(console.error)
  },

  //微信广告通用回调（根据click_id、Action_Type、创建时保存的数据源ID，调用API进行回传，获取成功应答）
  wechatAdvertCallback (data) {
    return axios({
      method: 'post',
      url: '/user/api/market-content-server/v1/wechat/wechatAdvert/callback',
      data: data
    }).then(res => res && res.data)
      .catch(console.error)
  },

  //广点通上报转化行为
  qqAdvertCallback (data) {
    return axios({
      method: 'post',
      url: '/user/api/market-content-server/v1/qq/advert/callback',
      data: data
    }).then(res => res && res.data)
      .catch(console.error)
  },
  getSelectionClassTag (params) {
    return axios.get('//wxxbk.zhangmenkid.com/api/special/appoint-content', {
      params
    });
  },
  //市场部-少儿渠道组-头条投放对接api 不区分新用户，老用户
  toutiaoAdvertCallback (data) {
    return axios({
      method: 'post',
      url: '/user/api/market-content-server/v1/toutiao/advert/callback',
      data: data
    }).then(res => res && res.data)
      .catch(console.error)
  },
  //市场部-少儿渠道组-对接百度api 不区分新用户，老用户
  baiduApiCallback (data) {
    return axios({
      method: 'post',
      url: '/user/api/bus/push/sys',
      data: data
    })
  },
  //微博投放对接api 不区分新老用户
  weiboAdvertCallback (params) {
    return axios({
      method: 'get',
      url: 'https://thirdpartydatareflow.biz.weibo.com/landingpage_monitor',
      params: params
    }).then(res => res && res.data)
      .catch(console.error)
  },
  getAssociationQrcode (data) {
    return axios({
      method: 'get',
      url: '/user/api/operation-activity-center/v1/uv/getImage',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: data
    })
  },
  checkIsNewUser (params) {
    return axios({
      method: 'get',
      url: '/officialV3/api/user/user-state',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      params
    });
  },
  getWechatKidQrcode (params) {
    return axios.post(
      urlConfig.appGateway + '/kids/api/wechat/Wx/qrcode/getWechatQrcodeEncrypt',
      params
    )
  },
  loginBySmsCode (params) {
    return axios.post(
      // api/operation-external-server/v1/white/loginBySmsCode
      // /kids/api/wechat/login/official/web/by/sms/code 新接口
      urlConfig.kidAppGateway + "/kids/api/wechat/login/official/web/by/sms/code",
      params
    )
  },
  sendSmsCode (params) {
    return axios.post(
      // /api/operation-external-server/v1/white/sendSmsCode
      // /kids/api/wxmp/mp/activity/login/sendCertificationCode 新接口
      urlConfig.kidAppGateway + "/kids/api/wxmp/mp/activity/login/sendCertificationCode",
      params
    )
  },
  kidComment (data) {
    return axios({
      method: 'post',
      url: `${urlConfig.appGateway}/api/operation-activity-center/v1/act/support/kidComment`,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data
    })
  },
  // 少儿微信服务号身份验证
  kidIdentityVarify (data) {
    return axios({
      method: 'post',
      url: `${urlConfig.kidAppGateway}/kids/api/wechat/identity/${urlConfig.kidAppId}/login`,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data
    })
  },

  // 少儿服务
  // 获取验证码
  getIdentityCode (data) {
    return axios({
      method: 'post',
      desc: '发送验证码',
      url: `${urlConfig.kidAppGateway}/kids/user/h5/identity/check`,
      data,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  // 登陆
  kidsLogin (data) {
    return axios({
      method: 'post',
      desc: '登陆',
      url: `${urlConfig.kidAppGateway}/kids/user/h5/identity/login`,
      data,
      headers: {
        'Content-Type': 'application/json',
        'App-Name': 'KidsH5'
      }
    })
  },
  // 添加收货地址
  addAddress (secretKey, data) {
    return axios({
      method: 'post',
      headers: {
        'Api-Version': '2.6.0',
        'app-name': 'kidsApp',
        'secret-key': secretKey,
        'Content-Type': 'text/plain',
        accessToken: cookies.get('accessToken')
      },
      url: `${urlConfig.kidAppGateway}/kids/user/api/address/add`,
      data,
    })
  },
  // 获取省市区词典
  getAreaDic () {
    return axios({
      method: 'get',
      headers: {
        'Api-Version': '2.6.0',
        accessToken: cookies.get('accessToken')
      },
      url: `${urlConfig.kidAppGateway}/kids/user/api/address/dict`,
    })
  },
  // QQ广点通数据回传
  submitDataForGDT (data) {
    return axios({
      method: 'post',
      headers: {
        accessToken: cookies.get('accessToken'),
      },
      // ${urlConfig.kidAppGateway}
      url: `${urlConfig.kidAppGateway}/kids/api/wechat/ad/tencent/web/syncQqData`,
      data,
    })
  },
  // 微信朋友圈广告数据回传
  submitDataForWeChatAdData (data) {
    return axios({
      method: 'post',
      headers: {
        accessToken: cookies.get('accessToken'),
      },
      // ${urlConfig.kidAppGateway}
      url: `${urlConfig.kidAppGateway}/kids/api/wechat/ad/tencent/web/syncWechatData`,
      data,
    })
  },
  // 获取微信分享签名
  kidGetSignature (url) {
    return axios({
      method: 'post',
      headers: {
        accessToken: cookies.get('accessToken'),
      },
      url: `${urlConfig.kidAppGateway}/kids/api/wxmp/wx/jssdk/create/signature/learning/center`,
      data: { url },
    })
  },
  // 小胖对应的极验 /kidsApi/geetest/api1
  kidsApiGeetest () {
    return axios({
      method: 'get',
      url: `${urlConfig.kidAppGateway}/kidsApi/geetest/api1`
    })
  },
  // 获取微信分享签名
  kidGeeTest () {
    return axios({
      method: 'get',
      url: `${urlConfig.kidAppGateway}/kids/api/wxmp/mp/geetest/api1`
    })
  },
  //获取验证码
  KidGetVerifyCode (data) {
    return axios({
      method: 'post',
      desc: '发送验证码',
      url: `${urlConfig.kidAppGateway}/kids/api/wxmp/mp/activity/login/sendCertificationCode`,
      data
    })
  },
  KidAppointmentWithCode (data) {
    if (data) {
      const map = {
        "grade": "stuGradeCode",
        "weakSubject": "weakSubjectCode",
        "channel_code": "code",
        "channel_keyword": "key"
      }

      for (let key in data) {
        if (key in map) {
          data[map[key]] = data[key]
          delete data[key]
        }
      }
    }
    return axios({
      method: 'post',
      desc: '预约试听课--有验证码(等同于登录注册)',
      url: `${urlConfig.kidAppGateway}/kids/api/wechat/landing/page/nine/appointment/with/code`,
      data
    })
  },
  KidChannelMoney (data, appid) {
    return axios({
      method: 'post',
      desc: '登陆注册',
      url: `${urlConfig.kidAppGateway}/kids/api/wechat/identity/${appid || 10000}/appointment`,
      data
    })
  },
  KidChannelFree (data) {
    return axios({
      method: 'post',
      desc: '渠道落地页（免费）',
      url: `${urlConfig.kidAppGateway}/kids/api/wechat/common/landing/page/code`,
      data
    })
  },

  KidAppointmentWithoutCode (data) {
    if (data) {
      const map = {
        "grade": "stuGradeCode",
        "weakSubject": "weakSubjectCode",
        "channel_code": "code",
        "channel_keyword": "key"
      }

      for (let key in data) {
        if (key in map) {
          data[map[key]] = data[key]
          delete data[key]
        }
      }
    }
    return axios({
      method: 'post',
      desc: '预约试听课--无验证码,(等同于登录注册)仅限9.9',
      url: `${urlConfig.kidAppGateway}/kids/api/wechat/landing/page/nine/appointment/without/code`,
      data
    })
  },
  KidAppointWithoutCodeUpdate (data) {
    // if (data) {
    //   const map = {
    //     "grade": "stuGradeCode",
    //     "weakSubject": "weakSubjectCode",
    //     "channel_code": "code",
    //     "channel_keyword": "key"
    //   }

    //   for (let key in data) {
    //     if (key in map) {
    //       data[map[key]] = data[key]
    //       delete data[key]
    //     }
    //   }
    // }
    return axios({
      method: 'post',
      desc: '预约试听课--无验证码,(等同于登录注册)',
      url: `${urlConfig.kidAppGateway}/kids/operation/landing/page/engine/execute/${urlConfig.kidAppoinmentId}`,
      data
    })
  },
  recordData (data) {
    return axios({
      method: 'post',
      desc: '9.9记录落地页数据',
      headers: {
        accessToken: cookies.get('accessToken'),
      },
      url: `${urlConfig.kidAppGateway}/kids/api/wechat/landing/page/nine/appointment/save/data`,
      data
    })
  },

  KidUserHasPaid (accessToken) {
    return axios({
      method: 'get',
      desc: '9.9落地页产品，用户是否已购买，以及邀请人数和返现金额',
      url: `${urlConfig.kidAppGateway}/kids/api/wechat/activity/refund/index`,
      headers: {
        accessToken
      },
    })
  },
  KidShareRelationSave (data) {
    return axios({
      method: 'post',
      desc: '用户通过其它用户分享的页面登录注册后，保存当前用户与分享用户的关系',
      url: `${urlConfig.kidAppGateway}/kids/api/wechat/activity/refund/referral/save`,
      data
    })
  },
  KidEncrypt (params) {
    return axios({
      method: 'get',
      desc: '用户通过其它用户分享的页面登录注册后，保存当前用户与分享用户的关系',
      url: `${urlConfig.kidAppGateway}/kids/api/wechat/activity/refund/encrypt`,
      params
    })
  },
  KidGetUserId (accessToken) {
    return axios({
      method: 'get',
      desc: '获取用户加密的userId',
      url: `${urlConfig.kidAppGateway}/kids/api/wechat/activity/refund/invite`,
      headers: {
        accessToken
      },
    })
  },
  KidGetSchoolList (data) {
    return axios({
      method: 'post',
      desc: '获取校渠学校列表',
      url: `${urlConfig.kidAppGateway}/kids/api/wechat/school/list`,
      data
    })
  },
  // 头条API
  callAdTouTiao (data) {
    return axios({
      method: 'post',
      desc: '头条API',
      url: `${urlConfig.kidAppGateway}/kids/api/wechat/headlines/ad/data/sync`,
      data
    })
  },
  smsSwitch (params) {
    return axios({
      method: 'get',
      desc: '验证码开关',
      url: '/kids/api/wechat/landing/page/config/getBySource',
      params
    })
  },
  getinfo (data) {
    return axios({
      method: 'post',
      desc: '查询活动时间',
      url: `${urlConfig.kidAppGateway}/kids/api/wechat/activity/engine/info`,
      data
    })
  },
  execute (data) {
    return axios({
      method: 'post',
      desc: '查询是否填写后跳转添加微信页面',
      url: `${urlConfig.kidAppGateway}/kids/operation/landing/page/engine/execute/${data.id}`,
      data
    })
  },
}
