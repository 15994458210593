import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import swiper from '@/components/swiper/swiper.vue'
import 'swiper/swiper-bundle.css';
const VueAwesomeSwiper = require('vue-awesome-swiper/dist/ssr')

Vue.use(VueAwesomeSwiper)
Vue.component(swiper.name, swiper);
Vue.config.productionTip = false;
if (
  process.env.NODE_ENV === 'development' ||
  process.env.NODE_ENV === 'testing'
) {
  const { ZMSDK } = window;
  if (ZMSDK) {
    ZMSDK.topic = 'h5sdk_test'
  }
}
const { ZM_JSSDK } = window;
console.log(process.env.NODE_ENV, '埋点环境');
let env = "prod";
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'testing' || process.env.NODE_ENV === 'fat' ) {
  env = "fat";
} else if (process.env.NODE_ENV === 'uat') {
  env = "uat";
}
if (ZM_JSSDK) {
  // 配置sdk参数
  ZM_JSSDK.setConfig({
    environment: env,
    // 如果你使用了router并且是history模式
    // 如果你使用了vue-router的hash模式，也是设置history
    history: true,
    interceptor: false,
    urlParams: true,
  });

  // 配置上报公共属性
  ZM_JSSDK.setDefaults({
    appId: '12847',
    // appVersion: pkg.version,
    deviceId: localStorage.getItem('ZM_DEVICEID')
    // 其他公共字段，appId & appVersion请尽量参考**使用须知**
  });
}
Vue.prototype.$track = ZM_JSSDK;

Vue.filter('global_filter_subject', (value: string, gradeCodeOrText: string = '') => {
  const mathTextCheck = '数学|数理逻辑思维|奥数|数理逻辑训练|';
  const chineseTextCheck = '语文素养|语文思维训练|';
  const mathText = '逻辑思维';
  const chineseText = '国学素养';
  const math = '数学';
  const chinese = '语文';
  const babyText = '国学启蒙';
  const babyReg = '未入园|PREPARATORY_KINDERGAREN';
  const gradeReg = '幼儿园小班|幼儿园中班|幼儿园大班|KINDERGARTEN_1|KINDERGARTEN_2|KINDERGARTEN_3';
  const gradeOtherReg = '小一|小二|小三|小学一年级|小学二年级|小学三年级|PRIMARY_SCHOOL_1|PRIMARY_SCHOOL_2|PRIMARY_SCHOOL_3';

  const gradeCheck = (grade: string, reg: string) => {
    return grade && reg.indexOf(grade) > -1;
  };
  const transferSubject = (subject: string, resMath: string, resChinese: string) => {
    return mathTextCheck.indexOf(subject) > -1 ? resMath : chineseTextCheck.indexOf(subject) > -1 ? resChinese : subject;
  };

  if (gradeCheck(gradeCodeOrText, gradeReg)) {
    return transferSubject(value, mathText, chineseText);
  } else if (gradeCheck(gradeCodeOrText, gradeOtherReg)) {
    return transferSubject(value, math, chinese);
  } else if (gradeCheck(gradeCodeOrText, babyReg)) {
    return transferSubject(value, mathText, babyText);
  } else {
    return value;
  }
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
