<template>
  <div :mySwiper="swiperOption" :class="swipeid" class="swiper-container">
    <div class="swiper-wrapper">
      <slot name="swiper-con" />
    </div>
    <!-- 分页器 -->
    <div
      v-show="paginationShow"
      @click="handleClick()"
      class="swiper-pagination"
    />
    <!--切换箭头-->
    <slot v-if="arrowButton" name="swiper-arrow" />
  </div>
</template>
<script>
export default {
  name: 'ZmSwiper',
  props: {
    swipeid: {
      type: String,
      default: ''
    },
    effect: {
      type: String,
      default: 'slide'
    },
    loop: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: 'horizontal'
    },
    pagination: {
      type: Object,
      default: () => ({
        el: '.swiper-pagination',
        type: 'bullets'
      })
    },
    autoplay: {
      default: 5000
    },
    paginationType: {
      type: String,
      default: 'bullets'
    },
    slidesPerView: {
      type: String
    },
    arrowButton: {
      type: Boolean,
      default: false
    },
    onSlideNextStart: {
      type: Function,
      default: null
    },
    onSlideNextEnd: {
      type: Function,
      default: null
    },
    onProgress: {
      type: Function,
      default: null
    },
    onSetTransition: {
      type: Function,
      default: null
    },
    onSlideChangeStart: {
      type: Function,
      default: null
    },
    onSlideChange: {
      type: Function,
      default: null
    },
    onSlideChangeEnd: {
      type: Function,
      default: null
    },
    allowSwipeToNext: {
      type: Boolean,
      default: true
    },
    noSwiping: {
      type: Boolean,
      default: false
    },
    watchSlidesProgress: {
      type: Boolean,
      default: false
    },
    centeredSlides: {
      type: Boolean,
      default: false
    },
    loopedSlides: {
      type: Number
    },
    paginationShow: {
      type: Boolean,
      default: true
    },
    paginationClickable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      dom: '',
      swiperOption: {
        //是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
        preventClicks: false,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        direction: 'horizontal',
        freeMode: false,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
        effect: 'slide',
        loop: true,
        observer: true,
        observeParents: true,
        on: {
          slideChange: this.onSlideChange,
          slideNextStart: this.onSlideNextStart,
          slideChangeEnd: this.onSlideChangeEnd,
          slideChangeStart: this.onSlideChangeStart,
          slideNextEnd: this.onSlideNextEnd,
          progress: this.onProgress,
          setTransition: this.onSetTransition
        }
        // autoplayDisableOnInteraction: false,
        // onlyExternal: false,//v3.0写法
        // allowTouchMove: true//v4.0写法
      }
    }
  },
  mounted () {
    var That = this
    var option = {
      loop: That.loop,
      pagination: That.pagination,
      autoplay: That.autoplay,
      direction: That.direction,
      effect: That.effect,
      autoplayDisableOnInteraction: false,
      allowSwipeToNext: That.allowSwipeToNext,
      noSwiping: That.noSwiping,
      observer: true,
      observeParents: true,
      on: {
        slideChange: That.onSlideChange,
        slideChangeEnd: That.onSlideChangeEnd,
        slideNextStart: That.onSlideNextStart,
        slideChangeStart: That.onSlideChangeStart,
        slideNextEnd: That.onSlideNextEnd,
        progress: That.onProgress,
        setTransition: That.onSetTransition
      },
      watchSlidesProgress: That.watchSlidesProgress,
      slidesPerView: That.slidesPerView,
      centeredSlides: That.centeredSlides,
      loopedSlides: That.loopedSlides
    }
    console.log(That.arrowButton)
    if (That.arrowButton) {
      Object.assign(option, {
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next'
        }
      })
    }
    this.swiperOption = option
  },
  methods: {
    next () {
      if (this.mySwiper) {
        this.mySwiper.slideNext();
      }
    },
    preview () {
      if (this.mySwiper) {
        this.mySwiper.slidePrev();
      }
    },
    handleClick () {
      if (this.$route.name == 'home') {
        setTimeout(() => {
          switch (this.mySwiper.activeIndex) {
            case 1:
              _hmt.push(['_trackEvent', '语文思维', '少儿课程', this.$route.name]);
              break;
            case 2:
              _hmt.push(['_trackEvent', '数理逻辑', '少儿课程', this.$route.name]);
              break;
            case 3:
              _hmt.push(['_trackEvent', '学习力', '少儿课程', this.$route.name]);
              break;
          }
        }, 0);
      }
    }
  }
}
</script>
<style lang="scss">
// @import './../../styles/mixin.scss';
</style>
