






























































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BScroll from 'better-scroll';
@Component({
  components: {},
})
export default class Protocol extends Vue {
  private bScroll: any = null;
  @Prop({
    type: Boolean,
    default: false,
  })
  private showProtocol: any;
  @Watch('showProtocol')
  private change(newVal: any) {
    if (newVal) {
      const that: any = this.$refs;
      this.$nextTick(() => {
        this.bScroll = that.protocolContent
          ? new BScroll(that.protocolContent, {
              click: true,
            })
          : null;
      });
    }
  }
  private handleClick() {
    this.$emit('update:showProtocol', false);
  }
}
