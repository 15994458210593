// import { addJs } from "./utils/util"

const FAT = {
  mKids: 'https://m-test.zhangmenkid.com',
  wwwURL: 'http://www-test.zhangmenkid.com/api',
  bannerGroupID: '5b333dee7c15aa7c5b08f2f0',
  semBannerGroupID: '5c1e1b76e2801d21d8bcec8a',
  c0d0BannerGroupId: '5d1eba83bdcb2757195ca8e0',
  mSuccessPageURL: 'http://m-test.zhangmen.com/appointment-success',
  aiH5PageURL: 'http://ai-activity-h5-test.zmaxis.com/wechat-operation/success1',
  nodeHost: '/node-host',
  appGateway: 'https://operation-gateway-test.zmlearn.com',
  operationExternalServer: 'https://operation-gateway-test.zmlearn.com/api/operation-external-server',
  marketingZmtest: 'http://marketing.zmtest.net',
  newPaySaleId: 'SID1591932871587',
  newPay3SaleId: 'SID1603942679462',
  newPayBdSaleId: 'SID1603942679462',
  newPayHTSaleId: 'SID1603942679462',
  newPayYNSaleId: 'SID1603942679462',
  newPayChannelSaleId: 'SID1614583101056',
  zminet: 'http://net001.zmaxis.com/api/web-node',
  courseShop: 'https://integral.zmtest.net/course-shop/commodity',
  kidAppGateway: 'https://kids-app-gateway-test.zmlearn.com',
  kidAppId: 'wx35abfc3702314419',
  codeKey9Point9: {
    channel_code: '23116',
    channel_keyword: 'd5bb76b373ffa6ea'
  },
  kidAppoinmentId: 178
};

const UAT = {
  mKids: 'http://mzhangmenkid.uat.zmops.cc',
  wwwURL: 'https://wwwzhangmenkid.uat.zmops.cc/api',
  bannerGroupID: '5b611a4a06bf736ce867f61d',
  semBannerGroupID: '',
  c0d0BannerGroupId: '',
  mSuccessPageURL: 'http://m.uat.zmops.cc/appointment-success',
  aiH5PageURL: 'http://ai-activity-h5-test.zmaxis.com/wechat-operation/success1',
  nodeHost: '/node-host',
  appGateway: 'https://app-gateway-op.uat.zmops.cc',
  operationExternalServer: 'https://app-gateway-op.uat.zmops.cc/api/operation-external-server',
  marketingZmtest: 'http://marketing.zmtest.net',
  newPaySaleId: 'SID1591932871587',
  newPay3SaleId: 'SID1603942679462',
  newPayBdSaleId: 'SID1603942679462',
  newPayHTSaleId: 'SID1603942679462',
  newPayYNSaleId: 'SID1603942679462',
  newPayChannelSaleId: 'SID1612772120742',
  zminet: 'http://net001.zmaxis.com/api/web-node',
  courseShop: 'https://integral.zmtest.net/course-shop/commodity',
  kidAppGateway: 'https://kids-app-gateway.uat.zmops.cc',
  kidAppId: 'wx35abfc3702314419',
  codeKey9Point9: {
    channel_code: '23116',
    channel_keyword: 'd5bb76b373ffa6ea'
  },
};

const PRO = {
  mKids: 'https://m.zhangmenkid.com',
  wwwURL: 'https://www.zhangmenkid.com/api',
  bannerGroupID: '5b333dee7c15aa7c5b08f2f0',
  semBannerGroupID: '5c1dfcc03338b34df8e06433',
  c0d0BannerGroupId: '5d1ef8cab3ebdd46661b5f2f',
  mSuccessPageURL: 'https://m.zhangmen.com/appointment-success',
  aiH5PageURL: 'https://ai-cativity-h5.zmtalent.com/wechat-operation/success1',
  nodeHost: '/node-host',
  appGateway: 'https://app-gateway-op.zmlearn.com',
  operationExternalServer: 'https://app-gateway-op.zmlearn.com/api/operation-external-server',
  marketingZmtest: 'https://marketing.zmlearn.com',
  newPaySaleId: 'SID1592209712084',
  newPay3SaleId: 'SID1605075335803',
  newPayBdSaleId: 'SID1606102850281',
  newPayHTSaleId: 'SID1610099622832',
  newPayYNSaleId: 'SID1610093034476',
  newPayChannelSaleId: 'SID1612772120742',
  zminet: 'https://zminet.com/api/web-node',
  courseShop: 'https://personal.zmlearn.com/course-shop/commodity',
  kidAppGateway: 'https://kids-app-gateway.zmlearn.com',
  kidAppId: 'wx22d1ee14c28e717c',
  codeKey9Point9: {
    channel_code: '23116',
    channel_keyword: 'd5bb76b373ffa6ea'
  },
  kidAppoinmentId: 38,
  shareUrl: 'https://m.xyz-65.top'
};

let config: any;

if (process.env.NODE_ENV === 'production') {
  config = PRO
} else if (process.env.NODE_ENV === 'uat') {
  config = UAT
} else {
  config = FAT
  // addJs(
  //   'https://statics-lib.zmlearn.com/vconsole/3.3.2/vconsole.min.js',
  //   () => {
  //     new window.VConsole()
  //   }
  // )
}

export default config

