import axios from 'axios'
import urlConfig from './config'
import cookies from 'js-cookie'

export default {
  getSubjectData(): any {
    return axios({
      method: 'post',
      url: `${urlConfig.appGateway}/api/operation-web-server/v1/ows/getDictGrade`,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      data: {},
    });
  },
  getKidsOwnSubjectData() {
      return axios({
          method: 'get',
          url: `${urlConfig.kidAppGateway}/kids/user/h5/dict/gradeSubject`,
          headers: {
              'Content-Type': 'application/json;charset=utf-8'
          },
          data: {}
      })
  },
  getMkidSubjectData () {
    return axios({
      method: 'get',
      url: `${urlConfig.appGateway}/kids/user/wechat/dict/gradeSubject`,
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      data: {}
    })
  },
  /**
 * 画啦啦海外用户注册
 * @param {*} data
 */
  appoint2(data: any): any {
    return axios({
      url: '/kids/api/wechat/landing/page/nine/appointment/with/code/oversea',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      data,
    }).then((res) => res.data);
  },
  sendSmsCode(params: any): any {
    return axios.post("/kids/api/wxmp/mp/activity/login/sendCertificationCode",
      params
    )
  },
  tencetApi(params: any): any {
    return axios.post("/kids/api/wxmp/tencent/ad/wechat",
      params
    )
  },
  // 获取微信分享签名
  kidGetSignature(url: any) {
    return axios({
      method: 'post',
      headers: {
        accessToken: cookies.get('accessToken'),
      },
      url: '/kids/api/wxmp/wx/jssdk/create/signature/learning/center',
      data: { url },
    })
  },
  // 获取微信分享签名
  getWxImgUrl(params: any) {
    return axios({
      method: 'GET',
      url: '/kids/api/wechat/identity/kv',
      params
    })
  },
  // 订单状态
  getOrderStatus(params: any) {
    return axios({
      method: 'GET',
      headers: {
        accessToken: cookies.get('accessToken'),
      },
      url: '/kids/api/wechat/order/queryOrderBySubOrder',
      params
    })
  },
}
