<template>
  <div>
    <template v-if="type === 'math'">
      <header class="header">
        <img
          class="head-img"
          src="https://web-data.zmlearn.com/image/4WKEK1KYAELRtT3Fz7Mmx6/banner%402x.png"
          alt=""
          v-if="!offline"
        />
        <img
          class="head-img"
          src="https://web-data.zmlearn.com/image/vNGDxqBihUAa33oX3dp254/%E7%94%BB%E6%9D%BF%206.png"
          alt=""
          v-else
        />
        <lesson-form
          class="lesson-form-main"
          :type="type"
          :extraClass="extraClass"
          :point="point"
        ></lesson-form>
        <!-- <img
          class="receive"
          src="https://web-data.zmlearn.com/image/e6taiMRt5uWxZamJFBHo7w/%E6%8C%89%E9%92%AE%402x.png"
          @click="showForm('1', 'click_kid_act30_ljlq')"
          alt=""
        /> -->
      </header>
      <img
        src="https://web-data.zmlearn.com/image/wnnxDFpBVJksMpiRnyWTxY/banner.png"
        alt=""
        style="
          background-size: contain;
          width: 94vw;
          margin-top: 1.4vw;
          box-sizing: border-box;
        "
        v-if="offline"
      />
      <img
        class="main main-1"
        src="https://web-data.zmlearn.com/image/j6oDf3i5rUkAidgYs1dBJ4/2%403x.png"
        alt=""
      />
      <img
        class="main"
        src="https://web-data.zmlearn.com/image/mWB8ZaKyh77G1AKf1sqQdj/3%403x.png"
        alt=""
      />
      <div class="system-wrap">
        <div class="tab-wrap">
          <a
            href="javascript:;"
            @click="changeBanner(item - 1)"
            v-for="item in 5"
            :key="item"
          ></a>
        </div>
        <img
          class="sysyem-item"
          v-show="curBanner === index"
          v-for="(item, index) in bannerUrl"
          :key="item"
          :src="item"
          alt=""
        />
        <img
          class="main"
          src="https://static-app-manager.zmlearn.com/emLu4wLC3065.png"
          alt=""
        />
      </div>
      <img
        class="main"
        src="https://web-data.zmlearn.com/image/8zcqBEWYd7MGYfEyhHrGyt/5%403x.png"
        alt=""
      />
      <img
        class="main"
        src="https://web-data.zmlearn.com/image/hzfhKPprjit2muD3JKtdMA/6%403x.png"
        alt=""
      />
    </template>
    <template v-else>
      <header class="header">
        <img
          class="head-img"
          src="https://web-data.zmlearn.com/image/pqyiCjVENrD4DCEsVky1Tf/%E7%BB%84%2039%402x.png"
          alt=""
          v-if="!offline"
        />
        <img
          class="head-img"
          src="https://web-data.zmlearn.com/image/mXxjDSRBoXA9vL2THHBXdD/%E7%94%BB%E6%9D%BF%205.png"
          alt=""
          v-else
        />
        <lesson-form
          class="lesson-form-main"
          :type="type"
          :extraClass="extraClass"
          :point="point"
        ></lesson-form>
        <!-- <img
          class="receive"
          src="https://web-data.zmlearn.com/image/qZgnz959RqEdhRGmfLwj7k/%E9%A2%86%E5%8F%96%E6%8C%89%E9%92%AE%402x.png"
          @click="showForm('1', 'click_kid_act31_ljlq')"
          alt=""
        /> -->
      </header>
      <img
        src="https://web-data.zmlearn.com/image/wnnxDFpBVJksMpiRnyWTxY/banner.png"
        alt=""
        style="
          background-size: contain;
          width: 94vw;
          margin-top: 1.4vw;
          box-sizing: border-box;
        "
        v-if="offline"
      />
      <img
        class="main main-1"
        src="https://web-data.zmlearn.com/image/wc9ueQQoD9fef6nspDtEgm/%E5%A6%88%E5%A6%88%E9%9A%BE%403x.png"
        alt=""
      />
      <img
        class="main"
        src="https://web-data.zmlearn.com/image/niq5bD9CHEft7asdBCYjp9/%E8%AF%AD%E6%96%87%E8%AF%BE%403x.png"
        alt=""
      />
      <img
        class="main"
        src="https://web-data.zmlearn.com/image/3nfKyq29dZP4u8knfKhyJb/%E4%B8%93%E4%B8%9A%E8%AF%BE%E7%A8%8B%403x.png"
        alt=""
      />
      <img
        class="main"
        src="https://web-data.zmlearn.com/image/wy7FLv1x1WYZNs2cSP117g/%E8%AF%BE%E7%A8%8B%E6%9C%89%E8%B6%A3%403x.png"
        alt=""
      />
      <img
        class="main"
        src="https://web-data.zmlearn.com/image/m7YugnnYuYtu7nne4JU8qt/%E5%B8%88%E8%B5%84%403x.png"
        alt=""
      />
    </template>

    <div class="dialog-form" v-show="showDialogForm">
      <div class="lesson-form-content">
        <div class="lesson-form-box">
          <lesson-form :type="type"></lesson-form>
          <img
            alt="close"
            class="dialog-form-close"
            src="https://web-data.zmlearn.com/image/pMeJFjz6QKFohpr9b4wPSH/icon_close.png"
            @click="showDialogForm = false"
          />
        </div>
      </div>
    </div>
    <div
      class="footer"
      @click="showForm('2', 'click_kid_act14_ljlq')"
      v-show="showBottomBtnFixed"
    >
      <img
        src="https://web-data.zmlearn.com/image/pgJGxwcWuZiCpb7abrHDEQ/bg-icon.jpg"
        alt=""
      />
    </div>
    <div
      class="right-icon"
      @click="showForm('2', 'click_kid_act14_ljlq')"
      v-show="showBottomBtnFixed"
    >
      <img
        src="https://web-data.zmlearn.com/image/m8SUj7bz87qM4zVznw1Ebf/right-icon.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import lessonForm from "./lesson-form";
import api from "@/api.js";
export default {
  components: {
    lessonForm,
  },
  props: {
    type: String,
    default: 'math'
  },
  data () {
    return {
      curBanner: 0,
      showDialogForm: false,
      bannerUrl: [
        "https://web-data.zmlearn.com/image/cDrncYWCJEApLeNErxY8JA/%E7%BB%84%2047%403x.png",
        "https://web-data.zmlearn.com/image/5n4jghumogVwn7NHo6sFje/%E7%BB%84%2048%403x.png",
        "https://web-data.zmlearn.com/image/vbjXg57MovFD7d7qKRcRK4/slice_four.png",
        "https://web-data.zmlearn.com/image/7pGyq3U2zXNQscvcvJQtuM/slice_five.png",
        "https://web-data.zmlearn.com/image/33AMV5EWBUUie2CwjkHti8/slice_six.png",
      ],
      extraClass: true,
      point: true,
      showBottomBtnFixed: false,
      offline: false,
    };
  },
  mounted () {
    this.getShowVal();
    this.$nextTick(() => {
      console.log('vvavavv');
      window.addEventListener('scroll', this.checkPosition, false);
      this.checkPosition();
    });
  },
  methods: {
    checkPosition () {
      if (window.scrollY < 610) {
        this.showBottomBtnFixed = false;
      } else if (window.scrollY > 610 && window.scrollY < 1570) {
        this.showBottomBtnFixed = true;
      } else if (window.scrollY > 1570) {
        this.showBottomBtnFixed = true;
      }
    },
    changeBanner (index) {
      this.curBanner = index;
    },
    showForm (position, eventId) {
      this.showDialogForm = true;
      ZM_JSSDK &&
        ZM_JSSDK.sendEvent({
          eventId,
          eventParam: {
            position,
            code: this.$route.query.channel_code,
          }
        });
    },
    getShowVal () {
      api.getinfo({
        "actionName": "info",
        "activityId": 3703,
        "engineName": "common-driver"
      }).then(res => {
        if (res.data.data.params.startTimeStamp <= res.data.data.params.currentTimeStamp && res.data.data.params.currentTimeStamp <= res.data.data.params.endTimeStamp) {
          this.offline = true
        }
      });
    },
    destroyed () {
      window.removeEventListener('scroll', this.checkPosition);
    }
  },
};
</script>
<style lang="scss" scoped>
.header {
  position: relative;
}

.lesson-form-main {
  margin-left: 30px;
}

.head-img {
  width: 100%;
}

.receive {
  position: absolute;
  top: 969px;
  left: 50%;
  width: 634px;
  height: 138px;
  transform: translateX(-50%);
}

.system-wrap {
  position: relative;

  .sysyem-item {
    position: absolute;
    top: 240px;
    left: 50%;
    width: 671px;
    height: 621px;
    transform: translateX(-50%);
  }

  .tab-wrap {
    display: flex;
    position: absolute;
    top: 240px;
    left: 50%;
    z-index: 1;
    width: 671px;
    height: 75px;
    transform: translateX(-50%);
    font-size: 0;

    a {
      width: 120px;
      height: 100%;

      + a {
        margin-left: 20px;
      }
    }
  }
}

.main {
  margin-top: 60px;
  width: 100%;
}

.main-1 {
  margin-top: 23px;
}

.dialog-form {
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
.lesson-form-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .dialog-form-close {
    margin-top: 20px;
    width: 75px;
    height: 75px;
  }
}

.footer {
  position: fixed;
  bottom: -10px;
  left: 0;
  width: 100%;

  img {
    width: 100%;
  }
}
.right-icon {
  position: fixed;
  bottom: 230px;
  right: -55px;
  width: 200px;
  height: 200px;

  img {
    width: 100%;
  }
}
</style>