import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import newPay from './landing-page/index'
import otherPage from './other-page/index'
import overseaPage from './oversea-page/index';
import Index from '@/views/newpayIndex/index.vue';
import semPage from './sem-page/index';
import lpPage from './lp-page/index';

const newChannel = () => import('@/views/new-channel/new-channel.vue')
const appointSuccessKid = () => import('@/v1Module/appointment-success-kid.vue')
const appointSuccessKidPay = () => import('@/v1Module/appointment-success-kid-pay.vue')
const appointSuccessKidNot86 = () => import('@/v1Module/appointment-success-kid-not86.vue')
const courseDetailChinese = () => import('@/views/course-detail/chinese/index.vue')
const courseDetailMath = () => import('@/views/course-detail/math/index.vue')
const appointSuccessPeilian = () => import('@/v1Module/appointment-success-peilian.vue')
const sem7 = () => import('@/views/oversea/2020sem7.vue');
const newChannelCode = () => import('@/views/oversea/new-channel-code.vue');
const newChannel2 = () => import('@/views/oversea/new-channel.vue');
const newChannelCode56 = () => import('@/views/new-channel/new-channel-code56.vue')
const newChannelCode78 = () => import('@/views/new-channel/new-channel-code78.vue')
const zmkidActivity2 = () => import('@/v1Module/activity/zmkid-activity/second.vue')
const oversea = () => import('@/views/oversea/oversea.vue');
const newC1 = () => import('@/views/lp/newc1/index.vue');
const newChannel9 = () => import('@/views/new-channel/new-channel9.vue')
const sem5 = () => import('@/views/lp/2020sem5.vue')
const newChannel2020Sem2 = () => import('@/views/lp/new-channel-2020sem2.vue')
const BdYw = () => import('@/views/lp/bd-yw.vue')
const BdSl = () => import('@/views/lp/bd-sl.vue')
const bdqk = () => import('@/views/lp/bd-qk.vue')

const addWx = () => import('@/views/addWechat/index.vue')

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  ...newPay,
  ...otherPage,
  ...semPage,
  ...overseaPage,
  ...lpPage,
  // 新版M站首页
  {
    path: '/index',
    name: 'home',
    component: Index,
  },
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/new-channel1',
    name: 'august-kid-0815',
    component: newChannel
  },
  {
    path: '/2020sem1',
    name: '2020sem1',
    component: newChannel
  },
  {
    path: '/course-chinese',
    name: 'courseDetailChinese',
    component: courseDetailChinese,
  },
  {
    path: '/course-math',
    name: 'coruseDetailMath',
    component: courseDetailMath,
  },
  {
    path: '/appointment-success-kid-pay',
    name: 'appointment-success-kid-pay',
    component: appointSuccessKidPay,
  },
  {
    path: '/appointment-success-kid',
    name: 'appointment-success-kid',
    component: appointSuccessKid,
  },
  {
    path: '/appointment-success-peilian',
    name: 'appointment-success-peilian',
    component: appointSuccessPeilian,
  },
  {
    path: '/appointment-success-kid-not86',
    name: 'appointment-success-kid-not86',
    component: appointSuccessKidNot86,
  },
  {
    path: '/red-envelopes',
    name: 'Red-envelopes',
    component: () => import('@/views/redEnvelope/index.vue'),
  },
  {
    path: '/2020sem7',
    name: '2020sem7',
    component: sem7
  },
  {
    path: '/new-channel3',
    name: 'kid-test03',
    component: newChannelCode
  },
  {
    path: '/new-channel2',
    name: 'august-kid-test01',
    component: newChannel2
  },
  {
    path: '/new-channel4',
    name: 'kid-test04',
    component: newChannelCode
  },
  {
    path: '/new-channel5',
    name: 'kid-test05',
    component: newChannelCode56
  },
  {
    path: '/new-channel6',
    name: 'kid-test06',
    component: newChannelCode56
  },
  {
    path: '/hwxxl-channel5',
    name: 'hwxxl-channel5',
    component: newChannelCode56
  },
  {
    path: '/new-channel7',
    name: 'kid-test07',
    component: newChannelCode78
  },
  {
    path: '/new-channel8',
    name: 'kid-test08',
    component: newChannelCode78
  },
  {
    path: '/new-channel9',
    name: 'kid-test09',
    component: newChannel9
  },
  {
    path: '/zmkid-activity2',
    name: 'zmkid-activity2',
    component: zmkidActivity2
  },
  {
    path: '/oversea-xxl3',
    name: 'oversea-xxl3',
    component: oversea
  },
  {
    path: '/lp/c1/sem',
    name: 'c1-sem',
    component: newC1
  },
  {
    path: '/2020sem5',
    name: '2020sem5',
    component: sem5
  },
  {
    path: '/2020sem2',
    name: '2020sem2',
    component: newChannel2020Sem2
  },
  {
    path: '/lp/bd-yw1',
    name: 'bdYw1',
    component: BdYw,
    meta: {
      formType: 'pre-mobile',
      subject: 'chinese'
    }
  },
  {
    path: '/lp/bd-yw',
    name: 'bdYw',
    component: BdYw,
    meta: {
      formType: 'pre-mobile',
      subject: 'chinese'
    }
  },
  {
    path: '/lp/bd-sl',
    name: 'bdSl',
    component: BdSl,
    meta: {
      formType: 'pre-mobile',
      subject: 'math'
    }
  },
  {
    path: "/lp/bd-qk1",
    component: bdqk,
    name: "bdqk1",
    meta: {
      formType: 'pre-mobile',
    }
  },
  {
    path: "/lp/bd-qk",
    component: bdqk,
    name: "bdqk",
    meta: {
      formType: 'pre-mobile',
    }
  },
  {
    path: '/addWx',
    name: 'addWx',
    component: addWx
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/m-page/',
  routes
})

export default router
