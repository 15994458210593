import buyClass3742 from '@/views/buyClass/buyClass3742/index.vue'
import buyClass3811 from '@/views/buyClass/buyClass3811/index.vue'
import buyClass3835 from '@/views/buyClass/buyClass3835/index.vue'
import buyClassForm from '@/views/buyClass/basePage/form.vue'
import wxSuccess from '@/views/buyClass/basePage/wxSuccess.vue'
import h5Success from '@/views/buyClass/basePage/h5Success.vue'
export default [{
  path: '/buyClass3742',
  name: 'buyClass3742',
  component: buyClass3742,
  description: '0.01元渠道权益活动需求',
}, {
  path: '/buyClass3811',
  name: 'buyClass3811',
  component: buyClass3811,
  description: '0.01元数学专项专题需求',
}, {
  path: '/buyClass3835',
  name: 'buyClass3835',
  component: buyClass3835,
  description: '9.9元渠道权益活动需求',
}, {
  path: '/buyClassForm',
  name: 'buyClassForm',
  component: buyClassForm,
  description: '表单填写',
  beforeEnter: (to: any, from: any, next: any) => {
    console.log(to, 'op')
    if (window.ZM_JSSDK.os.browserName === 'WeChat' && !to.query.openId) {
      let baseUrl: any = ''
      let mUrl: any = ''
      if (
        process.env.NODE_ENV === 'production'
      ) {
        baseUrl = 'https://kids-active.zhangmenkid.com/'
        mUrl = 'https://m.zhangmenkid.com/';
      } else {
        baseUrl = 'https://kids-active.zmaxis.com/'
        mUrl = 'http://m-test.zhangmenkid.com/'
        // mUrl = 'http://172.25.108.47:8088/'
      }
      const rediUrl = `${mUrl}m-page/buyClassForm?type=${to.query.type}&channel_code=${to.query.channel_code}&channel_keyword=${to.query.channel_keyword}&subjectCode=${to.query.subjectCode}&gradeCode=${to.query.gradeCode}&clickId=${to.query.clickId}`;
      const url = `${baseUrl}kids/api/wxmp/mp/web/auth/learning/center/base?needToken=false&needLogin=false&redirect=${encodeURIComponent(`${rediUrl}`)}`;
      window.location.replace(url);
      // next();
    } else {
      next();
    }
  }

}, {
  path: '/wxSuccess',
  name: 'wxSuccess',
  component: wxSuccess,
  description: '微信支付成功页'
}, {
  path: '/h5Success',
  name: 'h5Success',
  component: h5Success,
  description: 'h5支付成功页'
}]
