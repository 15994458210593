<template>
  <form class="lesson-form" ref="lessonForm" >
    <div class="form-content">
      <h2 v-show="showTitle" v-html="TitleText"></h2>
      <div class="form-basic-info">
        <div class="form-item flex-item">
          <select class="grade" v-model="weakGrade" ref="grade">
            <option
              v-for="({label, value}, index) in gradeArr"
              :value="value"
              :key="index"
            >{{label}}</option>
          </select>
          <div class="arrow grade-arrow"></div>

          <select class="subject" v-model="weakSubject" ref="subject">
            <option :value="value" v-for="({label, value},index) in subjectArr" :key="index">{{label}}</option>
          </select>
          <div class="arrow subject-arrow"></div>
        </div>
        </div>

        <div class="form-item">
          <input
            type="text"
            :placeholder="placeholderName"
            class="username"
            :class="{alert : userNameAlert}"
            v-model="userName"
            @blur="onBlurChange"
          />
        </div>
      </div>

      <div class="form-item">
        <input
          type="number"
          :placeholder="placeholderTel"
          :class="{alert : mobileAlert}"
          class="tel"
          v-model="mobile"
          maxlength="11"
          @blur="onBlurChange"
        />
      </div>

      <div
        class="form-item vertify-code"
        v-if="vertifyCodeStatus"
      >
        <input
          type="text"
          :placeholder="vertifyCodePlaceholder"
          class="identifyingcode"
          v-model="identifyingCode"
        />
        <span class="get-identify-code" @click.stop="initGeetest">{{codeCountDown}}</span>
      </div>
    </div>
    <protocol :defaultValue="checkProtocol" @changeProtocol="changeProtocol" />

    <div
            class="form-protocol"
            v-if="noRefund"
    >
      <img
              :src="getUnRefundCheckedState"
              @click="checkNoRefund = !checkNoRefund"
              class="checkoutImg"
      />
      <div class="form-protocol-text">
        <span @click="checkNoRefund = !checkNoRefund">本产品属于虚拟电子服务，不支持退费</span>
      </div>
    </div>

    <div class="zm-btn" @click="handleSubmitBtn()">
      <span>{{btnText}}</span>
    </div>

    <div class="info" >
      <img style="margin-right:5px" src="@/components/lesson-form/img/newFromLock.png" />
      <span style="color:#575757">{{alertMsg}}</span>
    </div>

    <div class="new-pay-modal" v-if="showSecondCheckModal">
      <div class="new-pay-modal__container">
        <div class="new-pay-modal__content">
          <span class="new-pay-modal__content--mobile">{{getFormatMobile}}</span>
          <span class="new-pay-modal__content--warn">请确认手机号是否正确</span>
        </div>
        <div class="new-pay-modal__action">
          <button class="new-pay-modal__action--cancel" @click="closeModal">取消</button>
          <button class="new-pay-modal__action--ok" @click="handleSecondCheckBtn">确认</button>
        </div>
      </div>
    </div>
    <img
      class="dialog-form-close"
      v-show="!showSecondCheckModal"
      src="https://web-data.zmlearn.com/image/pMeJFjz6QKFohpr9b4wPSH/icon_close.png"
      @click="$emit('closeDialog')"
    />
  </form>
</template>

<script>
import api from '@/api.js'
import urlConfig from '@/config'
import Protocol from '@/components/protocol/index'
import Toast from '@/base/toast/toast'
require("@/vendor/gt.js");
import cookie from 'js-cookie'
import Geetest from "@/utils/geetest"; // 极验代码
import { dictionaryNewGrade, dictionaryBaseSubject } from '../../libs/dic';
import { mapState } from 'vuex'

export default {
  name: 'lesson-form',

  components: {
    Protocol
  },
  props: {
    placeholderName: {
      type: String,
      default: '请输入您孩子的姓名'
    },
    placeholderTel: {
      type: String,
      default: '请输入您的手机号码'
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    TitleText: {
      type: String,
      default:
        ''
    },
    btnText: {
      type: String,
      default: '0元试听'
    },
    alertMsg: {
      type: String,
      default: '为了您的权益，您的隐私将被严格保密'
    },
    type: {
      type: String,
      default: ''
    },
    vertifyCodePlaceholder: {
      type: String,
      default: '验证码'
    },
    noRefund: {
      type: Boolean,
      default: false
    },
    appId: {
      type: String,
      default: ''
    },
    openId: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      getCode: '获取验证码',
      codeCountDownNum: 60,
      codeCountSubmitNum: 3,
      vertifyCodeStatus: true,
      vertifyOnOff: true,
      submitOnOff: true,
      userName: '',
      weakGrade: 'PRIMARY_SCHOOL_3',
      weakSubject: 'MATH_LOGIC',
      subjectArr: [],
      gradeArr: [],
      gradeSubjectArr: [],
      mobile: '',
      identifyingCode: '',
      channelProgram: '',
      userNameAlert: false,
      mobileAlert: false,
      checkProtocol: false,
      showProtocol: false,
      checkNoRefund: true,
      codeNumber: '',
      changMobile: false,
      checkedState: {
        checked: require('@/components/lesson-form/img/newCheck.png'),
        unchecked: require('@/components/lesson-form/img/newUncheck.png')
      },
      currentPhoneNumber: '',
      currentPhoneNumberHasCCOrCR: false,
      showSecondCheckModal: false,
      needSms: true,
      userId: '',
      LOGINFO: {},
      latitude: '',
      longitude: ''
    }
  },
  created () {
    this.gradeArr = dictionaryNewGrade;
    this.subjectArr = dictionaryBaseSubject;
    this.getEncryptUserId(this.$route.query.accessToken);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((res) => {
        console.log(res.coords.latitude, res.coords.longitude)
        this.latitude = String(res.coords.latitude)
        this.longitude = String(res.coords.longitude)
      })
    }

    api.smsSwitch({
      source: 'nine_course_assistant'
    }).then(res => {
      this.needSms = this.vertifyCodeStatus = res.data.data.needSms;
    })
  },
  watch: {
    userName () {
      this.userNameAlert = false
    },
    mobile () {
      this.mobileAlert = false;
      this.changMobile = true;
      this.identifyingCode = ''
    }
  },
  computed: {
    codeCountDown () {
      if (this.codeCountDownNum === 60 || this.codeCountDownNum <= 0) {
        return this.getCode
      } else {
        return this.codeCountDownNum + 's'
      }
    },
    getProtocolCheckedState () {
      return this.checkProtocol && this.checkedState.checked || this.checkedState.unchecked;
    },
    getUnRefundCheckedState () {
      return this.checkNoRefund && this.checkedState.checked || this.checkedState.unchecked;
    },
    getFormatMobile () {
      const m = this.mobile;
      return m.slice(0, 3) + ' ' + m.slice(3, 7) + ' ' + m.slice(7)
    }
  },
  methods: {
    changeProtocol (val) {
      this.checkProtocol = val;
    },
    async sendEvent (openId, user_type) {
      ZM_JSSDK && ZM_JSSDK.sendEvent({
        eventId: 'click_kid_act29_9.9ljg',
        eventParam: {
          name: '点击|少儿|活动|点击9.9元立即购',
          desc: '点击表单弹窗的立即购买按钮触发',
          openId,
          user_type, // user_type,用户类型,1：新用户、2：一对一关联用户；
        }
      });
    },
    async sendSaveRelationEvent (openId, name) {
      ZM_JSSDK && ZM_JSSDK.sendEvent({
        eventId: 'click_kid_act29_9.9ljg',
        eventParam: {
          name,
          desc: '点击登录注册触发',
          openId,
          mobile: this.mobile
        }
      });
    },
    onBlurChange () {
      document.activeElement.scrollIntoViewIfNeeded(false);
    },
    async doRegister () {
      if (this.submitOnOff) {
        try {
          if (!this.currentPhoneNumberHasCCOrCR) {
            if (this.needSms) {
              await this.loginWithCode();
            } else {
              this.loginWithoutCode();
            }
          } else {
            this.openModal();
          }
        } catch (e) {
          console.error(e);
          this.submitOnOff = false
        }
      }
    },
    async identifySubmitBtn (data) {
      if (this.vertifyOnOff) {
        try {
          const res = await api.KidGetVerifyCode(data);
          if (res.data.code * 1 === 0) {
            this.changMobile = false;
            Toast('验证码发送成功');
            const timer = setInterval(() => {
              this.codeCountDownNum--;
              if (this.codeCountDownNum <= 0 || this.changMobile) {
                clearInterval(timer);
                this.vertifyOnOff = true;
                this.codeCountDownNum = 60;
                this.codeNumber = ''
              }
            }, 1000)
          } else {
            this.vertifyOnOff = true;
            Toast(res.data.message || '验证码发送失败，请稍后再试')
          }
        } catch (e) {
          Toast(e)
        }
        this.vertifyOnOff = false
      }
    },
    validatePhoneNumber () {
      if (!/^\d+$/.test(this.mobile)) {
        Toast('请输入手机号');
        return false;
      }
      if (!/^\d{11}$/.test(this.mobile)) {
        Toast('请填写正确手机号');
        return false;
      }
      return true;
    },
    validateForm () {
      return new Promise((resolve, reject) => {
        if (this.noRefund && !this.checkNoRefund) {
          reject('本产品属于虚拟电子服务，不支持退费');
        } else if (!/^([a-zA-Z]|[\u4e00-\u9fa5]){1,}$/.test(this.userName)) {
          this.userNameAlert = true;
          reject('请填写正确的孩子姓名')
        } else if (this.userName.length >= 7) {
          this.userNameAlert = true;
          reject('请填写正确的孩子姓名')
        } else if (this.mobile.length === 0) {
          this.mobileAlert = true;
          reject('请输入手机号')
        } else if (!/^\d{11}$/.test(this.mobile)) {
          this.mobileAlert = true;
          reject('请填写正确手机号')
        } else if (this.mobile === this.currentPhoneNumber) {
          reject({ message: '当前手机号已注册，无法享受购买优惠。你可以更换新手机号重新支付哦！', duration: 3000 });
        } else if (
          this.vertifyCodeStatus &&
          !/^\d{4,6}$/.test(this.identifyingCode) &&
          !this.currentPhoneNumberHasCCOrCR
        ) {
          reject('请输入正确的验证码')
        } else if (!this.checkProtocol) {
          reject('请勾选用户协议和隐私政策')
        } else {
          resolve('success')
        }

      })
    },
    // 如果是新用户报名成功则回传微信完成转化跟踪
    async handleSubmitBtn () {
      try {
        await this.validateForm();
        await this.doRegister();
      } catch (err) {
        Toast(err)
      }
    },
    async handleSecondCheckBtn () {
      try {
        await this.validateForm();
        await this.loginWithoutCode();
        this.closeModal();
      } catch (err) {
        Toast(err)
      }
    },
    // 极验
    async initGeetest () {
      if (!this.validatePhoneNumber()) { return; }
      const captchaObj = await Geetest.request({
        product: "bind",
        width: "320px",
      });
      this.captchaObj = captchaObj;
      captchaObj
        .onReady(() => {
          setTimeout(() => {
            this.captchaObj.verify();
          }, 100);
        })
        .onSuccess(async () => {
          try {
            const result = captchaObj.getValidate();
            if (!result) {
              return Toast("请完成验证");
            }
            const data = {
              areaCode: '86',
              mobile: this.mobile,
              geetestChallenge: result.geetest_challenge,
              geetestValidate: result.geetest_validate,
              geetestSeccode: result.geetest_seccode,
            };
            await this.identifySubmitBtn(data);
          } catch (e) {
            Toast(e.message);
            this.captchaObj = captchaObj;
          } finally {
            captchaObj.destroy();
          }
        })
        .onError(() => {
          Toast({
            message: "您刷新太多次了, 故关闭验证弹框, 请从新验证",
            duration: 2000,
          });
          captchaObj.destroy();
          this.captchaObj = captchaObj;
        })
        .onClose(() => {
          captchaObj.destroy();
          this.captchaObj = captchaObj;
        });
    },
    // 跳转至订单
    async redirectToOrder (token) {
      // sUId -- 分享给当前用户的用户的userId
      // userId -- 当前用户的 userId
      const { channel_code = '', channel_keyword = '', sUId = '', accessToken, openId } = this.$route.query;
      this.sendSaveRelationEvent(openId, '请求前|少儿|活动|保存关系接口|sUId: ' + sUId);
      if (sUId) {
        this.sendSaveRelationEvent(openId, '开始请求|少儿|活动|保存关系接口');
        await this.saveRelation();
      }
      if (!accessToken) { await this.getEncryptUserId(token) }
      const paidThenRedirect = encodeURIComponent(`${urlConfig.mKids}/new-pay-share?userId=${this.userId}&openId=${openId}&paid=1&channel_code=${channel_code}&channel_keyword=${channel_keyword}`);
      window.location.href = `${urlConfig.marketingZmtest}/equity_sell?bu=2&saleId=${urlConfig.newPayHTSaleId}&channel_code=${channel_code}&channel_keyword=${channel_keyword}&routeName=orderConfirm&token=${token}&openId=${openId}&redirect_url=${paidThenRedirect}`;
    },
    async loginWithCode () {
      let self = this;
      const { openId = '', channel_keyword = '', channel_code = '' } = this.$route.query;
      const params = {
        // activityId: 0,
        weakSubjectCode: this.weakSubject,
        stuGradeCode: this.weakGrade,
        mobile: this.mobile,
        credentials: this.vertifyCodeStatus ? this.identifyingCode : '',
        code: channel_code,
        key: channel_keyword,
        name: this.userName,
        appId: this.appId,
        openId,
      };
      console.log('===================> this.$route.query :');
      console.log(this.$route.query);
      const timer2 = setInterval(() => {
        this.codeCountSubmitNum--;
        if (this.codeCountSubmitNum <= 0) {
          clearInterval(timer2);
          this.codeCountSubmitNum = 3;
          self.submitOnOff = true
        } else {
          this.submitOnOff = false
        }
        return this.submitOnOff
      }, 1000);

      try {
        const { data: { code, data: { accessToken = '', status } = {}, message } } = await api.KidAppointmentWithCode(params);
        if (code * 1 === 0) {
          if (accessToken) {
            cookie.set('accessToken', accessToken)
            api.recordData({
              code: channel_code,
              key: channel_keyword,
              latitude: this.latitude,
              longitude: this.longitude,
              source: /micromessenger/i.test(window.navigator.userAgent) ? 'wx' : 'h5'
            })
          }
          if (status === 2 && accessToken) {
            await this.sendEvent(openId, 1);
            this.redirectToOrder(accessToken);
          } else if (status === 1) {
            this.currentPhoneNumberHasCCOrCR = true;
            this.currentPhoneNumber = this.mobile;
            this.vertifyCodeStatus = false;
            this.identifyingCode = '';
            this.mobile = '';
            Toast({
              message: '当前手机号已注册，无法享受购买优惠。你可以更换新手机号重新支付哦！',
              duration: 3000
            })
          } else {
            this.identifyingCode = '';
            Toast(message || '提交失败，请重试！')
          }
          !accessToken && console.error(accessToken);
        } else {
          this.identifyingCode = '';
          Toast(message || '提交失败，请重试！')
        }
      } catch (e) {
        console.log(e);
      }
      // console.log(this.$route.query);
    },
    async loginWithoutCode () {
      const { openId = '', channel_keyword = '', channel_code = '' } = this.$route.query;
      const params = {
        // activityId: 0,
        weakSubjectCode: this.weakSubject,
        stuGradeCode: this.weakGrade,
        mobile: this.mobile,
        credentials: this.vertifyCodeStatus ? this.identifyingCode : '',
        code: channel_code,
        key: channel_keyword,
        name: this.userName,
        appId: this.appId,
        openId,
      };
      try {
        api.KidAppointmentWithoutCode(params).then(
          res => {
            const { data: { code, data: { accessToken = '', status } = {}, message } } = res;
            if (code * 1 === 0) {
              if (accessToken) {
                cookie.set('accessToken', accessToken)
                api.recordData({
                  code: channel_code,
                  key: channel_keyword,
                  latitude: this.latitude,
                  longitude: this.longitude,
                  source: /micromessenger/i.test(window.navigator.userAgent) ? 'wx' : 'h5'
                })
              }
              if (status === 2 && accessToken) {
                this.redirectToOrder(accessToken);
              } else if (status === 1) {
                this.mobile = '';
                Toast({
                  message: '当前手机号已注册，无法享受购买优惠。你可以更换新手机号重新支付哦！',
                  duration: 3000
                })
              } else {
                Toast(message || '提交失败，请重试！')
              }
              !accessToken && console.error(accessToken);
            } else {
              Toast(message || '提交失败，请重试！')
            }
          }
        ).catch();

      } catch (e) {
        console.log(e);
      }
    },

    closeModal () {
      this.showSecondCheckModal = false;
    },
    openModal () {
      this.showSecondCheckModal = true;
    },
    async getEncryptUserId (accessToken) {
      try {
        if (!accessToken) { return; }
        const { data: { data, code, message } } = await api.KidGetUserId(accessToken);
        code * 1 === 0 && (this.userId = data) || Toast(message);
      } catch (e) {
        Toast(e.message || '出错了，请重试~');
      }
    },
    async saveRelation () {
      const { channel_code, channel_keyword, headImgUrl = '', nickname = '', sUId = '', openId = '' } = this.$route.query;
      try {
        const appointmentRequest = {
          areaCode: '86',
          code: channel_code,
          credentials: this.vertifyCodeStatus ? this.identifyingCode : '',
          encryptUserId: sUId,
          headImg: headImgUrl,
          key: channel_keyword,
          mobile: this.mobile,
          name: this.userName,
          nickName: nickname,
          stuGradeCode: this.weakGrade,
          weakSubjectCode: this.weakSubject
        };
        const { data: { code, message, data } } = await api.KidShareRelationSave(appointmentRequest);
        data && console.log('save user share relationship success =================>');
        this.sendSaveRelationEvent(openId, '请求成功|少儿|活动|保存关系接口');
      } catch (e) {
        console.error(e);
        this.sendSaveRelationEvent(openId, '请求失败|少儿|活动|保存关系接口');
      } finally {
        this.sendSaveRelationEvent(openId, '请求结束|少儿|活动|保存关系接口');
      }
    }

  }
}
</script>

<style lang="scss" rel="stylesheet/less" scoped>
@import '~@/components/lesson-form/css/lessonForm1.scss';
.dialog-form-close {
  position: absolute;
  bottom: -150px;
  display: block;
  width: 75px;
  height: 75px;
  margin-left: 295px;
  margin-top: 75px;
}
.new-pay-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  &__container {
    width: px2rem(600);
    background-color: #fff;
    border-radius: px2rem(16);
    padding: px2rem(32) px2rem(20);
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &--mobile {
      padding-bottom: px2rem(20);
      color: #ff6e35;
      font-size: px2rem(46);
    }
    &--warn {
      color: #202020;
      font-size: px2rem(32);
    }
  }
  &__action {
    button {
      min-width: px2rem(180);
      padding: px2rem(20) px2rem(23);
      margin-top: px2rem(32);
      border-radius: px2rem(21);
      border: none;
    }
    &--cancel {
      background-color: #ccc;
      margin-right: px2rem(32);
      color: #202020;
      font-size: px2rem(32);
    }
    &--ok {
      color: #fff;
      font-size: px2rem(32);
      background-color: rgba(255, 110, 53, 0.8);
    }
  }
}
</style>
