const LOCK_BODY_KEY = 'data-scroll-before-lock';
const LOCK_DATA_KEY = 'data-lock-scroll';

/**
 * 锁定屏幕，禁止滚动（适用于移动端）
 */
export function lockScroll() {
  const htmlStyle = document.documentElement.style;
  const bodyStyle = document.body.style;
  const scrollY = window.scrollY;
  const height = htmlStyle.height;
  const pos = bodyStyle.position;
  const top = bodyStyle.top;
  const left = bodyStyle.left;
  const right = bodyStyle.right;

  bodyStyle.position = 'fixed';
  bodyStyle.top = -scrollY + 'px';
  bodyStyle.left = '0';
  bodyStyle.right = '0';
  htmlStyle.height = '100vh';
  document.body.setAttribute(LOCK_BODY_KEY, scrollY + '');
  document.body.setAttribute(LOCK_DATA_KEY, JSON.stringify({
    height, pos, top, left, right, scrollY
  }));
}

export function unlockScroll() {
  const str = document.body.getAttribute(LOCK_DATA_KEY);
  if (!str) return;

  const { height, pos, top, left, right, scrollY } = JSON.parse(str);
  const bodyStyle = document.body.style;

  document.documentElement.style.height = height;
  bodyStyle.position = pos;
  bodyStyle.top = top;
  bodyStyle.left = left;
  bodyStyle.right = right;
  window.scrollTo(0, scrollY);
  setTimeout(() => {
    document.body.removeAttribute(LOCK_BODY_KEY);
    document.body.removeAttribute(LOCK_DATA_KEY);
  }, 30);
}