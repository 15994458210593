<template>
  <form class="lesson-form" ref="lessonForm">
    <div class="form-content">
      <!-- <h2 v-show="showTitle" v-html="TitleText"></h2> -->
      <div class="form-basic-info">
        <div class="form-item flex-item">
          <select class="grade" v-model="weakGrade" ref="grade">
            <option
              v-for="({ label, value }, index) in gradeArr"
              :value="value"
              :key="index"
            >
              {{ label }}
            </option>
          </select>
          <div class="arrow grade-arrow"></div>

          <select class="subject" v-model="weakSubject" ref="subject">
            <option
              :value="value"
              v-for="({ label, value }, index) in subjectArr"
              :key="index"
            >
              {{ label }}
            </option>
          </select>
          <div class="arrow subject-arrow"></div>
        </div>
      </div>

      <div class="form-item">
        <input
          type="text"
          :placeholder="placeholderName"
          class="username"
          :class="{ alert: userNameAlert }"
          v-model="userName"
          @blur="onBlurChange"
        />
      </div>
    </div>

    <div class="form-item phone-item">
      <input
        type="number"
        :placeholder="placeholderTel"
        :class="{ alert: mobileAlert }"
        class="tel"
        v-model="mobile"
        maxlength="11"
        @blur="onBlurChange"
      />
    </div>

    <div class="form-item vertify-code" v-if="vertifyCodeStatus">
      <input
        type="text"
        :placeholder="vertifyCodePlaceholder"
        class="identifyingcode"
        v-model="identifyingCode"
      />
      <span class="get-identify-codes" @click.stop="initGeetest">{{
        codeCountDown
      }}</span>
    </div>
    <div class="zm-btns" @click="handleSubmitBtn()"></div>
    <div class="form-protocol">
      <img
        :src="getProtocolCheckedState"
        @click="checkProtocol = !checkProtocol"
        class="checkoutImg"
      />
      <div class="form-protocol-text">
        <span @click="checkProtocol = !checkProtocol">我已阅读并同意 </span>
        <span class="high-light" @click.stop="protocol">《用户协议》</span>
        和 <span class="high-light" @click.stop="privacy">《隐私政策》</span>
      </div>
    </div>
    <div class="info">
      <img
        style="margin-right: 5px"
        src="@/components/lesson-form/img/newFromLock.png"
      />
      <span style="color: #575757">{{ alertMsg }}</span>
    </div>

    <protocol :showProtocol.sync="showProtocol" />
    <img
      class="dialog-form-close"
      v-show="!showSecondCheckModal"
      src="https://web-data.zmlearn.com/image/pMeJFjz6QKFohpr9b4wPSH/icon_close.png"
      @click="$emit('closeDialog')"
    />
  </form>
</template>
<script>
import api from '@/api.js'
import urlConfig from '@/config'
import Protocol from '@/components/lesson-form/protocol'
import Toast from '@/base/toast/toast'
require("@/vendor/gt.js");
import Geetest from "@/utils/geetest"; // 极验代码
import { dictionaryBaseSubject, dictionaryGradeAndSubjectNew, dictionaryMiddleGradeNew } from '../../libs/dic';

import { goToOrderAndPay } from '@/utils/tool';

export default {
  name: 'lesson-form',
  components: {
    Protocol
  },
  props: {
    placeholderName: {
      type: String,
      default: '请输入您孩子的姓名'
    },
    placeholderTel: {
      type: String,
      default: '请输入您的手机号码'
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    TitleText: {
      type: String,
      default:
        ''
    },
    btnText: {
      type: String,
      default: '0元试听'
    },
    alertMsg: {
      type: String,
      default: '为了您的权益，您的隐私将被严格保密'
    },
    type: {
      type: String,
      default: ''
    },
    vertifyCodePlaceholder: {
      type: String,
      default: '验证码'
    },
    noRefund: {
      type: Boolean,
      default: false
    },
    appId: {
      type: String,
      default: ''
    },
    openId: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      getCode: '获取验证码',
      codeCountDownNum: 60,
      codeCountSubmitNum: 3,
      vertifyCodeStatus: true,
      vertifyOnOff: true,
      submitOnOff: true,
      areaCode: 86,
      userName: '',
      weakGrade: 'PRIMARY_SCHOOL_3',
      weakSubject: 'CHINESE_LOGIC',
      gradeArr: [],
      mobile: '',
      identifyingCode: '',
      channelProgram: '',
      userNameAlert: false,
      mobileAlert: false,
      checkProtocol: false,
      showProtocol: false,
      checkNoRefund: true,
      codeNumber: '',
      changMobile: false,
      checkedState: {
        checked: require('@/components/lesson-form/img/newCheck.png'),
        unchecked: require('@/components/lesson-form/img/newUncheck.png')
      },
      currentPhoneNumber: '',
      showSecondCheckModal: false,
      userId: '',
      LOGINFO: {},
      showCountry: false,
      officialText: ['还差一步，即可领取【掌门59节趣味思维课程】哦!', "扫描下方二维码关注'掌门少儿学习中心'即可"]
    }
  },
  created () {
    this.gradeArr = dictionaryMiddleGradeNew;
    // this.getEncryptUserId(this.$route.query.accessToken);
  },
  watch: {
    userName () {
      this.userNameAlert = false
    },
    mobile () {
      this.mobileAlert = false;
      this.changMobile = true;
      this.identifyingCode = ''
    },
    subjectArr (newVal) {
      const result = newVal.find(({ value }) => value === 'CHINESE_LOGIC') || newVal[0];
      this.weakSubject = result && result.value;
    }
  },
  computed: {
    codeCountDown () {
      if (this.codeCountDownNum === 60 || this.codeCountDownNum <= 0) {
        return this.getCode
      } else {
        return this.codeCountDownNum + 's'
      }
    },
    getProtocolCheckedState () {
      return this.checkProtocol && this.checkedState.checked || this.checkedState.unchecked;
    },
    getUnRefundCheckedState () {
      return this.checkNoRefund && this.checkedState.checked || this.checkedState.unchecked;
    },
    getFormatMobile () {
      const m = this.mobile;
      return m.slice(0, 3) + ' ' + m.slice(3, 7) + ' ' + m.slice(7)
    },
    subjectArr () {
      const result = dictionaryGradeAndSubjectNew.find(({ value }) => value === this.weakGrade);
      return result && result.children || dictionaryBaseSubject;
    }
  },
  methods: {
    protocol () {
      window.location.href = 'https://www.zhangmen.com/service-agreement.html';
    },
    privacy () {
      window.location.href = 'https://www.zhangmen.com/privacy-agreement.html';
    },
    onBlurChange () {
      document.activeElement.scrollIntoViewIfNeeded(false);
    },
    async doRegister () {
      if (this.submitOnOff) {
        try {
          await this.loginWithCode();
        } catch (e) {
          console.error(e);
          this.submitOnOff = false
        }
      }
    },
    async identifySubmitBtn (data) {
      if (this.vertifyOnOff) {
        try {
          const res = await api.KidGetVerifyCode(data);
          if (res.data.code * 1 === 0) {
            this.changMobile = false;
            Toast('验证码发送成功');
            const timer = setInterval(() => {
              this.codeCountDownNum--;
              if (this.codeCountDownNum <= 0 || this.changMobile) {
                clearInterval(timer);
                this.vertifyOnOff = true;
                this.codeCountDownNum = 60;
                this.codeNumber = ''
              }
            }, 1000)
          } else {
            this.vertifyOnOff = true;
            Toast(res.data.message || '验证码发送失败，请稍后再试')
          }
        } catch (e) {
          Toast(e)
        }
        this.vertifyOnOff = false
      }
    },
    validatePhoneNumber () {
      if (!/^\d+$/.test(this.mobile)) {
        Toast('请输入手机号');
        return false;
      }
      // if (!/^\d{11}$/.test(this.mobile)) {
      //   Toast('请填写正确手机号');
      //   return false;
      // }
      return true;
    },
    validateForm () {
      return new Promise((resolve, reject) => {
        if (this.noRefund && !this.checkNoRefund) {
          reject('本产品属于虚拟电子服务，不支持退费');
        } else if (!/^([a-zA-Z]|[\u4e00-\u9fa5]){1,}$/.test(this.userName)) {
          this.userNameAlert = true;
          reject('请填写正确的孩子姓名')
        } else if (this.userName.length >= 7) {
          this.userNameAlert = true;
          reject('请填写正确的孩子姓名')
        } else if (this.mobile.length === 0) {
          this.mobileAlert = true;
          reject('请输入手机号')
        } else if (this.mobile === this.currentPhoneNumber) {
          reject({ message: '当前手机号已注册，无法享受购买优惠。你可以更换新手机号重新支付哦！', duration: 3000 });
        } else if (
          this.vertifyCodeStatus &&
          !/^\d{4,6}$/.test(this.identifyingCode)
        ) {
          reject('请输入正确的验证码')
        } else if (!this.checkProtocol) {
          reject('请勾选用户协议和隐私政策')
        } else {
          resolve('success')
        }
      })
    },
    // 如果是新用户报名成功则回传微信完成转化跟踪
    async handleSubmitBtn () {
      this.$emit('track', '6');
      try {
        await this.validateForm();
        await this.doRegister();
      } catch (err) {
        Toast(err)
      }
    },

    // 极验
    async initGeetest () {
      if (!this.validatePhoneNumber() || !this.vertifyOnOff) { return; }
      const captchaObj = await Geetest.request({
        product: "bind",
        width: "320px",
      });
      this.captchaObj = captchaObj;
      captchaObj
        .onReady(() => {
          setTimeout(() => {
            this.captchaObj.verify();
          }, 100);
        })
        .onSuccess(async () => {
          try {
            const result = captchaObj.getValidate();
            if (!result) {
              return Toast("请完成验证");
            }
            const data = {
              areaCode: this.areaCode,
              mobile: this.mobile,
              geetestChallenge: result.geetest_challenge,
              geetestValidate: result.geetest_validate,
              geetestSeccode: result.geetest_seccode,
            };
            await this.identifySubmitBtn(data);
          } catch (e) {
            Toast(e.message);
            this.captchaObj = captchaObj;
            this.vertifyOnOff = true;
          } finally {
            captchaObj.destroy();
          }
        })
        .onError(() => {
          Toast({
            message: "您刷新太多次了, 故关闭验证弹框, 请从新验证",
            duration: 2000,
          });
          captchaObj.destroy();
          this.captchaObj = captchaObj;
          this.vertifyOnOff = true;
        })
        .onClose(() => {
          captchaObj.destroy();
          this.captchaObj = captchaObj;
        });
    },
    // 跳转至订单
    async redirectToOrder (token) {
      const { channel_code = '', channel_keyword = '', openId } = this.$route.query;
      const paidThenRedirect = encodeURIComponent(`${urlConfig.mKids}/m-page/appointment-success-kid-pay?mobile=${this.areaCode}-${this.mobile}&flatfrom=appointment-success-kid-pay&keyword=yifengouke&officialText=${[this.officialText]}&source=33&jira=3446&channel_code=${channel_code}&channel_keyword=${channel_keyword}`);
      goToOrderAndPay(urlConfig.newPayChannelSaleId, channel_code, channel_keyword, token, paidThenRedirect, openId);
    },
    async loginWithCode () {
      let self = this;
      const { channel_keyword = '', channel_code = '' } = this.$route.query;
      const params = {
        // activityId: 0,
        activityCode: 3446,
        weakSubjectCode: this.weakSubject,
        stuGradeCode: this.weakGrade,
        mobile: this.mobile,
        credentials: this.vertifyCodeStatus ? this.identifyingCode : '',
        code: channel_code,
        key: channel_keyword,
        name: this.userName,
        appointmentValue: 4,
        // appId: this.appId,
        // openId,
        areaCode: this.areaCode
      };
      const timer2 = setInterval(() => {
        this.codeCountSubmitNum--;
        if (this.codeCountSubmitNum <= 0) {
          clearInterval(timer2);
          this.codeCountSubmitNum = 3;
          self.submitOnOff = true
        } else {
          this.submitOnOff = false
        }
        return this.submitOnOff
      }, 1000);

      try {
        const { data } = await api.KidAppointmentWithCode(params);
        console.log(data);
        const code = data.code;
        if (code * 1 === 0) {
          const { accessToken } = data.data;
          this.$emit('track', '7');
          setTimeout(() => {
            this.redirectToOrder(accessToken);
          }, 300);
        } else {
          this.identifyingCode = '';
          Toast(data.message || '提交失败，请重试！')
        }
      } catch (e) {
        // Toast(e.message || '提交失败，请重试！')
        console.log(e);
      }
      // console.log(this.$route.query);
    },
    closeModal () {
      this.showSecondCheckModal = false;
    }
  }
}
</script>
<style lang="scss" scoped>
// @import '~@/components/lesson-form/css/lessonForm1.scss';
.lesson-form {
  width: 100%;
  padding: 50px 0 0;
  box-shadow: 4px 4px 22px 0 rgba(4, 4, 4, 0.28);
  // border-radius: 10px;
  position: relative;
  height: 851px;
  box-sizing: border-box;
  background-size: 100% 100%;
  background: url('https://web-data.zmlearn.com/image/mzjD1TP5YW2im75q8ch4YJ/lessonPopup.png')
    no-repeat center/cover;
  .form-content {
    margin-top: 120px;
  }
  h2 {
    text-align: center;
    padding-bottom: 30px;
    font-size: 40px;
    color: #666666;
    letter-spacing: 0;
    span {
      font-size: 50px;
      color: #f65051;
      letter-spacing: 0;
    }
  }
  .form-item {
    box-sizing: border-box;
    width: 592px;
    height: 90px;
    margin: 0 auto 14px;
    font-family: 'SourceHanSansCN-Normal';
    font-size: 30px;
    input::-webkit-input-placeholder {
      color: #999999;
    }
    input::-moz-placeholder {
      color: #999999;
    }
    select::-webkit-input-placeholder {
      color: #999999;
    }
    select::-moz-placeholder {
      color: #999999;
    }
    &.phone-item {
      width: 100%;
      padding: 0 32px 16px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: row nowrap;
    }
    .area-code {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      margin-right: 0.93333vw;
      border-radius: 2.66667vw;
      padding-left: 2.53333vw;
      width: 16.8vw;
      height: 11.46667vw;
      background: #eeeeee;
      font-size: 3.73333vw;
      color: #999999;
      box-sizing: border-box;
    }
    .tel {
      width: 100%;
      height: 86px;
      padding: 0 20px;
      border-radius: 20px;
      border: none;
      box-sizing: border-box;
      color: #202020;
      background: #eee;
      font-size: 28px;
    }

    .identifyingcode {
      width: 100%;
      height: 86px;
      padding: 0 20px;
      border-radius: 20px;
      border: none;
      box-sizing: border-box;
      color: #202020;
      background: #eee;
      font-size: 28px;

      &::placeholder {
        color: #999;
      }

      .get-identify-codes {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 80px;
        border-radius: 10px;
        width: 181px;
        height: 66px;
        background: #ff7533;
        font-size: 28px;
        color: #fff;
      }
    }
    input,
    select {
      display: inline-block;
      text-indent: 16px;
      width: 100%;
      height: 84px;
      border: 1px solid #c5c5c5;
      border-radius: 8px;
      &.alert {
        border-color: #f65051;
      }
      &.alert::-webkit-input-placeholder {
        color: #f65051;
      }
      &.alert::-moz-placeholder {
        color: #f65051;
      }
    }
    select {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background: url('https://web-data.zmlearn.com/image/5w1HDtbhBym4m6QxoQon63/xiala.png')
        no-repeat 90% center transparent;
      background-size: 25px 16px;
      background-color: #ffffff;
      padding-right: 14px;
      option {
        color: #000000;
      }
    }
    .grade,
    .subject {
      width: 48%;
    }
  }
  .flex-item {
    display: flex;
    justify-content: space-between;
  }
  .form-protocol {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-size: 24px;
    margin-top: 0 !important;
    .form-protocol-checkbox {
      margin-right: 20px;
    }
    .form-protocol-text {
      color: #000000;
      .high-light {
        color: #c81432;
      }
    }
  }
  .info {
    text-align: center;
    margin-top: 10px;
    padding-bottom: 60px;
    img {
      width: 21px;
      height: 25px;
      position: relative;
      top: 2px;
    }
    span {
      font-size: 24px;
      color: #ababab;
    }
  }
  .zm-btns {
    width: 634px;
    height: 139px;
    margin: 20px auto 0;
    border: none;
    background: url('https://web-data.zmlearn.com/image/iuD9jCrVUc5ctAdZ16iBRv/%E7%BB%84%20195%402x.png')
      no-repeat center;
    background-size: contain;
    line-height: 108px;
    align-content: center;
    border-radius: 47px;
    text-align: center;
    font-family: 'PingFangSC-Medium';
    font-size: 42px;
    color: #ffffff;
    cursor: pointer;
  }
  .vertify-code {
    position: relative;
    .get-identify-codes {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 20px;
      top: 10px;
      border-radius: 10px;
      width: 181px;
      height: 66px;
      background: #ff7533;
      font-size: 28px;
      color: #fff;
    }
  }
}

.dialog-form-close {
  position: absolute;
  bottom: -150px;
  display: block;
  width: 58px;
  height: 58px;
  margin-left: 295px;
  margin-top: 75px;
}
.new-pay-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  &__container {
    width: 600px;
    background-color: #fff;
    border-radius: 16px;
    padding: 32px 20px;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &--mobile {
      padding-bottom: 20px;
      color: #ff6e35;
      font-size: 46px;
    }
    &--warn {
      color: #202020;
      font-size: 32px;
    }
  }
  &__action {
    button {
      min-width: 180px;
      padding: 20px 23px;
      margin-top: 32px;
      border-radius: 21px;
      border: none;
    }
    &--cancel {
      background-color: #ccc;
      margin-right: 32px;
      color: #202020;
      font-size: 32px;
    }
    &--ok {
      color: #fff;
      font-size: 32px;
      background-color: rgba(255, 110, 53, 0.8);
    }
  }
}
</style>
