import Vue from 'vue'
import BindForm from './index.vue'

const BindFormConstructor = Vue.extend(BindForm)


const BindFormComponent = (options = {}) => {

  // 挂在在div上
  let instance = new BindFormConstructor().$mount(document.createElement('div'))

  // 把组件挂在到body上
  const app = document.getElementById("app");
  const clientHeight = app.clientHeight;
  // 传值
  instance.mobile = options.mobile;
  instance.closeDialog = options.closeDialog;
  instance.open_id = options.open_id;
  instance.height = clientHeight;
  app.appendChild(instance.$el);

  //显示 
  instance.show();

  
  return instance
}


export default BindFormComponent
