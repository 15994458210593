import API from '@/api.js'
import { getAppId } from '../../utils/util'

export function shareMessage (config) {
  const { title, desc, imgUrl, link } = config;
  const appId = getAppId();
  // 微信图文分享
  try {
    API.getSignature1(window.location.href.split('#')[0], appId)
      .then(data => {
        let { timestamp, nonceStr, signature, appId:_appId } = data.data.data;
        console.log(_appId, timestamp, nonceStr, signature,'--------');
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: _appId, // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名，见附录1
          jsApiList: [
            'chooseImage',
            'previewImage',
            'uploadImage',
            'downloadImage',
            'checkJsApi',
            'onMenuShareTimeline',
            'onMenuShareAppMessage',
            'onMenuShareQQ',
            'onMenuShareWeibo'
          ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
        wx.ready(function () {
          wx.onMenuShareTimeline({
            title: title || defaultTitle, // 分享标题
            link: link || window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: imgUrl || defaultImgUrl, // 分享图标
            success: function () {
              // 用户点击了分享后执行的回调函数
            }
          });
          wx.onMenuShareAppMessage({
            title: title || defaultTitle,
            desc: desc || defaultDesc,
            link: link || window.location.href,
            imgUrl: imgUrl || defaultImgUrl,
            trigger: function (res) {
              // 不要尝试在trigger中使用ajax异步请求修改本次分享的内容，因为客户端分享操作是一个同步操作，这时候使用ajax的回包会还没有返回
              // alert('用户点击发送给朋友');
            },
            success: function (res) {
              // alert('已分享');
            },
            cancel: function (res) {
              // alert('已取消');
            },
            fail: function (res) {
              // alert(JSON.stringify(res));
            }
          })
        })
      })
      .catch(e => {
        console.log(e)
      })
  } catch (e) {
    console.log(e);
  }
}

export async function KidsWeChatShare (config, hideApiList = []) {
  // 分享功能 安全域名
  // Fat m-test.zhangmenkid.com  绑定在 掌学少儿 公众号
  // PRO m.zhangmenkid.com 绑定在 掌门少儿学习中心  公众号
  const { title, desc, imgUrl, link } = config;
  const defaultTitle = '掌门少儿';
  const defaultImgUrl = 'https://web-data.zmlearn.com/image/xs7MibaiU9biGhuHCD5xnZ/share_icon.png';
  const defaultDesc = '';
  // 微信图文分享
  try {
    const { data: { data: { timestamp, nonceStr, signature, appId } } } = await API.kidGetSignature(window.location.href.split('#')[0]);
    console.log(timestamp, nonceStr, signature);
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId, // 必填，公众号的唯一标识
      timestamp, // 必填，生成签名的时间戳
      nonceStr, // 必填，生成签名的随机串
      signature, // 必填，签名，见附录1
      jsApiList: [
        'chooseImage',
        'previewImage',
        'uploadImage',
        'downloadImage',
        'checkJsApi',
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
        'onMenuShareQQ',
        'onMenuShareWeibo',
        'hideMenuItems'
      ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    });
    wx.ready(function () {
      wx.hideMenuItems({
        menuList: hideApiList // 文档 http://caibaojian.com/wxwiki/0030551f015f01ecaa56d20b88ee3c6cb32503bf.html 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
      });
      wx.onMenuShareTimeline({
        title: title || defaultTitle, // 分享标题
        link: link || window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: imgUrl || defaultImgUrl, // 分享图标
        success: function () {
          // 用户点击了分享后执行的回调函数
        }
      });
      wx.onMenuShareAppMessage({
        title: title || defaultTitle,
        desc: desc || defaultDesc,
        link: link || window.location.href,
        imgUrl: imgUrl || defaultImgUrl,
        trigger: function (res) {
          // 不要尝试在trigger中使用ajax异步请求修改本次分享的内容，因为客户端分享操作是一个同步操作，这时候使用ajax的回包会还没有返回
          // alert('用户点击发送给朋友');
        },
        success: function (res) {
          // alert('已分享');
        },
        cancel: function (res) {
          // alert('已取消');
        },
        fail: function (res) {
          // alert(JSON.stringify(res));
        }
      })
    })
    return appId;
  } catch (e) {
    console.log(e);
  }
}