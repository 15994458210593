exports.gradeData = [
    ['幼儿园中班', '幼儿园中班'],
    ['幼儿园大班', '幼儿园大班'],
    ['幼儿园小班', '幼儿园小班'],
    ['小一', '小一'],
    ['小二', '小二'],
    ['小三', '小三'],
    ['小四', '小四'],
    ['小五', '小五'],
    ['小六', '小六'],
    ['初一', '初一'],
    ['初二', '初二'],
    ['初三', '初三'],
    ['初四', '初四'],
    ['高一', '高一'],
    ['高二', '高二'],
    ['高三', '高三']
  ]
  
  exports.subjectData = [
    [
      ['奥数', '数理逻辑训练'],
      ['学习力训练', '学习力训练'],
      ['钢琴陪练', '钢琴陪练']
    ],
    [
      ['奥数', '数理逻辑训练'],
      ['学习力训练', '学习力训练'],
      ['钢琴陪练', '钢琴陪练']
    ],
    [
      ['奥数', '数理逻辑训练'],
      ['学习力训练', '学习力训练'],
      ['钢琴陪练', '钢琴陪练']
    ],
    [
      ['语文', '语文逻辑训练'],
      ['奥数', '数理逻辑训练'],
      ['英语', '新概念英语'],
      ['学习力训练', '学习力训练'],
      ['钢琴陪练', '钢琴陪练']
    ],
    [
      ['语文', '语文逻辑训练'],
      ['奥数', '数理逻辑训练'],
      ['英语', '新概念英语'],
      ['学习力训练', '学习力训练'],
      ['钢琴陪练', '钢琴陪练']
    ],
    [
      ['语文', '语文逻辑训练'],
      ['奥数', '数理逻辑训练'],
      ['英语', '新概念英语'],
      ['学习力训练', '学习力训练'],
      ['钢琴陪练', '钢琴陪练']
    ],
    [
      ['数学', '数学'],
      ['语文', '语文'],
      ['英语', '英语'],
      ['奥数', '奥数'],
      ['钢琴陪练', '钢琴陪练']
    ],
    [
      ['数学', '数学'],
      ['语文', '语文'],
      ['英语', '英语'],
      ['奥数', '奥数'],
      ['钢琴陪练', '钢琴陪练']
    ],
    [
      ['数学', '数学'],
      ['语文', '语文'],
      ['英语', '英语'],
      ['奥数', '奥数'],
      ['钢琴陪练', '钢琴陪练']
    ],
    [
      ['数学', '数学'],
      ['语文', '语文'],
      ['英语', '英语'],
      ['政治', '政治'],
      ['历史', '历史'],
      ['地理', '地理'],
      ['化学', '化学'],
      ['生物', '生物'],
      ['科学', '科学'],
      ['物理', '物理']
    ],
    [
      ['数学', '数学'],
      ['语文', '语文'],
      ['英语', '英语'],
      ['政治', '政治'],
      ['历史', '历史'],
      ['地理', '地理'],
      ['化学', '化学'],
      ['生物', '生物'],
      ['科学', '科学'],
      ['物理', '物理']
    ],
    [
      ['数学', '数学'],
      ['语文', '语文'],
      ['英语', '英语'],
      ['政治', '政治'],
      ['历史', '历史'],
      ['地理', '地理'],
      ['化学', '化学'],
      ['生物', '生物'],
      ['科学', '科学'],
      ['物理', '物理']
    ],
    [
      ['数学', '数学'],
      ['语文', '语文'],
      ['英语', '英语'],
      ['政治', '政治'],
      ['历史', '历史'],
      ['地理', '地理'],
      ['化学', '化学'],
      ['生物', '生物'],
      ['科学', '科学'],
      ['物理', '物理']
    ],
    [
      ['数学', '数学'],
      ['语文', '语文'],
      ['英语', '英语'],
      ['政治', '政治'],
      ['历史', '历史'],
      ['地理', '地理'],
      ['化学', '化学'],
      ['生物', '生物'],
      ['物理', '物理']
    ],
    [
      ['数学', '数学'],
      ['语文', '语文'],
      ['英语', '英语'],
      ['政治', '政治'],
      ['历史', '历史'],
      ['地理', '地理'],
      ['化学', '化学'],
      ['生物', '生物'],
      ['文数', '文数'],
      ['理数', '理数'],
      ['物理', '物理']
    ],
    [
      ['数学', '数学'],
      ['语文', '语文'],
      ['英语', '英语'],
      ['政治', '政治'],
      ['历史', '历史'],
      ['地理', '地理'],
      ['化学', '化学'],
      ['生物', '生物'],
      ['文数', '文数'],
      ['理数', '理数'],
      ['物理', '物理']
    ]
  ]
  
  exports.subjectToLabelData = {
    数理逻辑训练: '奥数',
    语文逻辑训练: '语文',
    新概念英语: '英语'
  }
  
  // exports.gradeToLabel = {}
  