<template>
  <div v-if="show" class="not-network">
    <div class="network-main">
      <div class="network-content">
        <span>网络开小差啦，刷新下试试</span>
        <img src="./img/pic_no_network_big.png">
      </div>
      <div class="network-footer" @click="hide">
        <span>我知道啦</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FailDialog',
  data() {
    return {
      show: false
    }
  },
  methods: {
    postFormFail() {
      this.show = true
    },
    hide() {
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
// @import './../../styles/mixin.scss';

.not-network {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  .network-main {
    position: absolute;
    width: px2rem(550);
    height: px2rem(438);
    left: 50%;
    top: 50%;
    margin-left: px2rem(-275);
    margin-top: px2rem(-219);
    text-align: center;
    background: #ffffff;
    border-radius: 12px;
    .network-content {
      height: px2rem(349);
      span {
        display: inline-block;
        width: 100%;
        line-height: px2rem(42);
        font-size: px2rem(32);
        color: #333333;
        letter-spacing: 0;
        padding-top: px2rem(57);
      }
      img {
        width: px2rem(220);
        height: px2rem(220);
      }
    }
    .network-footer {
      height: px2rem(85);
      border-top: 1px solid #dddddd;
      line-height: px2rem(85);
      span {
        font-size: px2rem(34);
        color: #fb5156;
        letter-spacing: 0;
      }
    }
  }
}
</style>
