export default {
  subjectData: (state: any) => state.subjectData,
  subjectDataV1: (state: any) => state.subjectDataV1,
  subjectGrade: (state: any) => state.subjectGrade,
  subjectGradeV1: (state: any) => state.subjectGradeV1,
  subjectToLabel: (state: any) => state.subjectToLabel,
  newSubjectData: (state: any) => (grade: any) => {
    if (grade && grade.length > 0) {
      let res: any = []
      state.subjectGrade.map((item: any, index: any) => {
        if (grade.includes(item[0])) {
          res.push(state.subjectData[index])
        }
      })
      return res
    } else {
      return state.subjectData
    }
  },
  newSubjectGrade: (state: any) => (grade: any) => {
    if (grade && grade.length > 0) {
      let res = state.subjectGrade.filter((item: any, index: any) => {
        return grade.includes(item[0])
      })
      return res
    } else {
      return state.subjectGrade
    }
  }
  // gradeToLabel: state => state.gradeToLabel
}
