<template>
  <!-- 选择国家 -->
  <modal @input="$emit('input', $event)" :value="value" position="bottom">
    <div class="selectContryWrap">
      <h3 class="contryTitle"><span>选择国家/地区</span></h3>

      <div class="searchWrap">
        <img
          class="searchIcon"
          src="https://static-app-manager.zmlearn.com/dE0EVAU07406.png"
          srcset="
            https://static-app-manager.zmlearn.com/dE0EVAU07406.png 2x,
            https://static-app-manager.zmlearn.com/221c807X1953.png 3x
          "
          alt="搜索按钮"
        />
        <input
          class="search"
          v-model.trim="keywords"
          type="text"
          placeholder="搜索"
          @blur="blur"
        />
      </div>

      <div class="contriesWrap">
        <!-- 热门国家 -->
        <div class="hotWrap" v-show="!keywords">
          <h3 class="typeTitle">热门</h3>
          <ul class="itemWrap">
            <li
              v-for="item in contryInfo.hot"
              :key="item.name"
              @click="select(item.value)"
            >
              {{ item.name }}+{{ item.value }}
            </li>
          </ul>
        </div>

        <!-- 国家列表 -->
        <ul class="contryList" v-show="!keywords">
          <li v-for="item in contryInfo.contry" :key="item.type">
            <h3 class="typeTitle" ref="type">{{ item.type }}</h3>
            <a
              class="contryItem"
              href="javascript:;"
              v-for="element in item.data"
              :key="element.name"
              @click="select(element.value)"
            >
              <span>{{ element.name }}</span>
              <span>+{{ element.value }}</span>
            </a>
          </li>
        </ul>

        <!-- 搜索结果 -->
        <ul class="contryList" v-show="keywords">
          <li
            class="contryItem"
            v-for="item in filterSearch"
            :key="item.type"
            @click="select(item.value)"
          >
            <span>{{ item.name }}</span>
            <span>+{{ item.value }}</span>
          </li>
        </ul>
      </div>

      <!-- 字母 -->
      <ul class="letter">
        <li v-for="(item, index) in letter" :key="item" @click="jump(index)">
          {{ item }}
        </li>
      </ul>
    </div>
  </modal>
</template>
<script>
import api from '@/api'
import modal from '../modal/index.vue';
const contry  = require("./country.json");

export default {
  props: ['value'],
  data() {
    return {
      keywords: '',
      areaCode: 86,
      showCountry: false,
      contryInfo: contry,
      letter: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'R', 'S', 'T', 'W', 'X', 'Y', 'Z']
    }
  },
  computed: {
    filterSearch() {
      if (!this.keywords) return [];

      const result = [];

      this.contryInfo.contry.forEach((item) => {
        item.data.forEach((element) => {
          if (element.name.includes(this.keywords)) {
            result.push(element);
          }
        });
      });

      return result;
    }
  },
  components: {
    modal
  },
  methods: {
    select(areaCode) {
      this.$emit('select', areaCode)
      this.$emit('input', false)
      this.areaCode = areaCode;
      this.keywords = '';
    },
    // 字母定位
    jump(index) {
      if (this.keywords) return;

      const type = this.$refs.type;

      type[index].scrollIntoView();
    },
    blur() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>