<template>
  <form class="lesson-form" ref="lessonForm" :style="formStyle">
    <div class="form-content">
      <h2 v-show="showTitle" v-html="TitleText"></h2>

      <div v-if="type == 'bottom'">
        <div class="form-item">
          <input
            type="text"
            :placeholder="placeholderName"
            class="username"
            :class="{ alert: userNameAlert }"
            v-model="userName"
            @blur="onBlurChange"
          />
        </div>
        <div class="form-item flex-item">
          <select
            class="grade"
            v-model="weakGrade"
            ref="grade"
            @change="handleGradeChange"
          >
            <option
              :value="item[0]"
              v-for="(item, index) in subjectGrade"
              :key="index"
              v-if="typeName === 'young' && index < 7"
            >
             {{ item[1] | global_filter_subject(weakGrade)}}
            </option>
            <option
             :value="item[0]"
              v-for="(item, index) in subjectGrade"
              :key="index"
              v-if="typeName != 'young'"
            >
              {{ item[1] | global_filter_subject(weakGrade)}}
            </option>
          </select>
          <div class="arrow grade-arrow"></div>

          <select class="subject" v-model="weakSubject" ref="subject">
            <option
              :value="item[1]"
              v-for="(item, index) in weakSubjectArr"
              :key="index"
            >
              {{ item[0] | global_filter_subject( weakGrade) }}
            </option>
          </select>
          <div class="arrow subject-arrow"></div>
        </div>
      </div>

      <div v-else class="form-basic-info">
        <div v-show="justMobile !== 'firstStep'" class="form-item flex-item">
          <select
            class="grade"
            v-model="weakGrade"
            ref="grade"
            @change="handleGradeChange"
          >
            <option
              :value="item[0]"
              v-for="(item, index) in subjectGrade"
              :key="index"
              v-if="typeName === 'young' && index < 7"
            >
              {{ item[1] | global_filter_subject( weakGrade)}}
            </option>
            <option
              :value="item[0]"
              v-for="(item, index) in subjectGrade"
              :key="index"
              v-if="typeName != 'young'"
            >
              {{item[1] | global_filter_subject(weakGrade)}}
            </option>
          </select>
          <div class="arrow grade-arrow"></div>

          <select class="subject" v-model="weakSubject" ref="subject">
            <option
              :value="item[1]"
              v-for="(item, index) in weakSubjectArr"
              :key="index"
            >
              {{ item[0] | global_filter_subject(weakGrade) }}
            </option>
          </select>
          <div class="arrow subject-arrow"></div>
        </div>

        <div v-show="justMobile !== 'firstStep'" class="form-item">
          <input
            type="text"
            :placeholder="placeholderName"
            class="username"
            :class="{ alert: userNameAlert }"
            v-model="userName"
            @blur="onBlurChange"
          />
        </div>
      </div>

      <div class="form-item">
        <span class="area-code">+86</span>
        <input
          type="number"
          :placeholder="placeholderTel"
          :class="{ alert: mobileAlert }"
          class="tel"
          v-model="mobile"
          maxlength="11"
          @blur="onBlurChange"
        />
      </div>

      <div
        class="form-item vertify-code"
        v-if="vertifyCodeStatus"
        v-show="justMobile !== 'firstStep'"
      >
        <input
          type="text"
          :placeholder="vertifyCodePlaceholder"
          class="identifyingcode"
          v-model="identifyingCode"
        />
        <span class="get-identify-code" @click.stop="identifySubmitBtn">{{
          codeCountDown
        }}</span>
      </div>
    </div>

    <div class="form-protocol" v-if="type == '' && modelBox !== 'modelBox'">
      <input
        type="checkbox"
        class="form-protocol-checkbox"
        v-model="checkProtocol"
      />
      <div class="form-protocol-text">
        <span @click="checkProtocol = !checkProtocol">我已阅读并同意</span>
        <a
          href="https://www.zhangmen.com/service-agreement.html"
          class="high-light"
          >《用户协议》</a
        >和
        <a
          href="https://www.zhangmen.com/privacy-agreement.html"
          class="high-light"
          >《隐私政策》</a
        >
      </div>
    </div>
    <!-- 详情页 math -->
    <div class="form-protocol" v-if="$route.name === 'coruseDetailMath' || $route.name ==='courseDetailChinese'">
      <input
        type="checkbox"
        class="form-protocol-checkbox"
        v-model="checkProtocol"
      />
      <div class="form-protocol-text">
        <span @click="checkProtocol = !checkProtocol">我已阅读并同意</span>
        <a
          href="https://www.zhangmen.com/service-agreement.html"
          class="high-light"
          >《用户协议》</a
        >和
        <a
          href="https://www.zhangmen.com/privacy-agreement.html"
          class="high-light"
          >《隐私政策》</a
        >
      </div>
    </div>
    <div
      class="form-protocol"
      v-if="
        (type == 'newChannel' || type == 'homePage') && modelBox !== 'modelBox'
      "
    >
      <img
        src="https://web-data.zmlearn.com/image/ut7ZfqyGo5aSiWmLPWCdCr/%E7%BC%96%E7%BB%84%205.png"
        v-if="checkProtocol"
        @click="checkProtocol = !checkProtocol"
        class="checkoutImg"
      />
      <img
        v-else
        src="https://web-data.zmlearn.com/image/7cCwbkGdtrLweuC3eEEHSw/%E6%A4%AD%E5%9C%86%E5%BD%A2.png"
        @click="checkProtocol = !checkProtocol"
        class="checkoutImg"
      />
      <div class="form-protocol-text">
        <span @click="checkProtocol = !checkProtocol">我已阅读并同意</span>
        <a
          href="https://www.zhangmen.com/service-agreement.html"
          class="high-light"
          >《用户协议》</a
        >和
        <a
          href="https://www.zhangmen.com/privacy-agreement.html"
          class="high-light"
          >《隐私政策》</a
        >
      </div>
    </div>

    <div :style="btnStyle" class="zm-btn" @click="handleSubmitBtn()">
      <span>{{ btnText }}</span>
    </div>

    <div v-if="type == ''">
      <div class="info" v-if="showInfo">
        <img src="./img/lock@2x.png" />
        <span>{{ alertMsg }}</span>
      </div>
      <div
        class="info"
        v-else-if="
          activityName === 'augustActivity' ||
          activityName === 'septemberActivity19'
        "
      >
        <p><span>官网承诺您的信息将不被泄露</span></p>
        <p v-show="modelBox === 'modelBox'">
          <span
            >点击即阅读并同意
            <a
              href="https://www.zhangmen.com/service-agreement.html"
              class="high-light"
              >《用户协议》</a
            >和
            <a
              href="https://www.zhangmen.com/privacy-agreement.html"
              class="high-light"
              >《隐私政策》</a
            ></span
          >
        </p>
      </div>
    </div>
    <div v-else-if="type == 'sem'">
      <div class="sem">
        <img
          src="./img/icon_selected.png"
          v-if="checkProtocol"
          @click="checkProtocol = !checkProtocol"
        />
        <img
          v-else
          src="./img/icon_unselected.png"
          @click="checkProtocol = !checkProtocol"
          class="unselected"
        />
        <div class="form-protocol-text">
          <span @click="checkProtocol = !checkProtocol">我已阅读并同意</span>
          <a
            href="https://www.zhangmen.com/service-agreement.html"
            class="high-light"
            >《用户协议》</a
          >和
          <a
            href="https://www.zhangmen.com/privacy-agreement.html"
            class="high-light"
            >《隐私政策》</a
          >
          <span @click="checkProtocol = !checkProtocol"
            >，掌门承诺保护您的隐私</span
          >
        </div>
      </div>
    </div>
    <div v-else-if="type == 'newChannel' || type == 'homePage'">
      <div class="info" v-if="showInfo">
        <p v-if="index_form_showmsg">{{ index_form_showmsg }}</p>
        <img
          style="margin-right: 5px"
          src="https://web-data.zmlearn.com/image/vRuqLVCMvKdxPdhcoQBoCv/%E5%BD%A2%E7%8A%B6.png"
        />
        <span style="color: #575757">{{ alertMsg }}</span>
      </div>
    </div>

    <div
      :class="{ 'mask-box': true, 'model-size': modelBox === 'modelBox' }"
      v-if="showMask"
    >
      <div class="verification-box">
        <img
          src="./img/dialog-close.png"
          @click="hideMask()"
          class="closeIcon"
        />
        <div class="phone-text">
          验证码已发送至{{ mobile | filterPhoneNumber }}
        </div>
        <div class="code-box">
          <input type="number" v-model="codeNumber" class="codeNumber" />
          <div class="send-code" @click.stop="identifySubmitBtn()">
            {{ codeCountDown }}
          </div>
        </div>
        <div class="verification-btn" @click="doRegister()">
          最后一步，立即领取
        </div>
        <div class="bottom-title">领取后规划师会与您电话沟通课程详情</div>
      </div>
    </div>
    <fail-dialog ref="failDialog"></fail-dialog>
    <bind-form ref="bindForm" :mobile="mobile" @closeDialog="closeDialog" />
  </form>
</template>

<script>
import api from '@/api.js';
import {
  request,
  queryObj,
  isEmptyObject,
  weiboJsUp
} from "@/utils/util";
import urlConfig from "@/config";
import {
  littleGenius
} from "@/utils/redirect";
import failDialog from "@/components/fail-dialog/fail-dialog";
import Toast from "@/base/toast/toast";
import BindForm from "./../bindForm/index";
import BindFormComponent from "./../bindForm/create";
// import { dictionaryBaseSubject, dictionaryMdashboard, dictionaryMiddleGradeNew } from '@/libs/dic';
// import Geetest from "@/utils/geetest"; // 极验代码
import {GeetestCode} from '@/components/Geetest/index.js';


import {
  mapState
} from "vuex";

export default {
  name: "lesson-form",
  components: {
    failDialog,
    BindForm,
  },
  props: {
    index_form_showmsg: {
      type: String,
      default: "",
    },
    homepageWeekSubject: {
      type: String,
      default: "语文思维训练",
    },
    hasBottom: {
      type: Boolean,
      default: true,
    },
    placeholderName: {
      type: String,
      default: "请输入您孩子的姓名",
    },
    placeholderTel: {
      type: String,
      default: "请输入您的手机号码",
    },
    cssType: {
      type: String,
      default: "default",
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    TitleText: {
      type: String,
      default: '免费领取试听服务',
    },
    btnStyle: {
      type: Object,
    },
    btnText: {
      type: String,
      default: "0元领取",
    },
    btnType: {
      type: String,
      default: "",
    },
    btnTextName: {
      type: String,
      default: "",
    },
    wxLinkUrl: {
      type: String,
      default: "",
    },
    formStyle: {
      type: Object,
      default () {
        return {};
      },
    },
    showInfo: {
      type: Boolean,
      default: true,
    },
    alertMsg: {
      type: String,
      default: "为了您的权益，您的隐私将被严格保密",
    },
    type: {
      type: String,
      default: "",
    },
    tabIndex: {
      type: String,
      default: "",
    },
    comeFrom: {
      type: String,
      default: null,
    },
    needCodeStatus: {
      type: Boolean,
      default: false,
    },
    vertifyCodePlaceholder: {
      type: String,
      default: "验证码",
    },
    activityName: {
      type: String,
      default: "",
    },
    modelBox: {
      type: String,
      default: "",
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    justMobile: {
      type: String,
      default: "",
    },
    trigger_site: {
      type: String,
      default: "1",
    },
  },
  data () {
    return {
      getCode: "获取验证码",
      codeCountDownNum: 60,
      codeCountSubmitNum: 3,
      vertifyCodeStatus: false,
      vertifyOnOff: true,
      submitOnOff: true,
      userName: "",
      weakGrade: "PRIMARY_SCHOOL_3",
      weakSubject: "CHINESE_LOGIC",
      gradeData: [],
      weakSubjectArr: [],
      mobile: "",
      identifyingCode: "",
      channelProgram: "",
      userNameAlert: false,
      mobileAlert: false,
      success: false,
      typeName: this.$route.query.type,
      checkProtocol: false,
      showProtocol: false,
      isWX: false,
      open_id: "",
      showMask: false,
      codeNumber: "",
      changMobile: false,
      sellerId: this.$route.query.sellerId || this.$route.query.sellerid,
      // subjectGrade: [],
      areaCode: '86',
      allSubjectArr: []
    };
  },
  filters: {
    filterPhoneNumber (number) {
      if (number.length == 11) {
        let pat = /(\d{3})\d*(\d{4})/;
        return (number = number.replace(pat, "$1****$2"));
      }
    },
  },
  watch: {
    subjectData: function () {
      // 异步获取到subjectData中的数据的话就执行这里
      // 默认显示高一
      this.$refs.grade.value = "小三";
      this.$nextTick(() => {
        this.handleGradeChange();
        this.weakSubject = this.homepageWeekSubject === '数理逻辑训练' ? 'MATH_LOGIC' : 'CHINESE_LOGIC'
        // this.initWeakSubject();
        this.autoCompleteForm();
        if (this.$route.name === "coruseDetailMath") {
          this.weakSubject = "MATH_LOGIC";
        }
      });
    },
    // subjectArr (newVal) {
    //   const result = newVal.find(({ value }) => value === 'CHINESE_LOGIC') || newVal[0];
    //   this.weakSubject = result && result.value;
    // },
    userName () {
      this.userNameAlert = false;
    },
    mobile () {
      this.mobileAlert = false;
      this.changMobile = true;
    },
  },
  mounted () {
    console.log(this.subjectGrade)
    if (this.$route.params.path === "jrtt_lr1") {
      this.addJrttCount("104582446236");
    } else if (this.$route.params.path === "jrtt_yk2") {
      this.addJrttCount("1635490300127244");
    } else {
      this.addJrttCount("111643154466");
    }
    this.open_id = this.urlQueryObj.open_id;
    this.isWX =
      navigator.userAgent.toLowerCase().match(/MicroMessenger/i) ==
      "micromessenger";
    // subjectData中的数据已经获取到的话就执行这里
    // this.isWX = true;
    if (this.subjectData.length) {
      console.log("===");
      // 默认显示幼儿园中班
      // this.$refs.grade.value = "小三";
      this.handleGradeChange();
      // this.initWeakSubject();
      this.autoCompleteForm();
    }
    this.changeStatusByPlatform();
  },
  computed: {
    ...mapState([
      "subjectData",
      "subjectGrade",
      "subjectToLabel",
      "urlQueryObj",
    ]),
    codeCountDown () {
      if (this.codeCountDownNum == 60 || this.codeCountDownNum <= 0) {
        return this.getCode;
      } else {
        return this.codeCountDownNum + "s";
      }
    },
    submitCountDown () {
      return this.submitOnOff;
    },
    // subjectArr () {
    //   const result = dictionaryMdashboard.find(({ value }) => value === this.weakGrade);
    //   return result && result.children || dictionaryBaseSubject;
    // }
  },
  created () {
    if (process.browser) {
      this.$store.dispatch("getSubjectData");
    }
    // dictionaryMdashboard.forEach(item => {
    //   this.allSubjectArr.push({
    //     'label': item.label,
    //     'value': item.value
    //   });
    // })
    // Object.assign(this.subjectGrade, this.allSubjectArr)
    // this.subjectGrade = dictionaryMdashboard;
    if (
      this.needCodeStatus ||
      this.justMobile == "firstStep" ||
      this.justMobile == "secondStep"
    ) {
      this.vertifyCodeStatus = true;
    }
  },
  methods: {
    initWeakSubject () {
      const defaultSubject = this.homepageWeekSubject;
      var $this = this.$refs.grade;
      this.weakSubjectArr = this.subjectData[$this.selectedIndex];
      var tmpArr1 = [];
      var tmpArr2 = [];
      this.weakSubjectArr.map((item, index) => {
        tmpArr1.push(item[0]);
        tmpArr2.push(item[1]);
      });
      this.weakSubject = defaultSubject;
      if (
        tmpArr1.includes(this.weakSubject) ||
        tmpArr2.includes(this.weakSubject)
      ) {
        if (this.subjectToLabel[this.weakSubject]) {
          this.weakSubject = this.subjectToLabel[this.weakSubject];
        }
      } else {
        this.weakSubject = this.subjectData[$this.selectedIndex][0][1];
      }
      if (this.$route.name === "xxlyw" || this.$route.name === "xxlyw2") {
        this.weakSubject = "语文思维训练";
      }
      if (this.$route.name === "coruseDetailMath") {
        this.weakSubject = "数理逻辑训练";
      }
    },
    // alert(this.props.successType);
    // 带URL参数自动填入表单
    autoCompleteForm () {
      const query = queryObj();
      if (!isEmptyObject(query)) {
        for (let key in query) {
          if (query["userName"]) {
            this.userName = decodeURIComponent(query["userName"]);
          }
          if (query["grade"]) {
            this.weakGrade = decodeURIComponent(query["grade"]);
          }

          this.$refs.grade.value = this.weakGrade;
          if (query["weakSubject"]) {
            this.weakSubject = decodeURIComponent(query["weakSubject"]);
          }
          if (query["mobile"])
            this.mobile = decodeURIComponent(query["mobile"]);
          if (query["grade"]) this.grade = decodeURIComponent(query["grade"]);
          if (query["channelProgram"]) {
            this.channelProgram = decodeURIComponent(query["channelProgram"]);
          }
        }

        // 对应的年级，自动切换科目

        // var $this = this.$refs.grade;

        // if ($this.selectedIndex >= 0) {
        //   switch ($this.selectedIndex) {
        //     case 0:
        //       this.weakSubjectArr = this.subjectData[0];
        //       this.weakSubject = this.subjectData[0][0][0];
        //       $this.options[$this.selectedIndex].selected = true;
        //       break;
        //     default:
        //       console.log($this.selectedIndex);
        //       this.weakSubjectArr = this.subjectData[$this.selectedIndex];
        //       var tmpArr1 = [];
        //       var tmpArr2 = [];
        //       this.weakSubjectArr.map((item, index) => {
        //         tmpArr1.push(item[0]);
        //         tmpArr2.push(item[1]);
        //       });
        //       if (
        //         tmpArr1.includes(this.weakSubject) ||
        //         tmpArr2.includes(this.weakSubject)
        //       ) {
        //         if (this.subjectToLabel[this.weakSubject]) {
        //           this.weakSubject = this.subjectToLabel[this.weakSubject];
        //         }
        //       } else {
        //         this.weakSubject = this.subjectData[$this.selectedIndex][0][0];
        //       }
        //       break;
        //   }
        // } else {
        //   if (JSON.stringify(this.subjectGrade).indexOf(this.weakGrade) != -1) { } else {
        //     this.weakGrade = "幼儿园中班";
        //   }
        //   this.weakSubjectArr = this.subjectData[0];
        //   this.weakSubject = this.subjectData[0][0][0];
        // }
      }
    },
    onBlurChange () {
      document.activeElement.scrollIntoViewIfNeeded(false);
      if (this.showDialog) window.scroll(0, 0);
    },
    changeStatusByPlatform () {
      const urlParams = queryObj() || {};
      if (request("cooperatorStore") != "") {
        this.vertifyCodeStatus = true;
      }
      api.getPlatformOnOffStatus(urlParams)
        .then((res) => {
          if (res.data.code === 0) {
            if (
              request("cooperatorStore") == "" &&
              !this.needCodeStatus &&
              this.justMobile === "" &&
              res.data.data == false
            ) {
              this.vertifyCodeStatus = false;
            } else {
              this.vertifyCodeStatus = true;
            }
          } else {
            // console.log(res.data.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendMonitor () {
      if (this.$route.params.path === "jrtt") {
        meteor.track("form", {
          convert_id: 1635379215076356
        });
      }
      if (this.$route.params.path === "jrtt_lr1") {
        meteor.track("form", {
          convert_id: 1635920344907783
        });
      }
      if (this.$route.params.path === "jrtt_yk2") {
        meteor.track("form", {
          convert_id: 1635940049301512
        });
      }
      if (["/lp/c0/wyxw", "/lp/d0/wyxw"].includes(this.$route.path)) {
        _nfe.report({
          convert_id: "2832",
          convert_method: "1",
          convert_type: "4",
        });
      }
      // meteor.track('form', { convert_id: 1626600581369860 })
      if (!queryObj().ABTestUrl) return;
      ZMSDK.onEvent(
        "ABTest报名成功",
        `${window.location.origin}${window.location.pathname}`, {
        mobile: this.mobile,
        ABTestUrl: decodeURIComponent(queryObj().ABTestUrl),
      }
      );
    },
    // 极验
    async initGeetest () {
      if (!this.mobile) { return; }
      const captchaObj = await Geetest.request({
        product: "bind",
        width: "320px",
      });
      this.captchaObj = captchaObj;
      captchaObj
        .onReady(() => {
          setTimeout(() => {
            this.captchaObj.verify();
          }, 100);
        })
        .onSuccess(async () => {
          try {
            const result = captchaObj.getValidate();
            if (!result) {
              return Toast("请完成验证");
            }
            const verifyParams = {
              areaCode: this.areaCode,
              mobile: this.mobile,
              geetestChallenge: result.geetest_challenge,
              geetestValidate: result.geetest_validate,
              geetestSeccode: result.geetest_seccode,
            };
            api
              .KidGetVerifyCode(verifyParams)
              .then((res) => {
                if (res.data.code == 0) {
                  this.changMobile = false;
                  Toast("验证码发送成功");
                  if (this.justMobile === "firstStep") {
                    this.$emit("changeForm");
                  }
                  const timer = setInterval(() => {
                    this.codeCountDownNum--;
                    if (this.codeCountDownNum <= 0 || this.changMobile) {
                      clearInterval(timer);
                      this.vertifyOnOff = true;
                      this.codeCountDownNum = 60;
                      this.codeNumber = "";
                    }
                  }, 1000);
                } else {
                  this.vertifyOnOff = true;
                  Toast(res.data.message || "验证码发送失败，请稍后再试");
                }
              })
              .catch((err) => {
                Toast(err);
              });
            this.vertifyOnOff = false;
            // await this.doRegister('', data);
          } catch (e) {
            Toast(e.message);
            this.captchaObj = captchaObj;
          } finally {
            captchaObj.destroy();
          }
        })
        .onError(() => {
          Toast({
            message: "您刷新太多次了, 故关闭验证弹框, 请从新验证",
            duration: 2000,
          });
          captchaObj.destroy();
          this.captchaObj = captchaObj;
        })
        .onClose(() => {
          captchaObj.destroy();
          this.captchaObj = captchaObj;
        });
    },
    async doRegister (isNew) {
      let self = this;
      if (self.submitOnOff) {
        const timer2 = setInterval(() => {
          this.codeCountSubmitNum--;
          if (this.codeCountSubmitNum <= 0) {
            clearInterval(timer2);
            this.codeCountSubmitNum = 3;
            self.submitOnOff = true;
          } else {
            this.submitOnOff = false;
          }
          return this.submitOnOff;
        }, 1000);
        const parmas1 = {
          name: self.userName,
          grade: self.weakGrade,
          weakSubject: self.weakSubject,
          mobile: self.mobile,
          checkCodeFlag: true,
          credentials: self.vertifyCodeStatus ? self.identifyingCode : "",
          completed: "1",
          channelProgram: self.channelProgram,
          channel_code: self.$route.query.channel_code || "",
          channel_keyword: self.$route.query.channel_keyword || "",
          appointmentValue: 6
        };
        const parmas2 = {
          name: self.userName,
          stuGradeCode: self.weakGrade,
          weakSubjectCode: self.weakSubject.value || self.weakSubject, // 跟现在接口字段保持一致
          mobile: self.mobile,
          completed: "0",
          channelProgram: self.channelProgram,
          code: self.$route.query.channel_code || 25963,
          key: self.$route.query.channel_keyword || '49523e9b41b69e62',
          credentials: this.codeNumber,
          appointmentValue: 6
          // geetestChallenge: geetestData.geetestChallenge,
          // geetestValidate: geetestData.geetestValidate,
          // geetestSeccode: geetestData.geetestSeccode
        };
        console.log(parmas2);

        var parmas3 = {
          referMobile: self.$route.query.referMobile || "",
          stuGrade: self.weakGrade || "",
          stuMobile: self.mobile || "",
          stuName: self.userName || "",
          weakSubject: self.weakSubject || "",
        };
        const parmas4 = {
          name: self.userName,
          grade: self.weakGrade,
          weakSubject: self.weakSubject,
          mobile: self.mobile,
          completed: "0",
          channelProgram: self.channelProgram,
          channel_code: self.$route.query.channel_code || "",
          channel_keyword: self.$route.query.channel_keyword || "",
          regTypeEnum: 'REG_REGISTERED_UNASSIGNED',
          extInfo: this.sellerId ? { 'sellerId': this.sellerId } : null
        };
        if (this.vertifyCodeStatus) {
          this.parmas = parmas1;
        } else {
          if (this.$route.name == 'zmkid-activity2') {
            this.parmas = parmas4;
          } else {
            this.parmas = parmas2;
          }
        }
        const urlParams = queryObj();

        // for (let key in urlParams) {
        //   this.parmas[key] = urlParams[key]
        // }
        if (this.parmas.grade == "初四") {
          this.parmas.grade = "初三";
        }

        if (this.$route.name == "youke-home") {
          this.parmas.comeFrom = "优课m站";
          if (
            self.$route.query.province != null ||
            self.$route.query.province != ""
          ) {
            this.parmas.province = self.$route.query.province;
          }

          if (self.$route.query.city != null || self.$route.query.city != "") {
            this.parmas.city = self.$route.query.city;
          }

          if (self.$route.query.area != null || self.$route.query.area != "") {
            this.parmas.area = self.$route.query.area;
          }
          this.parmas.channelProgram = "m站";
        }
        if (this.comeFrom) {
          this.parmas["comeFrom"] = this.comeFrom;
        }
        var page_id = window.location.href;
        ZMSDK.onEvent("点击0元试听", "少儿m站", {
          bu: "2",
          channel_id: self.$route.query.channel_code,
          refer_page_id: page_id,
          page_id: page_id,
        });

        const isFollow = self.urlQueryObj.isFollow; // 在微信里打开，用户是否绑定
        const open_id = self.urlQueryObj.open_id; // open_id
        const isBind = self.urlQueryObj.isBind; // 是否关注
        if (self.$route.query.channel_code === '27340' && self.$route.query.channel_keyword === '36dd94337a692a89'){
          let {stuGradeCode:gradeCode,weakSubjectCode:subjectCode,mobile,key,code,credentials:smsCode,comeFrom,name} = self.parmas
          let params = {
            id: "28", // test 152  prod 28
            gradeCode,
            subjectCode,
            segmentCode: "execute",
            mobile,
            name,
            key,
            code,
            smsCode,
            comeFrom
          }
          const executeRes = await api.execute(params)
          if(executeRes.data.data.wxInfo){
            self.$router.push({
                name: 'addWx',
                query: executeRes.data.data.wxInfo
              })
            return
          }else{
            this.judgeJumpPage();
            return
          }
        }
        if (self.$route.query.referMobile) {
          api.appointmentForMpReferral(parmas3).then((res) => {
            if (res.data.code === 0) {
              try {
                this.sendMonitor();
                this.baiduAndToday();
                this.submitSuccessMD(); // 表单预约报名成功埋点
                // java接口改造 少儿各渠道广告回传接口调用下线
                // this.doAdvertCb(isNew)
                // this.handelBaiduApi() // 对接百度api
                this.ucTrackCb(isNew); // uc信息api
              } catch (error) {
                console.log(error);
              }
              if (res.data.data.data) {
                if (!this.vertifyCodeStatus) {
                  // 页面无验证码
                  // 判断是否标记
                  this.judgeHasTag();
                } else {
                  // 页面有验证码
                  this.judgeJumpPage();
                }
              } else {
                res.data.msg ?
                  Toast(res.data.data.msg || "预约失败") :
                  this.$refs.failDialog.postFormFail();
              }
            } else {
              res.data.msg ?
                Toast(res.data.data.msg || "预约失败") :
                this.$refs.failDialog.postFormFail();
            }
          });
        } else {
          const weakGradeList = [
            "幼儿园小班",
            "幼儿园中班",
            "幼儿园大班",
            "小一",
            "小二",
            "小三",
          ];
          if (
            self.$route.query.useridv2 &&
            weakGradeList.includes(self.weakGrade)
          ) {
            api
              .getSelectionClassTag({
                stuId: self.$route.query.useridv2 || ""
              })
              .catch((e) => console.log(e));
          }
          api
            .KidAppointmentWithCode(this.parmas)
            .then((res) => {
              if (location.pathname === '/') {
                gtag('event', 'conversion', { 'send_to': 'AW-465936768/EniSCJuwvuwBEIDDlt4B' });
              }
              console.log(res);
              if (res.data.code == 0 || Number(res.data.code) == 0) {
                this.sendMonitor();
                this.baiduAndToday();
                this.submitSuccessMD(); // 表单预约报名成功埋点
                this.vertifyCodeMD();

                // java接口改造 少儿各渠道广告回传接口调用下线
                // this.doAdvertCb(isNew)
                // this.handelBaiduApi() // 对接百度api
                this.ucTrackCb(isNew); // uc信息api
                if (!this.vertifyCodeStatus) {
                  // 页面无验证码
                  // 判断是否标记
                  // 跳转到成功落地页
                  // this.redirect()
                  this.judgeJumpPage();
                  // this.judgeHasTag();
                } else {
                  // 页面有验证码
                  this.judgeJumpPage();
                }
              } else {
                res.data.message ?
                  Toast(res.data.message) :
                  this.$refs.failDialog.postFormFail();
              }
            })
            .catch((err) => {
              this.$refs.failDialog.postFormFail();
            });
        }
        this.submitOnOff = false;
      }
    },
    vertifyCode (number) {
      if (number) {
        let reg = new RegExp(/^\d{4}|\d{6}$/);
        if (reg.test(number)) {
          return true;
        } else {
          Toast("请输入正确的验证码");
          return false;
        }
      } else {
        Toast("请输入验证码");
        return false;
      }
    },
    checkBtn () {
      if (this.$route.path === "/" && this.trigger_site) {
        ZM_JSSDK &&
          ZM_JSSDK.sendEvent({
            eventId: "click_kid_m_zhybljlq",
            eventParam: {
              name: "点击|少儿|官网m端|最后一步立即领取",
              code_site: this.trigger_site,
            },
          });
      }

      if (this.$route.path === "/august-activity-2019") {
        let text = "";
        if (this.modelBox === "modelBox") {
          switch (this.tabIndex) {
            case "1":
              text = "少儿优惠";
              break;
            case "2":
              text = "少儿视频播放";
              break;
            case "3":
              text = "少儿课程";
              break;
            case "4":
              text = "少儿师资";
              break;
            case "5":
              text = "少儿底部导航";
              break;
          }
          _hmt.push(["_trackEvent", `验证手机号`, text, "掌门少儿8月份活动"]);
        } else {
          let text = "";
          _hmt.push([
            "_trackEvent",
            "验证手机号",
            "少儿表单",
            "掌门少儿8月份活动",
          ]);
        }
      }

      if (!this.vertifyCode(this.codeNumber)) return;
      // 校验验证码
      const validateParams = {
        mobile: this.mobile,
        verifycode: this.codeNumber,
        verified: 1,
      };
      api
        .validateNew(validateParams)
        .then((res) => {
          if (res.data.code == 0) {
            // 验证手机号（弹窗）埋点
            this.vertifyCodeMD();

            // 验证码无误跳转页面
            this.judgeJumpPage();
          } else {
            Toast(res.data.message);
          }
        })
        .catch((err) => {
          Toast(err);
        });
    },
    hideMask () {
      this.showMask = false;
    },
    // 预约成功后 跳转至成功落地页
    redirect (data) {
      const is86AreaCode = this.areaCode === '86'
      const params = {
        mobile: is86AreaCode ? this.mobile : `${this.areaCode}-${this.mobile}`,
        flatfrom: this.$route.name || '',
        grade: this.weakGrade,
        channel_code: this.channel_code,
        channel_keyword: this.channel_keyword,
        showPeilian: false
      }
      // 重复预约跳微信绑定成功页
      this.$router.push({
        name: is86AreaCode ? 'appointment-success-kid' : 'appointment-success-kid-not86',
        params
      })
    },
    judgeJumpPage () {
      // 现在匹配value值 而不是匹配label
      let self = this;
      const isFollow = self.urlQueryObj.isFollow; // 在微信里打开，用户是否绑定
      const open_id = self.urlQueryObj.open_id; // open_id
      const isBind = self.urlQueryObj.isBind; // 是否关注
      if (self.isWX && isFollow == "1") {
        if (self.isWX && isFollow == "1" && isBind == "0") {
          // 在微信端打开，已经关注 ，有open_id 已经绑定
          // this.$refs.bindForm.show();
          const _ = this;
          BindFormComponent({
            mobile: _.mobile,
            open_id: open_id,
            closeDialog: (success) => {
              _.closeDialog(success);
            },
          });
        } else {
          // 新的报名成功页面
          this.closeDialog(true);
        }
      } else {
        if (/PREPARATORY_KINDERGAREN/.test(self.weakGrade)) {
          this.getFlatfrom(self.mobile, 5);
          return;
        }
        if (/(NORTH_AMERICAN_TEACHERS)|(ACCOMPLISHMENT)/.test(self.weakSubject)) {
          this.getFlatfrom(self.mobile, 5);
          return;
        }
        if (/(PIANO_PARTNER)|(VIOLIN)/.test(self.weakSubject)) {
          this.getFlatfrom(self.mobile, 3);
          return;
        }
        if (!this.goKid()) {
          this.getFlatfrom(this.mobile, 1);
        }
      }
    },
    identifySubmitBtn () {
      // if (!/^\d+$/.test(this.mobile)) {
      //   return Toast("请输入手机号");
      // }
      // if (!/^\d{11}$/.test(this.mobile)) {
      //   return Toast("请填写正确手机号");
      // }
      if (this.vertifyOnOff) {
        // this.initGeetest()
        GeetestCode('', this.mobile, this)
      }
    },
    judgeHasTag () {
      if (!/^\d+$/.test(this.mobile)) {
        return Toast("请输入手机号");
      }
      const verifyParams = {
        mobile: this.mobile,
      };
      api
        .hasTag(verifyParams)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.data) {
              // true则为标记过直接调转页面
              this.judgeJumpPage();
            } else {
              // false则为未标记过显示验证码弹窗
              this.showMask = true;
              this.identifySubmitBtn(); // 显示验证码弹窗并自动发送验证码
            }
          } else {
            Toast(res.data.msg[0]);
          }
        })
        .catch((err) => {
          Toast(err);
        });
    },
    // 报名弹窗验证手机号埋点
    vertifyCodeMD () {
      if (this.$route.path === "/zmkid-activity") {
        ZM_JSSDK &&
          ZM_JSSDK.sendEvent({
            eventId: "6popup_code",
            eventParam: {
              name: "弹窗点击验证码",
              order_site: "首屏表单",
            },
          });
      }
      if (this.$route.path === "/zmkid-activity618") {
        ZM_JSSDK &&
          ZM_JSSDK.sendEvent({
            eventId: "popup618_code",
            eventParam: {
              name: "弹窗点击验证码",
              order_site: "首屏表单",
            },
          });
      }
      if (this.$route.path === "/august-activity-2019") {
        // 跳转埋点
        let text = "";
        if (this.modelBox === "modelBox") {
          switch (this.tabIndex) {
            case "1":
              text = "少儿优惠";
              break;
            case "2":
              text = "少儿视频播放";
              break;
            case "3":
              text = "少儿课程";
              break;
            case "4":
              text = "少儿师资";
              break;
            case "5":
              text = "少儿底部导航";
              break;
          }
          _hmt.push(["_trackEvent", `预约成功`, text, "掌门少儿8月份活动"]);
        } else {
          let text = "";
          _hmt.push([
            "_trackEvent",
            "预约成功",
            "少儿表单",
            "掌门少儿8月份活动",
          ]);
        }
      } else if (this.btnType && this.type == "newChannel") {
        switch (this.btnType) {
          case "1免费领课":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              "少儿表单",
              this.$route.name,
            ]);
            break;
          case "2获取0元试听":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              "少儿课程1",
              this.$route.name,
            ]);
            break;
          case "2获取课程价格":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              "少儿课程2",
              this.$route.name,
            ]);
            break;
          case "3获取0元试听":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              "少儿特色1",
              this.$route.name,
            ]);
            break;
          case "3获取课程价格":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              "少儿特色2",
              this.$route.name,
            ]);
            break;
          case "4获取0元试听":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              "少儿师资1",
              this.$route.name,
            ]);
            break;
          case "4获取课程价格":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              "少儿师资2",
              this.$route.name,
            ]);
            break;
          case "50元领课":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              "少儿底部导航",
              this.$route.name,
            ]);
            break;
          case "60元领课":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              "少儿侧边栏",
              this.$route.name,
            ]);
            break;
        }
      } else if (this.btnType && this.$route.name == "septemberActivity19") {
        switch (this.btnType) {
          case "少儿表单":
            _hmt.push([
              "_trackEvent",
              " 验证手机号",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "囤课理由1":
            _hmt.push([
              "_trackEvent",
              " 验证手机号",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "囤课理由2":
            _hmt.push([
              "_trackEvent",
              " 验证手机号",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "囤课理由3":
            _hmt.push([
              "_trackEvent",
              " 验证手机号",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "少儿特色":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "少儿课程":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "少儿师资":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "底部banner":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "少儿底部导航":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "少儿侧边栏":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              this.btnType,
              this.$route.name,
            ]);
            break;
        }
      } else if (this.btnType && this.$route.name == "home") {
        ZM_JSSDK &&
          ZM_JSSDK.sendEvent({
            eventId: "enter_kid_m_success",
            eventParam: {
              name: "进入|少儿|官网m端|领取成功",
              phone_num: this.mobile
            },
          });
        switch (this.btnType) {
          case "首页少儿表单":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "学习兴趣":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "语文思维课":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "数理逻辑课":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "预约好老师":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "8步打造学习闭环":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "4大承诺":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "少儿底部导航":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "少儿侧边栏":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "少儿官网手机端首页banner":
            _hmt.push([
              "_trackEvent",
              "验证手机号",
              this.btnType,
              this.$route.name,
            ]);
            break;
        }
      }
    },
    // 报名成功埋点
    submitSuccessMD () {
      if (this.btnType && this.type == "newChannel") {
        switch (this.btnType) {
          case "1免费领课":
            _hmt.push([
              "_trackEvent",
              "预约成功",
              "少儿表单",
              this.$route.name,
            ]);
            break;
          case "2获取0元试听":
            _hmt.push([
              "_trackEvent",
              "预约成功",
              "少儿课程",
              this.$route.name,
            ]);
            break;
          case "2获取课程价格":
            _hmt.push([
              "_trackEvent",
              "免费获取价格",
              "少儿课程",
              this.$route.name,
            ]);
            break;
          case "3获取0元试听":
            _hmt.push([
              "_trackEvent",
              "预约成功",
              "少儿特色",
              this.$route.name,
            ]);
            break;
          case "3获取课程价格":
            _hmt.push([
              "_trackEvent",
              "免费获取价格",
              "少儿特色",
              this.$route.name,
            ]);
            break;
          case "4获取0元试听":
            _hmt.push([
              "_trackEvent",
              "预约成功",
              "少儿师资",
              this.$route.name,
            ]);
            break;
          case "4获取课程价格":
            _hmt.push([
              "_trackEvent",
              "免费获取价格",
              "少儿师资",
              this.$route.name,
            ]);
            break;
          case "50元领课":
            _hmt.push([
              "_trackEvent",
              "预约成功",
              "少儿底部导航",
              this.$route.name,
            ]);
            break;
          case "60元领课":
            _hmt.push([
              "_trackEvent",
              "预约成功",
              "少儿侧边栏",
              this.$route.name,
            ]);
            break;
        }
      } else if (this.btnType && this.$route.name == "septemberActivity19") {
        switch (this.btnType) {
          case "少儿表单":
            _hmt.push([
              "_trackEvent",
              " 预约成功",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "囤课理由1":
            _hmt.push([
              "_trackEvent",
              " 预约成功",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "囤课理由2":
            _hmt.push([
              "_trackEvent",
              " 预约成功",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "囤课理由3":
            _hmt.push([
              "_trackEvent",
              " 预约成功",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "少儿特色":
            _hmt.push([
              "_trackEvent",
              "预约成功",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "少儿课程":
            _hmt.push([
              "_trackEvent",
              "预约成功",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "少儿师资":
            _hmt.push([
              "_trackEvent",
              "预约成功",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "底部banner":
            _hmt.push([
              "_trackEvent",
              "预约成功",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "少儿底部导航":
            _hmt.push([
              "_trackEvent",
              "预约成功",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "少儿侧边栏":
            _hmt.push([
              "_trackEvent",
              "预约成功",
              this.btnType,
              this.$route.name,
            ]);
            break;
        }
      } else if (this.btnType && this.$route.name == "home") {
        ZM_JSSDK &&
          ZM_JSSDK.sendEvent({
            eventId: "enter_kid_m_success",
            eventParam: {
              name: "进入|少儿|官网m端|领取成功",
              phone_num: this.mobile
            },
          });
        switch (this.btnType) {
          case "首页少儿表单":
            _hmt.push([
              "_trackEvent",
              "免费领课",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "学习兴趣":
            _hmt.push([
              "_trackEvent",
              "免费领课",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "语文思维课":
            _hmt.push([
              "_trackEvent",
              "免费领课",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "数理逻辑课":
            _hmt.push([
              "_trackEvent",
              "免费领课",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "预约好老师":
            _hmt.push([
              "_trackEvent",
              "免费领课",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "8步打造学习闭环":
            _hmt.push([
              "_trackEvent",
              "免费领课",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "4大承诺":
            _hmt.push([
              "_trackEvent",
              "免费领课",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "少儿底部导航":
            _hmt.push([
              "_trackEvent",
              "免费领课",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "少儿侧边栏":
            _hmt.push([
              "_trackEvent",
              "免费领课",
              this.btnType,
              this.$route.name,
            ]);
            break;
          case "少儿官网手机端首页banner":
            _hmt.push([
              "_trackEvent",
              "免费领课",
              this.btnType,
              this.$route.name,
            ]);
            break;
        }
      }
    },
    closeDialog (isBindSuccess) {
      if (isBindSuccess) {
        this.$store.dispatch("bindSuccess");
      }
      this.getFlatfrom(self.mobile, 4);
    },
    async getFlatfrom (mobile, type) {
      let showPeilian = false;
      let goPath = "";
      let currentPath = this.$route.name;
      let flatfromMap = [{
        path: "home",
        flatfrom: "少儿M站"
      },
      {
        path: "coruseDetailMath",
        flatfrom: "数理逻辑-M站"
      },
      {
        path: "courseDetailChinese",
        flatfrom: "语文思维-M站"
      },
      {
        path: "crouseDetailStudy",
        flatfrom: "学习力-M站"
      },
      {
        path: "crouseDetailEnglisg",
        flatfrom: "DreamenKid-English-M站"
      },
      {
        path: "fwhWenben",
        flatfrom: "服务号文本消息"
      },
      {
        path: "juneActivity19",
        flatfrom: "少儿M站6月活动"
      },
      ];
      let params = {
        mobile: this.mobile,
        showPeilian: showPeilian,
        mark_id: this.$route.query.mark_id ? this.$route.query.mark_id : "",
        origin_url: this.$route.query.origin_url ?
          this.$route.query.origin_url : "",
        weibo_path: this.$route.path,
      };
      if (showPeilian) {
        params = Object.assign(params, {
          channel_code: this.$route.query.channel_code,
          channel_keyword: this.$route.query.channel_keyword,
          grade: this.weakGrade,
        });
      }
      switch (type) {
        case 1:
          goPath = "appointment-success5";
          break;
        case 2:
          goPath = "appointment-success-kid";
          break;
        case 3:
          goPath = "appointment-success-peilian";
          break;
        case 4:
          goPath = "appointment-success-wxbind";
          break;
        case 5:
          goPath = "appointment-success-little-genius";
          break;
      }
      for (let i = 0; i < flatfromMap.length; i++) {
        if (currentPath == flatfromMap[i].path) {
          params.flatfrom = flatfromMap[i].flatfrom;
          break;
        }
      }
      if (!params.flatfrom) params.flatfrom = window.location.pathname;

      if (this.$route.path === "/august-activity-2019") {
        // 跳转至成功页埋点
        let text = "";
        if (this.modelBox === "modelBox") {
          switch (this.tabIndex) {
            case "1":
              text = "少儿优惠";
              break;
            case "2":
              text = "少儿视频播放";
              break;
            case "3":
              text = "少儿课程";
              break;
            case "4":
              text = "少儿师资";
              break;
            case "5":
              text = "少儿底部导航";
              break;
          }
          _hmt.push(["_trackEvent", `预约成功`, text, "掌门少儿8月份活动"]);
        } else {
          let text = "";
          _hmt.push([
            "_trackEvent",
            "预约成功",
            "少儿表单",
            "掌门少儿8月份活动",
          ]);
        }
      }
      if (goPath == "appointment-success5") {
        const mobile = this.mobile ? this.mobile : "";
        const flatfrom = this.$route.name ? this.$route.name : "";
        const otherSiteFrom = "m-kid";
        const grade = this.weakGrade ? this.weakGrade : "";
        const channel_code = this.$route.query.channel_code ?
          this.$route.query.channel_code :
          "";
        const channel_keyword = this.$route.query.channel_keyword ?
          this.$route.query.channel_keyword :
          "";
        window.location.href = `${urlConfig.mSuccessPageURL}?mobile=${mobile}&grade=${grade}&otherSiteFrom=${otherSiteFrom}&flatfrom=${flatfrom}&channel_code=${channel_code}&channel_keyword=${channel_keyword}`;
      } else if (goPath === "appointment-success-little-genius") {
        littleGenius({
          mobile: this.mobile || "",
          grade: this.weakGrade || "",
          subject: this.weakSubject,
        });
      } else {
        // 微博api及uc信息需要使用显式query传参
        if (this.$route.query.mark_id) {
          weiboJsUp(this.$route.path);
          this.$router.push({
            name: goPath,
            query: params,
          });
        } else if (this.$route.query.uctrackid) {
          this.$router.push({
            name: goPath,
            query: {
              uctrackid: this.$route.query.uctrackid
            },
          });
        } else {
          this.$router.push({
            name: goPath,
            params: params,
            query: this.$route.query,
          });
        }
      }
    },
    goKid () {
      let kidWeakGrade = [
        "KINDERGARTEN_1",
        "KINDERGARTEN_2",
        "KINDERGARTEN_3",
        "PRIMARY_SCHOOL_1",
        "PRIMARY_SCHOOL_2",
        "PRIMARY_SCHOOL_3",
      ];
      let kidWeakSubject = [
        "MATH_OLYMPIAD",
        "LEARNING_ABILITY_TRAINING",
        "MATH_LOGIC",
        "ENGLISH_CONCEPT",
        "CHINESE_LOGIC",
      ];
      if (
        kidWeakGrade.includes(this.weakGrade) &&
        kidWeakSubject.includes(this.weakSubject)
      ) {
        this.getFlatfrom(this.mobile, 2);
        return true;
      } else {
        return false;
      }
    },
    checkPeilian () {
      let peilianGrade = [
        "小六",
        "小五",
        "小四",
        "小三",
        "小二",
        "幼儿园小班",
        "幼儿园大班",
        "幼儿园中班",
        "小一",
      ];
      return new Promise((resolve, reject) => {
        if (peilianGrade.includes(this.weakGrade)) {
          api
            .postLessonHasPeilian({
              mobile: this.mobile
            })
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          reject();
        }
      });
    },

    // java接口改造 少儿各渠道广告回传接口调用下线
    handelBaiduApi () {
      // 对接百度API
      const query = queryObj();
      let codeList = [
        "14688",
        "15645",
        "15646",
        "15647",
        "15648",
        "15469",
        "15649",
      ];
      if (query["channel_code"] && codeList.includes(query["channel_code"])) {
        let getUuid = require("uuid/v4"); // 引用uuid npm包随机生成uuid
        let _uuid = getUuid();
        let uuid = _uuid.replace(/-/g, "");
        const dataObj = {
          data: {
            channelCode: query["channel_code"],
            referUrl: window.location.href,
            userMobile: this.mobile,
          },
          event: "ADVERT_CALLBACK_EVENT",
          source: "m-kid",
          traceId: uuid,
        };
        api.baiduApiCallback(dataObj);
      }
    },

    baiduAndToday () {
      const routename = this.$route.name;
      if (this.$route.name == "home") {
        _taq.push({
          convert_id: "1616464417623092",
          event_type: "view"
        });
        _taq.push({
          convert_id: "1616464620993539",
          event_type: "view"
        });
        _taq.push({
          convert_id: "1616458191502391",
          event_type: "form"
        });
        // window._agl && window._agl.push(['track', ['success', { t: 3 }]])
      }
      if (this.$route.params.path == "bdxxl") {
        window._agl && window._agl.push(["track", ["success", {
          t: 3
        }]]);
      }
      if (routename == "c0" || routename == "d0") {
        window._agl && window._agl.push(["track", ["success", {
          t: 3
        }]]);
      }
    },
    validateForm () {
      return new Promise((resolve, reject) => {
        if (this.justMobile == "firstStep") {
          if (this.mobile.length == 0) {
            this.mobileAlert = true;
            reject("请输入手机号");
          } else if (!/^\d{11}$/.test(this.mobile)) {
            this.mobileAlert = true;
            reject("请填写正确手机号");
          } else {
            resolve("success");
          }
        } else {
          if (!/^([a-zA-Z]|[\u4e00-\u9fa5]){1,}$/.test(this.userName)) {
            this.userNameAlert = true;
            reject("请填写正确的孩子姓名");
          } else if (this.userName.length >= 7) {
            this.userNameAlert = true;
            reject("请填写正确的孩子姓名");
          } else if (this.mobile.length == 0) {
            this.mobileAlert = true;
            reject("请输入手机号");
          } else if (!/^\d{11}$/.test(this.mobile)) {
            this.mobileAlert = true;
            reject("请填写正确手机号");
          } else if (
            this.vertifyCodeStatus &&
            !/^\d+$/.test(this.identifyingCode)
          ) {
            reject("请输入正确的验证码");
          } else if (!this.checkProtocol) {
            reject("请勾选用户协议和隐私政策");
          } else {
            resolve("success");
          }
        }
      });
    },
    handleGradeChange () {
      var $this = this.$refs.grade;
      console.log($this.selectedIndex);
      switch ($this.selectedIndex) {
        case 12:
          this.weakSubjectArr = this.subjectData[12];
          this.weakSubject = this.subjectData[12][0][1];
          $this.options[$this.selectedIndex].selected = true;
          break;
        default:
          this.weakSubjectArr = this.subjectData[$this.selectedIndex];
          this.weakSubject = this.subjectData[$this.selectedIndex][0][1];
          break;
      }
    },

    addJrttCount (id) {
      if (window.meteor) return;
      (function (r, d, s, l) {
        var meteor = (r.meteor = r.meteor || []);
        meteor.methods = ["track", "off", "on"];
        meteor.factory = function (method) {
          return function () {
            var args = Array.prototype.slice.call(arguments);
            args.unshift(method);
            meteor.push(args);
            return meteor;
          };
        };
        for (var i = 0; i < meteor.methods.length; i++) {
          var key = meteor.methods[i];
          meteor[key] = meteor.factory(key);
        }
        meteor.load = function () {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          js = d.createElement(s);
          js.src = "https://analytics.snssdk.com/meteor.js/v1/" + l + "/sdk";
          fjs.parentNode.insertBefore(js, fjs);
        };
        meteor.load();
        if (meteor.invoked) {
          return;
        }
        meteor.invoked = true;
        meteor.track("pageview");
      })(window, document, "script", id);
    },

    // 如果是新用户报名成功则回传微信完成转化跟踪
    handleSubmitBtn () {
      if (this.$route.path === "/" && this.trigger_site && ZM_JSSDK) {
        let eventData = {
          eventId: "click_kid_m_mflk",
          eventParam: {
            name: "点击|少儿|官网m端|免费领课",
            grade: this.weakGrade,
            subject: this.weakSubject,
            trigger_site: this.trigger_site,
            position: "弹窗"
          },
        };
        if (this.trigger_site === "首页少儿表单") {
          //   eventData.eventId = "click_kid_m_mflk";
          //   eventData.eventParam.name = "首页点击【免费领课】";
          eventData.eventParam.position = "主页";
        }
        ZM_JSSDK.sendEvent(eventData);
      }
      if (this.$route.path === "/august-activity-2019") {
        let text = "";
        if (this.modelBox === "modelBox") {
          switch (this.tabIndex) {
            case "1":
              text = "少儿优惠";
              break;
            case "2":
              text = "少儿视频播放";
              break;
            case "3":
              text = "少儿课程";
              break;
            case "4":
              text = "少儿师资";
              break;
            case "5":
              text = "少儿底部导航";
              break;
          }
          _hmt.push(["_trackEvent", `获得0元试听`, text, "掌门少儿8月份活动"]);
        } else {
          let text = "";
          _hmt.push([
            "_trackEvent",
            "免费领课",
            "少儿表单",
            "掌门少儿8月份活动",
          ]);
        }
      } else if (this.$route.path === "/zmkid-activity") {
        ZM_JSSDK && ZM_JSSDK.sendEvent({
          eventId: 'zmkid_activity_click',
          eventParam: {
            name: '点击提交按钮'
          }
        });
      } else if (this.$route.path === "/zmkid-activity618") {
        ZM_JSSDK &&
          ZM_JSSDK.sendEvent({
            eventId: "c618_order",
            eventParam: {
              name: "首屏表单领课",
              grade: this.weakGrade,
              subject: this.weakSubject,
            },
          });
      }

      this.validateForm()
        .then(() => {
          if (this.justMobile === "firstStep") {
            this.identifySubmitBtn();
          } else {
            if(this.vertifyCodeStatus){
              this.doRegister();
              return;
            }
            this.showMask = true;
            this.identifySubmitBtn();
            // this.checkAdvertNeed().then(this.initGeetest);
          }
        })
        .catch((err) => {
          Toast(err);
        });
    },

    checkAdvertNeed () {
      let {
        gdt_vid,
        qz_gdt,
        clickid,
        uctrackid
      } = this.$route.query;
      return new Promise((resolve, reject) => {
        if (qz_gdt || gdt_vid || clickid || uctrackid) {
          api
            .checkIsNewUser({
              mobile: this.mobile
            })
            .then((res) => {
              if (res.data && res.data.code == 0) {
                resolve(res.data.data.isNewUser);
              } else {
                resolve(false);
              }
            })
            .catch(() => {
              resolve(false);
            });
        } else {
          resolve(false);
        }
      });
    },

    // java接口改造 少儿各渠道广告回传接口调用下线
    doAdvertCb (isNew) {
      // 不区分新用户
      if (!isNew) {
        return;
      }
      const {
        clickid
      } = this.$route.query;
      const url = location.href;
      if (clickid) {
        api.toutiaoAdvertCallback({
          accountType: "tt_kid",
          callbackType: 3,
          clickId: clickid,
          sourceUrl: encodeURIComponent(url),
          userMobile: this.mobile,
        });
      }
      let accountType = this.getAccountType();

      let {
        gdt_vid,
        qz_gdt
      } = this.$route.query;
      if (gdt_vid) {
        api.wechatAdvertCallback({
          clickId: gdt_vid,
          referUrl: window.location.href,
          userMobile: this.mobile,
          accountType: "zmkid0",
        });
      }
      if (qz_gdt) {
        api.qqAdvertCallback({
          clickId: qz_gdt,
          referUrl: window.location.href,
          userMobile: this.mobile,
          accountType: accountType,
        });
      }
    },

    ucTrackCb (isNew) {
      if (isNew) {
        if (/uc_qs01/.test(this.$route.path)) {
          utq("track", "FormSubmit", "370687");
          console.log("UC信息Track1");
        } else if (/uc_qs02/.test(this.$route.path)) {
          utq("track", "FormSubmit", "377614");
          console.log("UC信息Track2");
        }
      }
    },

    weiboMaidian () {
      const path = this.$route.path;

      const paths = [
        "/lp/a1/wb_ld01",
        "/lp/b1/wb_ld01",
        "/lp/a2/wb_ld01",
        "/lp/b2/wb_ld01",
        "/lp/c0/wb_ld01",
        "/lp/d0/wb_ld01",
      ];
      if (paths.indexOf(path) > -1) {
        console.log("微博埋点代码", window.wbadmt);
        try {
          window.wbadmt.send({
            eventid: "303959",
            eventtype: "form",
            eventvalue: "",
          });
        } catch (error) { }
      }
    },
    getAccountType () {
      let path = window.location.pathname;
      let accountType = "KID_QQ_AD";
      const pathList = [
        "/lp/c0/gdtwm",
        "/lp/d0/gdtwm",
        "/lp/a1/gdtwm",
        "/lp/a2/gdtwm",
        "/lp/b1/gdtwm",
        "/lp/b2/gdtwm",
      ];
      if (pathList.indexOf(path) > -1) {
        accountType = "ZM_EDU_AD";
      }
      return accountType;
    },
  },
};
</script>

<style lang="scss">
// @import './../../styles/mixin.scss';
@import './css/lessonForm1.scss';

.mask-box {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;

  .verification-box {
    width: 624px;
    height: 504px;
    border-radius: 20px;
    position: relative;
    transform: translate(-50%, -50%);
    background: #fff;
    left: 50%;
    top: 50%;
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    box-sizing: border-box;
    align-items: center;

    .closeIcon {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 24px;
      right: 24px;
    }

    .phone-text {
      width: 100%;
      text-align: center;
      height: 50px;
      font-size: 36px;
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      color: rgba(51, 51, 51, 1);
      line-height: 50px;
      margin-bottom: 60px;
    }

    .code-box {
      display: flex;
      align-items: center;
      width: 506px;
      justify-content: space-around;
      margin-bottom: 60px;

      input.codeNumber {
        width: 318px;
        box-sizing: border-box;
        height: 76px;
        background: rgba(246, 246, 246, 1);
        border-radius: 8px;
        margin-right: 20px;
        font-size: 28px;
        font-weight: 600;
        border: none;
        padding: 0 20px;
      }

      .send-code {
        text-align: center;
        width: 186px;
        height: 68px;
        background: rgba(239, 76, 79, 1);
        border-radius: 8px;
        font-size: 28px;
        font-weight: 600;
        color: #fff;
        line-height: 68px;
        font-family: PingFangSC-Semibold;
      }
    }

    .verification-btn {
      text-align: center;
      width: 444px;
      height: 76px;
      background: rgba(239, 76, 79, 1);
      border-radius: 42px;
      font-size: 32px;
      font-weight: 400;
      color: #fff;
      line-height: 76px;
      font-family: PingFangSC-Regular;
      margin-bottom: 20px;
    }

    .bottom-title {
      text-align: center;
      width: 456px;
      height: 34px;
      line-height: 34px;
      font-family: PingFangSC-Regular;
      color: #666;
      font-size: 24px;
    }
  }

  &.model-size {
    width: 100vw;
    height: 100vh;

    .verification-box {
      position: absolute;
      left: 48%;
      top: 20%;
      transform: translate(-50%, -50%);
    }
  }
}

.area-code {
  display: none;
}
</style>
