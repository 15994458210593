<template>
  <div v-if="show" class="success-dialog">
    <div class="success-dialog-main">
      <div class="success-btn" @click="hide">
        <span>我知道啦</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessDialog",
  data() {
    return {
      show: false
    };
  },
  methods: {
    postFormSuccess() {
      this.show = true;
    },
    hide() {
      this.show = false;
    }
  }
};
</script>

<style lang="scss" scoped>
// @import "./../../styles/mixin.scss";

.success-dialog {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  .success-dialog-main {
    position: relative;
    width: 528px;
    height: 610px;
    top: 50%;
    left: 50%;
    margin-left: -264px;
    margin-top: -305px;
    background: url("./img/pic_succeed.png") no-repeat center;
    background-size: contain;
    position: relative;
    .success-btn {
      width: 334px;
      height: 91px;
      background: url("./img/btn_know.png") no-repeat center;
      background-size: contain;
      text-align: center;
      line-height: 91px;
      position: absolute;
      bottom: -38px;
      left: 100px;
      span {
        font-size: 38px;
        color: #ffffff;
        letter-spacing: 0;
      }
    }
  }
}
</style>
