// 最新版隐私协议
<template>
  <div class="form-protocol">
    <input
      type="checkbox"
      class="form-protocol-checkbox"
      v-model="checkProtocol"
    />
    <div class="form-protocol-text">
      <span @click="checkProtocol = !checkProtocol">{{lang === 'zh-tw' ? '我已閱讀並同意':'我已阅读并同意'}}</span>
      <a
        :href="lang === 'zh-tw' ? 'http://m.zhangmenkid.com/agreement?type=1':'https://www.zhangmen.com/service-agreement.html'"
        class="high-light"
        >{{lang === 'zh-tw' ? '《用戶協議》':'《用户协议》'}}</a
      >和
      <a
        :href="lang === 'zh-tw' ? 'http://m.zhangmenkid.com/agreement?type=2':'https://www.zhangmen.com/privacy-agreement.html'"
        class="high-light"
        >{{lang === 'zh-tw' ? '《隱私政策》':'《隐私政策》'}}</a
      >
    </div>
  </div>
</template>
<script>
export default {
  props: ['defaultValue', 'lang'],
  data () {
    return {
      checkProtocol: this.defaultValue
    }
  },
  watch: {
    checkProtocol (val) {
      this.$emit('changeProtocol', this.checkProtocol);
    }
  },
}
</script>
<style lang="scss" rel="stylesheet/less">
.form-protocol {
  margin: 20px auto;
  .form-protocol-checkbox[type='checkbox'] {
    margin-right: 10px;
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    outline: none;
    -webkit-appearance: none;
    background: none;
    border: none;
  }
  .form-protocol-checkbox[type='checkbox']::before {
    content: '';
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 20px;
    background: url('https://web-data.zmlearn.com/image/uCEXdz9GVkVvydborYvq2y/uncheck.png')
      no-repeat center center;
    background-size: contain;
  }
  .form-protocol-checkbox[type='checkbox']:checked::before {
    content: '';
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 20px;
    background: url('https://web-data.zmlearn.com/image/pyFQEoJm2Z6aTCihhxAvHb/checked.png')
      no-repeat center center;
    background-size: contain;
  }
  .form-protocol-text {
    a {
      text-decoration: none;
    }
    display: inline-block;
    vertical-align: middle;
    color: #000;
    .high-light {
      color: #ff9000;
    }
  }
}
</style>