import newPay from '@/v1Module/newPay/index.vue';
import newPay3 from '@/v1Module/newPay3/index.vue';
import newPayShare from '@/v1Module/newPay/share.vue';
import newPayBd from '@/v1Module/new-paybd/index.vue'
import newPayHt from '@/v1Module/newPayHt/index.vue'
import newPayYn from '@/v1Module/newPayYn/index.vue'
import newPayMoney from '@/views/newpay-money/index.vue';
import newPayFree from '@/views/newpay-free/index.vue';
import newPay3503 from '@/views/newPay3503/index.vue';

export default [
  {
    path: '/new-pay',
    name: 'Year2020NewPay',
    component: newPay,
    description: '旧版9.9更新20201127, 过滤1对1用户(地推专用)'
  },
  {
    path: '/new-pay-referral',
    name: 'Year2020NewPay',
    component: newPay,
    description: '20201216添加9.9 转介绍专用路由(后续可能会需要映射到其它页面)'
  },
  {
    path: '/new-pay-share',
    name: 'Year2020NewPayShare',
    component: newPayShare,
    description: '9.9落地页添加分享功能'
  },
  {
    path: '/new-pay3',
    name: 'Year2020NewPay3',
    component: newPay3,
    description: '20201104第一版投放朋友圈及广点通广告(20201216：(业务反馈：页面暂时不用))'
  },
  {
    path: '/new-pay-bd',
    name: 'Year2020NewPayBd',
    component: newPayBd,
    description: '20201124 KWO-2580 9.9 newPay 页面复制, bd 代表商务(20201216：(业务反馈：页面暂时不用))'
  },
  {
    path: '/dt-haotuo',
    name: 'Year2021NewPayHt',
    component: newPayHt,
    description: '20201124 KWO-2580 9.9 newPay 页面复制, bd 代表商务(20201216：(业务反馈：页面暂时不用))'
  },
  {
    path: '/dt-younuo',
    name: 'Year2021NewPayYn',
    component: newPayYn,
    description: '20201124 KWO-2580 9.9 newPay 页面复制, bd 代表商务(20201216：(业务反馈：页面暂时不用))'
  },
  {
    path: '/new-pay-money',
    name: 'NewPayMoney',
    component: newPayMoney
  },
  {
    path: '/new-pay-free',
    name: 'NewPayFree',
    component: newPayFree
  },
  {
    path: '/new-pay-3503',
    name: 'NewPay3503',
    component: newPay3503,
    desc: 'KWO-3503'
  },
]

