













































import { Vue, Component } from 'vue-property-decorator';
import api from '@/api.js';
import Qrious from 'qrious';
import { initWechat } from '@/utils/wechat/init-wechat';
import wx from 'weixin-jsapi';
import { generateEntype } from '@/utils/util.js';
@Component({
  components: {},
})
export default class BuyClassForm extends Vue {
  private qrCodeImg: any = '';
  private line1: any = '还差一步即可领取【掌门独家资料】哦！';
  private line2: any = '扫描下方二维码关注“掌门少儿学习中心”即可~';
  private mobile: any = '';
  private channel_code: any = '';
  private channel_keyword: any = '';
  private flatfrom: any = '';
  private wechatNo = '';
  private created() {
    this.mobile = this.$route.params.mobile || this.$route.query.mobile || '';
    this.channel_code =
      this.$route.params.channel_code || this.$route.query.channel_code || '';
    this.channel_keyword =
      this.$route.params.channel_keyword ||
      this.$route.query.channel_keyword ||
      '';
    this.flatfrom = this.$route.params.flatfrom || 'buyClass4935';
  }
  private mounted() {
    setTimeout(() => {
      this.getQrCodeFun();
    }, 500);
    initWechat({}).then(() => {
      wx.ready(wx.hideOptionMenu);
    });
    switch (this.$route.query.type) {
      case '3742':
        this.$track.sendEvent({
          eventId: 'enter_kid_act61_success01',
          eventParam: {
            code: this.$route.query.channel_code,
          },
        });
        break;
      case '3811':
        this.$track.sendEvent({
          eventId: 'enter_kid_act62_success01',
          eventParam: {
            code: this.$route.query.channel_code,
          },
        });
        break;
      case '3835':
        this.$track.sendEvent({
          eventId: 'enter_kid_act63_success01',
          eventParam: {
            code: this.$route.query.channel_code,
          },
        });
        break;
      default:
        break;
    }
  }
  getQrCodeFun() {
    if (!this.mobile) return;
    api
      .getWechatKidQrcode({
        mobile: this.$route.params.mobile
          ? generateEntype(this.mobile)
          : this.mobile,
        platform: this.flatfrom,
        keyword: 'AIkeluodiye',
        source: 20,
      })
      .then((res: any) => {
        if (res.data && res.data.code == 0 && res.data.data) {
          this.qrCodeImg = res.data.data.pictureUrl;
        } else {
          this.qrCodeImg = require('@/assets/image/appointment-success-kid/defaultQrcode.png');
          console.log('获取二维码失败');
        }
      })
      .catch((err: any) => {
        console.log('网络错误', err);
      });
  }
}
