import Geetest from "@/utils/geetest"; // 极验代码
import Toast from "@/base/toast/toast";
import api from '@/api.js';
let timer = true;

export const GeetestCode = (async (areaCode = 86, mobile, _this) => {
  if (!timer) return;
  timer = false;
  setTimeout(() => {
    timer = true;
  }, 2000)
  if (!/^\d+$/.test(mobile)) {
    Toast('请输入手机号');
    return;
  }
  const captchaObj = await Geetest.request({
    product: "bind",
    width: "320px",
  });
  console.log(captchaObj);
  _this.captchaObj = captchaObj;
  captchaObj
    .onReady(() => {
      setTimeout(() => {
        _this.captchaObj.verify();
      }, 100);
    })
    .onSuccess(async () => {
      try {
        const result = captchaObj.getValidate();
        if (!result) {
          return Toast("请完成验证");
        }
        const verifyParams = {
          areaCode: areaCode,
          mobile: mobile,
          geetestChallenge: result.geetest_challenge,
          geetestValidate: result.geetest_validate,
          geetestSeccode: result.geetest_seccode,
        };
        api
          .KidGetVerifyCode(verifyParams)
          .then((res) => {
            if (res.data.code == 0) {
              _this.changMobile = false;
              Toast("验证码发送成功");
              if (_this.justMobile === "firstStep") {
                _this.$emit("changeForm");
              }
              const timer = setInterval(() => {
                _this.codeCountDownNum--;
                console.log(_this.codeCountDownNum);
                if (_this.codeCountDownNum <= 0 || _this.changMobile) {
                  clearInterval(timer);
                  _this.vertifyOnOff = true;
                  _this.codeCountDownNum = 60;
                }
              }, 1000);
            } else {
              _this.vertifyOnOff = true;
              Toast(res.data.message || "验证码发送失败，请稍后再试");
            }
          })
          .catch((err) => {
            Toast(err);
          });
        _this.vertifyOnOff = false;
        // await _this.doRegister('', data);
      } catch (e) {
        Toast(e.message);
        _this.captchaObj = captchaObj;
      } finally {
        captchaObj.destroy();
      }
    })
    .onError(() => {
      Toast({
        message: "您刷新太多次了, 故关闭验证弹框, 请从新验证",
        duration: 2000,
      });
      captchaObj.destroy();
      _this.captchaObj = captchaObj;
    })
    .onClose(() => {
      captchaObj.destroy();
      _this.captchaObj = captchaObj;
    });
})
