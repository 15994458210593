import { render, staticRenderFns } from "./newMath.vue?vue&type=template&id=1b0dfc8b&scoped=true&"
import script from "./newMath.vue?vue&type=script&lang=js&"
export * from "./newMath.vue?vue&type=script&lang=js&"
import style0 from "./newMath.vue?vue&type=style&index=0&id=1b0dfc8b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b0dfc8b",
  null
  
)

export default component.exports