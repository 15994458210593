<template>
  <div class="landing-page">
    <main-content type="chinese"></main-content>
  </div>
</template>

<script>
import api from '@/api'
import main from './main';

export default {
  name: 'landingPage',
  components: {
    mainContent: main,
  },
  data () {
    return {}
  },
  created () {
    if (ZM_JSSDK) {
      const now = +new Date()
      ZM_JSSDK.sendEvent({
        eventId: "enter_kid_act14_homepage",
        eventParam: {
          code: this.$route.query.channel_code
        }
      });

      const timer = setInterval(() => {
        ZM_JSSDK.sendEvent({
          eventId: "enter_kid_act14_holdtime",
          eventParam: {
            code: this.$route.query.channel_code,
            hold_time: `${(+new Date() - now) / 1000}s`
          }
        });
      }, 5000);

      this.$on('hook:beforeDestroy', () => {
        clearInterval(timer)
      });
    }
  },
}
</script>
<style lang="scss" scoped>
.landing-page {
  padding-bottom: 254px;
  background: #fff4d5;
}
</style>