<template>
  <div class="swiper-main">
    <div v-if="status == 'error'" class="banner-img">
      <img
        :class="{ visible: staticVisible }"
        width="100%"
        height="100%"
        src="https://web-data.zmlearn.com/image/7UgkX42HT8nbY2WWDkzUmm/banner1.png"
        @load="staticBannerLoad(1)"
      >
    </div>
    <div v-else-if="status != 'success'" class="loader">
      <div class="loader-inner ball-spin-fade-loader">
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
      </div>
    </div>
    <div v-show="status == 'success'" class="swiperBanner">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in bannerList" :key="index">
          <!-- <div class="banner-img">
                          <img @load=bannerLoad(index) :class='{visible:item.visible}' width=100% height=100% :src="item.imgUrl">
          </div>-->
          <template v-if="item.bannerMode == 'hover'">
            <div class="banner-img">
              <img
                :style="
                    'height:' + imgHeight + ';border-radius:' + borderRadius + ';'
                  "
                :class="{ visible: item.visible }"
                :src="item.imgUrl"
                width="100%"
                height="100%"
                @load="bannerLoad(index)"
                @click="hoverClick(item)"
              >
            </div>
          </template>
          <template v-else-if="item.bannerMode == 'redirect'">
            <a :href="item.bannerUrl" class="banner-img">
              <img
                :style="
                    'height:' + imgHeight + ';border-radius:' + borderRadius + ';'
                  "
                :class="{ visible: item.visible }"
                :src="item.imgUrl"
                width="100%"
                height="100%"
                @load="bannerLoad(index)"
              >
            </a>
          </template>
          <template v-else-if="item.bannerMode == 'blank'">
            <a :href="item.bannerUrl" class="banner-img" target="_blank">
              <img
                :style="
                    'height:' + imgHeight + ';border-radius:' + borderRadius + ';'
                  "
                :class="{ visible: item.visible }"
                :src="item.imgUrl"
                width="100%"
                height="100%"
                @load="bannerLoad(index)"
              >
            </a>
          </template>
        </div>
      </div>
      <div class="swiper-pagination"></div>
      <!-- <span v-show='bannerOptions&&bannerOptions.usePage' class="swiper-pagination" slot="pagination"></span> -->
    </div>
  </div>
</template>

<script>
// import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { Swiper, Pagination, Autoplay } from 'swiper';
Swiper.use([Pagination, Autoplay])
import axios from 'axios'
import urlConfig from '@/config'

export default {
  components: {
    // swiper,
    // swiperSlide
  },
  props: {
    padding: {
      type: String,
      default: '0'
    },
    imgHeight: {
      type: String,
      default: 'auto'
    },
    borderRadius: {
      type: String,
      default: '0'
    },
    page: {
      type: String,
      default: 'home'
    }
  },
  data() {
    return {
      swiperOption: {
        //是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
        preventClicks: false,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        direction: 'horizontal',
        freeMode: false,
        // loop: true,
        observer: true,
        observeParents: true
      },
      bannerList: [],
      status: '',
      calltime: 5,
      staticVisible: false
    }
  },
  mounted() {
    let getBanner = () => {
      this.getBannerData()
        .then(data => {
          try {
            let list = data.data.data;
            for (let i = 0; i < list.length; i++) {
              list[i].visible = false
              this.bannerList.push(list[i])
            }
            var mySwiper = new Swiper('.swiperBanner', {
              loop: true,
              autoplay: true,
              preventClicks: false,
              preventClicks: false,
              autoplay: {
                delay: 3000,
                stopOnLastSlide: false,
                disableOnInteraction: false
              },
              direction: 'horizontal',
              freeMode: false,
              // loop: true,
              observer: true,
              observeParents: true
            });
            
            if (list && list.length > 0) this.status = 'success'
            else this.status = 'error'
          } catch (e) {
            console.log(e)
            this.status = 'error'
          }
        })
        .catch(e => {
          console.log("e",e);
          this.calltime--
          if (this.calltime == 0) {
            this.status = 'error'
            return false
          }
          getBanner()
        })
    }
    getBanner()
  },
  methods: {
    getBannerData() {
      let groupId = ''
      console.log(this.$route.name)
      if (['c0', 'd0','c0-mpwm','c0-gdtwm','d0-mpwm','d0-gdtwm','c0-wb_ld01','d0-wb_ld01','c0-uc_qs01','c0-uc_qs02'].includes(this.$route.name)) {
        groupId = urlConfig.c0d0BannerGroupId
      } else {
        groupId = urlConfig.bannerGroupID
      }
      // let apiurl = `/api/zixun/get-banner?condition={"group_id":"${
      //   groupId
      // }"}`

      let apiurl = `${urlConfig.appGateway}/api/operation-web-server/v1/ow/getBannerDoing?groupId=${groupId}`;
      // console.log(apiurl)
      return axios(apiurl, {
        timeout: 8000,
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
          // 'Accept-Ranges': 'bytes',
          // 'Access-Control-Allow-Credentials': true,
          // 'Access-Control-Allow-Origin': '*'
        }
      })
    },
    staticBannerLoad() {
      this.staticVisible = true
    },
    bannerLoad(index) {
      console.log('bannerList', this.bannerList)
      this.bannerList[index].visible = true
    },
    hoverClick(item){
      console.log("item",item)
      this.$emit("showBannerDialog")
    }
  }
}
</script>

<style lang="scss" scoped>
// @import './../src/styles/loaders/animations/ball-spin-fade-loader.scss';
@import '~@/styles/loaders/animations/ball-spin-fade-loader.scss';

.loader {
  width: 100%;
  height: 100%;
  .ball-spin-fade-loader {
    position: relative;
    left: 50%;
    top: 2.2rem;
    div {
      background-color: #ff5455;
    }
  }
}
@import './swiper.min.css';

@function px2rem($px) {
  @return ($px / 75) * 1rem;
}
.swiper-slide-duplicate {
  img {
    opacity: 1 !important;
  }
}
.swiper-main {
  height: 100%;
  // div{
  //   height: 100%;
  // }
  img {
    opacity: 0;
    transition: opacity 0.3s ease-in;
    &.visible {
      opacity: 1;
    }
  }
  .activity-rule {
    position: absolute;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 0 16px;
    /*width: px2rem(166);*/
    height: 45px;
    border-radius: 45px;
    line-height: 45px;
    right: 30px;
    top: 30px;
    z-index: 10;
    .arrow {
      display: inline-block;
      vertical-align: baseline;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      border: 1px solid #fff;
      border-radius: 50%;
    }
  }
  img {
    width: 100%;
    vertical-align: bottom;
  }
}

.activity-dialog {
  .header {
    font-size: 32px;
    margin-bottom: 10px;
  }
  .grey-desc {
    overflow: scroll;
    height:480px;
    font-size: 26px;
    line-height: 40px;
    color: #666;
    padding: 30px 10px 30px 50px;
    background: #f0f0f0;
    text-align: left;
    span {
      margin-left:-30px;
    }
  }
  .footer {
    width: 260px;
    height: 70px;
    line-height: 70px;
    background: #ea4949;
    border-radius: 35px;
    margin-top: 20px;
    border: none;
    outline: none;
    span {
      font-size: 24px;
      color: #fff;
    }
  }
}
</style>
