<template>
  <div v-if="show" class="success-dialog">
    <div class="success-dialog-main">
      <h3 class="success-title">领取成功</h3>
      <div class="qrcode-wrap">
        <img :src="getQrcode" alt width="100%" height="100%" />
      </div>
      <h3 class="des-first">
        扫码享
        <span style="color: #ff3a4b">优先排课</span>
      </h3>
      <p class="des-second">
        第一时间了解宝贝
        <span style="color: #ff6042">预约进程</span>
      </p>
      <div class="success-btn" @click="hide">
        <img src="./img/close.png" width="100%" height="100%" />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
export default {
  name: "SuccessDialog",
  data() {
    return {
      show: false,
      qrcode: "",
      url: "/kid/api/wechat/get-wechat-qrcode"
    };
  },
  computed: {
    getQrcode() {
      return this.qrcode ? this.qrcode : require("./img/defaultQrcode_kid.jpg");
    }
  },
  methods: {
    postFormSuccess(mobile) {
      this.show = true;
      let flatfrom = this.getFlatfrom()
        ? this.getFlatfrom()
        : window.location.pathname;

      api
        .postLessonForm(this.url, { mobile: mobile, flatfrom: flatfrom })
        .then(res => {
          // console.log('qrcode', res)
          if (res.data && res.data.code == 0) {
            this.qrcode = res.data.data.ticket
              ? "https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=" +
              res.data.data.ticket
              : "";
          } else {
            console.log("获取二维码失败");
          }
        })
        .catch(err => {
          console.log("网络错误", err);
        });
    },
    hide() {
      this.show = false;
    },
    getFlatfrom() {
      let pathName = this.$route.name;
      let flatfromMap = [
        { pathName: "home", flatfrom: "少儿M站" },
        { pathName: "coruseDetailMath", flatfrom: "数理逻辑-M站" },
        { pathName: "courseDetailChinese", flatfrom: "语文思维-M站" },
        { pathName: "courseDetailStudy", flatfrom: "学习力-M站" },
        { pathName: "courseDetailEnglish", flatfrom: "DreamenKid-English-M站" },
        { pathName: "fwhWenben", flatfrom: "服务号文本消息" }
      ];
      for (let i = 0; i < flatfromMap.length; i++) {
        if (flatfromMap[i].pathName == pathName) {
          return flatfromMap[i].flatfrom;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
// @import "./../../styles/mixin.scss";

.success-dialog {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  .success-dialog-main {
    position: relative;
    width: 611px;
    height: 659px;
    top: 50%;
    left: 50%;
    margin-left: -305px;
    margin-top: -330px;
    background: url("./img/pic_succeed_new.png") no-repeat center;
    background-size: contain;
    position: relative;
    .success-title {
      color: #fff;
      font-size: 52px;
      text-align: center;
      padding-top: 10px;
      line-height: 130px;
      font-family: "Adobe 黑体 Std";
      letter-spacing:6px;
    }
    .qrcode-wrap {
      width: 244px;
      height: 244px;
      margin: 40px auto 0;
    }
    .des-first {
      font-size: 48px;
      text-align: center;
      color: #000;
      font-family: "Adobe 黑体 Std";
      margin-top: 54px;
      letter-spacing: 6px;
    }
    .des-second {
      font-size: 26px;
      text-align: center;
      color: #9e9e9e;
      font-family: "Adobe 黑体 Std";
      margin-top: 36px;
      letter-spacing: 4px;
    }
    .success-btn {
      width: 70px;
      height: 70px;
      position: absolute;
      bottom: -100px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
