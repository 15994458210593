<template>
  <div
    class="modalContainer"
  >
    <!-- 背景蒙层 -->
    <transition name="custom-classes-transition"
      :enter-active-class="'fadeEnterActive'"
      :enter-class="'fadeEnter'"
      :leave-to-class="'fadeLeaveTo'"
    >
      <!-- 自定义背景，如右上角分享提示 -->
      <div class="modalBg" v-show="value">
        <slot name="bg"></slot>
      </div>
    </transition>

    <!-- 主内容部分 -->
    <transition
      name="custom-classes-transition"
      :enter-active-class="'enterActiveClass'"
      :enter-class="'enterClass'"
      :leave-to-class="'leaveToClass'"
    >
      <div class="modalWrap" :class="[position].concat(modalClass)" v-show="value" @click="onMaskClick" ref="mask">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import { lockScroll, unlockScroll } from './lockScroll';

export default {
  props: {
    clickClose: {
      type: Boolean,
      default: true
    },
    value: {
      type: Boolean
    },
    animate: {
      type: String,
      default: 'fade'
    },
    position: {
      type: String,
      default: 'center'
    },
    needLock: {
      type: Boolean,
      default: true
    },
    modalClass: {
      type: [Array, String]
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        val && this.needLock ? lockScroll() : unlockScroll();
      }
    }
  },
  beforeDestroy () {
    unlockScroll();
  },
  computed: {
    enterActiveClass() {
      if (this.position === 'bottom') {
        return this.slideEnterActive;
      }
      return this.animate ? this.$style[`${this.animate}EnterActive`] : undefined;
    },
    enterClass() {
      if (this.position === 'bottom') {
        return undefined;
      }
      return this.animate ? this.$style[`${this.animate}Enter`] : undefined;
    },
    leaveToClass() {
      if (this.position === 'bottom') {
        return undefined;
      }

      return this.animate ? this.$style[`${this.animate}leaveTo`] : undefined;
    }
  },
  methods: {
    onMaskClick(e) {
      if (this.clickClose && this.$refs.mask === e.target) {
        this.$emit('input', false);
      }
    },
    blur() {
      window.scrollTo(0, 0);
    }
  }
}
</script>
<style lang="scss" scoped>
  @import './index.scss';
</style>