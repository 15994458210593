















import { Vue, Component } from 'vue-property-decorator';
import Qrious from 'qrious';
import Clipboard from 'clipboard';
import Toast from '@/base/toast/toast.js';
import api from '@/api';
import { initWechat } from '@/utils/wechat/init-wechat';
import wx from 'weixin-jsapi';
@Component({
  components: {},
})
export default class H5Success extends Vue {
  private qrImg: any = '';
  private wechatNo = '掌门少儿学习中心';
  private created() {
    this.getImgUrl();
  }
  private mounted() {
    initWechat({}).then(() => {
      wx.ready(wx.hideOptionMenu);
    });
    document.documentElement.scrollTop = 0;
    switch (this.$route.query.type) {
      case '3742':
        this.$track.sendEvent({
          eventId: 'enter_kid_act61_success02',
          eventParam: {
            code: this.$route.query.channel_code,
          },
        });
        break;
      case '3811':
        this.$track.sendEvent({
          eventId: 'enter_kid_act62_success02',
          eventParam: {
            code: this.$route.query.channel_code,
          },
        });
        break;
      case '3835':
        this.$track.sendEvent({
          eventId: 'enter_kid_act63_success02',
          eventParam: {
            code: this.$route.query.channel_code,
          },
        });
        break;
      default:
        break;
    }
  }
  private async getImgUrl() {
    const res = await api.getWxImgUrl({ type: this.$route.query.type || 3742 });
    console.log(res.data.data.url, 'rere');
    // const qr = new Qrious({
    //   element: document.getElementById('qr'),
    //   value: res.data.data.url,
    // });
    this.qrImg = res.data.data.url;
    const clipboard: any = new Clipboard('.copyBtn', {
      text: () => this.wechatNo,
    });

    clipboard.on('success', (e: any) => {
      Toast('复制成功');
    });
  }
}
